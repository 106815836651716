import React from 'react'

const emailSent = ({ width = '53', height = '61', fillIcon = '#a6b6bd', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 61" width={width} height={height} {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill={fillIcon} fillRule="nonzero">
          <path d="M39.6 23.114H4.4c-2.427 0-4.4 1.993-4.4 4.439v19.976c0 2.448 1.973 4.439 4.4 4.439h35.2c2.427 0 4.4-1.991 4.4-4.44V27.554c0-2.446-1.973-4.439-4.4-4.439zm-4.151 4.439L22 36.033l-13.449-8.48H35.45zM4.4 47.529v-17.36l16.434 10.364a2.19 2.19 0 002.332 0L39.6 30.17l.002 17.359H4.4zM19.8 8.499v10.176h4.4V8.499l5.045 5.087 3.11-3.139L22 0 11.645 10.447l3.11 3.139z" />
        </g>
        <circle fill="#E00280" cx="44" cy="52" r="9" />
        <path
          d="M40.806 51.129c-.638 0-1.16.523-1.16 1.161 0 .639.522 1.162 1.16 1.162.64 0 1.162-.523 1.162-1.162 0-.638-.523-1.161-1.162-1.161zm6.968 0c-.639 0-1.161.523-1.161 1.161 0 .639.522 1.162 1.161 1.162s1.161-.523 1.161-1.162c0-.638-.522-1.161-1.16-1.161zm-3.484 0c-.638 0-1.161.523-1.161 1.161 0 .639.523 1.162 1.161 1.162.639 0 1.162-.523 1.162-1.162 0-.638-.523-1.161-1.162-1.161z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default emailSent
