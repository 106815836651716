import { createSlice } from '@reduxjs/toolkit'
import constants from 'app/constants'
import requestService from 'common/services/request'
import { urlSearchParams } from '../common/util/urlSearchParams'
import { toastService } from 'api/toast'

const initialState = {
  comments: {
    data: [],
    status: constants.IDLE,
    error: null,
    pagination: null,
  },
}

const comments = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    /* Fetch comments */
    fetchCommentsStart: state => {
      state.comments.status = constants.LOADING
    },
    fetchCommentsSuccess: (state, action) => {
      const { results, pagination, fetchBehavior } = action.payload

      state.comments.status = constants.COMPLETE
      state.comments.pagination = pagination

      if (fetchBehavior === 'loadMore') {
        /*
         * There is a case where user post comments or replies we add those posted comments without re-fetching all comments
         * When the user load more comments the comments and replies we pushed will be fetched in the load more
         * To avoid redundancy we need to get only the comments that we are not already showing from the more loaded comments
         */

        let existing_comments_ids = state.comments.data.map(comment => comment.id)

        let non_redundant_results = results.filter(comment => !existing_comments_ids.includes(comment.id))

        state.comments.data = [...state.comments.data, ...non_redundant_results]
      }

      if (fetchBehavior === 'refresh') {
        state.comments.data = results
      }
    },
    fetchCommentsError: (state, action) => {
      state.comments.error = action.payload
      state.comments.status = constants.ERROR
    },

    /* Post comment */
    postCommentStart: state => {
      state.comments.status = constants.LOADING
    },
    postCommentSuccess: (state, action) => {
      const { postedComment } = action.payload

      state.comments.status = constants.COMPLETE
      state.comments.data = [postedComment, ...state.comments.data]
    },
    postCommentError: (state, action) => {
      state.comments.error = action.payload
      state.comments.status = constants.ERROR
    },

    /* Reply to a comment */
    replyToCommentStart: state => {
      state.comments.status = constants.LOADING
    },
    replyToCommentSuccess: (state, action) => {
      const { parentCommentId, postedReply } = action.payload

      // Find the index of the comment with the given commentId
      const parentCommentIndex = state.comments.data.findIndex(comment => comment.id === parentCommentId)

      // Insert the new comment right after his parent
      state.comments.data = [
        ...state.comments.data.slice(0, parentCommentIndex + 1),
        postedReply,
        ...state.comments.data.slice(parentCommentIndex + 1),
      ]

      state.comments.status = constants.COMPLETE
    },
    replyToCommentError: (state, action) => {
      state.comments.error = action.payload
      state.comments.status = constants.ERROR
    },

    /* Like and Unlike comment */
    likeCommentStart: (state, action) => {
      const { commentId } = action.payload
      const comment = state.comments.data.find(comment => comment.id === commentId)

      if (comment && !comment.is_liked) {
        comment.is_liked = true
        comment.likes_count += 1
      }
    },
    likeCommentError: (state, action) => {
      /* revert the like in case of error */

      const { commentId } = action.payload
      const comment = state.comments.data.find(comment => comment.id === commentId)

      if (comment) {
        comment.is_liked = false
        comment.likes_count -= 1
      }

      state.comments.error = action.payload
    },

    unlikeCommentStart: (state, action) => {
      const { commentId } = action.payload
      const comment = state.comments.data.find(comment => comment.id === commentId)

      if (comment && comment.is_liked) {
        comment.is_liked = false
        comment.likes_count -= 1
      }
    },
    unlikeCommentError: (state, action) => {
      /* revert the unlike in case of error */

      const { commentId } = action.payload
      const comment = state.comments.data.find(comment => comment.id === commentId)

      if (comment) {
        comment.is_liked = true
        comment.likes_count += 1
      }

      state.comments.error = action.payload
    },

    /* Report comment */
    reportCommentError: (state, action) => {
      state.comments.error = action.payload
      state.comments.status = constants.ERROR
    },
  },
})

export const {
  fetchCommentsStart,
  fetchCommentsSuccess,
  fetchCommentsError,
  postCommentStart,
  postCommentSuccess,
  postCommentError,
  replyToCommentStart,
  replyToCommentSuccess,
  replyToCommentError,
  likeCommentStart,
  likeCommentError,
  unlikeCommentStart,
  unlikeCommentError,
  reportCommentError,
} = comments.actions

export default comments.reducer

// Selectors:
export const selectComments = state => state.comments.comments.data
export const selectCommentsPagination = state => state.comments.comments.pagination
export const selectCommentsStatus = state => state.comments.comments.status

// Thunks:

export const fetchComments =
  (marketId, fetchBehavior = 'loadMore') =>
  async (dispatch, getState) => {
    /* behavior can be 'loadMore' or 'refresh' */

    const state = getState()

    const pageSize = state.comments.comments.pagination ? state.comments.comments.pagination.page_size : 20
    let offset = state.comments.comments.pagination ? state.comments.comments.pagination.offset : 0 // default offset value

    if (fetchBehavior === 'loadMore') {
      //increment offset
      if (state.comments.comments.pagination && state.comments.comments.pagination.next) {
        offset += pageSize
      }
    }

    const params = '?' + new urlSearchParams({ offset: offset, question: marketId }).toString()

    await dispatch(fetchCommentsStart())

    try {
      const response = await requestService.get(`/comments${params}`) //TODO: add marketId `/comments?marketId=${marketId}`
      const data = await response.json()

      dispatch(fetchCommentsSuccess({ ...data, ...{ fetchBehavior: fetchBehavior } }))
    } catch (err) {
      toastService('fail', {
        title: err.errors && err.errors[0].detail,
        body: err.errors && err.errors[0].code,
      })
      dispatch(fetchCommentsError(err))
    }
  }

export const postComment = (marketId, commentData) => async dispatch => {
  await dispatch(postCommentStart())

  try {
    const response = await requestService.post(`/comments/`, commentData)
    const data = await response.json()

    await dispatch(postCommentSuccess({ postedComment: data }))
  } catch (err) {
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    dispatch(postCommentError(err))
  }
}

export const replyToComment = (marketId, replyData) => async dispatch => {
  await dispatch(replyToCommentStart())

  try {
    const response = await requestService.post(`/comments/`, replyData)
    var data = await response.json()

    await dispatch(replyToCommentSuccess({ postedReply: data, parentCommentId: replyData.parent }))
  } catch (err) {
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    dispatch(replyToCommentError(err))
  }

  return data
}

export const likeComment = (marketId, commentId) => async dispatch => {
  await dispatch(likeCommentStart({ commentId: commentId }))

  try {
    await requestService.post(`/comments/${commentId}/like/`)
  } catch (err) {
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    dispatch(likeCommentError({ ...err, ...{ commentId: commentId } }))
  }
}

export const unlikeComment = (marketId, commentId) => async dispatch => {
  await dispatch(unlikeCommentStart({ commentId: commentId }))

  try {
    await requestService.post(`/comments/${commentId}/unlike/`)
  } catch (err) {
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    dispatch(unlikeCommentError({ ...err, ...{ commentId: commentId } }))
  }
}

export const toggleCommentVisibility = (marketId, commentId) => async dispatch => {
  await dispatch(unlikeCommentStart({ commentId: commentId }))

  try {
    await requestService.post(`/comments/${commentId}/unlike/`)
  } catch (err) {
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    dispatch(unlikeCommentError({ ...err, ...{ commentId: commentId } }))
  }
}

export const reportComment = (marketId, commentId, payload) => async dispatch => {
  try {
    await requestService.post(`/comments/${commentId}/flag/`, payload)
  } catch (err) {
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    dispatch(reportCommentError(err))
  }
}
