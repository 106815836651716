import React from 'react'

const eyeCrossed = ({ fill = 'currentColor', ...props }) => {
  return (
    <svg {...props} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8831 18.297C15.1241 19.4128 13.0832 20.0035 11.0001 20C5.60813 20 1.12213 16.12 0.182129 11C0.611817 8.67134 1.78268 6.54393 3.52013 4.93501L0.394129 1.80801L1.80813 0.394012L21.6071 20.192L20.1931 21.607L16.8831 18.297ZM4.93613 6.35001C3.57677 7.58547 2.62972 9.20867 2.22313 11C2.53542 12.3665 3.16239 13.6412 4.05413 14.7226C4.94587 15.8041 6.07775 16.6624 7.35968 17.2293C8.64161 17.7962 10.0382 18.0561 11.4382 17.9881C12.8383 17.9202 14.2031 17.5264 15.4241 16.838L13.3961 14.81C12.5328 15.3538 11.5103 15.5881 10.4964 15.4745C9.4824 15.3608 8.53717 14.9059 7.8157 14.1844C7.09423 13.463 6.63936 12.5177 6.52569 11.5038C6.41202 10.4898 6.64631 9.46732 7.19013 8.60401L4.93613 6.35001ZM11.9141 13.328L8.67213 10.087C8.49419 10.5399 8.45232 11.035 8.55163 11.5114C8.65095 11.9877 8.88715 12.4248 9.23125 12.7689C9.57535 13.113 10.0124 13.3492 10.4888 13.4485C10.9652 13.5478 11.4612 13.5059 11.9141 13.328ZM19.8071 15.593L18.3761 14.162C19.045 13.2094 19.5212 12.1353 19.7781 11C19.5064 9.80963 18.9954 8.68694 18.2762 7.70021C17.557 6.71348 16.6447 5.88331 15.5946 5.26017C14.5446 4.63704 13.3788 4.23395 12.1681 4.07542C10.9575 3.9169 9.7272 4.00624 8.55213 4.33801L6.97413 2.76001C8.22113 2.27001 9.58013 2.00001 11.0001 2.00001C16.3921 2.00001 20.8781 5.88001 21.8191 11C21.5129 12.6661 20.8241 14.2383 19.8071 15.593ZM10.7231 6.50901C11.3596 6.46968 11.9972 6.56608 12.5937 6.79183C13.1901 7.01758 13.7317 7.36752 14.1827 7.81846C14.6336 8.2694 14.9836 8.81104 15.2093 9.40747C15.4351 10.0039 15.5315 10.6415 15.4921 11.278L10.7221 6.50801L10.7231 6.50901Z"
        fill={fill}
      />
    </svg>
  )
}

export default eyeCrossed
