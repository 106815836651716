import React from 'react'
import classNames from 'classnames'
import './Loading.scss'

const LoadingDot = ({ index }) => (
  <g transform={`translate(${index * 10 + 4} 4)`}>
    <circle cx="0" cy="0" r="4" transform="scale(0)">
      <animateTransform
        begin={`${index * 300}ms`}
        dur="1.2s"
        repeatCount="indefinite"
        attributeName="transform"
        attributeType="XML"
        type="scale"
        values="0;1;0;0"
        keyTimes="0;.33;.66;1"
        keySplines=".2 .2 .4 .8;.2 .6 .4 .8;.2 .6 .4 .8"
        calcMode="spline"
      />
    </circle>
  </g>
)

const LoadingIndicator = ({ className, style, mode }) => (
  <svg
    version="1.1"
    viewBox="0 0 28 8"
    className={classNames('loading', className, {
      'loading--list': mode === 'list',
      'loading--overlay': mode === 'overlay',
      'loading--inline': mode === 'inline',
    })}
    style={style}>
    <LoadingDot index={0} />
    <LoadingDot index={1} />
    <LoadingDot index={2} />
  </svg>
)

export default LoadingIndicator
