import React, { createContext, useState, useContext } from 'react'
import classNames from 'classnames'
import './TabScrollView.scss'

const TabContext = createContext()

export default function TabScrollView({ name, initialActive, children, onContentChange, onScroll }) {
  const [currentTab, setTab] = useState(initialActive)

  const content =
    children
      .filter(tab => tab.props.tabKey === currentTab)
      .map(tab => tab.props.children)
      .pop() || null

  function changeTab(newTabKey) {
    setTab(newTabKey)
    if (onContentChange) {
      onContentChange(newTabKey)
    }
  }

  return (
    <TabContext.Provider
      value={{
        currentTab,
        changeTab,
      }}>
      <div className={classNames('tab-scroll-view', name)}>
        <nav className="tab-scroll-view__tabs">{children}</nav>
        <div className="tab-scroll-view__content" onScroll={onScroll}>
          {content}
        </div>
      </div>
    </TabContext.Provider>
  )
}

export function Tab({ label, tabKey, onTabSelected }) {
  const { currentTab, changeTab } = useContext(TabContext)
  const isActive = currentTab && currentTab === tabKey

  function handleClick() {
    if (currentTab && currentTab !== tabKey) {
      changeTab(tabKey)
      if (onTabSelected) {
        onTabSelected(tabKey)
      }
    }
  }

  return (
    <span
      className={classNames('tab', {
        'tab--active': isActive,
      })}
      onClick={handleClick}
      key={tabKey}>
      {label}
      {isActive && <span className="tab--underline"></span>}
    </span>
  )
}
