/* @flow */
import { CancelIcon } from 'common/components/icons'
import React from 'react'
class Fail extends React.PureComponent {
  handleClick() {
    const { onMessageClick } = this.props
    if (onMessageClick) {
      onMessageClick(process.env.REACT_APP_FRONT_URL)
    }
  }

  render() {
    const { title, body } = this.props
    return (
      <div className="message" onClick={this.handleClick.bind(this)}>
        <CancelIcon width="48px" />

        <div className="message__text">
          <div className="message__main-text">{title}</div>
          <div className="message__sub-text">{body}</div>
        </div>
      </div>
    )
  }
}

export default Fail
