import requestService from 'common/services/request'
import { createSlice } from '@reduxjs/toolkit'
import { transformArrayIntoMap } from 'common/util/mapArray'
import { getMarketType } from 'common/util/currencies'
import constants from 'app/constants'

const initialState = {
  pool: { data: {}, status: {}, error: {} },
  tree: {
    [constants.PLAY_MONEY]: { status: {}, data: {}, error: {} },
    [constants.REAL_MONEY]: { status: {}, data: {}, error: {} },
    ALL: { status: constants.IDLE, data: {}, error: {} },
  },
  root: {
    [constants.PLAY_MONEY]: { status: constants.IDLE, data: [], error: {} },
    [constants.REAL_MONEY]: { status: constants.IDLE, data: [], error: {} },
    ALL: { status: constants.IDLE, data: [], error: {} },
  },
}

// Reducers / Slices
const categories = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    getRootCategoriesStart: (state, action) => {
      const { marketType } = action.payload
      state.root[marketType].status = constants.LOADING
    },

    getRootCategoriesSuccess: (state, action) => {
      const { marketType, categories } = action.payload
      const categoriesObj = transformArrayIntoMap(categories)
      state.pool.data = { ...state.pool.data, ...categoriesObj }
      state.root[marketType].status = constants.COMPLETE
      state.root[marketType].data = categories.map(cat => cat.id)
    },
    getMainRootCategoriesSuccess: (state, action) => {
      const { categories } = action.payload
      state.root['ALL'].status = constants.COMPLETE
      state.root['ALL'].data = categories
    },

    getRootCategoriesError: (state, action) => {
      const { marketType, error } = action.payload
      state.root[marketType].error = error
      state.root[marketType].status = constants.ERROR
    },

    getCategoriesStart: (state, action) => {
      const { marketType, parentCategoryId } = action.payload
      state.tree[marketType].status[parentCategoryId] = constants.LOADING
    },

    getCategoriesSuccess: (state, action) => {
      const { marketType, category, children, parentCategoryId } = action.payload
      const data = transformArrayIntoMap(children)
      const categoryIds = children.map(el => el.id)

      if (!state.pool.data[category.id]) {
        state.pool.data[category.id] = {}
      }

      state.pool.data = { ...state.pool.data, ...data }
      state.pool.data[category.id] = { ...state.pool.data[category.id], ...category }
      state.tree[marketType].data[category.id] = categoryIds
      state.tree[marketType].status[parentCategoryId] = constants.COMPLETE
    },

    getCategoriesError: (state, action) => {
      const { marketType, parentCategoryId, error } = action.payload
      state.tree[marketType].status[parentCategoryId] = error
      state.tree[marketType].error[parentCategoryId] = error
    },

    clearCategories: state => {
      // eslint-disable-next-line
      state = { ...initialState }
    },

    addCategoriesToPool: (state, action) => {
      const { categories } = action.payload
      if (state.pool && state.pool.data) {
        const categoryMap = transformArrayIntoMap(categories)
        state.pool.data = { ...state.pool.data, ...categoryMap }
      }
    },
  },
})

// Selectors
export const selectCategoryRepository = () => state => state.categories.data

export const selectRootCategoriesStatus = marketType => state => {
  return state.categories.root[marketType].status
}

export const selectMainRootCategoryList = () => state => state.categories.root['ALL'].data

export const selectRootCategoryList = marketType => state => {
  const categoryList = state.categories.root[marketType].data
  if (categoryList) {
    return categoryList.map(id => state.categories.pool.data[id])
  }

  return []
}

export const selectCategoryById = categoryId => state => state.categories.pool.data[categoryId]

export const selectCategoryStatusById = (parentId, marketType) => state =>
  (state.categories.tree[marketType] && state.categories.tree[marketType].status[parentId]) || constants.IDLE

export const selectCategoriesByParentId = (parentId, marketType) => state => {
  const categoryList = state.categories.tree[marketType].data[parentId]
  if (!categoryList) {
    return []
  }

  return categoryList.map(id => state.categories.pool.data[id])
}

export const {
  getRootCategoriesStart,
  getRootCategoriesSuccess,
  getMainRootCategoriesSuccess,
  getRootCategoriesError,
} = categories.actions
export const { getCategoriesSuccess, getCategoriesStart, getCategoriesError, addCategoriesToPool, clearCategories } =
  categories.actions
export default categories.reducer

// Thunks
export const fetchRootCategories =
  (marketType, options = {}) =>
  async dispatch => {
    const currencyMode = getMarketType(marketType === constants.REAL_MONEY)
    const params = requestService.parseFilters({ ...options, currency_mode: currencyMode })
    dispatch(getRootCategoriesStart({ marketType }))
    try {
      var response = await requestService.get(`/categories/root/?${params.toString()}`)
    } catch (error) {
      dispatch(getRootCategoriesError({ marketType, error }))
      throw error
    }

    dispatch(getRootCategoriesSuccess({ marketType, categories: await response.json() }))
  }
export const fetchMainRootCategories = marketType => async dispatch => {
  dispatch(getRootCategoriesStart({ marketType: 'ALL' }))
  try {
    const currencyMode = getMarketType(marketType === constants.REAL_MONEY)
    var response = await requestService.get(`/categories/root_and_main_children/?currency_mode=` + currencyMode)
  } catch (error) {
    console.log(error)
    throw error
  }

  dispatch(getMainRootCategoriesSuccess({ categories: await response.json() }))
}

export const fetchCategories =
  (parentCategoryId, marketType, options = {}) =>
  async dispatch => {
    const currencyMode = getMarketType(marketType === constants.REAL_MONEY)
    const params = requestService.parseFilters({ ...options, currency_mode: currencyMode })

    dispatch(getCategoriesStart({ marketType, parentCategoryId }))
    try {
      var response = await requestService.get(`/categories/${parentCategoryId}/?` + params.toString())
    } catch (error) {
      dispatch(getCategoriesError({ marketType, parentCategoryId, error }))
      throw error
    }

    const { children, ...category } = await response.json()
    dispatch(getCategoriesSuccess({ marketType, parentCategoryId, category, children }))
  }
