/* @flow */
import * as React from 'react'
import classNames from 'classnames'

function LoginError({ error }) {
  let showError
  let errorMessage = null
  switch (error && error.status) {
    case 400:
      showError = false
      break
    case 401:
      showError = true
      errorMessage = 'Invalid email or password'
      break
    case 404:
      showError = false
      break
    case 429:
      showError = true
      errorMessage = 'Too many requests. Try again later'
      break
    case 500:
      showError = true
      errorMessage = 'Server communication failure'
      break
    default:
      showError = !!error
      errorMessage = error && error.message
  }

  const className = classNames('login__form__error', { hidden: !showError })

  return <div className={className}>{errorMessage}</div>
}

export default LoginError
