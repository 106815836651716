import store from 'store'
import { selectActiveLanguage } from 'store/app'

class SettingsService {
  getLanguage() {
    if (!store) return null
    const state = store.getState()
    return selectActiveLanguage()(state)
  }
}

const instance = new SettingsService()
export default instance
