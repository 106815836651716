import { useEffect } from 'react'
import { useCurrentUser } from 'common/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { urlSearchParams } from 'common/util/urlSearchParams'
import { signupFormUrl } from 'urls'
import { useDispatch } from 'react-redux'
import { updateCurrentUserSettings } from 'store/account'

const RouteController = props => {
  const location = useLocation()
  const navigate = useNavigate()
  const me = useCurrentUser()
  const dispatch = useDispatch()

  useEffect(() => {
    storeLang()
  }, [location])

  useEffect(() => {
    checkFirstAccess()
  }, [me])

  const storeLang = () => {
    const query = new urlSearchParams(location.search)
    if (query.has('lg')) {
      dispatch(updateCurrentUserSettings({ language: query.get('lg') }))
    }
  }

  const checkFirstAccess = () => {
    // Check if user profile is complete
    if (me && me.settings && !me.username) {
      navigate(signupFormUrl())
    }
  }

  return props.children
}

export default RouteController
