import React from 'react'

function SPEI({ ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
 
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="111.84 48.85 7874.03 2659.55">
      <path
        fill="#343084"
        d="M1590.45 853.28c239.8-12.21 467.61-30.53 703.82-41.04-67.9-498.98-308.88-763.39-1037.63-763.39-715.59 0-1026.31 367.85-1067.35 705.82-46.18 380.33 240.11 623.95 697.83 757.04 395.35 115.84 726.67 196.34 736.99 390.5 11.11 208.81-227.43 326.15-345.59 326.15-211.18 0-440.92-233.93-468.41-448.34-229.84 18.24-468.43 19.22-698.27 37.46 0 582.44 473.37 890.92 1101.01 890.92 585.3 0 1160.13-246.13 1160.13-875.57 0-764.21-826.87-763.98-1368.08-952.19-189.25-104.48-90.56-333.08 87.56-353.16 359.61-43.65 421.35 160.93 497.98 325.8zm2253.12-742.54c469.01 0 801.11 258.43 801.11 782.31 0 480.12-330.42 786.61-810.05 786.61H3235.6v919.38c0 63.16 0 63.16-55.78 63.16h-624.98c-78.25 0-78.25 0-78.25-58.77V219.89c0-109.15 0-109.15 109.83-109.15zm-547.73 1083.1c-44.31 0-60.24-18.19-60.24-45.69V654.26c0-36.22 29.56-56.57 56.57-56.57h262.58c226.01 33.35 308.74 155.81 308.74 315.48 0 228.46-203.65 280.67-380.54 280.67h-187.12z"></path>
      <path
        fill="#ff9400"
        d="M4815.26 181.3c0-32 22.97-56.49 48.29-56.49h1992.19c43.96-1.99 48.75 16.09 48.75 33.2v455.26c2.05 21.13-15.34 30.79-30.79 30.79H5612.23c-26.36 0-35.08 27.02-35.08 56.54v307.39c0 33.15 23.64 63.46 63.46 63.46h1116.16l25.47 25.47v426.87c-12.76 18.31-25.52 36.61-38.28 54.93H5619.21c-26.02 0-42.07 16.06-42.07 42.07v409.49c0 22.58 26.91 47.72 58.37 47.72h1243.51c16.44 0 25.46 15.65 25.46 25.46v497.34c0 11.7-13.23 24.48-24.48 24.48H4835.6c-10.84 0-20.34-10.7-20.34-20.34V181.31z"></path>
      <path
        fill="#343084"
        d="M7985.86 1535.24v1090.04h-709.39c-10.84 0-20.34-10.7-20.34-20.34v-973.8c0-26.08 18.02-41.09 31.48-41.09h203.66c20.41 0 25.57 17.46 25.57 32.87V2078c0 57.49 48.2 32.72 73.98 0 131.69-180.92 263.37-361.84 395.05-542.76z"></path>
      <path
        fill="#ff9400"
        d="M7225.12 181.3c0-32 22.97-56.49 48.29-56.49h651.17c45.11 0 61.28 27.62 61.28 49.42v930.78l-398.7-557.94c-8.61-12.07-28.31-11.29-28.31 13.7v401.85c0 22.38-11.06 64.84-42.93 64.84H7256.1c-14.16 0-31.01-31.95-31.01-50.23V181.3z"></path>
    </svg>
  )
}

export default SPEI
