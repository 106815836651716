/* @flow */

export type URLParams = {
  startIndex?: number,
  stopIndex?: number,
} & {
  [key: string]: ?(string | number),
}

const encodeParams = (params = {}) => {
  return Object.keys(params)
    .map(k => [k, params[k]].map(encodeURIComponent).join('='))
    .join('&')
}

/** Pagination query string params helper */
function convertPaginationParams({ startIndex, stopIndex, ...params }: URLParams) {
  const convertedParams = { ...params }

  if (startIndex) {
    convertedParams.offset = startIndex
  }
  if (startIndex != null && stopIndex != null) {
    convertedParams.limit = stopIndex - startIndex
  }

  return convertedParams
}

/** URL Query string params helper */
export function processParams(params: URLParams) {
  if (Object.keys(params).length === 0) {
    return ''
  }
  const convertedParams = convertPaginationParams(params)
  let clearedParams = {}
  for (const k in convertedParams) {
    if (convertedParams[k] !== null && convertedParams[k] !== undefined) {
      clearedParams[k] = convertedParams[k]
    }
  }
  return encodeParams(clearedParams)
}

/** URL helper */
export function processUrl(url: string, params: URLParams) {
  const processedParams = processParams(params)
  return processedParams ? `${url}?${processedParams}` : url
}
