import React from 'react'
import LoadingIndicator from '../LoadingIndicator'
import './SuspensePanel.scss'
import classNames from 'classnames'
const SuspensePanel = ({ page = false }) => {
  return (
    <div
      className={classNames('suspense', {
        suspense__page: page,
      })}
    >
      <LoadingIndicator mode="list" />
    </div>
  )
}
export default SuspensePanel
