/* @flow */

export type CancelablePromise = {
  promise: Promise<any>,
  cancel: Function,
}

export default function makeCancelable(promise: Promise<any>): CancelablePromise {
  let hasCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      value => !hasCanceled && resolve(value),
      error => !hasCanceled && reject(error)
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true
    },
  }
}
