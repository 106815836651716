import { useEffect, useRef, useState } from 'react'
import throttle from 'lodash.throttle'

const SCROLL_UP = 'up'
const SCROLL_DOWN = 'down'

const useScrollDirection = (threshold = 0) => {
  const [scrollDir, setScrollDir] = useState()
  const lastScrollPosition = useRef(0)

  const throttledScroll = throttle(scrollTop => {
    const refScrollTop = lastScrollPosition.current
    lastScrollPosition.current = scrollTop

    if (scrollTop < threshold) {
      setScrollDir(SCROLL_UP)
      return
    }

    if (scrollDir !== SCROLL_UP && scrollTop < refScrollTop) {
      setScrollDir(SCROLL_UP)
    } else if (scrollDir !== SCROLL_DOWN && scrollTop > refScrollTop) {
      setScrollDir(SCROLL_DOWN)
    }
  }, 250)

  const onScroll = event => {
    if (event.target) {
      throttledScroll(window.scrollY)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return scrollDir
}

export default useScrollDirection
