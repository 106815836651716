import React from 'react'
import { useState } from 'react'

const MeduimIcon = () => {
  const [hovered, setHovered] = useState(false)
  return (
    <div onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {hovered ? (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="24" fill="black" />
          <path
            d="M16 15H32C32.2652 15 32.5196 15.1054 32.7071 15.2929C32.8946 15.4804 33 15.7348 33 16V32C33 32.2652 32.8946 32.5196 32.7071 32.7071C32.5196 32.8946 32.2652 33 32 33H16C15.7348 33 15.4804 32.8946 15.2929 32.7071C15.1054 32.5196 15 32.2652 15 32V16C15 15.7348 15.1054 15.4804 15.2929 15.2929C15.4804 15.1054 15.7348 15 16 15ZM29.3 27.94C29.2 27.89 29.15 27.74 29.15 27.639V20.006C29.15 19.906 29.2 19.756 29.3 19.655L30.255 18.55V18.5H26.84L24.28 24.978L21.366 18.5H17.852V18.55L18.755 19.806C18.956 20.006 19.006 20.308 19.006 20.559V26.082C19.056 26.384 19.006 26.735 18.856 27.036L17.5 28.894V28.944H21.116V28.894L19.76 27.087C19.61 26.785 19.559 26.484 19.61 26.133V21.11C19.66 21.21 19.71 21.21 19.76 21.411L23.174 29.044H23.224L26.54 20.76C26.49 21.06 26.49 21.412 26.49 21.664V27.589C26.49 27.739 26.44 27.839 26.34 27.94L25.335 28.894V28.944H30.256V28.894L29.302 27.94H29.3Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 15H32C32.2652 15 32.5196 15.1054 32.7071 15.2929C32.8946 15.4804 33 15.7348 33 16V32C33 32.2652 32.8946 32.5196 32.7071 32.7071C32.5196 32.8946 32.2652 33 32 33H16C15.7348 33 15.4804 32.8946 15.2929 32.7071C15.1054 32.5196 15 32.2652 15 32V16C15 15.7348 15.1054 15.4804 15.2929 15.2929C15.4804 15.1054 15.7348 15 16 15V15ZM29.3 27.94C29.2 27.89 29.15 27.74 29.15 27.639V20.006C29.15 19.906 29.2 19.756 29.3 19.655L30.255 18.55V18.5H26.84L24.28 24.978L21.366 18.5H17.852V18.55L18.755 19.806C18.956 20.006 19.006 20.308 19.006 20.559V26.082C19.056 26.384 19.006 26.735 18.856 27.036L17.5 28.894V28.944H21.116V28.894L19.76 27.087C19.61 26.785 19.559 26.484 19.61 26.133V21.11C19.66 21.21 19.71 21.21 19.76 21.411L23.174 29.044H23.224L26.54 20.76C26.49 21.06 26.49 21.412 26.49 21.664V27.589C26.49 27.739 26.44 27.839 26.34 27.94L25.335 28.894V28.944H30.256V28.894L29.302 27.94H29.3Z"
            fill="currentColor"
          />
          <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="currentColor" strokeOpacity="0.48" />
        </svg>
      )}
    </div>
  )
}

export default MeduimIcon
