import { useEffect, useRef } from 'react'
import requestService from 'common/services/request'
import Pusher from 'pusher-js'
import { useCurrentUser } from 'common/hooks/useCurrentUser'

const userRealTimeDepositStatus = ({ onReceiveMessage }) => {
  const currentUser = useCurrentUser()
  const userId = currentUser && currentUser.id ? currentUser.id : 0
  const pusherSocket = useRef()

  useEffect(() => {
    if (!userId) {
      return
    }

    if (!process.env.REACT_APP_PUSHER_APP_KEY || !process.env.REACT_APP_PUSHER_APP_CLUSTER) {
      return
    }

    setupPusherConnection()
    return () => {
      if (pusherSocket.current) {
        console.log('connection lost')

        pusherSocket.current.disconnect()
      }
    }
  }, [userId])

  const setupPusherConnection = async () => {
    const authorizationToken = await requestService.getAuthorizationToken()
    if (!authorizationToken) {
      throw '[PUSHER] Unauthorized: user is not logged in'
    }

    const channelName = `private-user-${currentUser.id}`

    pusherSocket.current = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: true,
      authEndpoint: requestService.getRequestUrl('/pusher/auth/'),
      auth: {
        headers: {
          Authorization: authorizationToken,
        },
      },
    })
    let pusherChannel = pusherSocket.current.subscribe(channelName)
    pusherChannel.bind('deposit-success', data => {
      onReceiveMessage(data)
    })
  }
}

export default userRealTimeDepositStatus
