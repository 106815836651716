import { Subject } from 'rxjs'
import { getCredential } from 'api/auth'
import requestService from 'common/services/request'
import { urlSearchParams } from 'common/util/urlSearchParams'
import {
  FeedbackSent,
  UserUnfollow,
  UserFollow,
  WagerBuy,
  WagerSell,
  WagerSellAll,
  Withdraw,
  PushNotification,
  MarketFollow,
} from 'notifications/components/message-types'

const state = new Subject()

async function getAll({ startIndex, stopIndex, ...filters }) {
  const params = new urlSearchParams(filters)
  if (startIndex) {
    params.set('offset', startIndex)
  }
  if (stopIndex) {
    params.set('limit', stopIndex - startIndex)
  }

  const response = await requestService.get(`/notifications/?${params.toString()}`)

  if (response.ok) {
    return response.json()
  }

  throw new Error(`server error: ${response.status}`)
}

class NotificationService {
  getAll(filters) {
    const params = new urlSearchParams(filters)
    return new Promise((resolve, reject) => {
      requestService.get(`/notifications/?${params.toString()}`).then(response => {
        if (response.status !== 200) {
          return reject(new Error(`server error${response.status}`))
        }
        return resolve(response.json())
      })
    }).then(notifications => notifications.results)
  }

  update(notification) {
    return new Promise((resolve, reject) => {
      requestService.patch(`/notifications/${notification.id}/`, notification).then(response => {
        if (response.status !== 200) {
          return reject(new Error(`server error${response.status}`))
        }
        return resolve(response.json())
      })
    })
  }

  pushClient(type, dataProps) {
    const component = this.from(type)
    state.next({
      component,
      dataProps,
    })
  }

  subscribe(func) {
    return state.subscribe(func)
  }

  from(typeString) {
    switch (typeString) {
      case 'wagerBuy':
        return WagerBuy
      case 'wagerSell':
        return WagerSell
      case 'wagerSellAll':
        return WagerSellAll
      case 'feedbackSent':
        return FeedbackSent
      case 'userFollow':
        return UserFollow
      case 'withdraw':
        return Withdraw
      case 'userUnfollow':
        return UserUnfollow
      case 'pushNotification':
        return PushNotification
      case 'marketFollow':
        return MarketFollow
      case 'marketUnfollow':
        return MarketFollow
      case 'currencyChanged':
        return
      case 'newFollower':
        return new Error('Invalid parameter to push method of NotificationService: ' + typeString)
      default:
        return new Error('Invalid parameter to push method of SnackBarService: ' + typeString)
    }
  }
}

const instance = new NotificationService()
export default instance

class NotificationResource {
  id = null
  message = ''
  is_read = null

  user_following = {}

  pk() {
    return this.id
  }

  static urlRoot = process.env.REACT_APP_API_URL + '/v1/notifications/'

  static fetchPlugin = async request => {
    const credential = await getCredential()
    request.set('Authorization', `${credential.tokenType} ${credential.accessToken}`)
  }

  static listRequest() {
    return {
      ...super.listRequest(),
      schema: { results: [this.getEntitySchema()] },
    }
  }
}

export { NotificationResource, instance as NotificationService, getAll }
