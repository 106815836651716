import React from 'react'
import { RightArrowIcon } from '../icons'
import './Breadcrumb.scss'

const Breadcrumb = ({ items, containsMainTitle, showLastArrow = false }) => {
  const getItemContent = (item, lastItem) => {
    const link = (
      <a className="breadcrumb__link" itemProp="item" href={item.href}>
        <span itemProp="name">{item.value}</span>
      </a>
    )

    return containsMainTitle && lastItem ? <h1>{link}</h1> : link
  }

  return (
    <ol className="breadcrumb" itemScope itemType="https://schema.org/BreadcrumbList">
      {items.map((el, idx) => {
        return (
          <React.Fragment key={'breadcrumb-item-' + idx}>
            <li
              className="breadcrumb__item"
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              {getItemContent(el, idx === items.length - 1)}
              <meta itemProp="position" content={idx + 1} />
            </li>
            {(idx < items.length - 1 || showLastArrow) && (
              <span className="breadcrumb__sep">
                <RightArrowIcon width="5px" />
              </span>
            )}
          </React.Fragment>
        )
      })}
    </ol>
  )
}

export default Breadcrumb
