import messaging from 'push-notification'
import { getToken, getMessaging } from 'firebase/messaging'
import { registerPushNotification } from 'store/notifications'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import storage from 'common/util/storage'
import { toastService } from 'api/toast'

const useWebPushNotification = () => {
  const dispatch = useDispatch()

  const [shouldAskPermission, setShouldAskPermission] = useState(false)

  useEffect(() => {
    if (!isSupported() && typeof Notification === 'undefined') return
    if (Notification) {
      setShouldAskPermission(Notification.permission === 'default')
    } else {
      setShouldAskPermission(false)
    }

    if (isNotificationGranted()) {
      onMessageListener()
    }
  }, [shouldAskPermission])

  const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window
  // const EnsureTokenFreshness = async () => {
  //   // TODO : replace in settings
  //   const EXPERATION_DELAY = 30
  //   const fcmToken = await storage('FCM_TOKEN').get()
  //   let shouldRenewToken = false
  //   // ? when user clear localStorage
  //   if (!fcmToken) {
  //     shouldRenewToken = true
  //   } else {
  //     const tokenDate = await dispatch(checkFCMTokenExpiration(fcmToken.token))

  //     if (tokenDate && tokenDate.last_renew && tokenDate.last_renew.length > 0) {
  //       console.log('Date of token : ' + tokenDate.last_renew[0])
  //       // ? if permission is granted we check if we need to renew the token based on how old he was created
  //       const dateDiffrences = Math.ceil(
  //         Math.abs(new Date() - new Date(tokenDate.last_renew[0])) / (1000 * 60 * 60 * 24)
  //       )
  //       shouldRenewToken = dateDiffrences > EXPERATION_DELAY
  //     } else {
  //       shouldRenewToken = true
  //     }
  //   }

  //   if (shouldRenewToken) {
  //     await askForNotificationPermissions()
  //   }
  // }

  const isPushNotificationSupported = () => {
    return 'serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window
  }
  // const shouldCheckForTokenExpiration = async () => {
  //   const fcm = await storage('FCM_TOKEN').get()
  //   return fcm !== null
  // }
  const isNotificationGranted = () => {
    if (!isSupported()) return
    return Notification && Notification.permission === 'granted'
  }

  const shouldAskForNotification = () => {
    return isPushNotificationSupported() && shouldAskPermission
  }

  const pushSubscription = async () => {
    setShouldAskPermission(false)
    const token = await retrieveDeviceToken()
    if (token) {
      // if there is old token that's mean we are re-newing
      const oldTokenData = await storage('FCM_TOKEN').get()
      const oldToken = oldTokenData !== null ? oldTokenData.token : null
      dispatch(registerPushNotification('web', token, oldToken))
      storage('FCM_TOKEN').set({
        token,
        date: new Date(),
      })
    }
  }

  const retrieveDeviceToken = async () => {
    try {
      const messaging = getMessaging()
      let currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID,
      })
      return currentToken
    } catch (error) {
      console.log('An error occurred while retrieving token. ', error)
    }
  }

  const onMessageListener = () => {
    messaging?.onMessage(payload => {
      toastService('pushNotification', payload && payload.notification)
    })
  }
  // const shouldAddTokenForUser = async () => {
  //   const fcmToken = await storage('FCM_TOKEN').get()
  //   if (fcmToken !== null) {
  //     const tokenDate = await dispatch(checkFCMTokenExpiration(fcmToken.token))
  //     return tokenDate.last_renew === null
  //   }
  //   return true
  // }
  // const AddTokenForUser = async () => {
  //   const token = await retrieveDeviceToken()
  //   dispatch(registerPushNotification('web', token))
  // }
  return {
    pushSubscription,
    isPushNotificationSupported,
    onMessageListener,
    shouldAskForNotification,
    isNotificationGranted,
    // shouldCheckForTokenExpiration,
    // EnsureTokenFreshness,
    // shouldAddTokenForUser,
    // AddTokenForUser,
  }
}
export default useWebPushNotification
