import constants from 'app/constants'
import React from 'react'
import './HotTopic.scss'
import Slider from 'common/ui/slider'
const HotTopic = ({ banners, bannersLoading, slidable = false }) => {
  const hotTopicBanners = banners.filter(b => b.hot_topic)

  const onRenderSegments = () => {
    const banners = [1, 2, 3]
    return (
      <>
        {banners.map(b => (
          <div className="categories__banners--card" key={'categories__banners--card' + b}>
            <div className="categories__banners--card__image skeleton"></div>
            <div className="categories__banners--card__footer">
              <div>
                <h3 className="skeleton"></h3>
                <h4 className="skeleton"></h4>
              </div>
              <h5 className="skeleton"></h5>
            </div>
          </div>
        ))}
      </>
    )
  }
  const hotTopicListUI = () =>
    hotTopicBanners
      .filter(banner => banner.open_markets_count)
      .map(banner => (
        <a className="categories__banners--card  " key={'categories__banners--card' + banner.id} href={banner.link}>
          <img className="categories__banners--card__image " src={banner.mobile_thumbnail_webp} alt="" />
          {/* <div className="categories__banners--card__image">
    </div> */}
          <div className="categories__banners--card__footer ">
            <div>
              <h3>{banner.title}</h3>
              <span>{banner.open_markets_count} markets</span>
            </div>
            {/* <div className="link">
        <span>
          <ArrowRightUp width="16px" />
        </span>
        <span>View hot topic</span>
      </div> */}
          </div>
        </a>
      ))

  if ((!hotTopicBanners || !hotTopicBanners.length) && bannersLoading === constants.COMPLETE) return null
  return (
    <div className="categories__banners--list">
      {(!banners || !hotTopicBanners || !hotTopicBanners.length) && onRenderSegments()}
      {hotTopicBanners &&
        Boolean(hotTopicBanners.length) &&
        (slidable ? (
          <Slider
            settings={{
              slidesPerView: 1.5,
              centeredSlides: false,
              spaceBetween: 10,
            }}>
            {hotTopicListUI()}
          </Slider>
        ) : (
          <>{hotTopicListUI()}</>
        ))}
    </div>
  )
}

export default HotTopic
