/* @flow */

type orientationType =
  | 'default'
  | 'portrait'
  | 'portrait-primary'
  | 'portrait-secondary'
  | 'landscape'
  | 'landscape-primary'
  | 'landscape-secondary'

type orientationParam = orientationType | Array<orientationType>

const { screen } = global

const dummy = (orientation: orientationParam) => {
  console.warn(`[Screen] Can't lock the orientantion at "${orientation.toString()}"`)
}

export default function orientationLock(orientation: orientationParam): Promise<void> {
  return new Promise(resolve => {
    if (screen.orientation && screen.orientation.lock) {
      // Chrome: Prevent promisse unhandled exception on desktops
      screen.orientation.lock(orientation).catch(() => null)
      resolve()
    } else {
      const lockOrientation = screen.lockOrientation || screen.mozLockOrientation || screen.msLockOrientation || dummy
      lockOrientation(orientation)
      resolve()
    }
  })
}
