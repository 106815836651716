import React from 'react'

const cancelIcon = ({ fill = '#E00280', ...props }) => {
  return (
    <svg {...props} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.80791 2.65076C5.37391 0.0920708 9.54912 0.0920708 12.1151 2.65076C14.6811 5.20945 14.6811 9.37277 12.1151 11.9315C9.54912 14.4902 5.37391 14.4902 2.80791 11.9315C0.241906 9.37277 0.241906 5.20945 2.80791 2.65076ZM3.73863 3.57883C1.68592 5.62569 1.68592 8.95654 3.73863 11.0034C5.79133 13.0503 9.1317 13.0503 11.1844 11.0034C13.2371 8.95654 13.2371 5.62569 11.1844 3.57883C9.1317 1.53197 5.79133 1.53197 3.73863 3.57883ZM5.60007 4.5069L7.46152 6.36304L9.32296 4.5069L10.2537 5.43497L8.39224 7.29111L10.2537 9.14725L9.32296 10.0753L7.46152 8.21918L5.60007 10.0753L4.66935 9.14725L6.53079 7.29111L4.66935 5.43497L5.60007 4.5069Z"
        fill={fill}
      />
    </svg>
  )
}

export default cancelIcon
