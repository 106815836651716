import React from 'react'

const SkrillIcon = ({ size = 118, ...props }) => {
  return (
    <svg width={size} height={size / 2.95} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M83.12 9.61c2.655 0 5.807-2.15 5.807-4.803A4.806 4.806 0 1084.12 9.61h-1zM41.754 21.73V2.187l-10.06 1.988v35.422h10.06V28.648c2.913 4.37 4.352 10.95 4.352 10.95h12.05c-1.192-4.956-6.406-14.092-6.406-14.092 4.68-5.933 6.735-12.255 7.062-13.302H47.306c-.217.545-1.778 4.955-5.553 9.525zm18.416 2.473v15.395h9.83V27.043c0-4.812.63-6.875 6.34-7.071v-7.997c-.961-.204-2.586-.14-2.586-.14-9.01.289-13.584 4.331-13.584 12.367zM10.736 13.271c0-3.099 4.108-3.099 5.648-3.099 2.709 0 6.217.8 8.722 1.55 0 0 1.397.492 2.587.997l.11.03V4.262l-.152-.045c-2.956-1.035-6.394-2.03-12.768-2.03C3.893 2.187 0 8.59 0 14.074c0 3.161 1.355 10.602 13.94 11.46 1.068.066 3.9.229 3.9 2.883 0 2.19-2.319 3.483-6.218 3.483-4.273 0-8.418-1.09-10.935-2.123v8.741C4.456 39.506 8.704 40 13.684 40c10.745 0 15.557-6.057 15.557-12.06 0-6.807-5.385-11.23-14.42-11.835-1.232-.081-4.085-.266-4.085-2.834zm68.512 26.326h9.75V12.235h-9.75v27.362zm28.11 0h9.752V2.187l-9.752 1.745v35.665zm-4.487-37.41v37.41h-9.743V3.932l9.743-1.745z"
        fill="#97A4AA"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SkrillIcon
