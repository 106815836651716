/* @flow */
import React from 'react'
import { ShareIcon } from 'common/components/icons'
import Image from 'common/components/Image'
import Device from 'common/util/Device'
import Currency from 'common/currency'
//FIXME: This import is horrendous, but we have no time left
import shareService from 'social/services/share'
import { questionDetailUrl, keepQueryString } from 'urls'

import { i18n } from 'common/locale/i18n'
import { Link } from 'react-router-dom'
import { useCurrentRouteName } from 'common/hooks'

const WagerBuy = props => {
  const currentPage = useCurrentRouteName()
  const isMarketPage = currentPage === 'Market page'
  const { outcome, question, total_amount, actions, currency } = props

  const handleShare = event => {
    event.preventDefault()
    event.stopPropagation()
    const shareUrl = questionDetailUrl(props.question)
    shareService.share({ url: shareUrl })
  }

  let out = !isFinite(outcome) ? outcome : question.outcomes.filter(o => o.id === outcome)[0]
  const amount = Array.isArray(actions) && actions.length ? actions[actions.length - 1].amount : total_amount
  const sharingAvailable =
    !Device.isCordova && window.plugins && window.plugins.socialsharing && window.plugins.socialsharing.shareWithOptions
  return (
    <a className="message" href={keepQueryString(questionDetailUrl(question))}>
      <Image className="message__image message__image--round" src={out.thumbnail_2x_webp} alt={outcome.title} />

      <div className="message__text WagerBuy" style={isMarketPage ? { justifyContent: 'center' } : {}}>
        <div className="message__main-text">
          <div>{i18n('place_bet.bought')}</div>
          <div className="message__main--second-text">
            {i18n('place_bet.bet_made') + ' '}
            <em>
              <Currency value={amount} currency={currency} showIcon={false} />
            </em>
            {' on '} <em>{out.title}</em>
          </div>
        </div>
        {!isMarketPage && (
          <div className="message__sub-text">
            <Link to={keepQueryString(questionDetailUrl(question))}>{i18n('place_bet.view_market_details')}</Link>
          </div>
        )}
      </div>

      {sharingAvailable &&
        (!Device.isCordova ? null : (
          <span className="message__icon message__icon--share" onClick={handleShare}>
            <ShareIcon />
          </span>
        ))}
    </a>
  )
}

export default WagerBuy
