import React from 'react'

const Transfer = ({ width = 16, height = 17, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.375 8.54134L15.5 12.6663L11.375 16.7913L10.1967 15.613L12.31 13.4988L1.33333 13.4997V11.833H12.31L10.1967 9.71967L11.375 8.54134ZM4.625 0.208008L5.80333 1.38634L3.69 3.49967H14.6667V5.16634H3.69L5.80333 7.27967L4.625 8.45801L0.5 4.33301L4.625 0.208008Z" />
    </svg>
  )
}

export default Transfer
