import format from 'date-fns/format'
import localePtBr from 'date-fns/locale/pt-BR'
import settingsService from '../services/settings'
import parseISO from 'date-fns/parseISO'
import dateFnsFormatDistance from 'date-fns/formatDistance'

// TODO: fix typo on filename!!!
export function formatDate(date, dateFormat) {
  const locale = settingsService.getLanguage()
  const getDateFormat = () => {
    if (locale.indexOf('pt') >= 0) {
      return "dd 'de' MMMM, y 'às' HH'h'mm"
    }

    return "MMMM dd, y 'at' HH':'mm"
  }

  let formatString = dateFormat || getDateFormat()
  let parsedDate
  if (typeof date === 'string') {
    parsedDate = parseISO(date)
  } else {
    parsedDate = date
  }

  if (!parsedDate) return null
  if (locale.indexOf('pt') >= 0) {
    return format(parsedDate, formatString, { awareOfUnicodeTokens: true, locale: localePtBr })
  } else {
    return format(parsedDate, formatString, { awareOfUnicodeTokens: true })
  }
}

export function formatDistance(date1, date2) {
  let locale = settingsService.getLanguage()
  if (locale === 'pt-br') {
    return dateFnsFormatDistance(date1, date2, { locale: localePtBr })
  } else {
    return dateFnsFormatDistance(date1, date2)
  }
}
