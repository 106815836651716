import React from 'react'
import Button from 'common/ui/button'
import './GoToWebVersion.scss'
import { CrossIcon, FutuurIconB } from 'common/components/icons'
import { i18n } from 'common/locale/i18n'

const GoToWebVersion = ({ onClose }) => {
  const onClickButton = () => {
    window.open('https://futuur.com', '_blank')
  }

  return (
    <aside className="go-to-web-version">
      <div className="go-to-web-version__container">
        <i className="go-to-web-version__futuur-logo-container">
          <FutuurIconB width="1em" fillcolor="currentColor" />
        </i>
        <div className="go-to-web-version__content">
          <i className="go-to-web-version__close" onClick={onClose}>
            <CrossIcon width="1em" />
          </i>
          <h4 className="go-to-web-version__title">{i18n('go_to_web_version.title')}</h4>
          <p>{i18n('go_to_web_version.p')}</p>
          <Button onClick={onClickButton} size="small" variant="primary" type="text-only">
            {i18n('go_to_web_version.cta')}
          </Button>
        </div>
      </div>
    </aside>
  )
}

export default GoToWebVersion
