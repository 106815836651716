import React from 'react'
import Modal from 'common/components/modal/Modal'
import ActionSheetLayout from 'layouts/components/action-sheet-layout'
import { i18n } from 'common/locale/i18n'
import { useState } from 'react'
import Device from 'common/util/Device'
import './AppUpdateRequest.scss'
const AppUpdateRequest = () => {
  const [open, setOpen] = useState(true)
  const onClose = () => {
    setOpen(false)
  }
  const renderTitle = () => {
    return <div>{i18n('update_request.need_update')}</div>
  }
  const renderContent = () => {
    return (
      <div className="update_request">
        <div className="update_request__image"></div>
        <div className="update_request__title">{i18n('update_request.title')}</div>
        <div className="update_request__description">{i18n('update_request.description')}</div>
      </div>
    )
  }
  const goToPlayStore = () => {
    if (Device.isAndroid) {
      window.open('https://play.google.com/store/apps/details?id=id=com.futuur.app', '_system')
    }
    if (Device.isIOS) {
      window.open('https://apps.apple.com/tt/app/futuur/id1289492137', '_system')
    }
  }
  return (
    <Modal darkOverlay isOpen={open} position="bottom" onCloseRequested={onClose}>
      <ActionSheetLayout
        title={renderTitle()}
        content={renderContent()}
        onButtonClick={() => goToPlayStore()}
        showCloseButton={true}
        action={i18n('update_request.update')}
        onClose={onClose}
      />
    </Modal>
  )
}
export default AppUpdateRequest
