import React from 'react'

const GlobeIcon = ({ fillcolor = 'currentColor', ...props }) => {
  return (
    <svg {...props} fill={fillcolor || 'currentColor'} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM16.355 13.952V13.847C16.355 12.925 16.355 12.504 15.703 12.131C15.4934 12.0122 15.2782 11.9037 15.058 11.806C14.691 11.639 14.448 11.53 14.12 11.05C14.0808 10.993 14.0422 10.9357 14.004 10.878C13.659 10.353 13.41 9.975 12.462 10.125C10.597 10.421 10.459 10.749 10.377 11.303L10.364 11.394C10.243 12.204 10.221 12.476 10.559 12.831C11.824 14.158 12.582 15.115 12.812 15.675C12.924 15.948 13.212 16.775 13.014 17.593C14.2278 17.1095 15.3083 16.3425 16.165 15.356C16.275 14.982 16.355 14.516 16.355 13.952ZM10 1.833C7.683 1.833 5.59 2.799 4.104 4.349C4.281 4.472 4.435 4.645 4.541 4.883C4.745 5.34 4.745 5.811 4.745 6.228C4.745 6.556 4.745 6.868 4.85 7.093C4.994 7.401 5.616 7.533 6.165 7.647C6.362 7.689 6.564 7.731 6.748 7.782C7.254 7.922 7.646 8.377 7.959 8.742C8.089 8.893 8.282 9.116 8.379 9.172C8.429 9.136 8.59 8.961 8.669 8.674C8.731 8.454 8.713 8.26 8.624 8.154C8.064 7.494 8.095 6.224 8.268 5.755C8.54 5.016 9.39 5.071 10.012 5.111C10.244 5.126 10.462 5.141 10.626 5.12C11.248 5.042 11.44 4.095 11.575 3.91C11.867 3.51 12.761 2.907 13.315 2.535C12.2715 2.07099 11.142 1.83181 10 1.833Z" />
    </svg>
  )
}

export default GlobeIcon
