import React, { useEffect, useState } from 'react'
import FilterDropdown from 'common/components/filter-dropdown/FilterDropdown'
import { i18n } from 'common/locale/i18n'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import './FilterDropdownPanel.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectRootCategoryList } from 'store/categories'
import {
  selectAbTestsStatus,
  selectActiveCurrency,
  selectActiveMarketType,
  setMarketType,
  storeActiveCurrency,
} from 'store/app'
import constants from 'app/constants'
import { useABTests, useCurrencies, useCurrentUser } from 'common/hooks'
import CurrencyIcon from '../currencyIcon' 
import Modal from 'common/components/modal/Modal'
import ActionSheetLayout from 'layouts/components/action-sheet-layout'
import { DotIcon, FiltersIcon } from '../icons'
import Button from 'common/ui/button'
import { urlSearchParams } from 'common/util/urlSearchParams'
import { RadioControl, ToggleControl } from '../form-controls'
import useIsMobileSize from 'common/hooks/useIsMobileSize'

const FilterDropdownPanel = ({
  className = '',
  containerClass = '',
  queryParams = {},
  onChange,
  filterOf = 'all',
  page = 'feed',
  setOnChangeFilters = [],
  statusList = [],
  currencies,
  ...props
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const activeMarketType = useSelector(selectActiveMarketType)
  const currentUser = useCurrentUser()
  const _currencies = useCurrencies().currencies
  const categories = useSelector(selectRootCategoryList(activeMarketType))
  const [activeCurrencyMode, setLocalActiveCurrencyMode] = useState(
    useSelector(selectActiveCurrency) == 'OOM' ? constants.PLAY_MONEY : constants.REAL_MONEY
  )
  const isMobileSize = useIsMobileSize();

  const abTestsStatus = useSelector(selectAbTestsStatus)
  const { getABTestSegment } = useABTests()

  const [categoriesSelected, setCategoriesSelected] = useState(props.categoriesSelected)
  const [currenciesSelected, setCurrenciesSelected] = useState(props.currenciesSelected)
  const [marketStatusSelected, setMarketStatusSelected] = useState(props.marketStatusSelected)
  const [modalOpen, setModalOpen] = useState(false)

  const sortByOptions = [
    { value: '-created_on', text: i18n('markets.most_recent') },
    { value: 'bet_end_date', text: i18n('markets.bet_end_date') },
    { value: '-wagers_count', text: i18n('markets.number_of_bets') },
    { value: '-volume', text: i18n('markets.volume') },
    { value: '-relevance', text: i18n('markets.relevance') },
  ]
  useEffect(() => setMarketStatusSelected(props.marketStatusSelected), [props.marketStatusSelected])

  useEffect(() => {
    /**
     * An async function to get query parameters from the URL,
     * and set a default ordering if not present.
     */
    const getQueries = () => {
      const _queryParams = Object.fromEntries(new urlSearchParams(location.search))

      const feedDefaultSort = getABTestSegment('feed-sort') || ''

      if (!_queryParams.ordering) {
        _queryParams.ordering = feedDefaultSort
      }

      return _queryParams
    }

    if (abTestsStatus === constants.COMPLETE) {
      const query = getQueries()
      queryParams.ordering = query.ordering
    }
  }, [location.search, abTestsStatus])

  const categorieOptions = categories
    .filter(cat => cat !== undefined)
    .map(cat => ({ value: cat.id + '', text: cat.title }))

  // if (page !== 'portfolio') categorieOptions.unshift({ value: '', text: i18n('filters.default_category') })
  const currencySelector = [
    {
      value: constants.REAL_MONEY,
      text: i18n('Real_money'),
    },
    {
      value: constants.PLAY_MONEY,
      text: i18n('Play_money'),
    },
  ]

  if (page === 'portfolio') {
    queryParams.currency_mode = activeCurrencyMode === 'OOM' ? constants.PLAY_MONEY : constants.REAL_MONEY
  }
  const getCatagoryValue = () => { 
    const selectedCat = categoriesSelected || (queryParams.categories ? queryParams.categories.split(',') : null)
    return !selectedCat ? categorieOptions.map(c => c.value) : selectedCat
  }

  const changeSearchFilter = (filter, value) => {
    const params = {}
    if (queryParams.currency_mode) params.currency_mode = queryParams.currency_mode
    if (queryParams.currenciesSelected) params.currenciesSelected = queryParams.currenciesSelected
    if (queryParams.resolved_and_sold) params.resolved_and_sold = queryParams.resolved_and_sold
    if (queryParams.market_status) params.market_status = queryParams.market_status
    if (queryParams.live_soon) params.live_soon = queryParams.live_soon
    if (queryParams.live) params.live = queryParams.live
    if (queryParams.resolved_only) params.resolved_only = queryParams.resolved_only
    if (queryParams.hide_my_bets) params.hide_my_bets = queryParams.hide_my_bets
    if (queryParams.only_markets_i_follow) params.only_markets_i_follow = queryParams.only_markets_i_follow
    if (queryParams.people_i_follow) params.people_i_follow = queryParams.people_i_follow
    if (queryParams.ordering) params.ordering = queryParams.ordering
    if (queryParams.categories) params.categories = queryParams.categories
    if (queryParams.type) params.type = queryParams.type
    if (queryParams.q) params.q = queryParams.q

    value ? (params[filter] = value) : delete params[filter]

    if (!isMobileSize && page !== 'portfolio') {
      setOnChangeFilters.forEach(field => {
        field.action(queryParams[field.filter])
      })
    }
    // Inverted filters : one cancels the other
    if (filter === 'live_soon' || filter === 'live' || filter === 'all') {
      delete params.resolved_only
      delete params.resolved_and_sold
    }
    if (filter === 'resolved_only' || filter === 'resolved_and_sold' || filter === 'all') {
      delete params.live_soon
      delete params.live
    }
    if (!params.categories || params.categories?.length <= 0) delete params.categories
    const filterTouched = setOnChangeFilters.find(field => field.filter === filter)
    if (!isMobileSize && filterTouched && filterTouched.action) {
      filterTouched.action(params[filter])
    }
    if (page !== 'portfolio') navigate(location.pathname + '?' + new URLSearchParams(params).toString())
    if (onChange && !isMobileSize) onChange()
  }
  const onChangeFilter = (filter, value) => {
    changeSearchFilter(filter, value)
  }
  const changeCurrencyMode = currency_mode => {
    dispatch(storeActiveCurrency(currency_mode === constants.REAL_MONEY ? constants.CANONICAL_CURRENCY : 'OOM'))
    setLocalActiveCurrencyMode(currency_mode)
    queryParams.currency_mode = currency_mode
    dispatch(setMarketType(currency_mode))
  }

  const shouldShowFilter = filter => {
    switch (filter) {
      case 'sort_by':
        return ['all', 'markets'].includes(filterOf) && page !== 'portfolio'
      case 'currencies':
        return page === 'portfolio'
      case 'currency_mode':
        return false
      case 'default_category':
        return ['all', 'markets'].includes(filterOf) && page !== 'category'
      case 'live_markets':
        return false //['all', 'markets'].includes(filterOf) && page === 'feed'
      case 'live':
        return false //page === 'profile'
      case 'resolved_and_sold':
        return false //page === 'profile'
      case 'market_status':
        return page === 'portfolio'
      case 'resolved_only':
        return ['all', 'markets'].includes(filterOf) && page !== 'portfolio'
      case 'all':
        return ['all', 'markets'].includes(filterOf) && page !== 'portfolio'
      case 'hide_my_bets':
        return false
      case 'people_i_follow':
        return ['users'].includes(filterOf)
      case 'ordering':
        return page !== 'search'
      case 'filter_by':
        return ['all', 'markets'].includes(filterOf) && page !== 'portfolio'
      case 'only_markets_i_follow':
        return page !== 'portfolio'
      default:
        return true
    }
  }
  const hideModal = () => {
    setModalOpen(false)
  }

  const render = () => {
    return (
      <ul className={classnames('filter-dropdown-panel', 'flex-col md:flex-row', className)}>
        {shouldShowFilter('currencies') && (
          <li>
            <FilterDropdown
              type="list"
              multiple
              title={i18n('question_card.currencies')}
              placeholder={i18n('portfolio.all_currencies')}
              value={currenciesSelected}
              list={
                _currencies &&
                currencies
                  .map(c => [c, _currencies[c]])
                  .map(([currencyId, currency]) => ({
                    value: currencyId,
                    text: currencyId === 'OOM' ? i18n('Play_money') : currencyId,
                    icon: <CurrencyIcon currency={currency} width="20px" />,
                  }))
                  .filter(({ value }) => value !== constants.REAL_MONEY_PLACEHOLDER)
              }
              onChange={value => {
                setCurrenciesSelected(value.length > 0 ? value : null)
                onChangeFilter('currencies', value)
              }}
              flat={isMobileSize}
            />
          </li>
        )}
        {shouldShowFilter('market_status') && (
          <li>
            <FilterDropdown
              type="list"
              multiple
              title={i18n('filters.status')}
              placeholder="Status"
              value={marketStatusSelected && marketStatusSelected.length > 0 ? marketStatusSelected : null}
              list={statusList}
              onChange={value => {
                setMarketStatusSelected(value.length > 0 ? value : null)
                onChangeFilter('market_status', value)
              }}
              flat={isMobileSize}
            />
          </li>
        )}
        {shouldShowFilter('currency_mode') && (
          <li>
            <FilterDropdown
              type="list"
              placeholder={i18n('currency_selector.title')}
              value={activeCurrencyMode}
              list={currencySelector}
              onChange={changeCurrencyMode}
              flat={isMobileSize}
            />
          </li>
        )}
        {shouldShowFilter('filter_by') && (
          <li>
            <FilterDropdown
              type="list"
              placeholder={
                <>
                  {i18n('filters.filter_by')}{' '}
                  {(queryParams.hide_my_bets || queryParams.only_markets_i_follow) &&
                    `(${(queryParams.hide_my_bets ? 1 : 0) + (queryParams.only_markets_i_follow ? 1 : 0)})`}
                </>
              }
              custom={
                <ul className="flex flex-col">
                  <li
                    className="!p-4 cursor-pointer flex gap-1 items-center leading-4"
                    onClick={e => {
                      e.stopPropagation()
                      if (queryParams.resolved_only || queryParams.live) onChangeFilter('all', true)
                    }}>
                    <span className="flex items-center gap-2">
                      <RadioControl active={!queryParams.resolved_only && !queryParams.live} />
                      <span>{i18n('filters.open_markets')}</span>
                    </span>
                  </li>
                  <li
                    className="!p-4 cursor-pointer flex gap-1 items-center leading-4"
                    onClick={e => {
                      e.stopPropagation()
                      if (!queryParams.resolved_only) onChangeFilter('resolved_only', true)
                    }}>
                    <span className="flex items-center gap-2">
                      <RadioControl active={queryParams.resolved_only} />
                      <span>{i18n('filters.resolved_market')}</span>
                    </span>
                  </li>
                  {currentUser && (
                    <>
                      <hr className="!my-2 !mx-4" />
                      <li
                        onClick={e => {
                          e.stopPropagation()
                          onChangeFilter('hide_my_bets', !queryParams.hide_my_bets)
                        }}
                        className="!p-4 cursor-pointer flex gap-1 items-center leading-4">
                        <div className="flex items-center gap-2">
                          <ToggleControl active={queryParams.hide_my_bets} />
                          <span>{i18n('filters.hide_my_bets')}</span>
                        </div>
                      </li>
                      <li
                        onClick={e => {
                          e.stopPropagation()
                          onChangeFilter('only_markets_i_follow', !queryParams.only_markets_i_follow)
                        }}
                        className="!p-4 cursor-pointer flex gap-1 items-center leading-4">
                        <div className="flex items-center gap-2">
                          <ToggleControl active={queryParams.only_markets_i_follow} />
                          <span>{i18n('filters.market_i_follow')}</span>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
              }
              onChange={value => onChangeFilter('live', value)}
              flat={isMobileSize}
              showOptionsCount
            />
          </li>
        )}

        {shouldShowFilter('default_category') && (
          <li>
            <FilterDropdown
              type="list"
              placeholder={i18n('filters.categories')}
              title={i18n('filters.categories')}
              value={getCatagoryValue()}
              list={categorieOptions}
              multiple
              onChange={(newArray, lastSelected) => {
                const valuesToAffect =
                  lastSelected === ''
                    ? newArray.indexOf('') >= 0
                      ? categorieOptions.map(c => c.value)
                      : newArray
                    : newArray.filter(c => c !== '')

                setCategoriesSelected(valuesToAffect.length > 0 ? valuesToAffect : null)
                onChangeFilter('categories', valuesToAffect)
              }}
              flat={isMobileSize}
              showOptionsCount={getCatagoryValue()?.length < categorieOptions.length}
            />
          </li>
        )}
        {shouldShowFilter('resolved_and_sold') && (
          <li>
            <FilterDropdown
              type="boolean"
              value={queryParams.resolved_and_sold}
              placeholder={i18n('markets.resolved_and_sold')}
              onChange={value => {
                onChangeFilter('resolved_and_sold', value)
              }}
              flat={isMobileSize}
            />
          </li>
        )}

        {shouldShowFilter('live_markets') && (
          <li>
            <FilterDropdown
              type="boolean"
              placeholder={i18n('markets.live_markets')}
              value={queryParams.live}
              onChange={value => onChangeFilter('live', value)}
              flat={isMobileSize}
            />
          </li>
        )}
        {shouldShowFilter('live') && (
          <li>
            <FilterDropdown
              type="boolean"
              placeholder={i18n('markets.live_markets')}
              value={queryParams.live}
              onChange={value => onChangeFilter('live', value)}
              flat={isMobileSize}
            />
          </li>
        )}

        {shouldShowFilter('hide_my_bets') && (
          <li>
            <FilterDropdown
              type="boolean"
              value={queryParams.hide_my_bets}
              placeholder={i18n('filters.hide_my_bets')}
              onChange={value => onChangeFilter('hide_my_bets', value)}
              flat={isMobileSize}
            />
          </li>
        )}
        {shouldShowFilter('people_i_follow') && (
          <li>
            <FilterDropdown
              type="boolean"
              value={queryParams.people_i_follow}
              placeholder={i18n('filters.people_i_follow')}
              onChange={value => onChangeFilter('people_i_follow', value)}
              flat={isMobileSize}
            />
          </li>
        )}
        {!isMobileSize && shouldShowFilter('sort_by') && (
          <li>
            <FilterDropdown
              type="list"
              title={i18n('markets.sort_by')}
              keepPlaceholder={true}
              value={queryParams.ordering || '-relevance'}
              list={sortByOptions}
              arrow="outlined"
              highlightSelected
              onChange={value => onChangeFilter('ordering', value)}
              flat={isMobileSize}
            />
          </li>
        )}
      </ul>
    )
  }
  return isMobileSize ? (
    <>
      <div
        onClick={() => setModalOpen(true)}
        className="rounded-full relative flex w-10 h-10 items-center justify-center bg-Neutral-1 border border-solid border-Neutral-3">
        <FiltersIcon width="13px" fill="var(--color-Neutral-6)" />
        {(currenciesSelected || marketStatusSelected || categoriesSelected) && (
          <i className="absolute right-0 bottom-0">
            <DotIcon color="var(--color-brand-04)" />
          </i>
        )}
      </div>

      <Modal className="h-auto" isOpen={modalOpen} position="bottom" onCloseRequested={hideModal}>
        <ActionSheetLayout
          containerClass={containerClass}
          title={i18n('filters.filter_by')}
          content={render()}
          onClose={hideModal}
          showCloseButton
          footer={
            <div className="absolute bottom-0 w-full left-0 p-4 bg-Neutral-1">
              <Button
                size="full"
                onClick={() => {
                  if (page === 'portfolio') {
                    setOnChangeFilters.find(f => f.filter === 'currencies').action(currenciesSelected)
                    setOnChangeFilters.find(f => f.filter === 'categories').action(categoriesSelected)
                    setOnChangeFilters.find(f => f.filter === 'market_status').action(marketStatusSelected)
                  }
                  hideModal()
                }}>
                {i18n('filters.apply')}
              </Button>
            </div>
          }
        />
      </Modal>
    </>
  ) : (
    render()
  )
}

export default FilterDropdownPanel
