/* @flow */
import React from 'react'
import Image from 'common/components/Image'

class PushNotification extends React.PureComponent {
  handleClick() {
    const { onMessageClick } = this.props
    if (onMessageClick) {
      onMessageClick(process.env.REACT_APP_FRONT_URL)
    }
  }

  render() {
    const { title, body, image, icon } = this.props
    return (
      <div className="message" onClick={this.handleClick.bind(this)}>
        {icon && icon}
        {image && <Image className="message__image message__image--square" src={image} alt={title} title={title} />}
        <div className="message__text">
          <div className="message__main-text">{title}</div>
          <div className="message__sub-text">{body}</div>
        </div>
      </div>
    )
  }
}

export default PushNotification
