import importExternal from '../common/util/importExternal'

if (process.env.REACT_APP_GTM_ID) {
  initGTM(process.env.REACT_APP_GTM_ID)
}

/** GTM init */
async function initGTM(gtmId) {
  // when logged in we add push event: "user_logged_in"
  global.dataLayer = global.dataLayer || []
  const options = {
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  }

  global.dataLayer.push(options)

  importExternal(`https://www.googletagmanager.com/gtm.js?id=${gtmId}`)
}
const addParamsToGTM = params => {
  global.dataLayer = global.dataLayer || []
  global.dataLayer.push(params)
}

export { addParamsToGTM }
