import requestService from 'common/services/request'
import { createSlice } from '@reduxjs/toolkit'
import constants from 'app/constants'
import { transformArrayIntoMap } from 'common/util/mapArray'

const initialState = {
  pool: { data: [], status: constants.IDLE, error: null },
  cloud: { data: [], status: constants.IDLE, error: null },
  tempTags: { data: [] },
}

// Reducers / Slices
const tags = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    getTagsListStart: state => {
      state.pool.status = constants.LOADING
    },

    getTagsListSuccess: (state, action) => {
      const { tags } = action.payload
      state.pool.data = tags
      state.pool.status = constants.COMPLETE
    },

    getTagsListError: (state, action) => {
      const { error } = action.payload
      state.pool.error = error
    },
    getTagsCloudListStart: state => {
      state.cloud.status = constants.LOADING
    },

    getTagsCloudListSuccess: (state, action) => {
      state.cloud.data = action.payload
      state.cloud.status = constants.COMPLETE
    },

    getTagsCloudListError: (state, action) => {
      const { error } = action.payload
      state.cloud.error = error
    },

    resetTags: state => {
      state.pool.data = []
      state.pool.status = constants.IDLE
      state.pool.error = null
    },
    addTagsToPool: (state, action) => {
      const { tags } = action.payload
      if (state.pool && state.pool.data) {
        const tagMap = transformArrayIntoMap(tags)
        state.pool.data = { ...state.pool.data, ...tagMap }
      }
    },
    getTempsTag: (state, action) => {
      const { tag } = action.payload
      if (!state.tempTags.data.find(t => t.id === tag.id)) state.tempTags.data.push(tag)
    },
  },
})

export const { getTagsListStart, getTagsListSuccess, getTagsListError, resetTags, addTagsToPool, getTempsTag } =
  tags.actions
export const { getTagsCloudListStart, getTagsCloudListSuccess, getTagsCloudListError } = tags.actions
tags.actions
export default tags.reducer

// Selectors:
export const selectTagsList = () => state => {
  return state.tags.pool.data
}

export const selectTagsListStatus = () => state => {
  return state.tags.pool.status
}

export const selectTagsListError = () => state => {
  return state.tags.pool.error
}
export const selectTagsCloudList = () => state => {
  return state.tags.cloud.data
}

export const selectTagsListCloudStatus = () => state => {
  return state.tags.cloud.status
}

export const selectTagsListCloudError = () => state => {
  return state.tags.cloud.error
}
export const selectTempTagBySlug = slug => state => {
  return state.tags.tempTags.data.find(t => t.slug === slug)
}

// Thunks:

export const fetchTagsList = () => async dispatch => {
  dispatch(getTagsListStart())
  try {
    var response = await requestService.get(`/tags/`)
  } catch (error) {
    dispatch(getTagsListError({ error }))
    throw error
  }
  const json = await response.json()
  dispatch(getTagsListSuccess({ tags: json.results }))
}
export const getTagCloud = currency_mode => async dispatch => {
  dispatch(getTagsCloudListStart())
  try {
    var response = await requestService.get(`/tags/cloud/?currency_mode=${currency_mode}`)
  } catch (error) {
    dispatch(getTagsCloudListError({ error }))
    throw error
  }
  const json = await response.json()
  dispatch(getTagsCloudListSuccess([...json]))
}

export const getTagName = tagSlug => async dispatch => {
  try {
    var response = await requestService.get(`/tags/${tagSlug}`)
  } catch (error) {
    console.log(error)
    throw error
  }
  const json = await response.json()
  dispatch(getTempsTag({ tag: json }))
}
