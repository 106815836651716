import React, { useEffect } from 'react'
import UserFollowItem from 'social/components/user-follow-item'
import './UserResult.scss'
import { fetchUserSummary, followUser, selectUserSummary, selectUserSummaryStatus, unfollowUser } from 'store/users'
import { useDispatch, useSelector } from 'react-redux'
import { useCurrentUser } from 'common/hooks'
import constants from 'app/constants'
import { toastService } from 'api/toast'
import { useModal } from 'app/components/modal-provider/ModalProvider'
import EmailNotConfirmed from 'app/components/modal-provider/global-modal/email-not-confirmed'

const UserResult = ({ total, users = [], onViewAll, summary }) => {
  const me = useCurrentUser()
  const currentUserSummary = useSelector(selectUserSummary(me))
  const currentUserSummaryStatus = useSelector(selectUserSummaryStatus(me))
  const { showModal } = useModal()

  const dispatch = useDispatch()
  const onUnfollow = user => {
    dispatch(unfollowUser(me, user.id))
    toastService('userUnfollow', user)
  }

  useEffect(() => {
    if (me && currentUserSummaryStatus === constants.IDLE) {
      dispatch(fetchUserSummary(me))
    }
  }, [me])
  const onFollow = user => {
    if (me && !me.email_confirmed) {
      showModal({
        title: ' ',
        showCloseButton: true,
        content: <EmailNotConfirmed />,
      })
      return
    }
    dispatch(followUser(me, user.id))
    toastService('userFollow', user)
  }

  const renderUser = user => {
    if (user == null) {
      return null
    }
    return (
      <UserFollowItem
        following={currentUserSummary && currentUserSummary.following_user_ids}
        me={me}
        user={user}
        key={user.id}
        onFollow={onFollow}
        onUnfollow={onUnfollow}
      />
    )
  }
  const renderSummary = () => {
    const userResults = users.slice(0, 4).map(renderUser)

    if (!onViewAll) {
      console.warn('Summary and onViewAll props must be provided together')
    }

    return (
      <React.Fragment>
        <div className="user-results__grid">{userResults}</div>
      </React.Fragment>
    )
  }
  const renderComplete = () => {
    return users.map(renderUser)
  }

  if (total === 0) {
    return null
  }

  return (
    <div className="user-results">
      <div className="container-md-wide">
        <div className="result-list">{summary ? renderSummary() : renderComplete()}</div>
      </div>
    </div>
  )
}

export default UserResult
