import React from 'react'
import { useState } from 'react'

const TiktokIcon = () => {
  const [hovered, setHovered] = useState(false)
  return (
    <div onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {hovered ? (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="24" fill="black" />
          <path
            d="M28.5999 17.82C27.9163 17.0396 27.5396 16.0374 27.5399 15H24.4499V27.4C24.4261 28.071 24.1428 28.7066 23.6597 29.1729C23.1766 29.6393 22.5314 29.8999 21.8599 29.9C20.4399 29.9 19.2599 28.74 19.2599 27.3C19.2599 25.58 20.9199 24.29 22.6299 24.82V21.66C19.1799 21.2 16.1599 23.88 16.1599 27.3C16.1599 30.63 18.9199 33 21.8499 33C24.9899 33 27.5399 30.45 27.5399 27.3V21.01C28.7929 21.9099 30.2973 22.3926 31.8399 22.39V19.3C31.8399 19.3 29.9599 19.39 28.5999 17.82Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M28.6 17.82C27.9165 17.0396 27.5397 16.0374 27.54 15H24.45V27.4C24.4262 28.071 24.1429 28.7066 23.6598 29.1729C23.1767 29.6393 22.5315 29.8999 21.86 29.9C20.44 29.9 19.26 28.74 19.26 27.3C19.26 25.58 20.92 24.29 22.63 24.82V21.66C19.18 21.2 16.16 23.88 16.16 27.3C16.16 30.63 18.92 33 21.85 33C24.99 33 27.54 30.45 27.54 27.3V21.01C28.793 21.9099 30.2974 22.3926 31.84 22.39V19.3C31.84 19.3 29.96 19.39 28.6 17.82Z"
            fill="currentColor"
          />
          <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="currentColor" strokeOpacity="0.48" />
        </svg>
      )}
    </div>
  )
}

export default TiktokIcon
