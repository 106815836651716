import { getCommunityToken } from 'api/auth'
import requestService from 'common/services/request'

/**
 * Helper method to make HTTP requests to the Flarum API.
 */
export const communityAPIRequest = async ({ endpoint = '/', data, method = 'GET', mode = 'cors' }) => {
  if (!process.env.REACT_APP_COMMUNITY_API_URL) {
    return null
  }

  const requestUrl = process.env.REACT_APP_COMMUNITY_API_URL + endpoint
  const headers = new Headers()
  const communityToken = getCommunityToken()
  if (!communityToken) return
  headers.set('Content-Type', 'application/json')
  if (communityToken) {
    headers.set('Authorization', 'Token ' + communityToken)
  }

  try {
    const body = data && JSON.stringify(data)
    const init = { method, headers, mode, body }
    const request = new global.Request(requestUrl, init)

    const response = await global.fetch(request)
    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      throw response
    }
  } catch (error) {
    console.log('Error fetching :' + error)
  }
}

export const retrieveDiscussion = questionID => {
  return requestService.get(`/questions/${questionID}/get_comment`)
}

export const searchUsers = (searchTerm, limit = 5) => {
  const urlterms = encodeURIComponent(searchTerm)
  return communityAPIRequest({
    endpoint: '/users?filter[q]=' + urlterms + '&page[limit]=' + limit,
  })
}

export const toggleLike = (questionID, commentId) => {
  return requestService.post(`/questions/${questionID}/like_comment/`, {
    comment_id: commentId,
  })
  // return communityAPIRequest({
  //   endpoint: '/posts/' + commentId,
  //   method: 'PATCH',
  //   data: {
  //     data: {
  //       type: 'posts',
  //       id: commentId,
  //       attributes: { isLiked: !isLiked },
  //     },
  //   },
  // })
}

export const postComment = (questionID, commentText, replyingTo = null) => {
  const body = {
    comment: commentText,
    comment_username: replyingTo && replyingTo.username ? replyingTo.username : null,
    comment_id: replyingTo && replyingTo.commentId ? replyingTo.commentId : null,
  }
  return requestService.post(`/questions/${questionID}/post_comment/`, body)

  // return communityAPIRequest({
  //   endpoint: '/posts',
  //   method: 'POST',
  //   data: {
  //     data: {
  //       type: 'posts',
  //       attributes: {
  //         content: commentText,
  //       },
  //       relationships: {
  //         discussion: {
  //           data: { id: discussionId, type: 'discussions' },
  //         },
  //       },
  //     },
  //   },
  // })
}

export const subscribeToDiscussion = async discussionId => {
  if (!discussionId) return
  return communityAPIRequest({
    endpoint: `/discussions/${discussionId}`,
    method: 'PATCH',
    data: {
      data: {
        type: 'discussions',
        id: `${discussionId}`,
        attributes: {
          subscription: 'follow',
        },
      },
    },
  })
}
