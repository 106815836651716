import React from 'react'
import { Helmet } from 'react-helmet-async'
import { i18n } from 'common/locale/i18n'

export default ({ id, url, title, description, images, alt, follow = true, ...props }) => {
  let imageList = []
  if (images) {
    imageList = imageList.concat(images)
  }

  const ldData = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Futuur',
    url: 'https://www.futuur.com',
    sameAs: [
      'https://twitter.com/futuurHQ',
      'https://www.facebook.com/futuurHQ',
      'https://www.youtube.com/channel/UCEJkPOzzJzlV8jeb5mKRHeQ',
      'https://www.medium.com/futuur',
      'https://www.instagram.com/futuurHQ',
    ],
  }

  const pageUrl = url ? url : window.location.href
  const canonicalUrl = pageUrl.split('?')[0]

  return (
    <Helmet prioritizeSeoTags={true} key={id}>
      <title>{title}</title>
      <meta name="description" content={description} />
      {follow && <meta name="robots" content="index, follow" />}
      <link rel="alternate" href={canonicalUrl + '?lg=pt_br'} hrefLang="pt_br" />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:locale" content={i18n('global.locale')} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      {imageList.map(url => {
        return <meta property="og:image" content={url} key={url} />
      })}
      {alt && <meta property="og:image:alt" content={alt} />}
      {props.children}

      <script type="application/ld+json">{JSON.stringify(ldData)}</script>
    </Helmet>
  )
}
