/* @flow */
import React from 'react'
import { walletUrl } from 'urls'
import { useNavigate } from 'react-router-dom'
import CurrencyIcon from 'common/components/currencyIcon'
class Deposit extends React.PureComponent {
  handleClick() {
    const navigate = useNavigate()
    const url = walletUrl()
    navigate(url)
  }

  render() {
    const { amount, currency } = this.props
    return (
      <div className="message" onClick={this.handleClick.bind(this)}>
        {currency && <CurrencyIcon currency={currency} width="24px" />}

        <div className="message__text">
          <div className="message__main-text">Deposit succeeded</div>
          <div className="message__sub-text">
            <b>
              {amount} {currency && currency.label}
            </b>{' '}
            has been added to your Futuur wallet!
          </div>
        </div>
      </div>
    )
  }
}

export default Deposit
