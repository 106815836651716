export class AuthenticationError extends Error {
  constructor(response) {
    super((response && response.statusText) || 'This resource requires authentication')
    this.response = response
    this.status = (response && response.status) || 401
  }
}

export class NetworkError extends Error {
  constructor(response: global.Response) {
    super((response && response.statusText) || 'NetworkError')
    this.response = response
    this.status = response && response.status
  }
}

export class ValidationError extends Error {
  constructor(inputErrorMap) {
    super('ValidationError')
    this.errors = inputErrorMap
  }
}
