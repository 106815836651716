import React from 'react'
import './FAQs.scss'
import Accordion from 'common/ui/Accordion'
import { i18n } from 'common/locale/i18n'
import { useState } from 'react'
import { ArrowRightTailed } from 'common/components/icons'
import { helpCenterUrl } from 'social/urls'
import { selectActiveLanguage } from 'store/app'
import { useSelector } from 'react-redux'
const FAQs = () => {
  const list = ['what_is_futuur', 'how_are_priced_determined', 'do_you_have_api', 'suggest_a_market']
  const [FAQOpen, setFAQOpen] = useState('what_is_futuur')
  const language = useSelector(selectActiveLanguage()) 
  
  return (
    <section className="FAQs">
      <div className="container-wide FAQs__container relative">
        <div className="FAQs__header">
          <h1>{i18n('faqs.title')}</h1>
          <a className="link flex gap-2 items-center" href={helpCenterUrl(language)} target='_blank' rel="noreferrer">
            {i18n('footer.help_center')} <ArrowRightTailed width="10px" />
          </a>
        </div>
        <ul className="FAQs__list">
          {list.map(faq => (
            <>
              <li key={faq} onClick={() => setFAQOpen(v => (v === faq ? '' : faq))}>
                <Accordion
                  isOpen={FAQOpen == faq}
                  title={<h6>{i18n('faqs.' + faq + '.title')}</h6>}
                  description={i18n('faqs.' + faq + '.description')}
                />
              </li>
              <hr />
            </>
          ))}
        </ul>
        <svg
          className="absolute left-0 bottom-0 hidden md:block"
          xmlns="http://www.w3.org/2000/svg"
          width="213"
          height="158"
          viewBox="0 0 213 158"
          fill="none">
          <path d="M-117 165L48 0H213L48 165H-117Z" fill="#ED0071" />
        </svg>
      </div>
    </section>
  )
}

export default FAQs
