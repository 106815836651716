import * as React from 'react'

export default function FutuurIcon({ fillcolor, className, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 14 28"
      className={className}
      fill={fillcolor === 'gradient' ? 'url(#gradient)' : fillcolor}
      {...props}>
      {fillcolor === 'gradient' && (
        <defs>
          <linearGradient
            id="paint0_linear_1996_6976"
            x1="19.2712"
            y1="33.1932"
            x2="-3.26169"
            y2="2.0105"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.03" stopColor="#FF5129" />
            <stop offset="0.11" stopColor="#F7333F" />
            <stop offset="0.2" stopColor="#EF1755" />
            <stop offset="0.28" stopColor="#EA0663" />
            <stop offset="0.35" stopColor="#E90068" />
            <stop offset="0.44" stopColor="#CE0179" />
            <stop offset="0.63" stopColor="#8B06A4" />
            <stop offset="0.91" stopColor="#200DEA" />
            <stop offset="0.99" stopColor="#0010FF" />
          </linearGradient>
        </defs>
      )}
      <path d="M0,0 h14 l-14,14 h14 v7 h-7 v7 h-7 z" />
    </svg>
  )
}
