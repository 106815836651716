import { setNavigationBarVisibility, setRemoveNavbar } from 'store/app'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const usePageWithoutNavbar = (allResolutions = false) => {
  const dispatch = useDispatch()

  useEffect(() => {
    allResolutions ? dispatch(setRemoveNavbar(true)) : dispatch(setNavigationBarVisibility(false))
    return () => {
      allResolutions ? dispatch(setRemoveNavbar(false)) : dispatch(setNavigationBarVisibility(true))
    }
  }, [])
}

export default usePageWithoutNavbar
