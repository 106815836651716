import React, { useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import constants from 'app/constants'
import Helmet from 'common/components/helmet/Helmet'
import scrollService from 'common/services/scroll'
import shareService from 'social/services/share'
import futuurCover from 'landing/assets/futuur.com.png'
import BannerGrid from 'questions/components/banner-grid'
import classNames from 'classnames'
import { feedUrl, categoryListUrl, keepQueryString } from 'urls'
import { fromEntries } from 'common/util/fromEntries'
import { i18n } from 'common/locale/i18n'
import { selectAbTestsStatus, selectActiveLanguage, selectActiveMarketType } from 'store/app'
import { urlSearchParams } from 'common/util/urlSearchParams'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchMarketsByCategoryId,
  selectMarketsByCategoryId,
  selectMarketsPaginationByCategoryId,
  selectMarketsStatusByCategoryId,
  selectMarketsErrorByCategoryId,
  resetCategoryMarkets,
} from 'store/markets'
import { fetchBannersByCategory, selectBannersByCategoryId, selectBannersStatusByCategoryId } from 'store/banners'
import {
  fetchCategories,
  selectCategoriesByParentId,
  selectCategoryById,
  selectCategoryStatusById,
} from 'store/categories'

import './ViewCategoryDetails.scss'
import MarketListTemplate from 'questions/components/market-list-template'
import Breadcrumb from 'common/components/breadcrumb'
import Slider from 'common/ui/slider' 
import { useState } from 'react'
import QuickBet from 'questions/components/quick-bet'
import useIsMobileSize from 'common/hooks/useIsMobileSize'

const ViewCategoryDetails = () => {
  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch()
  const [categoryId, setCategoryId] = useState(params.categoryId ? params.categoryId : 0)
  const marketType = useSelector(selectActiveMarketType)
  const lang = useSelector(selectActiveLanguage())
  const isMobileSize = useIsMobileSize();

  const markets = useSelector(selectMarketsByCategoryId(categoryId))
  const marketsStatus = useSelector(selectMarketsStatusByCategoryId(categoryId))
  const marketsPagination = useSelector(selectMarketsPaginationByCategoryId(categoryId))
  const error = useSelector(selectMarketsErrorByCategoryId(categoryId))

  const banners = useSelector(selectBannersByCategoryId(categoryId))
  const bannersStatus = useSelector(selectBannersStatusByCategoryId(categoryId))
  const abTestsStatus = useSelector(selectAbTestsStatus)

  const category = useSelector(selectCategoryById(categoryId))
  const categoryStatus = useSelector(selectCategoryStatusById(categoryId, marketType))
  const subcategories = useSelector(selectCategoriesByParentId(categoryId, marketType))
  const [outcomeSelected, setOutcomeSelected] = useState(null)
  const [marketSelected, setMarketSelected] = useState(null)

  const highlight = banners && banners.length ? banners[0] : null
  const isLoadingStructure = categoryStatus === constants.LOADING || bannersStatus === constants.LOADING
  const [queryParams, setQueryParams] = useState(fromEntries(new urlSearchParams(location.search)))
  useEffect(() => {
    setQueryParams(fromEntries(new urlSearchParams(location.search)))
  }, [location.search])

  useEffect(() => {
    setCategoryId(params.categoryId ? params.categoryId : 0)
  }, [params.categoryId])

  useEffect(() => {
    if (bannersStatus === constants.IDLE) {
      dispatch(fetchBannersByCategory(categoryId))
    }
  }, [marketType])

  useEffect(() => {
    if ((categoryId && categoryStatus === constants.IDLE) || categoryStatus === constants.COMPLETE) {
      dispatch(fetchCategories(categoryId, marketType))
    }
  }, [marketType, categoryId])

  useEffect(() => {
    if (marketsStatus !== constants.LOADING && abTestsStatus === constants.COMPLETE) {
      dispatch(resetCategoryMarkets(categoryId))
      dispatch(fetchMarketsByCategoryId(categoryId, marketType, { ...queryParams }))
    }
  }, [marketType, queryParams, abTestsStatus, categoryId])

  useEffect(() => {
    let lastScroll
    if (markets.length === 0) {
      lastScroll = scrollService.restoreScrollPosition('view-category-details')
      window.scroll(0, lastScroll)
    }

    return () => {
      scrollService.saveScrollPosition(window.scrollY, 'view-category-details')
    }
  }, [markets])

  const renderHelmet = () => {
    let helmetProps = {
      title: 'Futuur - Category',
      description: i18n('global.general_description'),
      images: highlight ? highlight.image : '',
      url: shareService.shareableUrl(feedUrl()),
    }
    if (category && category.seo) {
      const { seo } = category
      const language = lang === 'pt-br' ? 'pt_br' : 'en'
      helmetProps = {
        images: category.seo.seo_image || futuurCover,
        url: shareService.shareableUrl(categoryListUrl(category)),
        description: seo[`seo_description_${language}`],
        title: seo[`seo_title_${language}`] + ' Forecasts and Predictions | Futuur',
        alt: seo[`seo_alt_${language}`],
      }
    }

    return <Helmet id="category-helmet" {...helmetProps} />
  }

  const renderCategoryBanner = highlight => {
    const classList = classNames('view-category-details__banner', {
      'view-category-details__banner--with-highlight': highlight,
    })
    return (
      <section className={classList}>
        {highlight && (
          <div className="view-category-details__banner-container">
            <BannerGrid
              showDescription={true}
              isSubCategory={true}
              show_swipers={false}
              banners={[{ ...highlight, action_text: i18n('markets.make_your_forecasts') }]}
            />
          </div>
        )}
      </section>
    )
  }
  const renderSubCategory = sub => {
    return (
      sub && (
        <Link className="view-category-details__subDetails--sub" to={keepQueryString(categoryListUrl(sub))}>
          {sub.title}
        </Link>
      )
    )
  }
  const renderParentCategory = () => {
    if (!category) {
      return null
    }

    const categoryTree = (category && category.tree) || []
    const categoryTreeWithoutLastItem = [...categoryTree]
    categoryTreeWithoutLastItem.splice(-1)

    const breadcrumbs = [{ title: i18n('bet_history.all_types'), href: '/' }, ...categoryTreeWithoutLastItem].map(
      cat => {
        return { value: cat.title, href: cat.id ? categoryListUrl(cat) : cat.href }
      }
    )
    return <Breadcrumb items={breadcrumbs} showLastArrow />
  }
  const renderMakeBet = _outcomeSelected => {
    const outcome = _outcomeSelected
    return (
      <QuickBet
        marketSelected={marketSelected}
        outcome={outcome}
        setOutcomeSelected={setOutcomeSelected}
        setMarketSelected={setMarketSelected}
        shouldShow={marketSelected && marketSelected.scoring_rule_metadata}
        place="Category page"
      />
    )
  }
  const renderCategoryDetails = () => {
    // let slidesPerView = subcategories.length
    const sliderSettings = {
      slidesPerView: 1,
      arrows: false,
      spaceBetween: 0,
      watchOverflow: true,
      observer: true,
    }
    return (
      <div
        className={classNames('container-wide view-category-details__subDetails', {
          'container-wide view-category-details__subDetails--underline': subcategories.length > 0,
        })}>
        {renderParentCategory()}
        <h1 className="view-category-details__subDetails--mainSub">{category.title}</h1>
        {subcategories.length > 0 && (
          <ul className={'view-category-details__subDetails--childrens'}>
            <div
              key="mobile_subcategory"
              className="view-category-details__subDetails--childrens--swipe d-none d-md-flex">
              {subcategories.map(renderSubCategory)}
            </div>

            {isMobileSize && (
              <Slider settings={sliderSettings}>
                {subcategories && [
                  <div key="mobile_subcategory" className="view-category-details__subDetails--childrens--swipeMobile">
                    {subcategories.map(renderSubCategory)}
                  </div>,
                  <div key="mobile_subcategory_next"></div>,
                  <div key="mobile_subcategory_next"></div>,
                ]}
              </Slider>
            )}
          </ul>
        )}
      </div>
    )
  }

  return (
    <div key={`view-category-details-${params.categoryId}`} className="view-category-details">
      {renderHelmet()}
      {!isLoadingStructure && renderCategoryBanner(highlight)}
      {category && renderCategoryDetails()}
      <MarketListTemplate
        activeMarketType={marketType}
        queryParams={queryParams}
        markets={Object.values(markets)}
        pagination={marketsPagination}
        status={marketsStatus}
        error={error}
        returnUrl={category && categoryListUrl(category)}
        onLoadMore={(activeMarketType, options) =>
          dispatch(fetchMarketsByCategoryId(categoryId, marketType, { ...options, ...queryParams }))
        }
        setOutcomeSelected={setOutcomeSelected}
        setMarketSelected={setMarketSelected}
        marketSelected={marketSelected}
        OutcomeSelected={outcomeSelected}
        page="category"
      />
      {renderMakeBet(outcomeSelected)}
    </div>
  )
}

export default ViewCategoryDetails
