const cacheStorage = new Map()
const EXPIRING_TIME_IN_MS = 1 * 60 * 1000
const blacklist = []

const isCacheExpired = url => {
  const cache = cacheStorage.get(url)
  if (cache && cache.expires_at) {
    if (cache.expires_at > new Date()) {
      return false
    }
  }
  return true
}

const hasCache = url => {
  return cacheStorage.has(url) && !isCacheExpired(url)
}

const store = (url, response) => {
  try {
    cacheStorage.set(url, {
      response: response.clone(),
      expires_at: new Date(new Date().getTime() + EXPIRING_TIME_IN_MS),
    })
  } catch (err) {
    console.error(err)
  }
}

const shouldCache = url => {
  const el = blacklist.find(blacklistedUrl => {
    return url.match(blacklistedUrl)
  })

  return Boolean(!el)
}

const invalidate = url => {
  if (typeof url === 'string') {
    cacheStorage.delete(url)
  } else {
    cacheStorage.forEach(cachedUrl => {
      if (cachedUrl.test(url)) {
        cacheStorage.delete(cachedUrl)
      }
    })
  }
}

const clear = () => {
  cacheStorage.clear()
}

const wrap = (url, promiseCallback) => {
  if (!hasCache(url)) {
    const request = promiseCallback()
    request.then(response => {
      if (shouldCache(url)) {
        store(url, response)
      }
    })
    return request
  }

  const cachedData = cacheStorage.get(url)
  return new Promise((resolve, reject) => {
    try {
      resolve(cachedData.response.clone())
    } catch (err) {
      reject(err)
    }
  })
}

const cacheService = {
  hasCache,
  wrap,
  invalidate,
  clear,
}

export default cacheService
