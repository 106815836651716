import React from 'react'

const VideoPlayIcon = ({ width = 142, fill = '#ffffff', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48.452 60.982" width={width} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 60.982L48.452 30.49 0 0v60.981zm3.825-6.95l37.41-23.542L3.825 6.949z"
        fill={fill}
      />
    </svg>
  )
}

export default VideoPlayIcon
