import requestService from 'common/services/request'
import { splitArrayIntoMap } from 'common/util/mapArray'
import { createSlice } from '@reduxjs/toolkit'
import constants from 'app/constants'
import { toastService } from 'api/toast'

const initialState = {
  withdrawValidation: { status: constants.IDLE, data: {}, error: null },
  transactions: { status: constants.IDLE, data: {} },
  pendingWithdrawals: { status: constants.IDLE, data: {}, pagination: {} },
}

// Reducers / Slices

const wallet = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    getTransactionsStart: () => {},
    getTransactionsSuccess: () => {},
    getTransactionErrors: () => {},
    getPendingWithdrawalsStart: state => {
      state.pendingWithdrawals.status = constants.LOADING
    },
    getPendingWithdrawalsSuccess: (state, action) => {
      state.pendingWithdrawals.status = constants.COMPLETE
      state.pendingWithdrawals.data = {}
      state.pendingWithdrawals.data = splitArrayIntoMap(action.payload, 'currency')
    },
    getPendingWithdrawalsError: (state, action) => {
      state.pendingWithdrawals.status = constants.ERROR
      state.pendingWithdrawals.error = action.payload
    },
    getWithdrawValidationStart: state => {
      state.withdrawValidation.status = constants.LOADING
      state.withdrawValidation.data = constants.LOADING
      state.withdrawValidation.error = null
    },
    getWithdrawValidationError: (state, action) => {
      state.withdrawValidation.status = constants.ERROR
      state.withdrawValidation.error = action.payload
    },
    getWithdrawValidationSuccess: (state, action) => {
      state.withdrawValidation.status = constants.COMPLETE
      state.withdrawValidation.data = action.payload
    },
    resetWithdrawValidation: state => {
      state.withdrawValidation.status = constants.IDLE
      state.withdrawValidation.data = {}
      state.withdrawValidation.error = null
    },
  },
})

export const { getPendingWithdrawalsStart, getPendingWithdrawalsSuccess, getPendingWithdrawalsError } = wallet.actions
export const { getTransactionsStart, getTransactionsSuccess, getTransactionErrors } = wallet.actions
export const {
  getWithdrawValidationStart,
  getWithdrawValidationError,
  getWithdrawValidationSuccess,
  resetWithdrawValidation,
} = wallet.actions
export default wallet.reducer

export const selectWithdrawValidationStatus = () => state => state.wallet.withdrawValidation.status || constants.IDLE
export const selectWithdrawValidationData = () => state => state.wallet.withdrawValidation.data || {}
export const selectWithdrawValidationError = () => state => state.wallet.withdrawValidation.error || null

// Thunks:

export const fetchPendingWithdrawals = () => async dispatch => {
  dispatch(getPendingWithdrawalsStart())
  try {
    var response = await requestService.get(`/withdrawals/get_pending/`)
  } catch (err) {
    dispatch(getPendingWithdrawalsError(err))
    return
  }
  dispatch(getPendingWithdrawalsSuccess(await response.json()))
}

export const validateWithdraw = code => async dispatch => {
  dispatch(getWithdrawValidationStart())
  let output
  try {
    const data = await requestService.post('/withdrawals/confirm/', { code })
    output = await data.json()
    dispatch(getWithdrawValidationSuccess(output))
  } catch (err) {
    dispatch(getWithdrawValidationError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    output = err
  }
  return output
}

// Selectors:
export const selectPendingWithdrawals = state => state.wallet.pendingWithdrawals.data
export const selectPendingWithdrawalsStatus = state => state.wallet.pendingWithdrawals.status
