import { useSelector } from 'react-redux'
import { selectActiveCurrency } from 'store/app'
import useCurrencies from './useCurrencies'

const useActiveCurrency = () => {
  const currencies = useCurrencies().currencies
  const activeCurrency = useSelector(selectActiveCurrency)
  return currencies[activeCurrency] || currencies.REAL_MONEY_PLACEHOLDER
}

export default useActiveCurrency
