import React from 'react'
import './SearchWidget.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  resetSearch,
  searchAll,
  selectCategories,
  selectCategoriesPagination,
  selectCategoriesStatus,
  selectMarkets,
  selectMarketsPagination,
  selectMarketsStatus,
  selectTags,
  selectTagsPagination,
  selectTagsStatus,
  selectUsers,
  selectUsersPagination,
  selectUsersStatus,
} from 'store/search'
import { useEffect } from 'react'
import { selectActiveCurrency, selectPageLayout } from 'store/app'
import constants from 'app/constants'
import LoadingIndicator from 'common/components/LoadingIndicator'
import { keepQueryString, questionDetailUrl } from 'urls'
import CategoryResult from 'explore/components/category-result'
import UserResult from 'explore/components/user-result'
import { searchUrl } from 'explore/urls'
import { MagnifyIcon } from 'common/components/icons'
import { i18n } from 'common/locale/i18n'
import Tag from 'common/ui/tag'
import { urlSearchParams } from 'common/util/urlSearchParams'
import fromEntries from 'object.fromentries'

const SearchResults = ({ text }) => {
  const markets = useSelector(selectMarkets())
  const categories = useSelector(selectCategories())
  const users = useSelector(selectUsers())
  const tags = useSelector(selectTags())

  const marketsPagination = useSelector(selectMarketsPagination())
  const categoriesPagination = useSelector(selectCategoriesPagination())
  const usersPagination = useSelector(selectUsersPagination())
  const tagsPagination = useSelector(selectTagsPagination())

  const marketsStatus = useSelector(selectMarketsStatus())
  const categoriesStatus = useSelector(selectCategoriesStatus())
  const usersStatus = useSelector(selectUsersStatus())
  const tagsStatus = useSelector(selectTagsStatus())

  const tagsLoading = tagsStatus === constants.LOADING && !tags.length
  const marketsLoading = marketsStatus === constants.LOADING && !markets.length
  const categoriesLoading = categoriesStatus === constants.LOADING && !categories.length
  const usersLoading = usersStatus === constants.LOADING && !users.length

  const marketsLeft = marketsPagination.total
  const tagsLeft = tagsPagination.total
  const categoriesLeft = categoriesPagination.total
  const usersLeft = usersPagination.total

  return (
    <>
      {marketsLoading || tagsLoading || categoriesLoading || usersLoading ? (
        <LoadingIndicator mode="list" />
      ) : (
        <>
          {markets.length > 0 && (
            <div className="search-results__block">
              <div className="search-results__block--row">
                <span className="search-results__block--row--lables">{i18n('search.result.question.title')} </span>
                {marketsPagination.total > markets.slice(0, 4).length && (
                  <Link to={searchUrl({ q: text, type: 'markets' })}>
                    {i18n('search.result.question.cta')} {marketsLeft > 0 && `(${marketsLeft})`}
                  </Link>
                )}
              </div>
              <div className="search-results__block--markets">
                {markets &&
                  markets.length > 0 &&
                  markets.slice(0, 4).map(
                    market =>
                      market && (
                        <Link
                          to={keepQueryString(questionDetailUrl(market))}
                          className="search-results__block--markets__title"
                          key={'search' + (market ? market.id : '44')}>
                          {market && market.title}
                        </Link>
                      )
                  )}
              </div>
            </div>
          )}

          {tags.length > 0 && (
            <div className="search-results__block">
              <div className="search-results__block--row">
                <span className="search-results__block--row--lables">{i18n('search.result.tag.title')} </span>
                {tagsPagination.total > tags.slice(0, 4).length && (
                  <Link to={searchUrl({ q: text, type: 'tags' })}>
                    {i18n('search.result.tag.cta')} {tagsLeft > 0 && `(${tagsLeft})`}
                  </Link>
                )}
              </div>
              <div className="search-results__block--tags">
                <div className="search-results__block--tags--list">
                  {tags &&
                    tags.length > 0 &&
                    tags.slice(0, 4).map(tag => <Tag key={`question-summary-tag-${tag.slug}`} tag={tag} />)}
                </div>
              </div>
            </div>
          )}
          {categories.length > 0 && (
            <div className="search-results__block">
              <div className="search-results__block--row">
                <span className="search-results__block--row--lables">{i18n('search.result.category.title')} </span>
                {categoriesPagination.total > categories.slice(0, 4).length && (
                  <Link to={searchUrl({ q: text, type: 'categories' })}>
                    {i18n('search.result.category.cta')} {categoriesLeft > 0 && `(${categoriesLeft})`}
                  </Link>
                )}
              </div>
              <div className="search-results__block--categories">
                <CategoryResult categories={categories.slice(0, 4)} total={categoriesPagination.total} isSummary />
              </div>
            </div>
          )}
          {users.length > 0 && (
            <div className="search-results__block">
              <div className="search-results__block--row">
                <span className="search-results__block--row--lables">{i18n('search.result.user.title')} </span>
                {usersPagination.total > users.slice(0, 4).length && (
                  <Link to={searchUrl({ q: text, type: 'users' })}>
                    {i18n('search.result.user.cta')} {usersLeft > 0 && `(${usersLeft})`}
                  </Link>
                )}
              </div>
              <div className="search-results__block--users">
                <UserResult users={users.slice(0, 4)} total={users.total} />
              </div>
            </div>
          )}
          {users.length + tags.length + categories.length + markets.length <= 0 && (
            <div className="search-results__empty">
              <div className="search-results__empty--icon">
                <MagnifyIcon width="13px" />
              </div>
              <p>{i18n('search.empty.common')} </p>
              <Link to={keepQueryString('/q/suggest')}> {i18n('feedback.type.suggestion')}</Link>
            </div>
          )}
        </>
      )}
    </>
  )
}

const SearchWidget = ({ text = null, isOpen = true, _ref }) => {
  const dispatch = useDispatch()
  const activeCurrency = useSelector(selectActiveCurrency)
  const pageLayout = useSelector(selectPageLayout)
  const queryParams = fromEntries(new urlSearchParams(location.search))
  const input = text || queryParams.q
  let searchtime

  // useEffect(() => {
  //   if (!pageLayout.appLoading) handleSearch()
  // }, [pageLayout.appLoading])
  useEffect(() => {
    if (pageLayout.appLoading) return

    clearTimeout(searchtime)
    searchtime = setTimeout(() => {
      if (input !== null && input !== '') handleSearch()
      if (input === '') dispatch(resetSearch())
    }, 600)

    return () => {
      clearTimeout(searchtime)
    }
  }, [pageLayout.appLoading, text])

  const handleSearch = async () => {
    await dispatch(resetSearch())
    await dispatch(await searchAll({ q: input }, { currencyId: activeCurrency }))
  }

  return (
    <div className="search-widget" ref={_ref} style={isOpen ? {} : { display: 'none' }}>
      {isOpen && <SearchResults text={input} />}
    </div>
  )
}

export { SearchWidget, SearchResults }
