import React from 'react'
import './CategoryMenu.scss'
import OutsideAlerter from 'common/components/outside-alerter'
import getCatIcon from 'api/catergoryIcon'
import { useSelector } from 'react-redux'
import { selectMainRootCategoryList } from 'store/categories'
import { categoryListUrl, keepQueryString } from 'urls'
import classNames from 'classnames'
const CategoryMenu = ({ onClose = () => {}, isOpen }) => {
  const mainCategories = useSelector(selectMainRootCategoryList())
  const alphabetically = (a, b) => {
    if (a.title < b.title) {
      return -1
    }
    if (a.title > b.title) {
      return 1
    }
    return 0
  }

  const renderCatagoryEntry = catagoty => {
    return (
      <li className="category_menu__category" key={catagoty.id}>
        <a
          href={keepQueryString(categoryListUrl(catagoty))}
          onClick={onClose}
          className="category_menu__category__maincategory">
          <div className="category_menu__category__maincategory">
            <i>{getCatIcon(catagoty.slug, 'currentColor', '20px')}</i>
            <h2>{catagoty.title}</h2>
          </div>
        </a>
        {catagoty.children &&
          [...catagoty.children].sort(alphabetically).map(child => (
            <a
              className="category_menu__category__subcategory"
              key={catagoty.id + 'child' + child.id}
              href={keepQueryString(categoryListUrl({ ...child }))}>
              <div>{child.title}</div>
            </a>
          ))}
        <a
          className="category_menu__category__link"
          onClick={onClose}
          href={keepQueryString(categoryListUrl(catagoty))}>
          View More
        </a>
      </li>
    )
  }

  return (
    <OutsideAlerter onClose={onClose}>
      {mainCategories && (
        <div className="category_menu">
          {
            <ul
              className={classNames('category_menu__list ', {
                'category_menu__list--open': isOpen,
              })}
              // onMouseLeave={onClose}
            >
              {[...mainCategories].sort(alphabetically).map(renderCatagoryEntry)}
            </ul>
          }
        </div>
      )}
    </OutsideAlerter>
  )
}

export default CategoryMenu
