import React, { useState, useEffect } from 'react'
import './fishBowl.scss'

const Fishbowl = () => {
  const [fill, setFill] = useState(90)
  const [intervalId, setIntervalId] = useState(null)
  const [tabActive, setTabActive] = useState(false)

  useEffect(() => {
    const emptyingFn = () => {
      return setInterval(() => {
        setFill(currentFill => {
          const newFill = currentFill - 1
          if (newFill <= 0) {
            clearInterval(intervalId)
            return 0
          }
          return newFill
        })
      }, 200)
    }

    const newIntervalId = emptyingFn()
    setIntervalId(newIntervalId)

    // Cleanup interval on component unmount
    return () => clearInterval(newIntervalId)
  }, [])

  const onTapClick = () => {
    if (fill <= 0) {
      const newIntervalId = setInterval(() => {
        setFill(currentFill => {
          const newFill = currentFill - 1
          if (newFill <= 0) {
            clearInterval(intervalId)
            return 0
          }
          return newFill
        })
      }, 200)
      setIntervalId(newIntervalId)
    }
    setFill(Math.min(fill + 20, 90))
    setTabActive(true)
    setTimeout(() => {
      setTabActive(false)
    }, 500)
  }

  return (
    <div id="fishbowl" className="fishbowl">
      <div className="fishbowl__pool"></div>
      <div className="fishbowl__background"></div>
      <div className="fishbowl__bottom"></div>
      <div className="fishbowl__decoration">
        <div className="fishbowl__seaweed fishbowl__seaweed--1"></div>
        <div className="fishbowl__seaweed fishbowl__seaweed--2"></div>
        <div className="fishbowl__seaweed fishbowl__seaweed--3"></div>
      </div>
      <div className="fishbowl__water">
        <div
          id="fish"
          className={`fishbowl__fish ${fill < 20 ? 'fishbowl__fish--dead' : fill < 50 ? 'fishbowl__fish--dying' : ''}`}
        >
          <div className="fishbowl__fish-tail"></div>
        </div>
        <div className="fishbowl__water-color" style={{ height: `${fill}%` }}></div>
      </div>
      <div className={'fishbowl__top'}></div>
      <div id="tap" className={'fishbowl__tap' + (tabActive ? ' fishbowl__tap--active' : '')} onClick={onTapClick}>
        <div className="fishbowl__tap-base"></div>
        <div className="fishbowl__tap-handle"></div>
        <div className="fishbowl__tap-stream "></div>
        <div className="fishbowl__tap-end"></div>
        <div className="fishbowl__tap-head"></div>
        <div className="fishbowl__tap-text">Click to refill</div>
      </div>
    </div>
  )
}

export default Fishbowl
