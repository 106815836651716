import React from 'react'

export default function TrophyIcon({ fillcolor, ...props }) {
  const maskIdA = `mask-a-trophy-` + Math.random()
  const maskIdB = `mask-b-trophy-` + Math.random()

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={fillcolor || 'currentColor'} {...props}>
      <defs>
        <path
          d="M19 6.04c0 .304-.136.59-.375.78l-1.632 1.306c.002-.043.008-.084.008-.126V4h2v2.04zM11 12c-2.205 0-4-1.794-4-4V3h8v5c0 2.206-1.794 4-4 4zM3.376 6.82A.996.996 0 013 6.04V4h2v4c0 .042.005.083.007.124L3.377 6.82zM9 19h4v-1H9v1zM20 2h-3.278c-.347-.594-.984-1-1.721-1H7c-.739 0-1.376.406-1.723 1H2A1 1 0 001 3v3.04c0 .915.41 1.77 1.127 2.342l4.178 3.342A5.993 5.993 0 0010 13.909V16H8a1 1 0 00-1 1v2H6v2h10v-2h-1v-2c0-.552-.447-1-1-1h-2v-2.09a5.993 5.993 0 003.697-2.186l4.178-3.343A2.983 2.983 0 0021 6.04V3c0-.553-.447-1-1-1z"
          id={maskIdA}
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id={maskIdB} fill={fillcolor || 'currentColor'}>
          <use xlinkHref={`#${maskIdA}`} />
        </mask>
        <use fill={fillcolor || 'currentColor'} xlinkHref={`#${maskIdA}`} />
        <g mask={`url(#${maskIdB})`} fill={fillcolor}>
          <path d="M0 0h22v22H0z" />
        </g>
      </g>
    </svg>
  )
}
