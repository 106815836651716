import Device from '../../common/util/Device'
import analyticsService from '../../common/services/analytics'
import { toastService } from 'api/toast'
import { i18n } from 'common/locale/i18n'

// FRONT_URL slice removes the trailing '/'.
const BASE_PATH = process.env.REACT_APP_SHARE_PATH || process.env.REACT_APP_FRONT_URL.slice(0, -1) || ''

function shareViaBrowserTwitter(url) {
  const twitterShareUrl = `https://twitter.com/home?status=${encodeURIComponent(url)}`
  window.open(twitterShareUrl, '_blank', 'noopener')
}

function shareViaBrowserFacebook(url) {
  const available = window.FB && window.FB.ui

  if (!available) {
    console.warn('Facebook sdk is not available.')
    return
  }

  window.FB.ui({ method: 'share', href: url, display: 'popup' }, response => {
    if (response && !response.error_message) {
      console.warn('Error while sharing on facebook.', url)
    }
  })
}

function shareViaBrowserWhatsapp(url) {
  let whatsappUrl = 'https://wa.me/?text=' + encodeURIComponent(url)
  window.open(whatsappUrl, '_blank', 'noopener')
}

function shareViaBrowserLinkedin(url) {
  const linkedinUrl = 'https://www.linkedin.com/shareArticle?url=' + encodeURIComponent(url)
  window.open(linkedinUrl, '_blank', 'noopener')
}

function shareViaBrowserReddit(url) {
  const redditUrl = 'https://reddit.com/submit?url=' + encodeURIComponent(url)
  window.open(redditUrl, '_blank', 'noopener')
}

function shareViaBrowserCopyLink(url) {
  const available = instance && instance.shareInput

  if (!available) {
    console.warn('Share <input> is not available.')
    return
  }

  instance.shareInput.value = url
  instance.shareInput.select()
  document.execCommand('copy')
  toastService('success', { title: i18n('snackbar.copylink') })
}

function shareViaBrowser(url, type) {
  switch (type) {
    case 'facebook':
      shareViaBrowserFacebook(url)
      break

    case 'twitter':
      shareViaBrowserTwitter(url)
      break

    case 'whatsapp':
      shareViaBrowserWhatsapp(url)
      break

    case 'reddit':
      shareViaBrowserReddit(url)
      break

    case 'linkedin':
      shareViaBrowserLinkedin(url)
      break

    case 'copylink':
      shareViaBrowserCopyLink(url)
      break

    default:
      console.warn('Invalid share type.', type, url)
      break
  }
}

function shareViaCordovaPlugin(url, type) {
  const available = window.plugins && window.plugins.socialsharing && window.plugins.socialsharing.shareWithOptions
  if (!available) {
    console.warn('Cordova socialsharing plugin is not available.')
    return
  }
  const socialsharing = window.plugins.socialsharing
  switch (type) {
    case 'facebook':
      socialsharing.shareViaFacebook(url)
      break
    case 'twitter':
      socialsharing.shareViaTwitter(url)
      break
    case 'whatsapp':
      socialsharing.shareViaWhatsApp(url)
      break

    default:
      window.plugins.socialsharing.shareWithOptions({ url })
      break
  }
}

class ShareService {
  constructor() {
    if (typeof window !== 'undefined') {
      this.shareInput = document.createElement('input')
      this.shareInput.readOnly = true
      this.shareInput.style.cssText = `
        position: absolute;
        top: -10000px;
        opacity: 0;
        pointer-events: none;
      `
      window.document.body.appendChild(this.shareInput)
    }
  }

  // TODO: add onError, onSuccess callbacks or make Promise.
  share({ type, url }) {
    const shareUrl = url.startsWith('http') ? url : this.shareableUrl(url)
    analyticsService.track('share', { url: shareUrl, provider: type })

    if (Device.isMobile) {
      return shareViaCordovaPlugin(shareUrl, type)
    }

    if (Device.isDesktop || Device.isWebappMobile) {
      return shareViaBrowser(shareUrl, type)
    }
  }

  shareableUrl(url) {
    return BASE_PATH + url
  }
}

const instance = new ShareService()
export default instance
