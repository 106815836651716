import Button from 'common/ui/button'
import React from 'react'
import './email-not-confirmed.scss'
import { CheckIcon } from 'common/components/icons'
import { i18n } from 'common/locale/i18n'
import { useModal } from '../ModalProvider' 
import { formatNumber } from 'common/util/localizeNumber'
import { selectActiveLanguage } from 'store/app'
import { useSelector } from 'react-redux'
import { useCurrencies } from 'common/hooks'
const DepositSuccess = ({ data }) => {
  const { hideModal } = useModal() 
  const locale = useSelector(selectActiveLanguage())
  const currencies = useCurrencies().currencies

  return (
    <div className="view-crypto-cash-in__deposit_status">
      <i>
        <CheckIcon width="24px" height="24px" />
      </i>
      <div className="view-crypto-cash-in__deposit_status--title">{i18n(`cash_in.deposit_status.SUCCESS.title`)}</div>
      <div
        className="view-crypto-cash-in__deposit_status--description"
        dangerouslySetInnerHTML={{
          __html: i18n(`cash_in.deposit_status.SUCCESS.description`, {
            amount: formatNumber(data.amount, locale, currencies[data.currency].display_decimal_places, currencies[data.currency].display_decimal_places),
            currency: data.currency,
          }),
        }}></div>
      <Button
        size="large"
        onClick={() => { 
          hideModal()
        }}>
        {i18n(`cash_in.deposit_status.SUCCESS.ok`)}
      </Button>
    </div>
  )
}
export default DepositSuccess
