import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWagerConstants, selectWagerConstants, selectWagerConstantsStatus } from 'store/app'
import constants from 'app/constants'

const useWagerConstants = () => {
  const dispatch = useDispatch()
  const wagerConstants = useSelector(selectWagerConstants)
  const wagerConstantsStatus = useSelector(selectWagerConstantsStatus)
  const isLoading = wagerConstantsStatus !== constants.COMPLETE

  useEffect(() => {
    if (wagerConstantsStatus === constants.IDLE) {
      dispatch(fetchWagerConstants())
    }
  }, [])

  if (wagerConstants) {
    return wagerConstants
  } else {
    return { isLoading }
  }
}

export default useWagerConstants
