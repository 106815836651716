import { BehaviorSubject } from 'rxjs'
import requestService from 'common/services/request'
import { urlSearchParams } from 'common/util/urlSearchParams'

const questionSubject = new BehaviorSubject()

function subscribe(f) {
  return questionSubject.subscribe(f)
}

async function getAll({ startIndex, stopIndex, ...filters }) {
  const params = new urlSearchParams(filters)
  if (startIndex) {
    params.set('offset', startIndex)
  }
  if (stopIndex) {
    params.set('limit', stopIndex - startIndex)
  }

  const response = await requestService.get(`/questions/?${params.toString()}`)
  if (response.ok) {
    return response.json()
  }

  throw new Error(`server error: ${response.status}`)
}

async function retrieve(questionId) {
  const response = await requestService.get(`/questions/${questionId}/`)
  if (response.ok) {
    const data = await response.json()
    questionSubject.next(data)
    return data
  }

  throw {
    status: response.status,
  }
}

async function retrieveWagerConstants() {
  const response = await requestService.get(`/wagers/get_wager_constants/`)
  if (response.ok) {
    const data = await response.json()
    return data
  }
  throw {
    status: response.status,
  }
}

async function price_history(questionId, options) {
  const params = new urlSearchParams(options)
  const response = await requestService.get(`/questions/${questionId}/price_history/?${params.toString()}`)
  if (response.ok) {
    const data = await response.json()
    return data
  }

  throw {
    status: response.status,
  }
}
async function retrieveOutcomePrices(questionId, options) {
  const params = new urlSearchParams(options)
  const response = await requestService.get(`/outcome_price/${questionId}/?${params.toString()}`)
  if (response.ok) {
    const data = await response.json()
    return data
  }

  throw {
    status: response.status,
  }
}

function isLive(question) {
  if (!question) {
    return false
  }

  const now = Date.now()
  const eventStartTS = Date.parse(question.event_start_date || '')
  const eventEndTS = Date.parse(question.event_end_date || '')

  if (question.status === 'o' && eventStartTS <= now && eventEndTS > now && question.resolution == null) {
    return true
  }

  return false
}

function isWaitingForResults(question) {
  if (!question) {
    return false
  }

  if (question.is_wagerable === false && question.status === 'o') {
    return true
  }
  const now = Date.now()
  const betEndTS = Date.parse(question.bet_end_date || '')
  if (question.status === 'o' && betEndTS <= now && question.resolution == null) {
    return true
  }

  return false
}
function isPaused(question) {
  if (!question) {
    return false
  }

  if (question.is_wagerable === false && question.status === 'p') {
    return true
  }

  return false
}

function hasUpcomingEvent(question) {
  if (!question) {
    return false
  }

  const FOUR_HOURS = 4 * 60 * 60 * 1000
  const now = Date.now()
  const eventStartTS = Date.parse(question.event_start_date || '')
  return question.status === 'o' && now + FOUR_HOURS >= eventStartTS && eventStartTS > now
}

function isResolved(question) {
  if (!question) {
    return false
  }

  if (question.status === 'c' && question.resolution != null && question.is_wagerable === false) {
    return true
  }

  return false
}

function isCancelled(question) {
  if (!question) {
    return false
  }

  if (question.status === 'x') return true

  return false
}

function isActive(question) {
  return question && question.status === 'o' && question.is_wagerable && !isWaitingForResults(question)
}

export default {
  subscribe,
  retrieve,
  retrieveOutcomePrices,
  price_history,
  getAll,
  isActive,
  isLive,
  isWaitingForResults,
  isResolved,
  isCancelled,
  hasUpcomingEvent,
  retrieveWagerConstants,
  isPaused
}

export const QuestionRuleService = Object.freeze({
  isActive,
  isLive,
  isWaitingForResults,
  isResolved,
  isCancelled,
  hasUpcomingEvent,
})

export const QuestionAPIService = Object.freeze({
  subscribe,
  retrieve,
  getAll,
})
