import React from 'react'

const FutuurOutlineIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 126" {...props}>
      <path
        d="M63.877 1H1.878C1.393 1 1 1.379 1 1.845v122.31c0 .466.393.845.878.845h30.744c.485 0 .878-.379.878-.845V94.564c0-.467.393-.846.878-.846h30.744c.485 0 .878-.378.878-.845V63.282a.83.83 0 00-.257-.598.896.896 0 00-.621-.248H3.928A.732.732 0 013.26 62a.685.685 0 01.155-.761l61.076-58.83a.821.821 0 00.156-.892.878.878 0 00-.77-.517z"
        fillRule="nonzero"
        stroke="#EDEFED"
        fill="none"
        opacity=".5"
      />
    </svg>
  )
}

export default FutuurOutlineIcon
