import React from 'react'

const usdtIcon = ({ fill = '#A6B5BD', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" {...props}>
      <path
        d="M9.629 5.042V3.11h4.425V.159H2.006v2.95H6.43v1.933C2.834 5.207.13 5.919.13 6.772c0 .854 2.704 1.566 6.3 1.732V14.7H9.63V8.502c3.59-.165 6.288-.877 6.288-1.73 0-.852-2.698-1.564-6.288-1.73zm0 2.934v-.001c-.09.006-.554.034-1.588.034-.825 0-1.406-.024-1.61-.034v.002C3.253 7.837.88 7.284.88 6.622c0-.661 2.372-1.214 5.55-1.357v2.161c.207.015.802.05 1.624.05.987 0 1.481-.04 1.574-.049v-2.16c3.17.142 5.537.695 5.537 1.355 0 .662-2.366 1.214-5.537 1.354z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default usdtIcon
