import Raven from 'raven-js'

export default class Sentry {
  static init() {
    return new Sentry()
  }

  constructor() {
    const dsn = process.env.REACT_APP_SENTRY_DSN || null
    const environment = process.env.REACT_APP_NODE_ENV
    const isDev = environment === 'development'

    const getPlatform = () => {
      return location.host === 'futuur.com' ? 'production' : 'development'
    }

    if (dsn != null && !isDev) {
      const release = process.env.REACT_APP_VERSION
      const platform = process.env.REACT_APP_ENVIRONMENT || getPlatform()

      Raven.config(dsn, {
        release,
        environment: getPlatform(),
        tags: { platform },
      }).install()
    }
  }

  static log(error, extra) {
    //error must be an Error instance
    Raven.captureException(error, { extra })
  }

  static setUserContext(user) {
    Raven.setUserContext({
      id: user.id,
      is_staff: user.is_staff,
      test_variants: user.test_variants,
      username: user.username,
      balance: user.balance,
    })
  }

  static clearUserContext() {
    Raven.setUserContext()
  }
}
