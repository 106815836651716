import React from 'react'

export default function FutuurIconB({ fillcolor, className, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      fill={fillcolor || 'currentColor'}
      {...props}
    >
      <path fillRule="evenodd" d="M6.258 11H16v4h-5v5H6V2h11L6.258 11z" />
    </svg>
  )
}
