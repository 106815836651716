import React from 'react'

const DotIcon = ({ color = 'currentColor', ...props }) => {
  return (
    <svg width={props.width || "12"} height={props.height || "12"} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={props.width || "12"} height={props.height || "12"} rx="6" fill={color || "#C2CED1"} />
    </svg>
  )
}

export default DotIcon
