import React from 'react'
import {
  LtcIcon,
  BtcIcon,
  BtcCurrencyBadge,
  RealMoneyIcon,
  LtcCurrencyBadge,
  BitcoinCashBadge,
  BinanceCoinBadge,
  DogecoingBadge,
  EthereumBadge,
  TronBadge,
  UsdcBadge,
  CeloDollarBadge,
  OomsIcon,
} from 'common/components/icons'
import constants from 'app/constants'

export const oomsCurrency = {
  id: 'OOM',
  label: 'Ooms',
  general_label: 'Play money',
  currencySymbol: <OomsIcon width="1em" />,
  ratio: 1,
  display_decimal_places: 2,
  display_code: 'Ooms',
  defaultBet: 100,
  default_bet_amount: 100,
  mainColor: '#1591ed',
}

export const bitcoin = {
  id: 'BTC',
  label: 'Bitcoin',
  currencySymbol: <BtcCurrencyBadge width="1em" />,
  icon: BtcIcon,
  display_code: 'mBTC',
  display_ratio: 0.001,
  display_decimal_places: 8,
  mainColor: '#F7931A',
  defaultBet: 0.0001,
  default_bet_amount: 0.0001,
}

export const litecoin = {
  id: 'LTC',
  label: 'Litecoin',
  currencySymbol: <LtcCurrencyBadge width="1em" />,
  icon: LtcIcon,
  display_code: 'LTC',
  display_ratio: 1,
  display_decimal_places: 8,
  mainColor: '#325A98',
  defaultBet: 0.01,
}

export const litecoinTest = {
  id: 'LTCT',
  label: 'Litecoin Test',
  currencySymbol: <LtcCurrencyBadge width="1em" />,
  icon: LtcIcon,
  display_code: 'LTCT',
  display_ratio: 1,
  display_decimal_places: 8,
  mainColor: '#325A98',
  defaultBet: 0.01,
  default_bet_amount: 0.01,
}

export const bitcoinCash = {
  id: 'BCH',
  label: 'Bitcoin Cash',
  currencySymbol: <BitcoinCashBadge width="1em" />,
  icon: BitcoinCashBadge,
  display_code: 'BCH',
  display_ratio: 1,
  display_decimal_places: 8,
  mainColor: '#8DC351',
  defaultBet: 0.01,
  default_bet_amount: 0.01,
}

export const binanceCoin = {
  id: 'BNB',
  label: 'Binance Coin',
  currencySymbol: <BinanceCoinBadge width="1em" />,
  icon: BinanceCoinBadge,
  display_code: 'BNB',
  display_ratio: 1,
  display_decimal_places: 8,
  mainColor: '#F3BA2F',
  defaultBet: 0.01,
  default_bet_amount: 0.01,
}

export const dogecoin = {
  id: 'DOGE',
  label: 'Dogecoin',
  currencySymbol: <DogecoingBadge width="1em" />,
  icon: DogecoingBadge,
  display_code: 'DOGE',
  display_ratio: 1,
  display_decimal_places: 8,
  mainColor: '#c2a633',
  defaultBet: 25,
  default_bet_amount: 25,
}

export const ethereum = {
  id: 'ETH',
  label: 'Ether',
  currencySymbol: <EthereumBadge width="1em" />,
  icon: EthereumBadge,
  display_code: 'ETH',
  display_ratio: 1,
  display_decimal_places: 8,
  mainColor: '#627eea',
  defaultBet: 0.001,
  default_bet_amount: 0.001,
}

export const tron = {
  id: 'TRX',
  label: 'Tron',
  currencySymbol: <TronBadge width="1em" />,
  icon: TronBadge,
  display_code: 'TRX',
  display_ratio: 1,
  display_decimal_places: 8,
  mainColor: '#ef0027',
  defaultBet: 100,
  default_bet_amount: 100,
}

export const celoDollar = {
  id: 'CUSD',
  label: 'Celo Dollar',
  currencySymbol: <CeloDollarBadge width="1em" />,
  icon: CeloDollarBadge,
  display_code: 'cUSD',
  display_ratio: 1,
  display_decimal_places: 8,
  mainColor: '#45CD85',
  defaultBet: 10,
  default_bet_amount: 10,
}

export const usdc = {
  id: 'USDC',
  label: 'USDC (ERC-20)',
  symbol: 'USDC',
  currencySymbol: <UsdcBadge width="1em" />,
  icon: UsdcBadge,
  display_code: 'USDC',
  display_ratio: 1,
  display_decimal_places: 8,
  mainColor: '#2775ca',
  defaultBet: 10,
  default_bet_amount: 10,
}

export const euros = {
  id: 'EUR',
  label: 'Euros',
  display_code: 'EUR',
  symbol: <span style={{ fontFamily: 'Montserrat' }}>€</span>,
  currencySymbol: <span style={{ fontFamily: 'Montserrat' }}>€</span>,
  display_ratio: 1,
  display_decimal_places: 2,
  defaultBet: 10,
  default_bet_amount: 10,
}

export const realMoneyPlaceholder = {
  id: constants.REAL_MONEY_PLACEHOLDER,
  label: 'Real Money',
  symbol: <RealMoneyIcon width="1em" fill="currentColor" />,
  currencySymbol: <RealMoneyIcon />,
  display_code: '',
  display_ratio: 1,
  display_decimal_places: 2,
  icon: RealMoneyIcon,
}

// Keep this coin for testing purposes
export const testCoin = {
  id: 'TEST',
  label: 'TestCoin',
  symbol: <span style={{ fontFamily: 'Encode Sans' }}>Test</span>,
  currencySymbol: <span>Test</span>,
  display_code: 'TC',
  display_ratio: 0.001,
  display_decimal_places: 8,
  icon: BtcIcon,
  mainColor: '#F7931A',
  defaultBet: 0.0002,
  default_bet_amount: 0.0002,
}

const cryptoCurrencies = [
  bitcoin.id,
  litecoin.id,
  binanceCoin.id,
  bitcoinCash.id,
  dogecoin.id,
  ethereum.id,
  tron.id,
  celoDollar.id,
  usdc.id,
]

export const isCryptoCurrency = currencyId => {
  return cryptoCurrencies.indexOf(currencyId) >= 0
}

export const parseCurrencyDecimalPlaces = (decimalPlaces, ratio) => {
  return ratio === 1 ? decimalPlaces : decimalPlaces - Math.abs(Math.log10(ratio))
}

export const isRealMoney = currencyId => currencyId && currencyId !== oomsCurrency.id

export const getCurrencyParameter = isRealMoney => (isRealMoney ? bitcoin.id : oomsCurrency.id)

export const parseCurrencyParameter = currencyId => getCurrencyParameter(isRealMoney(currencyId))

export const getMarketType = isRealMoney => (isRealMoney ? constants.REAL_MONEY : constants.PLAY_MONEY)

export const getCurrencyMode = currencyId => getMarketType(isRealMoney(currencyId))

export const convertBitcoinTo = (valueInBtc, exchangeRate) => {
  return valueInBtc * exchangeRate
}

export const convertToBitcoin = (valueInCurrency, exchangeRate) => {
  return valueInCurrency / exchangeRate
}

export const realMoneyCurrencies = {
  [bitcoin.id]: { ...bitcoin },
  [litecoin.id]: { ...litecoin },
  [bitcoinCash.id]: { ...bitcoinCash },
  [dogecoin.id]: { ...dogecoin },
  [ethereum.id]: { ...ethereum },
  [tron.id]: { ...tron },
  [usdc.id]: { ...usdc },
}

export default {
  ...realMoneyCurrencies,
  OOM: { ...oomsCurrency },
  EUR: { ...euros },
  LTCT: { ...litecoinTest },
  BNB: { ...binanceCoin },
  TEST: { ...testCoin },
  CANONICAL: { ...bitcoin },
  [constants.REAL_MONEY_PLACEHOLDER]: realMoneyPlaceholder,
}
