import React from 'react'

const InboxIcons = ({ fill = '#009FE3', ...props }) => {
  return (
    <svg fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 44" {...props}>
      <path d="M14.54.667a7.758 7.758 0 0 0-7.08 4.599L.841 20.159c-.114.23-.174.53-.174.825v16.254c0 3.342 2.753 6.095 6.095 6.095H51.46c3.342 0 6.095-2.753 6.095-6.095V20.984c0-.285-.046-.602-.174-.825-2.21-4.962-4.414-9.929-6.62-14.893a7.758 7.758 0 0 0-7.079-4.6H14.54Zm0 4.063h29.142c1.46 0 2.772.852 3.365 2.187l5.35 12.036h-9.468c-2.031 0-3.885 1.2-4.719 3.051l-1.992 4.42c-.181.404-.57.655-1.012.655h-12.19c-.442 0-.83-.251-1.012-.654l-1.992-4.421a5.186 5.186 0 0 0-4.718-3.052H5.825l5.35-12.035A3.671 3.671 0 0 1 14.54 4.73ZM4.73 23.016h10.564c.442 0 .83.251 1.011.655l1.992 4.42a5.186 5.186 0 0 0 4.719 3.052h12.19c2.031 0 3.885-1.2 4.718-3.052l1.993-4.42c.181-.404.57-.655 1.011-.655h10.564v14.222c0 1.16-.871 2.032-2.032 2.032H6.762c-1.16 0-2.032-.871-2.032-2.032V23.016Z" />
    </svg>
  )
}

export default InboxIcons
