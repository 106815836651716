import React from 'react'
import { useState } from 'react'

const FacebookIcon = () => {
  const [hovered, setHovered] = useState(false)
  return (
    <div onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {hovered ? (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="24" fill="#1977F3" />
          <path
            d="M26 25.5H28.5L29.5 21.5H26V19.5C26 18.47 26 17.5 28 17.5H29.5V14.14C29.174 14.097 27.943 14 26.643 14C23.928 14 22 15.657 22 18.7V21.5H19V25.5H22V34H26V25.5Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M26 25.5H28.5L29.5 21.5H26V19.5C26 18.47 26 17.5 28 17.5H29.5V14.14C29.174 14.097 27.943 14 26.643 14C23.928 14 22 15.657 22 18.7V21.5H19V25.5H22V34H26V25.5Z"
            fill="currentColor"
          />
          <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="currentColor" strokeOpacity="0.48" />
        </svg>
      )}
    </div>
  )
}

export default FacebookIcon
