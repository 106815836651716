import { useCurrentUser, useActiveCurrency } from './index'

const useUserBalance = () => {
  const activeCurrency = useActiveCurrency()
  const currentUser = useCurrentUser()

  if (currentUser && currentUser.wallet) {
    if (currentUser.wallet.hasOwnProperty(activeCurrency.id)) {
      return currentUser.wallet[activeCurrency.id]
    } else {
      return 0
    }
  }

  return null
}

export default useUserBalance
