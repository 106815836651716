import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchInfo, selectInfo } from 'store/app'
import { AppUpdate } from '@robingenz/capacitor-app-update'
import { useClearBrowserCache } from 'react-clear-browser-cache'

const useGeneralInfo = () => {
  const dispatch = useDispatch()
  const info = useSelector(selectInfo)
  const contextValue = useClearBrowserCache()

  useEffect(() => {
    dispatch(fetchInfo())
  }, [])
  const getCurrentAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo()
    return result.currentVersion
  }
  const refreshCache = async () => {
    if (info && info.info && localStorage.getItem('WEBAPP_LAST_COMMIT') != info.info?.WEBAPP_LAST_COMMIT) {
      localStorage.setItem('WEBAPP_LAST_COMMIT', info.info?.WEBAPP_LAST_COMMIT)
      if (contextValue && contextValue.clearCacheAndReload) {
        await contextValue.clearCacheAndReload()
      }
    }
  }

  return { ...info, getCurrentAppVersion, refreshCache }
}

export default useGeneralInfo
