import * as React from 'react'

export default function Crevron({ className, direction = 'down', ...props }) {
  return (
    <svg
      {...props}
      className={className}
      style={{ transform: direction === 'up' ? 'rotateZ(180deg)' : 'unset' }}
      viewBox="0 0 12 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99999 4.97667L10.125 0.851669L11.3033 2.03L5.99999 7.33334L0.696655 2.03L1.87499 0.851669L5.99999 4.97667Z"
        fill={props.fillcolor || 'var(--color-Neutral-6)'}
      />
    </svg>
  )
}
