import React from 'react'

export default function ScienceIcon({ fillcolor, className, ...props }) {
  return (
    <svg className={className} {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 2V4H15V7.243C15 8.40042 15.2511 9.54406 15.736 10.595L20.017 19.871C20.1226 20.0996 20.1689 20.351 20.1518 20.6022C20.1346 20.8534 20.0545 21.0963 19.9188 21.3083C19.7831 21.5204 19.5962 21.695 19.3754 21.8158C19.1545 21.9367 18.9068 22 18.655 22H5.344C5.09222 22 4.84449 21.9367 4.62362 21.8158C4.40276 21.695 4.21587 21.5204 4.08019 21.3083C3.94451 21.0963 3.8644 20.8534 3.84724 20.6022C3.83008 20.351 3.87642 20.0996 3.982 19.871L8.263 10.595C8.74827 9.54414 8.99972 8.40049 9 7.243V4H8V2H16ZM13.387 10.001H10.611C10.5067 10.3648 10.3819 10.7224 10.237 11.072L10.079 11.434L6.124 20H17.874L13.92 11.434C13.7059 10.9703 13.5278 10.4909 13.387 10V10.001ZM11 7.243C11 7.496 10.99 7.749 10.972 8.001H13.029C13.02 7.8798 13.0134 7.75845 13.009 7.637L13 7.243V4H11V7.243Z"
        fill={fillcolor || 'currentColor'}
      />
    </svg>
  )
}
