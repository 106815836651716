import fromEntriesPolyfill from 'object.fromentries'
const fromEntries = searchParams => {
  if (Object.fromEntries) {
    return Object.fromEntries(searchParams)
  }

  return fromEntriesPolyfill(searchParams)
}

export { fromEntries }
