
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { feedUrl } from 'urls'
import FutuurLogo from 'common/components/FutuurLogo'

import './SignupLayout.scss'
import { ArrowRightTailed } from 'common/components/icons'
import Steper from 'common/ui/steps'

const SignupLayout = ({
  children,
  title,
  displayGoBackArrow = true,
  onGoBack,
  steperCurrent = null,
  style,
}) => {
  const navigate = useNavigate()

  const _onGoBack = () => {
    onGoBack ? onGoBack() : navigate(feedUrl())
  }


  return (
    <section className="signup-layout">
      <div className="signup-layout--left  d-none d-md-flex">
        <Link to={feedUrl()}>
          <FutuurLogo white width="1em" height="1em" />
        </Link>

        <h1>
          Bet on <br /> the future
        </h1>
      </div>
      <div className="signup-layout--right" style={style}>
        {displayGoBackArrow && (
          <span className="signup-layout__back" onClick={() => _onGoBack()}>
            <ArrowRightTailed style={{ transform: 'rotateY(180deg)' }} fillcolor="currentColor" width="1em" />
          </span>
        )}
        <main className="signup-layout__main">
          {steperCurrent !== null && steperCurrent >= 0 && (
            <div className="signup-layout__steper">
              <Steper style="dots" total={3} current={steperCurrent} currentFill="#1591ED" />
            </div>
          )}
          {title && <h2 className="signup-layout__title">{title}</h2>}
          {children}
        </main>
      </div>
    </section>
  )
}

export default SignupLayout
