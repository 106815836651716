import React from 'react'

export default function EyeIcon({ fillcolor, className, outline = false, ...props }) {
  return outline ? (
    <svg
      className={className}
      fill={fillcolor || 'currentColor'}
      {...props}
      viewBox="0 0 19 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.0002 0.5C14.4935 0.5 18.2319 3.73333 19.016 8C18.2327 12.2667 14.4935 15.5 10.0002 15.5C5.50688 15.5 1.76854 12.2667 0.984375 8C1.76771 3.73333 5.50688 0.5 10.0002 0.5ZM10.0002 13.8333C11.6998 13.833 13.3489 13.2557 14.6776 12.196C16.0063 11.1363 16.936 9.65689 17.3144 8C16.9346 6.34442 16.0043 4.86667 14.6757 3.80835C13.3471 2.75004 11.6988 2.17377 10.0002 2.17377C8.30162 2.17377 6.65328 2.75004 5.32469 3.80835C3.99609 4.86667 3.06585 6.34442 2.68604 8C3.06446 9.65689 3.9941 11.1363 5.32283 12.196C6.65155 13.2557 8.30065 13.833 10.0002 13.8333V13.8333ZM10.0002 11.75C9.00565 11.75 8.05182 11.3549 7.34856 10.6516C6.6453 9.94839 6.25021 8.99456 6.25021 8C6.25021 7.00544 6.6453 6.05161 7.34856 5.34835C8.05182 4.64509 9.00565 4.25 10.0002 4.25C10.9948 4.25 11.9486 4.64509 12.6519 5.34835C13.3551 6.05161 13.7502 7.00544 13.7502 8C13.7502 8.99456 13.3551 9.94839 12.6519 10.6516C11.9486 11.3549 10.9948 11.75 10.0002 11.75ZM10.0002 10.0833C10.5527 10.0833 11.0826 9.86384 11.4733 9.47314C11.864 9.08244 12.0835 8.55253 12.0835 8C12.0835 7.44747 11.864 6.91756 11.4733 6.52686C11.0826 6.13616 10.5527 5.91667 10.0002 5.91667C9.44767 5.91667 8.91777 6.13616 8.52707 6.52686C8.13637 6.91756 7.91688 7.44747 7.91688 8C7.91688 8.55253 8.13637 9.08244 8.52707 9.47314C8.91777 9.86384 9.44767 10.0833 10.0002 10.0833Z" />
    </svg>
  ) : (
    <svg
      className={className}
      fill={fillcolor || 'currentColor'}
      {...props}
      viewBox="0 0 19 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.984375 8C1.76771 3.73333 5.50688 0.5 10.0002 0.5C14.4935 0.5 18.2319 3.73333 19.016 8C18.2327 12.2667 14.4935 15.5 10.0002 15.5C5.50688 15.5 1.76854 12.2667 0.984375 8ZM10.0002 12.1667C11.1053 12.1667 12.1651 11.7277 12.9465 10.9463C13.7279 10.1649 14.1669 9.10507 14.1669 8C14.1669 6.89493 13.7279 5.83512 12.9465 5.05372C12.1651 4.27232 11.1053 3.83333 10.0002 3.83333C8.89514 3.83333 7.83533 4.27232 7.05393 5.05372C6.27253 5.83512 5.83354 6.89493 5.83354 8C5.83354 9.10507 6.27253 10.1649 7.05393 10.9463C7.83533 11.7277 8.89514 12.1667 10.0002 12.1667ZM10.0002 10.5C9.33717 10.5 8.70128 10.2366 8.23244 9.76777C7.7636 9.29893 7.50021 8.66304 7.50021 8C7.50021 7.33696 7.7636 6.70107 8.23244 6.23223C8.70128 5.76339 9.33717 5.5 10.0002 5.5C10.6632 5.5 11.2991 5.76339 11.768 6.23223C12.2368 6.70107 12.5002 7.33696 12.5002 8C12.5002 8.66304 12.2368 9.29893 11.768 9.76777C11.2991 10.2366 10.6632 10.5 10.0002 10.5Z" />
    </svg>
  )
}
