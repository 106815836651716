import { searchUrl } from 'explore/urls'
import { howItWorksUrl } from 'landing/urls'
import { activityUrl, questionDetailUrlById, userUrl, walletUrl, cashInUrl, feedUrl } from 'urls'

const getRedirectUrl = data => {
  if ('link' in data && data['link']) {
    const link = data['link']
    if (link.indexOf('http') !== -1) return { isExternel: true, link: data['link'] }
    else return data['link']
  }
  if ('redirectUrl' in data) {
    return data['redirectUrl']
  }

  if ('object_id' in data) {
    data['objectId'] = data['object_id']
  }

  if ('type_code' in data) {
    data['type'] = data['type_code']
  }

  if ('objectType' in data) {
    data['type'] = data['objectType']
  }
  if (data['object_type'] === 'wager') {
    return questionDetailUrlById(data.related_object.question.id)
  }

  if ('type' in data) {
    switch (data['type']) {
      case 'wager_won':
      case 'wager_lost':
      case 'wager_purchased':
      case 'wager_sold':
      case 'comment_made':
      case 'wager_reversed':
      case 'wager':
        return questionDetailUrlById(data['objectId'])

      case 'amount_earned':
        return howItWorksUrl()

      case 'invite_received':
      case 'invites_received':
        return searchUrl({ type: 'users' })

      case 'deposit_success':
      case 'deposit_pending':
      case 'withdrawal_success':
      case 'withdrawal_pending':
      case 'withdrawal_request':
        return walletUrl()

      case 'real_money_callout':
        return cashInUrl()

      case 'question_cancelled':
        if (data.related_object && data.related_object.question) {
          return questionDetailUrlById(data.related_object.question.id)
        }
        return feedUrl()

      case 'question':
      case 'question_resolved':
      case 'question_live':
      case 'question_is_live':
      case 'wager_disabled':
        return questionDetailUrlById(data['objectId'])

      case 'user':
      case 'follow_created':
      case 'new_follower':
      case 'new_follow':
        if (data.hasOwnProperty('object_type') && data.object_type === 'user' && data.related_object) {
          return userUrl({ username: data.related_object.username })
        }
        return userUrl({ username: data['objectId'] })

      default:
        return activityUrl()
    }
  }

  return null
}

export { getRedirectUrl }
