import React from 'react'

export default function s({ fillcolor, className, ...props }) {
  return (
    <svg
      className={className}
      fill={fillcolor || 'currentColor'}
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 9.50004H3.207L7.864 4.84304L7.157 4.13604L2.5 8.79304V9.50004ZM3.207 10.5H1.5V8.37854L8.2175 1.66104C8.31126 1.56731 8.43842 1.51465 8.571 1.51465C8.70358 1.51465 8.83074 1.56731 8.9245 1.66104L10.339 3.07554C10.4327 3.16931 10.4854 3.29646 10.4854 3.42904C10.4854 3.56162 10.4327 3.68878 10.339 3.78254L4.6215 9.50004L3.207 10.5ZM7.864 3.42904L8.571 4.13604L9.278 3.42904L8.571 2.72204L7.864 3.42904Z"
        fill="white"
      />
    </svg>
  )
}
