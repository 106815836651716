import React from 'react'

const CurrencyIcon = ({ currency, width, height }) =>
  currency && currency.icon ? (
    <svg width={width} height={height || width}>
      <image href={currency.icon} width={width} height={height || width} />
    </svg>
  ) : (
    currency && React.cloneElement(currency.currencySymbol, {
      width,
      height: height || width,
    })
  )

export default CurrencyIcon
