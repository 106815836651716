import { useEffect, useState } from 'react'

const useScrollPositionAtBottom = (container, threshold = 100) => {
  const [isAtBottom, setScrollAtBottom] = useState(false)

  useEffect(() => {
    let ticking = false

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (!container || !container.current) {
            return
          }

          const documentElement = global.document.documentElement
          const containerBottom = container.current.getBoundingClientRect().bottom
          const maxScroll = containerBottom - documentElement.clientHeight - threshold

          if (maxScroll < 0) {
            setScrollAtBottom(true)
          } else {
            setScrollAtBottom(false)
          }
          ticking = false
        })
        ticking = true
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return isAtBottom
}
export default useScrollPositionAtBottom
