import React from 'react'
import './PhonePage.scss' 
import { ArrowRightTailed } from '../icons'
import { useNavigate } from 'react-router-dom'
import useIsMobileSize from 'common/hooks/useIsMobileSize'

const PhonePage = ({ children, title }) => {
  const navigate = useNavigate()
  const isMobileSize = useIsMobileSize();

  if (!isMobileSize) return children

  return (
    <div className="phone-page">
      <header className="phone-page-header">
        <ArrowRightTailed
          onClick={() => {
            navigate(-1)
          }}
          style={{ transform: 'rotateY(180deg)' }}
          width="16px"
        />
        <h2>{title}</h2>
      </header>
      <>{children}</>
    </div>
  )
}
export default PhonePage
