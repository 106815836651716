/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import SubCategoryList from 'common/components/categories/SubCategoryList'
import classNames from 'classnames'
import Slider from 'common/ui/slider/Slider'
import PropTypes from 'prop-types'
import './CategoryNavigation.scss'

const CategoryNavigation = ({ parent, subcategories }) => {
  const [isSliderLocked, setSliderLocked] = useState(false)
  const id = parent ? parent.id : 'auto'
  const categoryNavigationClassName = classNames('category-navigation', {
    'category-navigation--locked': isSliderLocked,
    'category-navigation--no-subcategories': !subcategories || !subcategories.length,
  })

  const handleSliderLock = swiper => {
    setSliderLocked(swiper.isLocked)
  }

  const sliderSettings = {
    slidesPerView: 'auto',
    freeMode: true,
    onResize: handleSliderLock,
    onInit: handleSliderLock,
    onUpdate: handleSliderLock,
  } 
  return (
    <nav className={categoryNavigationClassName}>
      {subcategories && subcategories.length > 0 && (
        <Slider key={`category-navigation-${id}`} settings={sliderSettings}>
          {[...SubCategoryList({ subcategories: subcategories })]}
        </Slider>
      )}
    </nav>
  )
}

CategoryNavigation.propTypes = {
  parent: PropTypes.objectOf(
    PropTypes.objectOf({ tree: PropTypes.arrayOf({ title: PropTypes.string, href: PropTypes.string }) })
  ),
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
    })
  ),
}

export default CategoryNavigation
