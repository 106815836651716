import React from 'react'
import Button from 'common/ui/button'
import classnames from 'classnames'
import './Tooltip.scss'

const Tooltip = ({ children, buttons = [], componentClassName, indicatorClassName, light = false }) => {
  const renderFooter = () => {
    return (
      <footer className="tooltip__footer">
        {buttons.map((button, idx) => (
          <Button key={'tooltip-button-' + idx} className="tooltip__button" flat onClick={button.onClick}>
            {button.label}
          </Button>
        ))}
      </footer>
    )
  }

  const classNamesContainer = classnames('tooltip', componentClassName)
  const classNamesArrow = classnames('tooltip__arrow', indicatorClassName, { 'tooltip__arrow--light': light })
  return (
    <div className={classNamesContainer} role="tooltip">
      <i className={classNamesArrow} />
      <div
        className={classnames('tooltip__content', {
          'tooltip__content--light': light,
        })}
      >
        {children}
        {buttons.length > 0 && renderFooter()}
      </div>
    </div>
  )
}

export default Tooltip
