import { useEffect } from 'react'
import { FacebookLogin } from '@capacitor-community/facebook-login'
import Device from 'common/util/Device'

const useFacebookApi = () => {
  useEffect(() => {
    try {
      if (Device.isIOS || Device.isAndroid) FacebookLogin.initialize({ appId: process.env.REACT_APP_FACEBOOK_APP_ID })
    } catch (e) {
      console.log(e)
    }

    window.fbAsyncInit = function () {
      // eslint-disable-next-line
      FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: 'v5.0', // use graph api current version
      })
    }
    ;(function (d, s, id) {
      // Load the SDK asynchronously
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }, [])
}

export default useFacebookApi
