import React from 'react'

const oomsIcon = ({ fill = '#1591ed', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M15.6888 8.49901C16.5303 9.40631 17 10.6489 17 11.9901C17 14.712 15.1213 17 11.9902 17C10.8748 17 9.91585 16.7041 9.15264 16.211L8.68297 16.7633H7.15656L8.27202 15.4418C7.4501 14.5345 7 13.3116 7 11.9901C7 9.28797 8.87867 7 11.9902 7C13.0665 7 14.0059 7.27613 14.7495 7.72978L15.18 7.23669H16.7456L15.6888 8.49901ZM9.58317 11.9901C9.58317 12.5424 9.70059 13.0552 9.91585 13.4892L13.2231 9.5641C12.8708 9.34714 12.4599 9.2288 11.9902 9.2288C10.4442 9.2288 9.58317 10.5108 9.58317 11.9901ZM11.9902 14.7712C13.5362 14.7712 14.4168 13.4892 14.4168 11.9901C14.4168 11.4181 14.2798 10.8856 14.045 10.432L10.6986 14.3965C11.0509 14.6331 11.4814 14.7712 11.9902 14.7712Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM17 11.9901C17 10.6489 16.5303 9.40631 15.6888 8.49901L16.7456 7.23669H15.18L14.7495 7.72978C14.0059 7.27613 13.0665 7 11.9902 7C8.87867 7 7 9.28797 7 11.9901C7 13.3116 7.4501 14.5345 8.27202 15.4418L7.15656 16.7633H8.68297L9.15264 16.211C9.91585 16.7041 10.8748 17 11.9902 17C15.1213 17 17 14.712 17 11.9901ZM9.91585 13.4892C9.70059 13.0552 9.58317 12.5424 9.58317 11.9901C9.58317 10.5108 10.4442 9.2288 11.9902 9.2288C12.4599 9.2288 12.8708 9.34714 13.2231 9.5641L9.91585 13.4892ZM14.4168 11.9901C14.4168 13.4892 13.5362 14.7712 11.9902 14.7712C11.4814 14.7712 11.0509 14.6331 10.6986 14.3964L14.045 10.432C14.2798 10.8856 14.4168 11.4181 14.4168 11.9901Z"
        fill={fill}
      />
    </svg>
  )
}

export default oomsIcon
