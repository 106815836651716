import React from 'react'

const Star = props => {
  return (
    <svg {...props} viewBox="0 0 24 23" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 18.26L4.947 22.208L6.522 14.28L0.587002 8.792L8.614 7.84L12 0.5L15.386 7.84L23.413 8.792L17.478 14.28L19.053 22.208L12 18.26ZM12 15.968L16.247 18.345L15.298 13.572L18.871 10.267L14.038 9.694L12 5.275L9.962 9.695L5.129 10.267L8.702 13.572L7.753 18.345L12 15.968Z" />
    </svg>
  )
}

export default Star
