import requestService from 'common/services/request'
import { createSlice } from '@reduxjs/toolkit'
import constants from 'app/constants'
import { transformArrayIntoMap } from 'common/util/mapArray'
import { toastService } from 'api/toast'

const initialState = {
  pool: { data: {}, status: {}, error: {}, summary: {} },
  byUsername: { data: {}, status: {}, error: {} },
  summary: { data: {}, status: {}, error: {} },
  followers: { data: {}, status: {}, error: {}, pagination: {} },
  following: { data: {}, status: {}, error: {}, pagination: {} },
}

// Utils
const userLoading = (user, state, node) => {
  state[node].status[user.id] = constants.LOADING
}

const userLoadingArray = (user, state, node) => {
  state[node].status[user.id] = constants.LOADING
  if (!state[node].data[user.id]) {
    state[node].data[user.id] = []
  }
}

const userSuccessArray = (user, state, node, data, pagination) => {
  if (!state[node].data[user.id]) {
    state[node].data[user.id] = []
  }

  state[node].status[user.id] = constants.COMPLETE
  state[node].data[user.id] = state[node].data[user.id].concat(data.map(d => d.id))
  state[node].pagination[user.id] = pagination
  data.forEach(u => {
    state.pool.data[u.id] = u
  })
}

const userSuccess = (user, state, node, data) => {
  state[node].status[user.id] = constants.COMPLETE
  state[node].data[user.id] = data
}

const userError = (user, state, node, error) => {
  state[node].status[user.id] = constants.ERROR
  state[node].error[user.id] = error
}

// Reducers / Slices
const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUserByUsernameStart: (state, action) => {
      const { username } = action.payload
      state.byUsername.status[username] = constants.LOADING
    },

    getUserByUsernameSuccess: (state, action) => {
      const { user } = action.payload
      state.pool.data[user.id] = user
      state.pool.status[user.id] = constants.COMPLETE
      state.byUsername.status[user.username] = constants.COMPLETE
      state.byUsername.data[user.username] = user
    },

    getUserByUsernameError: (state, action) => {
      const { username, error } = action.payload
      state.byUsername.status[username] = constants.ERROR
      state.byUsername.error[username] = error
    },

    getUserSummaryStart: (state, action) => {
      userLoading(action.payload.user, state, 'summary')
    },

    getUserSummarySuccess: (state, action) => {
      userSuccess(action.payload.user, state, 'summary', action.payload.summary)
    },

    getUserSummaryError: (state, action) => {
      const { user, error } = action.payload
      userError(user, state, 'summary', error)
    },

    getUserFollowersStart: (state, action) => {
      userLoadingArray(action.payload.user, state, 'followers')
    },

    getUserFollowersSuccess: (state, action) => {
      const { user, results, pagination } = action.payload
      userSuccessArray(user, state, 'followers', results, pagination)
    },

    getUserFollowersError: (state, action) => {
      userError(action.payload.user, state, 'followers', action.payload.error)
    },

    getUserFollowingStart: (state, action) => {
      userLoadingArray(action.payload.user, state, 'following')
    },

    getUserFollowingSuccess: (state, action) => {
      userSuccessArray(action.payload.user, state, 'following', action.payload.results, action.payload.pagination)
    },

    getUserFollowingError: (state, action) => {
      userError(action.payload.user, state, 'following', action.payload.error)
    },

    removeFollowingUser: (state, action) => {
      const { userId, followingUserId } = action.payload

      if (state.following.data[userId]) {
        state.following.data[userId] = state.following.data[userId].filter(id => id !== followingUserId)
      }

      if (state.summary.data[userId]) {
        state.summary.data[userId].following_user_ids = state.summary.data[userId].following_user_ids.filter(
          id => id !== followingUserId
        )
      }
    },

    addFollowingUser: (state, action) => {
      const { userId, followingUserId } = action.payload
      if (state.pool.data[userId] && state.pool.data[followingUserId]) {
        state.following.data[userId].push(followingUserId)
      }

      if (state.summary.data[userId]) {
        state.summary.data[userId].following_user_ids.push(followingUserId)
      }
    },

    addUsersToPool: (state, action) => {
      const { users } = action.payload
      if (state.pool && state.pool.data) {
        const userMap = transformArrayIntoMap(users)
        state.pool.data = { ...state.pool.data, ...userMap }
      }
    },
  },
})

export const { getUserByUsernameStart, getUserByUsernameSuccess, getUserByUsernameError } = users.actions
export const { getUserFollowersStart, getUserFollowersError, getUserFollowersSuccess } = users.actions
export const { getUserFollowingStart, getUserFollowingError, getUserFollowingSuccess } = users.actions
export const { getUserSummaryStart, getUserSummarySuccess, getUserSummaryError, addUsersToPool } = users.actions
export const { removeFollowingUser, addFollowingUser } = users.actions
export default users.reducer

// Selectors:
export const selectUserByUsername = username => state => {
  // const userId = state.users.byUsername.data[username]
  return state.users.byUsername.data[username]
}

export const selectUserStatusByUsername = username => state => {
  return state.users.byUsername.status[username] || constants.IDLE
}

export const selectUserErrorByUsername = username => state => {
  return state.users.byUsername.error[username]
}

export const selectUserSummary = user => state => {
  if (!user) {
    return null
  }
  return state.users.summary.data[user.id]
}

export const selectUserSummaryStatus = user => state => {
  if (!user) {
    return constants.IDLE
  }
  return state.users.summary.status[user.id] || constants.IDLE
}

export const selectUserSummaryErrorByUsername = user => state => {
  if (!user) return null
  return state.users.summary.error[user.id]
}

export const selectUserFollowers = user => state => {
  if (!user || !state.users.followers.data[user.id]) return []
  return state.users.followers.data[user.id].map(userId => state.users.pool.data[userId])
}

export const selectUserFollowersStatus = user => state => {
  if (!user) return null
  return state.users.followers.status[user.id] || constants.IDLE
}

export const selectUserFollowersPagination = user => state => {
  if (!user || !state.users.followers.pagination[user.id]) return {}
  return state.users.followers.pagination[user.id]
}

export const selectUserFollowing = user => state => {
  if (!user || !state.users.following.data[user.id]) return []
  return state.users.following.data[user.id].map(userId => state.users.pool.data[userId])
}

export const selectUserFollowingStatus = user => state => {
  if (!user) return null
  return state.users.following.status[user.id] || constants.IDLE
}

export const selectUserFollowingPagination = user => state => {
  if (!user || !state.users.following.pagination[user.id]) return {}
  return state.users.following.pagination[user.id]
}

// Thunks:

export const fetchUserByUsername = username => async dispatch => {
  dispatch(getUserByUsernameStart({ username }))
  try {
    var response = await requestService.get(`/users/${username}/`)
  } catch (err) {
    dispatch(getUserByUsernameError({ username, err }))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }

  const user = await response.json()
  dispatch(getUserByUsernameSuccess({ user }))
}

export const fetchUserSummary = user => async dispatch => {
  dispatch(getUserSummaryStart({ user }))
  try {
    var response = await requestService.get(`/users/${user.id}/summary/`)
  } catch (error) {
    dispatch(getUserSummaryError({ user, error }))
    throw error
  }

  const summary = await response.json()
  dispatch(getUserSummarySuccess({ user, summary }))
}

export const fetchUserFollowers =
  (user, options = {}) =>
  async dispatch => {
    const params = requestService.parseFilters(options)
    dispatch(getUserFollowersStart({ user }))
    try {
      var response = await requestService.get(`/users/${user.username}/followers/?${params.toString()}`)
    } catch (error) {
      dispatch(getUserFollowersError({ user, error }))
    }
    const results = await response.json()
    dispatch(getUserFollowersSuccess({ user, ...results }))
  }

export const fetchUserFollowing =
  (user, options = {}) =>
  async dispatch => {
    const params = requestService.parseFilters(options)
    dispatch(getUserFollowingStart({ user }))
    try {
      var response = await requestService.get(`/users/${user.username}/following/?${params.toString()}`)
    } catch (error) {
      dispatch(getUserFollowingError({ user, error }))
    }
    const results = await response.json()
    dispatch(getUserFollowingSuccess({ user, ...results }))
  }

export const followUser = (user, followingId) => async dispatch => {
  try {
    await requestService.post(`/users/${followingId}/follow/`)
    dispatch(addFollowingUser({ userId: user.id, followingUserId: followingId }))
  } catch (error) {
    console.error(error)
  }
}

export const unfollowUser = (user, followingId) => async dispatch => {
  try {
    await requestService.post(`/users/${followingId}/unfollow/`)
    dispatch(removeFollowingUser({ userId: user.id, followingUserId: followingId }))
  } catch (error) {
    console.error(error)
  }
}
