export const accountUrl = () => '/a'
export const settingsUrl = setting => (setting ? `/a/settings/${setting}` : `/a/settings`)
export const userUrl = user => `/${user.username}`
export const userUrlByUsername = username => `/${username}`
export const publicFollowUrl = user => `${userUrl(user)}/follow/`
export const followersUrl = user => `${userUrl(user)}/followers/`
export const followingUrl = user => `${userUrl(user)}/following/`

export const canonicalUserUrl = user => `${process.env.REACT_APP_FRONT_URL}${user.username}`
export const canonicalFollowersUrl = username => `${process.env.REACT_APP_FRONT_URL}${username}/followers/`
export const canonicalFollowingUrl = username => `${process.env.REACT_APP_FRONT_URL}${username}/following/`
