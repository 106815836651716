/* @flow */
import * as React from 'react'
import { withFormsy } from 'formsy-react'
import classNames from 'classnames'
import './FormField.scss'
import { dataURL64toDataView, dataURLToFile } from '../util/dataUrl'
import { transparentGif } from '../util/image'

// degrees are in clockwise orientation
type OrientationValue =
  | 1 // No rotation (or orientation info not found)
  | 2 // Horizontal flip
  | 3 // 180deg
  | 4 // Vertical flip
  | 5 // Horizontal flip + 270deg
  | 6 // 270deg
  | 7 // Horizontal flip + 90deg
  | 8 // 90deg

type Props = {
  className: ?string,
  outputMaxWidth: number,
  outputMaxHeight: number,
  outputFormat: string,
  outputQuality: number,
  placeholderImg: string,
  disabled: boolean,
  imageHasChanged: (file: File) => void,
  // FormsyHOC -----------------------
  name: string,
  id: string,
  isPristine: () => boolean,
  isValid: () => boolean,
  showRequired: () => boolean,
  setValue: (value: ?(string | File)) => any,
}

type State = {
  dataURL: ?string,
}

class FormImage extends React.Component<Props, State> {
  static defaultProps = {
    outputMaxWidth: 1024,
    outputMaxHeight: 1024,
    outputFormat: 'image/jpeg',
    outputQuality: 0.6,
  }

  state = {
    dataURL: null,
  }

  input: ?HTMLInputElement = null

  /** Initialize Canvas and apply orientatio to context
   * Based on: http://stackoverflow.com/a/31273162/7225515
   */
  getCanvasContext = (
    canvas: HTMLCanvasElement,
    orientation: OrientationValue,
    size: number
  ): ?CanvasRenderingContext2D => {
    const width = size
    const height = size

    /* eslint-disable no-param-reassign */
    if ([5, 6, 7, 8].indexOf(orientation) < 0) {
      canvas.width = width
      canvas.height = height
    } else {
      canvas.width = height
      canvas.height = width
    }
    /* eslint-enable no-param-reassign */

    const context = canvas.getContext('2d')

    if (context == null) {
      return null
    }

    /* eslint-disable no-multi-spaces, space-in-parens */
    switch (orientation) {
      case 1:
        break
      case 2:
        context.transform(-1, 0, 0, 1, width, 0)
        break
      case 3:
        context.transform(-1, 0, 0, -1, width, height)
        break
      case 4:
        context.transform(1, 0, 0, -1, 0, height)
        break
      case 5:
        context.transform(0, 1, 1, 0, 0, 0)
        break
      case 6:
        context.transform(0, 1, -1, 0, height, 0)
        break
      case 7:
        context.transform(0, -1, -1, 0, height, width)
        break
      case 8:
        context.transform(0, -1, 1, 0, 0, width)
        break
      default:
        break
    }
    /* eslint-enable no-multi-spaces */

    return context
  }

  resizeImage = (img: HTMLImageElement, orientation: OrientationValue): ?string => {
    const { outputMaxWidth, outputMaxHeight, outputFormat, outputQuality } = this.props
    let inputX = 0
    let inputY = 0
    let inputWidth = img.width
    let inputHeight = img.height
    const inputAR = inputWidth / inputHeight
    let outputWidth = 0
    let outputHeight = 0
    const outputAR = outputMaxWidth / outputMaxHeight

    if (Math.abs(inputAR - outputAR) < Number.EPSILON) {
      /** Input with same AR -> No cropping */
      outputWidth = Math.min(inputWidth, outputMaxWidth)
      outputHeight = Math.min(inputHeight, outputMaxHeight)
    } else if (inputAR > outputAR) {
      /** Input wider than output -> Crop horizontally */
      outputHeight = Math.min(inputHeight, outputMaxHeight)
      outputWidth = Math.round(outputHeight * outputAR)
      const inputCroppedWidth = Math.round(inputHeight * outputAR)
      inputX = (inputWidth - inputCroppedWidth) / 2
      inputWidth = inputCroppedWidth
    } else if (inputAR < outputAR) {
      /** Input narrower than output -> Crop vertically */
      outputWidth = Math.min(inputWidth, outputMaxWidth)
      outputHeight = Math.round(outputWidth / outputAR)
      const inputCroppedHeight = Math.round(inputWidth / outputAR)
      inputY = (inputHeight - inputCroppedHeight) / 2
      inputHeight = inputCroppedHeight
    } else if (process.env.REACT_APP_NODE_ENV !== 'production') {
      /** Error */
      // eslint-disable-next-line no-console
      console.error('Error processing image')
    }

    if (outputWidth === outputHeight) {
      console.warn('Canvas is not square')
    }

    const canvas = global.document.createElement('canvas')
    const context = this.getCanvasContext(canvas, orientation, outputWidth)

    if (!context) {
      return null
    }

    context.drawImage(img, inputX, inputY, inputWidth, inputHeight, 0, 0, outputWidth, outputHeight)

    return canvas.toDataURL(outputFormat, outputQuality)
  }

  /** Checks image rotation
   * Based on: http://stackoverflow.com/a/32490603
   */
  checkOrientation = (view: ?DataView): OrientationValue => {
    if (!view) {
      console.warn('Invalid image')
      return 1 // Invalid image
    }

    const length = view.byteLength
    let offset = 0

    // Check file header
    if (view.getUint16(offset) !== 0xffd8) {
      console.warn('Not a JPEG')
      return 1
    }

    offset += 2

    while (offset < length) {
      const marker = view.getUint16(offset)
      offset += 2

      if (marker === 0xffe1) {
        offset += 2

        if (view.getUint32(offset) !== 0x45786966) {
          console.warn('Orientation data not defined')
          return 1
        }

        offset += 6

        const little = view.getUint16(offset) === 0x4949
        offset += view.getUint32(offset + 4, little)

        const tagCount = view.getUint16(offset, little)
        offset += 2

        for (let tag = 0; tag < tagCount; tag++) {
          if (view.getUint16(offset + tag * 12, little) === 0x0112) {
            // $FlowIgnore Ignore typechecking from file data
            return view.getUint16(offset + tag * 12 + 8, little)
          }
        }
      } else if ((marker & 0xff00) !== 0xff00) {
        break
      } else {
        offset += view.getUint16(offset, false)
      }
    }

    console.warn('Orientation data not found')
    return 1
  }

  onInputRef = (element: ?HTMLInputElement) => {
    this.input = element
  }

  onImageChange = (event: any) => {
    event.preventDefault()
    const file = event.target.files[0]
    const imageReader = new global.FileReader()
    // reader.onload = this.onImageLoad;
    imageReader.onloadend = this.onImageRead
    imageReader.readAsDataURL(file)
    this.props.imageHasChanged(file)
  }

  /** Load as img element */
  onImageRead = (event: any) => {
    const img = new global.Image()
    img.src = event.target.result

    if (img.complete) {
      this.onImageLoad(img)
    }

    img.onload = () => {
      this.onImageLoad(img)
    }
  }

  onImageLoad = (img: HTMLImageElement) => {
    const dataView = dataURL64toDataView(img.src)
    const orientation = this.checkOrientation(dataView)
    const dataURL = this.resizeImage(img, orientation)

    if (!dataURL) {
      return
    }

    const file = dataURLToFile(dataURL)
    this.props.setValue(file)
    this.setState({ dataURL })
  }

  render() {
    const imageURL = this.state.dataURL || this.props.placeholderImg || transparentGif
    const className = classNames('field--image', this.props.className, {
      'field--image--required': this.props.showRequired(),
      'field--image--invalid': !this.props.isPristine() && !this.props.isValid(),
    })
    const { disabled } = this.props

    return (
      <div className={className}>
        <img className="field--image__preview" src={imageURL} alt="" />
        <input
          id={this.props.id}
          className="field--image__input"
          name={this.props.name}
          type="file"
          accept="image/*"
          ref={this.onInputRef}
          onChange={this.onImageChange}
          disabled={disabled}
        />
        {this.props.children}
      </div>
    )
  }
}

export default withFormsy(FormImage)
