/* @flow */
export default function importExternal(url) {
  return new Promise(resolve => {
    const script = global.document.createElement('script')
    script.async = true
    script.src = url
    script.defer = true
    script.crossorigin = 'anonymous'

    function onLoad() {
      if (!this.readyState || this.readyState === 'complete') {
        script.onload = null
        script.onreadystatechange = null
        resolve()
      }
    }

    script.onload = onLoad
    script.onreadystatechange = onLoad

    const sibling = global.document.getElementsByTagName('script')[0]
    sibling.parentNode.insertBefore(script, sibling)
  })
}
