import React, { useEffect } from 'react'
import store from 'store'
import App from 'app/components/App'
import { Provider } from 'react-redux'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import * as Sentry from "@sentry/react";
import 'common/styles/common.scss'
import { ModalProvider } from './modal-provider/ModalProvider'
const getRouterComponent = () => {
  if (process.env.REACT_APP_BROWSER_HISTORY) {
    return BrowserRouter
  } else {
    return HashRouter
  }
}

function Futuur(props) {
  let routerProps = props.routerProps || {}
  let RouterComponent = getRouterComponent()
  useEffect(() => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Sentry.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    })
  }, [])

  return (
    <Provider store={store}>
      <RouterComponent {...routerProps}>
        <HelmetProvider>
          <ModalProvider>
            <App />
          </ModalProvider>
        </HelmetProvider>
      </RouterComponent>
    </Provider>
  )
}

export default Futuur
