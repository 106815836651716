import React from 'react'

const Bug = props => {
  return (
    <svg viewBox="0 0 20 20" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M2 18V12C2 9.87827 2.84285 7.84344 4.34314 6.34315C5.84344 4.84285 7.87827 4 10 4C12.1217 4 14.1566 4.84285 15.6569 6.34315C17.1571 7.84344 18 9.87827 18 12V18H19V20H0.999999V18H2ZM4 18H16V12C16 10.4087 15.3679 8.88258 14.2426 7.75736C13.1174 6.63214 11.5913 6 10 6C8.4087 6 6.88258 6.63214 5.75736 7.75736C4.63214 8.88258 4 10.4087 4 12V18ZM9 0H11V3H9V0ZM17.778 2.808L19.192 4.222L17.072 6.343L15.657 4.929L17.778 2.808ZM0.807999 4.222L2.222 2.808L4.343 4.928L2.93 6.344L0.807999 4.222ZM5 12C5 10.6739 5.52678 9.40215 6.46446 8.46447C7.40215 7.52678 8.67392 7 10 7V9C9.20435 9 8.44129 9.31607 7.87868 9.87868C7.31607 10.4413 7 11.2044 7 12H5Z" />
    </svg>
  )
}

export default Bug
