import React from 'react'

export default function SportsIcon({ fillcolor, className, ...props }) {
  return (
    <svg className={className} {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 0.666626C13.6025 0.666626 17.3333 4.39746 17.3333 8.99996C17.3333 13.6025 13.6025 17.3333 9 17.3333C4.3975 17.3333 0.666667 13.6025 0.666667 8.99996C0.666667 4.39746 4.3975 0.666626 9 0.666626ZM10.3917 12.3333H7.60833L6.45833 13.9141L6.92 15.3358C7.5914 15.5557 8.29351 15.6674 9 15.6666C9.72583 15.6666 10.425 15.55 11.08 15.3358L11.5408 13.9141L10.3908 12.3333H10.3917ZM3.41167 8.05996L2.335 8.84079L2.33333 8.99996C2.33333 10.4416 2.79083 11.7758 3.56833 12.8666H5.16L6.2625 11.35L5.40583 8.70829L3.41167 8.05996ZM14.5883 8.05996L12.5942 8.70829L11.7375 11.35L12.8392 12.8666H14.4308C15.2365 11.7384 15.6687 10.3863 15.6667 8.99996L15.6642 8.84079L14.5883 8.05996ZM10.9083 2.61079L9.83333 3.39413V5.49163L12.0783 7.12246L13.9442 6.51663L14.4058 5.09746C13.5455 3.90652 12.3165 3.0325 10.9092 2.61079H10.9083ZM7.09 2.61079C5.68254 3.03272 4.45351 3.90704 3.59333 5.09829L4.055 6.51663L5.92083 7.12246L8.16667 5.49163V3.39413L7.09083 2.61079H7.09Z"
        fill={fillcolor || 'currentColor'}
      />
    </svg>
  )
}
