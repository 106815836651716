import React from 'react'

const RedditIcon = ({ height = 40, width = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 22 19">
      <defs>
        <path
          d="M14.273 14.066c-.823 0-1.514-.66-1.514-1.476 0-.814.691-1.497 1.514-1.497.822 0 1.489.683 1.489 1.497 0 .815-.667 1.476-1.49 1.476m.35 2.864c-.766.758-1.947 1.127-3.611 1.127l-.012-.001h-.011c-1.664 0-2.845-.368-3.61-1.126a.59.59 0 010-.842.605.605 0 01.85 0c.528.523 1.43.778 2.76.778h.023c1.329 0 2.232-.255 2.761-.778a.605.605 0 01.85 0 .592.592 0 010 .842m-8.384-4.34c0-.814.69-1.497 1.511-1.497.823 0 1.49.683 1.49 1.497 0 .815-.667 1.476-1.49 1.476-.821 0-1.51-.66-1.51-1.476m13.117-4.24c-.631 0-1.21.22-1.666.588-1.612-1.004-3.658-1.61-5.851-1.737l1.144-3.584 3.144.733a2.205 2.205 0 002.204 2.032 2.204 2.204 0 002.212-2.191C20.543 2.983 19.55 2 18.33 2c-.852 0-1.593.481-1.962 1.183l-3.654-.852a.601.601 0 00-.71.4l-1.42 4.448C8.222 7.237 6 7.844 4.27 8.906a2.646 2.646 0 00-1.626-.556C1.186 8.35 0 9.525 0 10.97a2.61 2.61 0 001.145 2.155 4.558 4.558 0 00-.044.629c0 1.806 1.06 3.486 2.983 4.73 1.844 1.193 4.284 1.85 6.871 1.85 2.587 0 5.028-.657 6.872-1.85 1.923-1.244 2.983-2.924 2.983-4.73 0-.193-.013-.384-.037-.574A2.614 2.614 0 0022 10.97c0-1.445-1.186-2.62-2.644-2.62z"
          id="reddit-icon_a"
        />
      </defs>
      <g transform="translate(0 -2)" fill="none" fillRule="evenodd">
        <mask id="reddit-icon_b" fill="#fff">
          <use xlinkHref="#reddit-icon_a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#reddit-icon_a" />
        <g mask="url(#reddit-icon_b)" fill="#FFF">
          <path d="M0 0h22v22H0z" />
        </g>
      </g>
    </svg>
  )
}

export default RedditIcon
