import Modal from 'common/components/modal/Modal'
import ActionSheetLayout from 'layouts/components/action-sheet-layout'
import React, { useState } from 'react'
const { countries } = require('assets/countries.json')
import { Dropdown } from 'semantic-ui-react'
import DatePicker from 'react-date-picker'
import { useDispatch, useSelector } from 'react-redux'
import { selectUpdateMissingInfoError, selectUpdateMissingInfoStatus, updateMissingInfoRequest } from 'store/account'
import { useCurrentUser, useModalActions } from 'common/hooks'
import { TextFieldFormsy } from 'common/components/text-field'
import Formsy from 'formsy-react'
import constants from 'app/constants'
import Button from 'common/ui/button'
import { i18n } from 'common/locale/i18n'
import '../deposit/ViewDeposit.scss'
import classNames from 'classnames'
import 'semantic-ui-css/semantic.min.css'
import { toastService } from 'api/toast'
import { useEffect } from 'react' 
import useIsMobileSize from 'common/hooks/useIsMobileSize'

const RenderAdditionalInfos = props => {
  const { closeAfter = false, nullAllowed = false, currentUser, onSubmitModal } = props || {}
  const dispatch = useDispatch()
  const modalActions = useModalActions()
  const isMobileSize = useIsMobileSize();

  const [date, setDate] = useState()
  const [countryOfResedent, setCountryOfResedent] = useState(currentUser ? currentUser.residence_country : null)
  const [valid, setValid] = useState(false)
  const updateMissingInfoStatus = useSelector(selectUpdateMissingInfoStatus())
  const updateMissingInfoError = useSelector(selectUpdateMissingInfoError())?.error
  useEffect(() => {
    setValid(false)
  }, [updateMissingInfoError])
  useEffect(() => {
    if (currentUser && currentUser.birth_date) {
      setDate(convertUTCToLocalDate(currentUser.birth_date))
    }
  }, [currentUser])

  const onFormValid = () => {
    if (!valid) {
      setValid(true)
    }
  }

  const onFormInvalid = () => {
    if (valid) {
      setValid(false)
    }
  }

  function convertUTCToLocalDate(date) {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return date
  }

  const onFormSubmit = async data => {
    const dateToSave = new Date(date.toString().split(' ').slice(0, 4).join(' '))

    const year = dateToSave.getFullYear()
    const month = dateToSave.getMonth() + 1 // Months are zero-indexed
    const day = dateToSave.getDate()

    const missing_data = {
      birth_date: `${year}-${month}-${day}`,
      residence_country: countryOfResedent || currentUser?.residence_country,
      first_name: data.first_name != undefined ? data.first_name : currentUser?.first_name,
      last_name: data.last_name != undefined ? data.last_name : currentUser?.last_name,
      address: data.address != undefined ? data.address : currentUser?.address,
    }

    await dispatch(updateMissingInfoRequest(missing_data))
    toastService('success', { title: i18n('kyc_form.success_message') })
    // if we came from the deposit modal we don't close we just clear the pre-kyc-completed
    if (onSubmitModal) {
      onSubmitModal()
      return
    }
    if (closeAfter) modalActions.close()
  }
  const countryChanged = (_, data) => {
    setCountryOfResedent(data?.value)
  }
  if (!currentUser) return null
  return (
    <div className="">
      <div className="view-crypto-cash-in__missingData__title">{i18n('cash_in.missing_data.title')}</div>
      <div className="view-crypto-cash-in__missingData__description">{i18n('cash_in.missing_data.description')}</div>
      <Formsy onValid={onFormValid} onInvalid={onFormInvalid} onValidSubmit={onFormSubmit}>
        <div className="view-crypto-cash-in__missingData--grid">
          <TextFieldFormsy
            name="first_name"
            type="text"
            value={currentUser.first_name}
            placeholder={i18n('kyc_form.first_name')}
            label={i18n('kyc_form.first_name')}
            validations={!nullAllowed && 'minLength:2'}
            validationError={i18n('kyc_form.error_first_name')}
            required={!nullAllowed}
            error={
              updateMissingInfoError && updateMissingInfoError['first_name'] && updateMissingInfoError['first_name'][0]
            }
          />
          <TextFieldFormsy
            name="last_name"
            type="text"
            value={currentUser.last_name}
            placeholder={i18n('kyc_form.last_name')}
            label={i18n('kyc_form.last_name')}
            validations={!nullAllowed && 'minLength:2'}
            validationError={i18n('kyc_form.error_last_name')}
            required={!nullAllowed}
            error={
              updateMissingInfoError && updateMissingInfoError['last_name'] && updateMissingInfoError['last_name'][0]
            }
          />

          <div className="countryOfResedence">
            <label className="text-field-el__label" htmlFor="countryOfResedent">
              {i18n('kyc_form.placeholder_country')}
            </label>
            <div className="">
              <Dropdown
                onChange={countryChanged}
                name="countryOfResedent"
                labeled="true"
                options={countries}
                value={countryOfResedent}
                placeholder={i18n('kyc_form.placeholder_country')}
                fluid
                search
                selection
                error={
                  updateMissingInfoError &&
                  updateMissingInfoError['residence_country'] &&
                  updateMissingInfoError['residence_country'][0]
                }
              />
            </div>
          </div>
          <div className={classNames('datePicker', { datePicker__open: date })}>
            <label htmlFor="date" className="text-field-el__label">
              {i18n('kyc_form.date')}
            </label>
            <DatePicker
              calendarClassName={'hide_calendar'}
              name="date"
              maxDate={new Date()}
              onChange={setDate}
              value={date && new Date(date)}
              calendarIcon={null}
              clearIcon={null}
              yearPlaceholder={'yyyy'}
              monthPlaceholder={'mm'}
              dayPlaceholder={'dd'}
              error={
                updateMissingInfoError &&
                updateMissingInfoError['birth_date'] &&
                updateMissingInfoError['birth_date'][0]
              }
            />
          </div>
          <div className="!mt-4 md:!mt-0 md:col-span-2">
            <TextFieldFormsy
              name="address"
              type="text"
              value={currentUser.address}
              placeholder={i18n('kyc_form.placeholder_adress')}
              label={i18n('kyc_form.address')}
              validations="minLength:2"
              validationError={i18n('kyc_form.error_last_name')}
              required={!nullAllowed}
              error={
                updateMissingInfoError && updateMissingInfoError['address'] && updateMissingInfoError['address'][0]
              }
            />
          </div>
          <div className="view-crypto-cash-in__missingData--grid__submit">
            <Button
              loading={updateMissingInfoStatus === constants.LOADING}
              size={isMobileSize ? 'full' : 'large'}
              disabled={!nullAllowed && (!valid || !date || !countryOfResedent)}>
              {i18n('kyc_form.submit')}
            </Button>
          </div>
        </div>
      </Formsy>
    </div>
  )
}

const ViewPersonalInfo = ({ header, onSubmitModal }) => {
  const modalActions = useModalActions()
  const currentUser = useCurrentUser()
  const onClose = () => {
    modalActions.close()
  }
  const renderTitle = () => {
    return (
      <div className="view-crypto-cash-in__header">
        <div>{i18n('settings.update_personal_info')}</div>
      </div>
    )
  }
  return (
    <Modal isOpen={true} position="bottom" onCloseRequested={onClose}>
      <ActionSheetLayout
        onClose={() => onClose()}
        className="view-crypto-withdraw view-crypto-cash-in--missing-info"
        showCloseButton={true}
        title={header || renderTitle()}
        content={RenderAdditionalInfos({ closeAfter: true, nullAllowed: true, currentUser, onSubmitModal })}
      />
    </Modal>
  )
}
export { RenderAdditionalInfos, ViewPersonalInfo }
