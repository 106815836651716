/* @flow */
import React from 'react'
import { ShareIcon } from 'common/components/icons'
import Image from 'common/components/Image'
import Device from 'common/util/Device'
import { keepQueryString, questionDetailUrl } from 'urls'
import { i18n } from 'common/locale/i18n'
import Currency from 'common/currency/Currency'
import { Link } from 'react-router-dom'

class WagerSell extends React.PureComponent {
  handleClick() {
    const { question, onMessageClick } = this.props

    if (onMessageClick) {
      const url = questionDetailUrl(question)
      onMessageClick(url)
    }
  }

  render() {
    const { currency, outcome, question, last_action } = this.props
    let out = !isFinite(outcome) ? outcome : question.outcomes.filter(o => o.id === outcome)[0]
    const amount = currency === 'BTC' ? last_action.amount * 1000 : last_action.amount
    return (
      <a className="message" href={keepQueryString(questionDetailUrl(question))}>
        <Image className="message__image message__image--round" src={out.thumbnail_2x_webp} alt={outcome.title} />

        <div className="message__text WagerSell">
          <div className="message__main-text">
            <div>{i18n('place_bet.sold')}</div>
            <div className="message__main--second-text">
              {i18n('place_bet.bet_sell') + ' '}
              <em>
                <Currency value={Math.abs(amount)} currency={currency} showIcon />
              </em>
              {' on '} <em>{out.title}</em>
            </div>
          </div>
          <div className="message__sub-text">
            <Link to={keepQueryString(questionDetailUrl(question))}>{i18n('place_bet.view_market_details')}</Link>
          </div>
        </div>

        {!Device.isCordova ? null : (
          <span className="message__icon message__icon--share" onClick={this.handleClick.bind(this)}>
            <ShareIcon />
          </span>
        )}
      </a>
    )
  }
}

export default WagerSell
