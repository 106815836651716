import React from 'react'
import { Link } from 'react-router-dom'
import { RightArrowIcon } from 'common/components/icons'
import { categoryListUrl, keepQueryString } from 'urls'
import './CategoryResult.scss'
import getCatIcon from 'api/catergoryIcon'

class CategoryResult extends React.Component {
  renderCategory = category => {
    if (!category) return null

    const url = categoryListUrl(category)
    const displayName = () =>
      category &&
      category.tree &&
      category.tree.map(category => [
        <Link
          to={keepQueryString(categoryListUrl(category))}
          key={`category-${category.id}`}
          className="category-list__item category-results__title">
          {category.title}
        </Link>,
        <span key={`category-${category.id}-separator`} className="category-list__separator">
          <RightArrowIcon width="5px" />
        </span>,
      ])
    return (
      <Link key={category.id} className="category" to={url}>
        {category && category.tree && <div style={{ display: 'flex' }}>{getCatIcon(category.tree[0].slug)}</div>}
        <div className="category__title">{displayName()}</div>
      </Link>
    )
  }

  renderSummary = () => {
    const { categories, onViewAll, isSummary } = this.props
    const categoryResults = isSummary
      ? categories.slice(0, 4).map(this.renderCategory)
      : categories.map(this.renderCategory)

    if (!onViewAll) {
      console.warn('Summary and onViewAll props must be provided together')
    }

    return <React.Fragment>{categoryResults}</React.Fragment>
  }

  renderComplete = () => {
    const { categories } = this.props
    return categories.map(this.renderCategory)
  }

  render() {
    const { total } = this.props

    if (total === 0) {
      return null
    }

    return (
      <div className="category-results">
        <div className="container-md-wide">
          {/* <h2 className="category-results__title">{i18n('search.result.category.title')}</h2> */}
          <div className="result-list">{this.renderSummary()}</div>
        </div>
      </div>
    )
  }
}

export default CategoryResult
