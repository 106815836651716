import React from 'react'
import './person.scss'
export default function PersonIcon({ fillcolor, className, ...props }) {
  return (
    <svg {...props} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={className}
        fill={fillcolor || 'currentColor'}
        d="M13.6667 18.3333H12V16.6666C12 16.0036 11.7366 15.3677 11.2678 14.8989C10.799 14.43 10.1631 14.1666 9.50004 14.1666H4.50004C3.837 14.1666 3.20111 14.43 2.73227 14.8989C2.26343 15.3677 2.00004 16.0036 2.00004 16.6666V18.3333H0.333374V16.6666C0.333374 15.5616 0.772361 14.5018 1.55376 13.7204C2.33516 12.939 3.39497 12.5 4.50004 12.5H9.50004C10.6051 12.5 11.6649 12.939 12.4463 13.7204C13.2277 14.5018 13.6667 15.5616 13.6667 16.6666V18.3333ZM7.00004 10.8333C6.34343 10.8333 5.69325 10.704 5.08662 10.4527C4.48 10.2014 3.9288 9.83314 3.46451 9.36885C3.00021 8.90455 2.63192 8.35336 2.38064 7.74673C2.12937 7.1401 2.00004 6.48992 2.00004 5.83331C2.00004 5.1767 2.12937 4.52652 2.38064 3.9199C2.63192 3.31327 3.00021 2.76207 3.46451 2.29778C3.9288 1.83349 4.48 1.46519 5.08662 1.21392C5.69325 0.962642 6.34343 0.833313 7.00004 0.833313C8.32612 0.833313 9.59789 1.3601 10.5356 2.29778C11.4733 3.23546 12 4.50723 12 5.83331C12 7.1594 11.4733 8.43116 10.5356 9.36885C9.59789 10.3065 8.32612 10.8333 7.00004 10.8333ZM7.00004 9.16665C7.8841 9.16665 8.73194 8.81546 9.35706 8.19034C9.98218 7.56521 10.3334 6.71737 10.3334 5.83331C10.3334 4.94926 9.98218 4.10141 9.35706 3.47629C8.73194 2.85117 7.8841 2.49998 7.00004 2.49998C6.11599 2.49998 5.26814 2.85117 4.64302 3.47629C4.0179 4.10141 3.66671 4.94926 3.66671 5.83331C3.66671 6.71737 4.0179 7.56521 4.64302 8.19034C5.26814 8.81546 6.11599 9.16665 7.00004 9.16665Z"
      />
    </svg>
  )
}
