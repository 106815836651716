import React from 'react'
import { useState } from 'react'

const YoutubeIcon = () => {
  const [hovered, setHovered] = useState(false)
  return (
    <div onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {hovered ? (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="24" fill="#FF0000" />
          <path
            d="M33.543 18.498C34 20.28 34 24 34 24C34 24 34 27.72 33.543 29.502C33.289 30.487 32.546 31.262 31.605 31.524C29.896 32 24 32 24 32C24 32 18.107 32 16.395 31.524C15.45 31.258 14.708 30.484 14.457 29.502C14 27.72 14 24 14 24C14 24 14 20.28 14.457 18.498C14.711 17.513 15.454 16.738 16.395 16.476C18.107 16 24 16 24 16C24 16 29.896 16 31.605 16.476C32.55 16.742 33.292 17.516 33.543 18.498ZM22 27.5L28 24L22 20.5V27.5Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M33.543 18.498C34 20.28 34 24 34 24C34 24 34 27.72 33.543 29.502C33.289 30.487 32.546 31.262 31.605 31.524C29.896 32 24 32 24 32C24 32 18.107 32 16.395 31.524C15.45 31.258 14.708 30.484 14.457 29.502C14 27.72 14 24 14 24C14 24 14 20.28 14.457 18.498C14.711 17.513 15.454 16.738 16.395 16.476C18.107 16 24 16 24 16C24 16 29.896 16 31.605 16.476C32.55 16.742 33.292 17.516 33.543 18.498V18.498ZM22 27.5L28 24L22 20.5V27.5Z"
            fill="currentColor"
          />
          <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="currentColor" strokeOpacity="0.48" />
        </svg>
      )}
    </div>
  )
}

export default YoutubeIcon
