import React from 'react'
import { useState } from 'react'

const LinkedinIcon = () => {
  const [hovered, setHovered] = useState(false)
  return (
    <div onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {hovered ? (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="24" fill="#0077B7" />
          <path
            d="M30.335 30.339H27.67V26.162C27.67 25.166 27.65 23.884 26.28 23.884C24.891 23.884 24.679 24.968 24.679 26.089V30.339H22.013V21.75H24.573V22.92H24.608C24.966 22.246 25.836 21.533 27.136 21.533C29.836 21.533 30.336 23.311 30.336 25.624V30.339H30.335ZM19.003 20.575C18.7996 20.5753 18.5981 20.5354 18.4101 20.4576C18.2221 20.3798 18.0513 20.2657 17.9075 20.1218C17.7636 19.9779 17.6497 19.8071 17.572 19.619C17.4944 19.431 17.4546 19.2294 17.455 19.026C17.4552 18.7198 17.5462 18.4206 17.7164 18.1661C17.8867 17.9117 18.1286 17.7134 18.4115 17.5965C18.6945 17.4795 19.0057 17.449 19.306 17.5089C19.6062 17.5689 19.882 17.7165 20.0983 17.9331C20.3147 18.1497 20.4619 18.4257 20.5215 18.726C20.581 19.0263 20.5501 19.3375 20.4328 19.6203C20.3154 19.9031 20.1169 20.1447 19.8622 20.3147C19.6075 20.4846 19.3082 20.5752 19.002 20.575H19.003ZM20.339 30.339H17.666V21.75H20.34V30.339H20.339ZM31.67 15H16.329C15.593 15 15 15.58 15 16.297V31.703C15 32.42 15.594 33 16.328 33H31.666C32.4 33 33 32.42 33 31.703V16.297C33 15.58 32.4 15 31.666 15H31.669H31.67Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M30.335 30.339H27.67V26.162C27.67 25.166 27.65 23.884 26.28 23.884C24.891 23.884 24.679 24.968 24.679 26.089V30.339H22.013V21.75H24.573V22.92H24.608C24.966 22.246 25.836 21.533 27.136 21.533C29.836 21.533 30.336 23.311 30.336 25.624V30.339H30.335ZM19.003 20.575C18.7996 20.5753 18.5981 20.5354 18.4101 20.4576C18.2221 20.3798 18.0513 20.2657 17.9075 20.1218C17.7636 19.9779 17.6497 19.8071 17.572 19.619C17.4944 19.431 17.4546 19.2294 17.455 19.026C17.4552 18.7198 17.5462 18.4206 17.7164 18.1661C17.8867 17.9117 18.1286 17.7134 18.4115 17.5965C18.6945 17.4795 19.0057 17.449 19.306 17.5089C19.6062 17.5689 19.882 17.7165 20.0983 17.9331C20.3147 18.1497 20.4619 18.4257 20.5215 18.726C20.581 19.0263 20.5501 19.3375 20.4328 19.6203C20.3154 19.9031 20.1169 20.1447 19.8622 20.3147C19.6075 20.4846 19.3082 20.5752 19.002 20.575H19.003ZM20.339 30.339H17.666V21.75H20.34V30.339H20.339ZM31.67 15H16.329C15.593 15 15 15.58 15 16.297V31.703C15 32.42 15.594 33 16.328 33H31.666C32.4 33 33 32.42 33 31.703V16.297C33 15.58 32.4 15 31.666 15H31.669H31.67Z"
            fill="currentColor"
          />
          <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="currentColor" strokeOpacity="0.48" />
        </svg>
      )}
    </div>
  )
}

export default LinkedinIcon
