import React from 'react'

const LinkedInIcon = ({ width = 22, height = 22, fill = 'currentColor' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-2,-2) scale(1.15,1.15)">
        <path
          d="M4.00098 3H20.001C20.2662 3 20.5205 3.10536 20.7081 3.29289C20.8956 3.48043 21.001 3.73478 21.001 4V20C21.001 20.2652 20.8956 20.5196 20.7081 20.7071C20.5205 20.8946 20.2662 21 20.001 21H4.00098C3.73576 21 3.48141 20.8946 3.29387 20.7071C3.10633 20.5196 3.00098 20.2652 3.00098 20V4C3.00098 3.73478 3.10633 3.48043 3.29387 3.29289C3.48141 3.10536 3.73576 3 4.00098 3ZM5.00098 5V19H19.001V5H5.00098ZM7.50098 9C7.10315 9 6.72162 8.84196 6.44032 8.56066C6.15901 8.27936 6.00098 7.89782 6.00098 7.5C6.00098 7.10218 6.15901 6.72064 6.44032 6.43934C6.72162 6.15804 7.10315 6 7.50098 6C7.8988 6 8.28033 6.15804 8.56164 6.43934C8.84294 6.72064 9.00098 7.10218 9.00098 7.5C9.00098 7.89782 8.84294 8.27936 8.56164 8.56066C8.28033 8.84196 7.8988 9 7.50098 9ZM6.50098 10H8.50098V17.5H6.50098V10ZM12.001 10.43C12.585 9.865 13.267 9.5 14.001 9.5C16.072 9.5 17.501 11.179 17.501 13.25V17.5H15.501V13.25C15.501 12.7859 15.3166 12.3408 14.9884 12.0126C14.6602 11.6844 14.2151 11.5 13.751 11.5C13.2868 11.5 12.8417 11.6844 12.5135 12.0126C12.1854 12.3408 12.001 12.7859 12.001 13.25V17.5H10.001V10H12.001V10.43Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default LinkedInIcon
