import React from 'react'

const WalletIcon = ({ fill = 'currentColor', ...props }) => {
  return (
    <svg {...props} viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M16.5 4H19.5C19.7652 4 20.0196 4.10536 20.2071 4.29289C20.3946 4.48043 20.5 4.73478 20.5 5V17C20.5 17.2652 20.3946 17.5196 20.2071 17.7071C20.0196 17.8946 19.7652 18 19.5 18H1.5C1.23478 18 0.98043 17.8946 0.792893 17.7071C0.605357 17.5196 0.5 17.2652 0.5 17V1C0.5 0.734784 0.605357 0.48043 0.792893 0.292893C0.98043 0.105357 1.23478 0 1.5 0H16.5V4ZM2.5 6V16H18.5V6H2.5ZM2.5 2V4H14.5V2H2.5ZM13.5 10H16.5V12H13.5V10Z"
      />
    </svg>
  )
}

export default WalletIcon
