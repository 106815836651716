import queryInjector from '../common/util/queryInjector'
const BASE_PATH = process.env.REACT_APP_SHARE_PATH || process.env.REACT_APP_FRONT_URL.slice(0, -1) || ''

export const inviteUrl = query => queryInjector('/c/invite', query)
export const inviteEmailUrl = query => queryInjector('/c/invite/email', query)
export const inviteManualUrl = query => queryInjector('/c/invite/manual', query)
export const invitedUrl = query => queryInjector(`${BASE_PATH}/`, query)

export const facebookUrl = () => 'https://www.facebook.com/futuurHQ/'
export const twitterUrl = () => 'https://twitter.com/futuurHQ'
export const youtubeUrl = () => 'https://www.youtube.com/channel/UCEJkPOzzJzlV8jeb5mKRHeQ'
export const mediumUrl = () => 'https://medium.com/futuur'
export const helpCenterUrl = (language = 'en') =>
  language === 'en'
    ? 'https://docs.futuur.com/Futuur-FAQ-EN-US-3a0c98b71bf643f58c8cbefc067491d7'
    : 'https://docs.futuur.com/Futuur-FAQ-PT-BR-1c9755229e6f466e839b6f932d328766'
