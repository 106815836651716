import React from 'react'
import classNames from 'classnames'
import './DropdownMenu.scss'
import UserPicture from 'profile/components/user-picture'
import { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import PhonePage from '../phone-page'

const DropdownMenu = props => {
  const [isOpen, setIsOpen] = useState(false)
  const iconRef = useRef(null)
  const listRef = useRef(null)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleClose = event => {
    if (!isOpen) {
      return
    }

    // Clicking on links
    const listNode = listRef.current
    if (listNode && listNode.contains(event.target)) {
      return
    }

    // Clicking outside links and icon
    const iconNode = iconRef.current
    if (iconNode && !iconNode.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClose, false)

    return () => {
      document.removeEventListener('mousedown', handleClose, false)
    }
  }, [isOpen]) // Empty array ensures that effect runs only on mount and unmount

  const { renderIcon, children, alwaysKeepHidden, me } = props

  return (
    <div
      className={classNames('dropdown-menu', {
        'dropdown-menu--open': isOpen && !alwaysKeepHidden,
        'dropdown-menu--loggedIn': me,
      })}
    >
      {me && me.id ? (
        <div className="dropdown-menu__icon noBorder" onClick={handleToggle} ref={iconRef}>
          <UserPicture user={me} />
        </div>
      ) : (
        <div className="dropdown-menu__icon" onClick={handleToggle} ref={iconRef}>
          {renderIcon()}
        </div>
      )}
      <div className="dropdown-menu__item-list" ref={listRef} onClick={handleToggle}>
        <PhonePage>{children}</PhonePage>
      </div>
    </div>
  )
}

export default DropdownMenu
