import React, { useEffect, useRef, useState } from 'react'
import { Link, matchPath, NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useWindowSize } from 'react-use'

import { accountUrl } from 'profile/urls'
import { BellIcon, Crevron, GlobeIcon, PortfolioIcon, RankingIcon } from 'common/components/icons'
import { CurrencySelector } from 'app/components/currency-selector'
import { cashInUrl, feedUrl, keepQueryString, signupUrl } from 'urls'
import { i18n } from 'common/locale/i18n'
import { selectPageLayout, setNotificationWidgetOpen } from 'store/app'
import { loginUrl, leaderboardUrl } from 'urls'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveCurrency, useCurrentUser, useCurrencies, useScrollDirection, useCurrentRouteName } from 'common/hooks'
import { selectCurrentUserStatus, selectUserFromAllowedCountry } from 'store/account'

import classNames from 'classnames'
import NavbarMenu from 'app/components/navbar-menu'
// import UserPicture from 'profile/components/user-picture'
// import FutuurIcon from 'common/components/icons/futuur'
import Button from 'common/ui/button'
import FutuurLogo from 'common/components/FutuurLogo'
import KycCallout from 'app/components/kyc-callout'

import './Navbar.scss'
import CategoryMenu from 'app/components/CategoryMenu'
import NotificationWidget from 'notifications/components/notification-widget'
import { activityUrl } from 'urls'
import { SearchWidget } from '../Search-widget'
import SearchBox from 'explore/components/search-box'
import { searchUrl } from 'explore/urls'
import constants from 'app/constants'
import { searchAll } from 'store/search'
import { selectUnreadNotificationCount } from 'store/notifications'
import { urlSearchParams } from 'common/util/urlSearchParams'
import fromEntries from 'object.fromentries'
import useIsMobileSize from 'common/hooks/useIsMobileSize'

const Navbar = props => {
  const currencies = useCurrencies().currencies
  const location = useLocation()
  const navigate = useNavigate()
  const currentRoute = useCurrentRouteName()
  const navbarRef = useRef()
  const scrollDirection = useScrollDirection(0)
  const windowSize = useWindowSize()
  const isMobileSize = useIsMobileSize()

  const me = useCurrentUser()
  const userStatus = useSelector(selectCurrentUserStatus)
  const dispatch = useDispatch()
  const layout = useSelector(selectPageLayout)
  const fromAllowedCountry = useSelector(selectUserFromAllowedCountry())
  const activeCurrency = useActiveCurrency()
  const undreadNotificationCount = useSelector(selectUnreadNotificationCount())
  const queryParams = fromEntries(new urlSearchParams(location.search))

  const [notificationWidget, setNotificationWidget] = useState(false)
  const [searchInput, setSearchInput] = useState(queryParams.q)
  const [marketMenuOpen, setMarketMenuOpen] = useState(false)
  const notificationPage = () => matchPath({ path: activityUrl() }, location.pathname)
  const isInSearchPage = currentRoute === 'Search page'
  const [isResultsVisible, setResultsVisible] = useState(false)
  const searchBoxRef = useRef(null)
  const searchResultsRef = useRef(null)
  useEffect(() => {
    const handleBlur = event => {
      if (event.target !== searchBoxRef.current && event.target !== searchResultsRef.current) {
        setResultsVisible(false)
      }
    }

    const handleLocationChange = () => {
      setResultsVisible(false)
    }

    document.addEventListener('click', handleBlur)
    window.addEventListener('popstate', handleLocationChange)

    return () => {
      document.removeEventListener('click', handleBlur)
      window.removeEventListener('popstate', handleLocationChange)
    }
  }, [])
  useEffect(() => {
    if (searchInput !== null && isInSearchPage) {
      dispatch(searchAll({ q: searchInput || queryParams.q, type: 'markets' }))
    }
  }, [searchInput])
  const onScroll = () => {
    if (isMobileSize) {
      if (scrollDirection === 'down') {
        document.body.classList.add('hide-navbar')
      } else {
        document.body.classList.remove('hide-navbar')
      }
    } else {
      document.body.classList.remove('hide-navbar')
    }
  }

  useEffect(() => {
    onScroll()
  }, [scrollDirection])
  useEffect(() => {
    dispatch(setNotificationWidgetOpen(notificationWidget))
  }, [notificationWidget])

  const renderLinkList = () => {
    return (
      <div className="navbar__link-list">
        <div
          className={classNames('navbar__link marketButton', { 'navbar__link--active': marketMenuOpen })}
          onClick={() => {
            navigate(keepQueryString(feedUrl()))
            setMarketMenuOpen(v => !v)
          }}>
          <div className="marketButton__overlay"></div>
          <GlobeIcon width="18px" />

          <span className="navbar__link-text">
            <div>{i18n('navbar.markets')}</div> <Crevron width="11px" fillcolor="currentColor" />
          </span>
          <CategoryMenu
            isOpen={marketMenuOpen}
            onClose={ev => {
              if (!Array.from(ev.target.classList).find(x => x == 'marketButton__overlay')) setMarketMenuOpen(false)
            }}
          />
        </div>
        {me && (
          <NavLink className="navbar__link" activeClassName="navbar__link--active" to={keepQueryString(accountUrl())}>
            <PortfolioIcon height="18px" />

            <span className="navbar__link-text">{i18n('navbar.portfolio')}</span>
          </NavLink>
        )}
        <NavLink className="navbar__link" activeClassName="navbar__link--active" to={keepQueryString(leaderboardUrl())}>
          <RankingIcon height="18px" />

          <span className="navbar__link-text">{i18n('navbar.leaderboard')}</span>
        </NavLink>
        {/* <NavLink className="navbar__link" activeClassName="navbar__link--active" to={keepQueryString(searchUrl())}>
         
          <span className="navbar__link-text">{i18n('navbar.search')}</span>
        </NavLink> */}
      </div>
    )
  }

  const scrollToTop = e => {
    if (location.pathname === feedUrl()) {
      e.preventDefault()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const renderKycCallout = () => {
    return <KycCallout me={me} />
  }

  const renderNavbarColumnLeft = () => {
    return (
      <Link to={feedUrl()} className="navbar__flex-column navbar__flex-column-left" onClick={scrollToTop}>
        {/* <FutuurIcon className="navbar__logo-mobile d-block d-xl-none" width="1.5em" fillcolor="gradient" /> */}
        <FutuurLogo className="navbar__logo d-block" width="100px" />
      </Link>
    )
  }

  const renderCurrencySelector = currency => {
    const _currency = me ? currency : currency && currency.id === 'OOM' ? currency : currencies.REAL_MONEY_PLACEHOLDER

    return (
      <div className="navbar__currency-selector">{_currency && <CurrencySelector currency={_currency} me={me} />}</div>
    )
  }
  const renderSkeletonButton = () => {
    return (
      <div className="navbar__skeleton">
        <div className="navbar__skeleton--button skeleton"></div>
      </div>
    )
  }

  const renderNavbarColumnRight = () => {
    return (
      <div className="navbar__flex-column navbar__flex-column--right">
        {me && me.id && fromAllowedCountry && (
          <Button
            onClick={() => navigate(cashInUrl())}
            className="navbar__button navbar__cash-in-button d-none d-xl-flex"
            size="medium">
            {i18n('navbar.deposit')}
          </Button>
        )}
        {me && me.id && (
          <div
            className={classNames('navbar__notification', {
              'navbar__notification--active': notificationWidget || notificationPage(),
            })}>
            <i
              onClick={() => {
                isMobileSize ? navigate(activityUrl()) : setNotificationWidget(v => !v)
              }}
              className="navbar__notification--bell">
              <span className="navbar__notification--bell--cover"></span>
              <BellIcon width={20} />
            </i>
            {undreadNotificationCount > 0 && <i className="navbar__notification-warning" />}
            <NotificationWidget isOpen={notificationWidget} onClose={() => setNotificationWidget(false)} />
          </div>
        )}

        {(!me || !me.id) && (
          <div className="navbar__auth-button">
            <Button
              className="navbar__button d-none d-xl-block"
              size="medium"
              onClick={() => navigate(signupUrl())}
              type="outlined">
              {i18n('login.signup')}
            </Button>
            <Button
              className="navbar__button"
              size="medium"
              onClick={() => {
                localStorage.setItem('lastPath', location.pathname)
                navigate(loginUrl())
              }}
              variant="primary">
              {i18n('login.login')}
            </Button>
          </div>
        )}

        <NavbarMenu hasUserSession={me && me.id} me={me} />
      </div>
    )
  }
  const renderSearch = () => {
    return (
      <>
        <div className="navbar__search">
          <SearchBox
            initialValue={searchInput}
            _ref={searchBoxRef}
            placeholder="Search here..."
            id="search__bar-input"
            value
            onChange={value => {
              setSearchInput(value)
              setResultsVisible(false)
              if (isInSearchPage) navigate(searchUrl({ q: value, type: 'markets' }))
            }}
            onEnter={() => {
              if (!isInSearchPage) navigate(searchUrl({ q: searchInput, type: 'markets' }))
            }}
            onFocus={() => setResultsVisible(true)}
          />
          <SearchWidget text={searchInput} isOpen={searchInput && isResultsVisible} _ref={searchResultsRef} />
        </div>
      </>
    )
  }

  const render = () => {
    const classList = classNames('navbar', {
      'navbar--guest': !me,
      'navbar--logged-in': me,
      'navbar--no-shadow': props.noBoxShadow,
      'navbar--no-fixed': props.noFixedBehaviour,
      'navbar--is-scrolling': window.scrollY > 0,
      'navbar--transparent': layout.transparentNavbar,
      'navbar--hide-infos': layout.hideNavbarInfos,
      'navbar--logo-only': layout.navbarLogoOnly,
      'd-none': layout.removeNavbar,
      overAddFuns: location.pathname === cashInUrl(),
    })
    const showKycCallout =
      me &&
      me.settings &&
      (me.kyc_status === 'requested' || me.kyc_status === 'refused') &&
      !me.settings.dismissed_kyc_callout
    return (
      <React.Fragment>
        <div className={classList} ref={navbarRef}>
          <div className={'navbar__container' + (location.pathname === cashInUrl() ? ' overAddFuns' : '')}>
            <div className="navbar__wrap">
              <div className="navbar__inner">
                {renderNavbarColumnLeft()}
                {renderCurrencySelector(activeCurrency)}
                {userStatus === constants.LOADING || layout.appLoading
                  ? renderSkeletonButton()
                  : renderNavbarColumnRight()}
                {window.innerWidth > 960 && renderSearch()}

                {renderLinkList()}
              </div>
              {props.children && <div className="navbar__attachment navbar--center">{props.children}</div>}
              {showKycCallout && renderKycCallout()}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  if (!layout.showNavigationBar && windowSize.width < 1024) {
    return null
  }

  return render()
}

export default Navbar
