import React, { useState } from 'react'
import ActionSheetLayout from 'layouts/components/action-sheet-layout'
import Button from 'common/ui/button'
import Device from 'common/util/Device'
import { FutuurIconB, OomsIcon } from 'common/components/icons'
import Modal from 'common/components/modal/Modal'
import { i18n } from 'common/locale/i18n'
import { useDispatch } from 'react-redux'
import { updateCurrentUserSettings } from 'store/account'

import './OnboardingTour.scss'
// import EyeFollow from './eyeFollow/eyeFollow'

const OnboardingTour = ({ isFromAllowedCountry = false }) => {
  const dispatch = useDispatch()
  const [currentSlide, setCurrentSlide] = useState(0)

  const realMoneySlides = [
    renderIntroSlideRealMoney(),
    renderOomsSlide(),
    renderAddFundsSlide(),
    renderMakeBetSlide(),
    renderBuyAndSellSlide(),
  ]

  const playMoneySlides = [
    renderIntroSlidePlayMoney(),
    renderOomsSlide(),
    renderMakeBetSlide(),
    renderBuyAndSellSlide(),
  ]

  const slides = isFromAllowedCountry && !Device.isCordova ? realMoneySlides : playMoneySlides

  const onClose = () => {
    dispatch(updateCurrentUserSettings({ seen_onboarding_app: true }))
  }

  function renderHeader() {
    return (
      <div className="onboarding-tour__header">
        <Button type="text-only" className="onboarding-tour__header-skip" onClick={onClose}>
          {i18n('onboarding_tour.skip')}
        </Button>
        <div className="onboarding-tour__header-counter">
          {Math.min(currentSlide + 1, slides.length)}/{slides.length}
        </div>
      </div>
    )
  }

  function renderContent(slide) {
    return (
      <div className="onboarding-tour__container">
        {renderHeader()}
        {slide}
      </div>
    )
  }

  function renderIntroSlideRealMoney() {
    return {
      render: (
        <div className="onboarding-tour__slide onboarding-tour__slide-intro">
          {/* <EyeFollow /> */}
          <i className="onboarding-tour__logo">
            <FutuurIconB width="50px" />
          </i>
          <h2 className="onboaring-tour__title">{i18n('onboarding_tour.intro_real_money.title')}</h2>
          <p>{i18n('onboarding_tour.intro_real_money.p1')}</p>
          <p>{i18n('onboarding_tour.intro_real_money.p2')}</p>
        </div>
      ),
      buttonText: i18n('onboarding_tour.lets_go'),
    }
  }

  function renderIntroSlidePlayMoney() {
    return {
      render: (
        <div className="onboarding-tour__slide onboarding-tour__slide--intro">
          <i className="onboarding-tour__logo">
            <FutuurIconB width="50px" />
          </i>
          <h2 className="onboaring-tour__title">{i18n('onboarding_tour.intro_play_money.title')}</h2>
          <p>{i18n('onboarding_tour.intro_play_money.p1')}</p>
          <p>{i18n('onboarding_tour.intro_play_money.p2')}</p>
        </div>
      ),
      buttonText: i18n('onboarding_tour.continue'),
    }
  }

  function renderOomsSlide() {
    return {
      render: (
        <div className="onboarding-tour__slide onboarding-tour__slide--ooms">
          <i className="onboarding-tour__ooms-icon">
            <OomsIcon width="1em" />
          </i>
          <h2 className="onboarding-tour__title">{i18n('onboarding_tour.ooms_slide.title')}</h2>
          <p>{i18n('onboarding_tour.ooms_slide.p1')}</p>
          <p>{i18n('onboarding_tour.ooms_slide.p2')}</p>
        </div>
      ),
      buttonText: i18n('onboarding_tour.continue'),
    }
  }

  function renderMakeBetSlide() {
    return {
      render: (
        <div className="onboarding-tour__slide onboarding-tour__slide--make-bet">
          <i className="onboarding-tour__find-market"></i>
          <h2 className="onboarding-tour__title">{i18n('onboarding_tour.make_bet.title')}</h2>
          <p>{i18n('onboarding_tour.make_bet.p1')}</p>
          <p>{i18n('onboarding_tour.make_bet.p2')}</p>
        </div>
      ),
      buttonText: i18n('onboarding_tour.continue'),
    }
  }

  function renderBuyAndSellSlide() {
    return {
      render: (
        <div className="onboarding-tour__slide onboarding-tour__slide--buy-and-sell">
          <i className="onboarding-tour__buy-and-sell"></i>
          <h2 className="onboarding-tour__title">{i18n('onboarding_tour.buy_and_sell.title')}</h2>
          <p>{i18n('onboarding_tour.buy_and_sell.p1')}</p>
          <p>{i18n('onboarding_tour.buy_and_sell.p2')}</p>
        </div>
      ),
      buttonText: i18n('onboarding_tour.ready_to_predict'),
    }
  }

  function renderAddFundsSlide() {
    return {
      render: (
        <div className="onboarding-tour__slide onboarding-tour__slide--add-funds">
          <i className="onboarding-tour__add-funds" />
          <h2>{i18n('onboarding_tour.add_funds.title')}</h2>
          <p>{i18n('onboarding_tour.add_funds.p1')}</p>
          <p>{i18n('onboarding_tour.add_funds.p2')}</p>
        </div>
      ),
      buttonText: i18n('onboarding_tour.continue'),
    }
  }

  const onButtonClick = () => {
    setCurrentSlide(currentSlide + 1)
    if (currentSlide === slides.length) {
      onClose()
    }
  }

  const renderOnboardingTour = slidesList => {
    const currentSlideIndex = Math.min(slidesList.length - 1, currentSlide)
    const slide = slidesList[currentSlideIndex]

    return (
      <Modal isOpen={true} position="center" onOverlayClick={onButtonClick} onCloseRequested={onClose}>
        <ActionSheetLayout
          className="onboarding-tour"
          title={''}
          content={renderContent(slide.render)}
          action={slide.buttonText}
          onButtonClick={onButtonClick}
        />
      </Modal>
    )
  }

  return renderOnboardingTour(slides)
}

export default OnboardingTour
