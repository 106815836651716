import React from 'react'

const IdentityIcon = ({ fill = 'currentColor', width = '1em', height = '100%', ...props }) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16" width={width} height={height} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.391 1.778H16c.98 0 1.778.796 1.778 1.778v10.666A1.78 1.78 0 0 1 16 16H1.778A1.78 1.78 0 0 1 0 14.222V3.556c0-.982.797-1.778 1.778-1.778h4.609A2.66 2.66 0 0 1 8.889 0a2.66 2.66 0 0 1 2.502 1.778Zm-1.613.889a.89.89 0 0 0-.89-.89.89.89 0 0 0-.888.89c0 .49.4.889.889.889.49 0 .889-.4.889-.89Zm-8 .889v10.666H16L16 3.556h-4.609A2.66 2.66 0 0 1 8.89 5.333a2.66 2.66 0 0 1-2.502-1.777h-4.61Zm3.555 5.333a1.778 1.778 0 1 0 0-3.556 1.778 1.778 0 0 0 0 3.556Zm-2.666 3.555c0-1.636 1.03-2.666 2.666-2.666C6.97 9.778 8 10.808 8 12.444H2.667Zm7.11-5.333h5.334V8.89H9.778V7.11Zm5.334 3.556H9.778v1.777h5.333v-1.777Z"
        fill={fill}
        fillOpacity=".6"
      />
    </svg>
  )
}

export default IdentityIcon
