import React from 'react'

const Kyc = props => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.364 15.5352L16.95 14.1212L18.364 12.7072C19.2821 11.7657 19.7923 10.5003 19.7841 9.1852C19.7758 7.87012 19.2498 6.61124 18.32 5.68125C17.3901 4.75126 16.1313 4.22506 14.8162 4.21664C13.5011 4.20821 12.2357 4.71824 11.294 5.63623L9.87799 7.05023L8.46499 5.63623L9.87899 4.22223C11.1918 2.90941 12.9724 2.17188 14.829 2.17188C16.6856 2.17188 18.4662 2.90941 19.779 4.22223C21.0918 5.53505 21.8293 7.31562 21.8293 9.17223C21.8293 11.0288 21.0918 12.8094 19.779 14.1222L18.364 15.5352ZM15.536 18.3642L14.122 19.7782C13.4719 20.4283 12.7002 20.9439 11.8509 21.2957C11.0016 21.6475 10.0913 21.8286 9.17199 21.8286C8.25269 21.8286 7.34239 21.6475 6.49307 21.2957C5.64374 20.9439 4.87203 20.4283 4.22199 19.7782C3.57194 19.1282 3.0563 18.3565 2.7045 17.5072C2.3527 16.6578 2.17163 15.7475 2.17163 14.8282C2.17163 13.9089 2.3527 12.9986 2.7045 12.1493C3.0563 11.3 3.57194 10.5283 4.22199 9.87823L5.63599 8.46423L7.05099 9.87823L5.63599 11.2932C4.71799 12.2349 4.20797 13.5004 4.21639 14.8155C4.22482 16.1305 4.75102 17.3894 5.68101 18.3192C6.61099 19.2491 7.86988 19.7751 9.18496 19.7833C10.5 19.7916 11.7654 19.2814 12.707 18.3632L14.122 16.9502L15.536 18.3642ZM14.829 7.75723L16.243 9.17123L9.17199 16.2432L7.75799 14.8282L14.828 7.75823L14.829 7.75723Z"
        fill={props.fill || 'currentColor'}
      />
    </svg>
  )
}

export default Kyc
