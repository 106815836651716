import React from 'react'
import { Link } from 'react-router-dom'
import { tagUrl } from 'urls'
import './Tag.scss'
const Tag = ({ tag }) => {
  return (
    <Link to={tagUrl(tag.slug)} className="tag">
      <span>#{tag.name}</span>
    </Link>
  )
}

export default Tag
