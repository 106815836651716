import React from 'react'
import classNames from 'classnames'
import createSrcset from '../util/createSrcset'
import makeCancelable from '../util/makeCancelable'
import './Image.scss'

class Image extends React.Component {
  static defaultProps = {
    style: null,
    className: null,
    imageClassName: null,
    overlayClassName: null,
    placeholder: null,
    src: null,
    srcSet: null,
    sizes: null,
    animate: true,
  }

  state = {
    animate: this.props.animate,
    loading: true,
    error: false,
  }

  componentDidUpdate(nextProps) {
    const { animate, src, srcSet } = this.props

    if (animate !== nextProps.animate) {
      this.setState({ animate: nextProps.animate })
    }

    const srcChanged = src !== nextProps.src
    const srcSetChanged = JSON.stringify(srcSet) !== JSON.stringify(nextProps.srcSet)

    if (srcChanged || srcSetChanged) {
      this.setState({ loading: true })
    }
  }

  img = null
  abortDecode = null

  destroy = () => {
    if (this.img) {
      this.img.onload = null
      this.img.onerror = null
      this.img = null
    }

    if (this.abortDecode) {
      this.abortDecode()
      this.abortDecode = null
    }
  }

  onRef = img => {
    this.destroy()
    this.img = img

    // unmounting
    if (!img) {
      return
    }

    // Already loaded
    if (!this.state.loading) {
      return
    }

    // Check if already loaded
    if (img.complete) {
      this.setState({ loading: false, animate: false })
      return
    }

    const hasSrc = !!(img.src || img.srcset)

    // Check if browser supports decode method
    if (hasSrc && typeof img.decode === 'function') {
      const { promise, cancel } = makeCancelable(img.decode())
      this.abortDecode = cancel
      promise.then(this.onLoad, this.onError)
    } else {
      /* eslint-disable no-param-reassign */
      img.onload = this.onLoad
      img.onerror = this.onError
      /* eslint-enable no-param-reassign */
    }
  }

  onLoad = () => {
    this.destroy()
    this.setState({ loading: false })
  }

  onError = error => {
    console.error(`[Image] ${error.message}`, this.img)
    this.destroy()
    this.setState({ loading: false, error: true })
  }

  render() {
    const { className, placeholder, sizes, src, srcSet, alt, imageClassName, overlayClassName } = this.props
    const { animate, loading, error } = this.state

    const imgSrc = src || (srcSet && Object.values(srcSet)[0])
    const imgSrcSet = createSrcset(srcSet)
    const showImg = imgSrc != null || imgSrcSet != null
    const style = { ...this.props.style }

    if (placeholder) {
      style.backgroundImage = `url('${placeholder}')`
    }

    return (
      <div className={classNames(className, 'image')} style={style}>
        {showImg && (
          <img
            className={classNames(imageClassName, 'image__img', {
              'image__img--animate': animate,
              'image__img--loading': loading,
              'image__img--error': error,
            })}
            ref={this.onRef}
            alt={alt}
            title={alt}
            src={imgSrc}
            srcSet={imgSrcSet}
            sizes={sizes}
          />
        )}
        {overlayClassName && (
          <div
            className={classNames(overlayClassName, 'image__overlay', {
              'image__overlay--animate': animate,
              'image__overlay--loading': loading,
              'image__overlay--error': error,
            })}
          />
        )}
      </div>
    )
  }
}

export default Image
