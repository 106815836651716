import * as React from 'react'

export default function UserIcon({ className, ...props }) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" className={className} {...props}>
      <path
        fillRule="evenodd"
        d="M9 11c3.53 0 6 1.85 6 4.5V17H3v-1.5C3 12.85 5.47 11 9 11zm-2.96 2.17c-.96.53-1.54 1.35-1.54 2.33h3.1l-1.56-2.33zm4.46-.52a7.5 7.5 0 0 0-3 0L9 14.9l1.5-2.25zm-.1 2.85h3.1c0-.98-.58-1.8-1.54-2.33L10.4 15.5zm2.35-9.25a3.75 3.75 0 0 1-7.5 0V2H7v1h1V1h2v2h1V2h1.75v4.25zM7 7c.3.87 1.08 1.5 2 1.5.92 0 1.7-.63 2-1.5H7zm0-1.5h4v-1H7v1z"
      />
    </svg>
  )
}
