import { urlSearchParams } from 'common/util/urlSearchParams'
import Device from 'common/util/Device'

export const languageQueryInjector = pathname => {
  if (Device.isCordova) {
    return pathname
  }

  const params = new urlSearchParams(window.location.search)
  const language = params.get('lg')

  if (language) {
    const parts = pathname.split('?')
    const pathnameQuery = new urlSearchParams(parts[1])
    pathnameQuery.set('lg', language)
    return parts[0] + '?' + pathnameQuery.toString()
  }

  return pathname
}
