import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import accountService from 'api/account'
import Device from 'common/util/Device'
import { useCurrentUser } from 'common/hooks'
import { getRedirectUrl } from 'common/util/pushNotifications'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'

const useCapacitorPushNotifications = () => {
  const navigate = useNavigate()
  const me = useCurrentUser()

  const addListeners = async () => {
    await PushNotifications.addListener('registration', token => {
      accountService.registerPushNotification(Device.isIOS ? 'ios' : 'android', token.value)
    })
    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      if (notification && notification.data) {
        const redirectUrl = getRedirectUrl(notification.data)
        if (redirectUrl.indexOf('http') >= 0) {
          window.open(redirectUrl)
          return
        }
        if (redirectUrl) {
          navigate(redirectUrl)
          return
        }
      }
    })
  }

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions()
    }

    if (permStatus.receive !== 'granted') {
      console.error('User denied push notification permissions.')
    }

    await PushNotifications.register()
  }

  useEffect(() => {
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications')

    if (isPushNotificationsAvailable) {
      addListeners()
      registerNotifications()

      return () => {
        PushNotifications.removeAllListeners()
      }
    }
  }, [me])


  
}

export default useCapacitorPushNotifications
