import React from 'react'

function Lost({ fillcolor, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 50 51"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <defs>
        <path
          id="path-1"
          d="M39.842 35.55l5.304 5.302 5.206-5.206 3.536 3.536-5.206 5.206 5.302 5.304-3.47 3.471-5.304-5.304-5.207 5.208-3.536-3.536 5.208-5.207-5.304-5.303 3.471-3.471zm-2.34-33.095c1.762 0 3.297.91 4.186 2.268l.118.188h8.196c1.326 0 2.408 1.01 2.493 2.29l.005.162v7.461a7.264 7.264 0 01-2.593 5.568l-.22.179-10.445 8.205a15.035 15.035 0 01-8.843 5.294l-.398.071v5.132h3.15l4.351 4.349v1.338l-4.538 4.539 2.046 2.046H15v-4.908h2.501V41.73c0-1.302 1.03-2.368 2.334-2.451l.164-.005h5.003V34.14a15.04 15.04 0 01-8.995-5.07l-.245-.295-10.446-8.202c-1.716-1.347-2.73-3.329-2.81-5.47l-.006-.28V7.363c0-1.302 1.033-2.364 2.337-2.447l.164-.005h8.193a5.004 5.004 0 014.068-2.45l.24-.006h20zM32.5 44.18h-9.998v2.456h9.998v-2.456zm5.003-36.818h-20v12.273c0 5.415 4.486 9.82 9.998 9.82 5.422 0 9.85-4.257 9.998-9.546l.004-.274V7.363zm9.999 2.456h-5v9.817l-.007.154-.01.155 4.08-3.204a2.425 2.425 0 00.93-1.73l.007-.187V9.82zm-35.002 0h-5v5.005c0 .686.29 1.332.798 1.795l.144.122 4.076 3.2-.01-.151-.008-.154V9.819z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-48 -256)">
          <g transform="translate(25 246)">
            <g transform="translate(21 8)">
              <mask id="mask-2" fill="#fff">
                <use xlinkHref="#path-1"></use>
              </mask>
              <use fill={fillcolor} fillRule="evenodd" xlinkHref="#path-1"></use>
              <g fill={fillcolor} fillRule="evenodd" mask="url(#mask-2)">
                <path d="M0 0H55V54H0z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Lost
