// import { BetRuleService } from 'api/bet'

// export const isBetSelectable = bet => {
//   return bet == null || BetRuleService.isSold(bet) || BetRuleService.isDisabled(bet)
// }
export const isBetSelectable = () => {
  return true
}
export const isBetAvailable = (question, currencyId) => {
  let wagerHasBeenMade = false
  if (question && question.wagers) {
    wagerHasBeenMade = question.wagers.find(
      wager => wager.currency === currencyId && wager.active_purchases && wager.active_purchases.length > 0
    )
  }

  return question.is_wagerable && !wagerHasBeenMade
}
