/* @flow */

class ScrollService {
  scrollHandlers = []
  scrollHandlerDestroy = null
  savedScroll = {}

  onScrollViewContent = (event, scrollId) => {
    const target = event.nativeEvent ? event.nativeEvent.target : event.target
    if (target.attributes['scrollid'] && target.attributes['scrollid'].value === scrollId) {
      for (let i = 0; i < this.scrollHandlers.length; i++) {
        this.scrollHandlers[i](event)
      }
    }
  }

  registerScrollListener(scrollHandler) {
    this.scrollHandlers.push(scrollHandler)
  }

  unregisterScrollListener(scrollHandler) {
    this.scrollHandlers = this.scrollHandlers.filter(handler => handler !== scrollHandler)
  }

  saveScrollPosition(position, scrollId) {
    this.savedScroll[scrollId] = position
    //console.log(this.savedScroll)
  }

  restoreScrollPosition(scrollId) {
    if (scrollId in this.savedScroll) {
      const position = this.savedScroll[scrollId]
      delete this.savedScroll[scrollId]
      return position
    } else {
      return 0
    }
  }
}

const instance = new ScrollService()
export default instance
