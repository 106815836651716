import { matchPath } from 'react-router-dom'
import { feedUrl } from 'urls'

const useCurrentRouteName = () => { 
  const routePaths = {
    Feed: matchPath({ path: feedUrl(), exact: true }, location.pathname),
    'Category page': location.pathname.startsWith('/q/category'),
    'Search page': location.pathname.startsWith('/search'),
    'Tags page': location.pathname.startsWith('/q/tag'),
    'Market page': matchPath({ path: '/q/:id' }, location.pathname),
  }
  const currentPage = Object.keys(routePaths).find(routeName => routePaths[routeName])

  return currentPage || 'not-found'
}
export default useCurrentRouteName
