import mixpanel from 'mixpanel-browser'

const product = () => {
  if (bet.currency === 'OOM') {
    return 'Play Money'
  } else return 'Real Money'
}
const getAllABTests = () => JSON.parse(localStorage.getItem('abTests')) || {}

const abTestList = () =>
  Object.fromEntries(Object.entries(getAllABTests()).map(([key, value]) => ['ab_test_' + key, value]))

export const getAllCampains = () => JSON.parse(localStorage.getItem('campains') || '[]')

const isBot = () => navigator.userAgent.match(/.*((AhrefsSiteAudit)|(AhrefsBot)).*/i)
const hasMixpanel = () => typeof mixpanel !== 'undefined'

function signUpPageViewed() {
  if (!hasMixpanel() || isBot()) return
  mixpanel.track('sign_up_page_view')
}
function pageView() {
  // if (!hasMixpanel() || isBot()) return
  // mixpanel.track_pageview({
  //   provider: props.provider,
  //   home-page-text: props.home-page-text,
  //   test_register_feed_alg: props.test_register_feed_alg,
  // })
}

function register(user, props) {
  console.log('sending register...')
  if (!hasMixpanel() || isBot()) return
  mixpanel.identify(user.id)
  mixpanel.track('register', {
    provider: props.provider,
    ...props,
  })
  mixpanel.people.set({
    $name: user.name,
    $email: user.email,
    $avatar: user.picture,
    $user_id: user.id,
    username: user.username,
    is_staff: user.is_staff,
    email_confirmed: user.email_confirmed,
    language: user.language,
    sort_algorithm: user.sort_algorithm,
    date_joined: user.date_joined,
    provider: props.provider,
    last_activity: 'login',
  })
}

function login(user, props) {
  if (!hasMixpanel() || isBot()) return
  mixpanel.identify(user.id)
  mixpanel.track('login', {
    provider: props.provider,
    ...props,
  })
  mixpanel.people.set({
    $name: user.name,
    $email: user.email,
    $avatar: user.picture,
    $user_id: user.id,
    username: user.username,
    is_staff: user.is_staff,
    email_confirmed: user.email_confirmed,
    language: user.language,
    sort_algorithm: user.sort_algorithm,
    date_joined: user.date_joined,
    provider: props.provider,
    last_activity: 'login',
  })
}

function logout() {
  if (!hasMixpanel() || isBot()) return
  mixpanel.reset()
}

function bet(bet, amountInUsd) {
  if (!hasMixpanel() || isBot()) return
  mixpanel.track('bet', {
    bet_id: bet.id,
    market_id: bet.question.id,
    market_title: bet.question.title,
    outcome_id: bet.outcome.id,
    outcome_title: bet.outcome.title,
    currency: bet.currency,
    shares: bet.shares,
    amount: bet.total_amount,
    amount_in_usd: amountInUsd,
    product: product,
    ...abTestList(),
    all_cmpains : getAllCampains()
  })
  mixpanel.people.set({
    last_bet: new Date().toISOString(),
  })
  mixpanel.people.set_once({
    first_bet: new Date().toISOString(),
  })
  mixpanel.people.increment('lifetime_bets')
  mixpanel.people.set({
    last_activity: 'bet',
  })
}

function sell(bet) {
  if (!hasMixpanel() || isBot()) return
  mixpanel.track('sell', {
    'bet-id': bet.id,
    'market-id': bet.question.id,
    'market-title': bet.question.title,
    'outcome-id': bet.outcome.id,
    'outcome-title': bet.outcome.title,
    currency: bet.currency,
    shares: bet.shares,
    amount: bet.total_amount,
    ...abTestList(),
  })
  mixpanel.people.set({
    last_sell: new Date().toISOString(),
  })
  mixpanel.people.set_once({
    first_sell: new Date().toISOString(),
  })
  mixpanel.people.increment('lifetime_sells')
  mixpanel.people.set({
    last_activity: 'sell',
  })
}

function deposit() {
  if (!hasMixpanel() || isBot()) return
  mixpanel.track('deposit', {
    ...abTestList(),
  })
  mixpanel.people.set({
    last_deposit: new Date().toISOString(),
  })
  mixpanel.people.set_once({
    first_deposit: new Date().toISOString(),
  })
  mixpanel.people.increment('lifetime_deposits')
  mixpanel.people.set({
    last_activity: 'deposit',
  })
}

function withdraw() {
  if (!hasMixpanel() || isBot()) return
  mixpanel.track('withdraw', {
    ...abTestList(),
  })
  mixpanel.people.set({
    last_withdrawal: new Date().toISOString(),
  })
  mixpanel.people.set_once({
    first_withdrawal: new Date().toISOString(),
  })
  mixpanel.people.increment('lifetime_withdrawals')
  mixpanel.people.set({
    last_activity: 'withdraw',
  })
}
function identify(userId) {
  if (!hasMixpanel() || isBot()) return
  mixpanel.identify(userId)
}
function addProperty(key, value) {
  if (!hasMixpanel() || isBot()) return
  mixpanel.people.set({
    [key]: value,
  })
}
const actions = {
  signUpPageViewed,
  register,
  login,
  logout,
  bet,
  sell,
  deposit,
  withdraw,
  pageView,
  identify,
  addProperty,
}

export default actions
