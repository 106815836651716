import React from 'react'

const ltcIcon = ({ fill = '#A6B6BD', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15" {...props}>
      <path
        d="M1.338 10.513L.236 15h12.07l.819-3.348h-7.24l.796-3.265 1.337-.535.638-2.579-1.322.536L8.758 0H3.95L1.999 7.902l-1.354.544L0 11.032l1.338-.519z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default ltcIcon
