import { useLocation, useNavigate } from 'react-router-dom'
import { feedUrl } from 'urls'

export default () => {
  const location = useLocation()
  const navigate = useNavigate()

  const onCloseModal = () => {
    if (location && location.state && location.state.returnUrl) {
      const parsedUrl = location.state.returnUrl.replace(/\/\//g, '/')
      const url = parsedUrl.split('?')[0]
      navigate(url, { replace: true })
    } else if (history.length) {
      navigate(-1)
    } else {
      navigate(feedUrl())
    }
  }

  return {
    close: onCloseModal,
  }
}
