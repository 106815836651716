import React from 'react'
import classnames from 'classnames'
import './UserPicture.scss'
import { useNavigate } from "react-router-dom";
import {userUrl} from "../../../urls";


export default function UserPicture({ user, children, height, width, enableNavigateToProfile = false, ...props }) {

  const navigate = useNavigate()

  const classList = classnames('user-picture', props.className)

  let style = {}

  if (user && user.picture) {
    style.backgroundImage = 'none'
  }

  if (height) {
    style.height = height + 'px'
  }

  if (width) {
    style.width = width + 'px'
  }
  return (
    <div className={classList} style={style} onClick={() => enableNavigateToProfile && navigate(userUrl(user))}>
      {user && user.picture && (
        <img
          src={user.picture}
          className="user-picture-img"
          alt={user.username + "'s picture"}
          title={user.username + "'s picture"}
        />
      )}
      {children}
    </div>
  )
}
