import React , { lazy } from 'react'

const ViewLeaderboard = lazy(() => import('views/leaderboard'))

export default [
  {
    path: '/c/leaderboard',
    element: <ViewLeaderboard />,
  },
]
