import React from 'react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import './Slider.scss'

const Slider = ({ name = 'swipe-slider', children, settings = {}, type, prevEl, nextEl }) => {
  let sliderSettings = {
    ...settings,
  }

  if (type === 'horizontal-scroll') {
    sliderSettings = {
      slidesPerView: 'auto',
      freeMode: true,
      ...settings,
    }
    SwiperCore.use([Navigation])
  }

  if (type === 'highlights') {
    sliderSettings = {
      slidesPerView: 'auto',
      centeredSlides: false,
      spaceBetween: 16,
      slideToClickedSlide: true,
    }
    SwiperCore.use([Navigation, Pagination])
  }

  if ((nextEl || prevEl) && !sliderSettings['navigation']) {
    sliderSettings['navigation'] = {}
  }

  if (nextEl) {
    sliderSettings['navigation']['nextEl'] = nextEl
  }

  if (prevEl) {
    sliderSettings['navigation']['prevEl'] = prevEl
  }

  return (
    <Swiper {...sliderSettings} style={{ width: '100%' }}>
      {children.map((el, idx) => (
        <SwiperSlide key={`${name}-slide-${idx}`}>{el}</SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Slider
