import React from 'react'
import classNames from 'classnames'
import SwipeableViews from 'react-swipeable-views'
import NotificationService from 'api/notification'
import './MessageBar.scss'

const leavingTimeout = 500
const durationTimeout = 3000

class MessageBar extends React.PureComponent {
  state = {
    eventList: [],
    isLeaving: false,
    isShowing: false,
  }

  componentDidMount() {
    this.subscription = NotificationService.subscribe(this.handleNextMessage.bind(this))
  }

  componentDidUpdate(prevProps, prevState) {
    // Condition: Has Events to show and not showing any event now.
    // Action: Ask to show the next event.
    if (this.state.eventList.length > 0 && !this.state.isShowing && !this.state.isLeaving && !prevState.isLeaving) {
      this.setState({ isShowing: true })
    }

    // Condition: Was not showing events, now is showing an event.
    // Action: Wait durationTimeout to start dismissing the event
    // and start the leaving animation.
    else if (this.state.isShowing && !prevState.isShowing) {
      this.hasTimeout = setTimeout(() => {
        this.setState({
          isShowing: false,
          isLeaving: true,
        })
      }, durationTimeout)
    }

    // Condition: An event finished presentation and is now being dismissed
    // Action: Wait the leavingTimeout to finish leaving animation
    else if (this.state.isLeaving && !prevState.isLeaving) {
      setTimeout(() => {
        this.setState({
          isLeaving: false,
        })
      }, leavingTimeout)
    }

    // Condition: Finished the leaving animation.
    // Action: Remove the event from the queue.
    else if (!this.state.isLeaving && prevState.isLeaving) {
      this.setState({
        eventList: this.state.eventList.slice(1),
      })
    }
  }

  componentWillUnmount() {
    if (this.subscription) this.subscription.unsubscribe()
  }

  handleNextMessage(nextEvent) {
    this.setState({ eventList: this.state.eventList.concat([nextEvent]) })
  }

  handleMessageClick(redirectUrl) {
    this.props.navigate(redirectUrl)
  }

  handleCloseClick() {
    this.setState({
      isShowing: false,
      isLeaving: true,
    })
  }

  handleDismiss(index, type) {
    // Swipe Started.
    // Cancel the timeout to start the leaving animation
    if (this.hasTimeout != undefined && type === 'move') {
      clearTimeout(this.hasTimeout)
      this.hasTimeout = undefined
      return
    }

    // Swipe Fishined, but has not removed the notification
    // Restart the timeout to start the leaving animation
    if (index === 1 && type === 'end') {
      this.hasTimeout = setTimeout(() => {
        this.setState({
          isShowing: false,
          isLeaving: true,
        })
      }, durationTimeout)
      return
    }

    // Swipe Fishined, and removed the notification
    // Directly set the state related to the leaving animation
    if (index !== 1 && type === 'end') {
      this.setState({
        isShowing: false,
        isLeaving: true,
      })
    }
    return
  }

  render() {
    const { eventList, isLeaving } = this.state

    if ((!eventList || eventList.length === 0) && !isLeaving) {
      return <div className="messagebar messagebar--empty"></div>
    }

    const Message = eventList[0]
    // TODO: use the onMessageClick callback on each message type :)
    let props = {
      onMessageClick: this.handleMessageClick.bind(this),
    }

    if (Message && Message.dataProps) {
      props = {
        ...Message.dataProps,
        ...props,
      }
    }

    return (
      <div
        className={classNames('messagebar', {
          'messagebar--empty': isLeaving,
        })}>
        <SwipeableViews enableMouseEvents index={1} onSwitching={this.handleDismiss.bind(this)}>
          <div />
          <div className="messagebar__content">
            <div />
            {Message && Message.component && <Message.component {...props} />}
            <button className="messagebar__close" onClick={this.handleCloseClick.bind(this)}>
              X
            </button>
          </div>
          <div />
        </SwipeableViews>
      </div>
    )
  }
}

export default MessageBar
