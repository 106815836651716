import React from 'react'
import PersonIcon from 'common/components/icons/person'
import { useActiveCurrency, useCurrencies, useCurrentUser } from 'common/hooks'
import Tooltip from 'common/components/tooltip'
import { EyeIcon, Growth, OomsIcon, RealMoneyIcon, StatsIcon } from 'common/components/icons'
import { formatNumber } from 'common/util/localizeNumber'
import { selectActiveLanguage, selectPageLayout } from 'store/app'
import { useDispatch, useSelector } from 'react-redux'
import { i18n } from 'common/locale/i18n'
import classNames from 'classnames'
import { useModal } from 'app/components/modal-provider/ModalProvider'
import EmailNotConfirmed from 'app/components/modal-provider/global-modal/email-not-confirmed'
import { followOrUnfollow } from 'store/markets'
import { toastService } from 'api/toast'

const QuestionFooter = ({ question, inMarketPage }) => {
  const locale = useSelector(selectActiveLanguage())
  const currencies = useCurrencies().currencies
  const activeCurrency = useActiveCurrency()
  const { adminMode } = useSelector(selectPageLayout)
  const currentUser = useCurrentUser()
  const { showModal } = useModal()
  const dispatch = useDispatch()

  const isRealMoney = activeCurrency && activeCurrency.id !== currencies.OOM.id

  const volumeLabel = question => {
    if (!question || (isRealMoney && !question.volume_real_money) || (!isRealMoney && !question.volume_play_money))
      return null
    let label = isRealMoney
      ? '$' + formatNumber(question.volume_real_money, locale, 2, 2)
      : formatNumber(question.volume_play_money, locale, 0, 0)

    return (
      <span>
        {isRealMoney && '~'} {label}
      </span>
    )
  }
  const renderVolume = question => {
    return volumeLabel(question) ? (
      <div>
        <div className="question-card__volume-text">
          <StatsIcon fillcolor={'var(--color-Neutral-4)'} width="13px" />
          <span>
            {volumeLabel(question)}
            {currencies[activeCurrency.id].id === 'OOM' ? ' ø' : ''}
          </span>
        </div>
      </div>
    ) : (
      <></>
    )
  }

  const renderNumberOfBets = question => {
    return (
      <div>
        <div className="question-card__number-of-bets-text">
          <PersonIcon className="question-card__person-icon" fillcolor="currentColor" width="13px" />

          <span className="question-card__wager-count">{forecastersCount(question)}</span>
        </div>
      </div>
    )
  }
  const renderRelavance = question => {
    return (
      <div>
        <div className="question-card__number-of-bets-text">
          <i className="">
            <Growth width="13px" height="13px" fill="currentColor" />
          </i>
          <span className="question-card__wager-count">{question?.admin_view?.relevance}</span>
        </div>
      </div>
    )
  }

  const forecastersCount = question => {
    return question.wagers_count_total || question.wagers_count_canonical
  }

  const renderSortResultsAdmin = question => {
    return (
      <div className="rounded-lg bg-Neutral-3 text-Neutral-6 p-2 mt-6">
        <ul>
          <li>
            Score A : <b>{question.admin_view?.sort_score_a}</b>
          </li>
          <li className="mt-2">
            Score B : <b>{question.admin_view?.sort_score_b}</b>
          </li>
        </ul>
      </div>
    )
  }

  const betSummary = renderNumberOfBets(question)
  const volume = renderVolume(question)
  // const currencyInfos = renderCurrencyInfos(activeCurrency.id)

  const renderLeftSideTooltip = () => {
    if (question.wagers_count_total > 0 || question.volume_play_money + question.volume_real_money > 0)
      return (
        <Tooltip
          light
          indicatorClassName="hidden"
          componentClassName="question-card__general-info-left--first__tooltip">
          <div className="flex gap-4 justify-between w-full">
            <div className="flex flex-col gap-2">
              <div className="flex gap-1 text-sm font-bold">
                <OomsIcon width="20px" /> <span>{i18n('Play_money')} </span>
              </div>
              <div className="flex gap-1">
                <PersonIcon fillcolor="currentColor" width="13px" />
                <span>{question.wagers_count} </span>
              </div>

              <div className="flex gap-1">
                <StatsIcon fillcolor={'var(--color-Neutral-5)'} width="13px" />
                <span>{formatNumber(question.volume_play_money, locale, 0, 0)}ø</span>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-1  text-sm font-bold">
                <RealMoneyIcon width="20px" /> <span>{i18n('Real_money')} </span>
              </div>
              <div className="flex gap-1">
                <PersonIcon fillcolor="currentColor" width="13px" />
                <span>{question.wagers_count_canonical}</span>
              </div>
              <div className="flex gap-1">
                <StatsIcon fillcolor={'var(--color-Neutral-5)'} width="13px" />
                <span>≈${formatNumber(question.volume_real_money, locale, 2, 2)}</span>
              </div>
            </div>
          </div>
        </Tooltip>
      )
  }
  const toogleFollow = async () => {
    if (currentUser && !currentUser.email_confirmed) {
      showModal({
        title: ' ',
        showCloseButton: true,
        content: <EmailNotConfirmed />,
      })
      return
    }
    await dispatch(followOrUnfollow(question.id))
    // await dispatch(fetchMarketById(question.id))
    toastService('marketFollow', {
      marketTitle: question.title,
      picture: question.outcomes[0].image_2x,
      is_followed: question.is_following,
    })
  }
  const renderFollowMarket = () => {
    return <EyeIcon width="16px" outline={!question.is_following} />
  }
  return (
    <>
      <div className="question-card__general-info">
        <div className="question-card__number-of-bets">
          {renderLeftSideTooltip()}
          <div
            className={classNames(
              'flex items-center gap-4 question-card__number-of-bets question-card__number-of-bets--labels',
              {
                'md:w-full md:justify-between': inMarketPage,
              }
            )}>
            {betSummary}
            {adminMode && renderRelavance(question)}
            {volume}
          </div>
        </div>

        {currentUser && !inMarketPage && (
          <div
            className="cursor-pointer"
            onClick={() => {
              toogleFollow(question.is_following)
            }}>
            {renderFollowMarket()}
          </div>
        )}
      </div>

      {adminMode && renderSortResultsAdmin(question)}
    </>
  )
}

export default QuestionFooter
