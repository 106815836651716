import { i18n } from 'common/locale/i18n'
import React from 'react'
import './MarketListHeader.scss'

const MarketListHeader = ({ numberOfActiveBets = 0, isRealMoney = false, isLoading, filters }) => {
  return (
    <>
      <div className="active-markets ">
        <div className="flex flex-col gap-2 justify-start text-xl">
          {isRealMoney ? i18n('active_markets.title_real_money') : i18n('active_markets.title_play_money')}
          {!isLoading && (
            <span className="text-sm text-Neutral-4">
              {numberOfActiveBets} {i18n('page_title.markets')}
            </span>
          )}
        </div>
        <div className="flex-gap-4">{filters}</div>
      </div>
    </>
  )
}

export default MarketListHeader
