import React from 'react'

export default function MXN( props ) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" {...props} viewBox="0 0 256 256">
      <defs></defs>
      <g
        style={{
          stroke: 'none',
          strokeWidth: 0,
          strokeDasharray: 'none',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1,
        }}
        transform="matrix(2.81 0 0 2.81 1.407 1.407)">
        <path
          d="M60 2.571V87.43c17.466-6.175 29.985-22.818 30-42.396v-.068C89.985 25.389 77.466 8.745 60 2.571z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#bc0000',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="M30 87.429V2.571C12.524 8.75 0 25.408 0 45s12.524 36.25 30 42.429z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#0b7226',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="M30 87.429A44.914 44.914 0 0 0 45 90c5.261 0 10.307-.912 15-2.571V2.57A44.913 44.913 0 0 0 45 0a44.91 44.91 0 0 0-15 2.571v84.858z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#f3f4f5',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="m47.913 46.215-1.61.805A2.358 2.358 0 0 0 45 49.129h1.457l1.456-2.914z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffd250',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="M45 52.77a.727.727 0 0 1-.728-.728v-2.913a.727.727 0 1 1 1.456 0v2.913a.728.728 0 0 1-.728.728z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#7a93a5',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="M45 49.857c-2.861 0-5.623-1.104-7.779-3.108a.728.728 0 0 1 .991-1.067C40.098 47.435 42.508 48.4 45 48.4c2.491 0 4.902-.965 6.787-2.718a.728.728 0 0 1 .991 1.067c-2.155 2.004-4.918 3.108-7.778 3.108zM45 52.77a9.42 9.42 0 0 1-4.574-1.18.719.719 0 0 1-.24-1.025l.008-.013a.728.728 0 0 1 .959-.227c2.363 1.283 5.331 1.283 7.693 0a.728.728 0 0 1 .959.227l.008.013a.719.719 0 0 1-.24 1.025A9.402 9.402 0 0 1 45 52.77z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#7a93a5',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="M40.543 47.536c-1.925 0-4.236-1.62-4.849-5.23l.254-.043a1.298 1.298 0 0 1 1.447.881c.716 2.17 2.237 2.937 3.2 2.935.34-.009.695-.127.723-.367.033-.287.041-.356-.721-.613-.414-.14-.883-.299-1.213-.676-1.332-1.521.471-3.132 1.338-3.905.153-.137.216-.253.2-.297-.048-.124-.333-.313-.656-.313-.925 0-1.627-.523-1.831-1.363a1.997 1.997 0 0 1 .087-1.214.731.731 0 0 1 1.329-.07c.1.193.097.416.022.62a.566.566 0 0 0-.022.319c.018.075.061.251.416.251.892 0 1.74.526 2.016 1.25.118.309.296 1.111-.59 1.902-.957.854-1.529 1.497-1.212 1.859.072.082.38.186.583.255.656.222 1.876.634 1.701 2.16-.109.959-.966 1.625-2.129 1.656a1.492 1.492 0 0 1-.093.003z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#89c15d',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="m42.087 36.509-.427.427a3.52 3.52 0 0 0-1.03 2.487s1.733-.879 2.317-1.158c.408-.196.334-.658.1-.881-.183-.174-.96-.875-.96-.875z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffd250',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="M45 55.683c-6.57 0-11.961-5.143-12.358-11.615a.728.728 0 0 1 1.454-.081c.355 5.707 5.11 10.24 10.904 10.24s10.549-4.533 10.903-10.239a.727.727 0 0 1 1.454.081C56.96 50.54 51.57 55.683 45 55.683z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#89c15d',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="M34.153 46.636a.99.99 0 1 1 0 1.979.99.99 0 0 1 0-1.979zM36.338 50.126a.99.99 0 1 1 0 1.979.99.99 0 0 1 0-1.979z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffe15a',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <circle
          cx={39.889}
          cy={53.759}
          r={0.989}
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffe15a',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="M55.95 46.636a.99.99 0 1 1 0 1.979.99.99 0 0 1 0-1.979zM53.765 50.126a.99.99 0 1 1 0 1.979.99.99 0 0 1 0-1.979zM50.214 52.766a.99.99 0 1 1 0 1.979.99.99 0 0 1 0-1.979z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffe15a',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="M45.728 53.498h-1.457a1.457 1.457 0 0 1 0-2.914h1.457a1.457 1.457 0 1 1 0 2.914z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#eda472',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
        <path
          d="M53.688 41.779a11.516 11.516 0 0 0-.214-1.018c-1.743-6.671-9.209-6.444-9.209-6.444h.001-.001c3.279.754 2.191 5.104 2.191 5.104v.039c-.082-.179-.161-.36-.249-.536a4.37 4.37 0 0 0-3.908-2.416h-.213c0 .69.329 1.335.879 1.751.351.266.578.687.578 1.162l-.002.002a2.874 2.874 0 0 0-.529 3.308l.323.646c.075.151.165.294.266.429l-1.364 2.11c-.12.359.2.71.568.624l2.829-.656 1.791 1.791a.285.285 0 0 0 .486-.201v-.769l.602.602c.51.51 1.29.637 1.935.314l1.832-.916-4.52-2.712a16.18 16.18 0 0 0-.605-2.708l3.944 1.898c.486 1.426 1.272 2.975 2.637 3.521h.001l.001.001c.218-1.946.176-3.569-.05-4.926z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#7c3d3d',
            fillRule: 'nonzero',
            opacity: 1,
          }}
        />
      </g>
    </svg>
  )
}
