import Button from 'common/ui/button'
import React from 'react'
import './email-not-confirmed.scss'
import { EnvelopeIcon } from 'common/components/icons'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { requestCurrentUserEmailConfirmation } from 'store/account'
import { i18n } from 'common/locale/i18n'
const EmailNotConfirmed = () => {
  const dispatch = useDispatch()
  const content = sent => ({
    title: sent ? i18n('email_confirmation_required.modal.sent_title'): i18n('email_confirmation_required.modal.title'),
    describtion: sent
      ? i18n('email_confirmation_required.modal.sent_description')
      : i18n('email_confirmation_required.modal.description'),
    button: i18n('email_confirmation_required.modal.button'),
  })
  const [verificationSent, setVerificationSent] = useState(false)

  const onResendConfirmationClick = () => {
    dispatch(requestCurrentUserEmailConfirmation())
    setVerificationSent(true)
  }
  return (
    <div className="email-not-confirmed__content">
      <i>
        <EnvelopeIcon width="16px" />
      </i>
      <div className="email-not-confirmed__content--title">{content(verificationSent).title}</div>
      <div className="email-not-confirmed__content--description">{content(verificationSent).describtion}</div>
      {!verificationSent && (
        <div className="email-not-confirmed__content--button">
          <Button size="large" onClick={onResendConfirmationClick}>
            {content(verificationSent).button}
          </Button>
        </div>
      )}
    </div>
  )
}
export default EmailNotConfirmed
