/* @flow */

export default function createFormData(data) {
  const formData = new global.FormData()

  for (const key of Object.keys(data)) {
    const value = data[key]
    if (value !== undefined) {
      // Safari is buggy when appending Files into FormData.
      // Workaround: Convert to Blob before.
      if (value instanceof global.File) {
        const blob = new global.Blob([value], { type: value.type })
        const filename = value.name
        formData.append(key, blob, filename)
      } else {
        formData.append(key, value)
      }
    }
  }

  return formData
}
