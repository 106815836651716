import React from 'react'
import Toast from 'app/components/toast'
import { toast } from 'react-toastify'
// import Device from 'common/util/Device'

export const toastService = (type, data, customOptions) => {
  const options = {
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    newestOnTop: true,
    progress: undefined,
    icon: false,
    theme: localStorage.getItem('theme') || 'light',
    ...customOptions,
  }

  const toastContent = <Toast component={type} _data={data} className={type} />

  toast(toastContent, options)
}
