import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import './Modal.scss'

const modalRoot = document.getElementById('modal-root')
const appRoot = document.body

class Modal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.contentRef = React.createRef()
    this.onOverlayClick = this.onOverlayClick.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
  }

  static defaultProps = {
    isOpen: false,
  }

  onOverlayClick(event) {
    if (this.contentRef.current.contains(event.target)) {
      return
    }
    if (this.props.onOverlayClick) {
      this.props.onOverlayClick()
    } else {
      this.onClose()
    }
  }

  onKeyUp(event) {
    if (event.key === 'Escape') {
      this.onClose()
    }
  }

  componentDidMount() {
    if (this.props.isOpen) {
      this.openModal()
    }
  }

  componentWillUnmount() {
    this.closeModal()
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen === prevProps.isOpen) {
      return
    }

    if (this.props.isOpen) {
      this.openModal()
    } else {
      this.closeModal()
    }
  }

  openModal() {
    appRoot.classList.add('app--noscroll')
    document.addEventListener('keyup', this.onKeyUp)
  }

  closeModal() {
    if (modalRoot && modalRoot.children.length <= 1) {
      appRoot.classList.remove('app--noscroll')
    }
    document.removeEventListener('keyup', this.onKeyUp)
  }

  onClose() {
    if(this.props.onCloseRequested)
    this.props.onCloseRequested()
  }

  render() {
    const { isOpen } = this.props
    if (!isOpen) {
      return null
    }

    const modalClassList = classNames('modal__overlay', this.props.className, {
      'modal__overlay--bottom': this.props.position === 'bottom',
      'modal__overlay--full': this.props.position === 'full',
      dark: this.props.dark,
    })

    const reactElement = (
      <div className={modalClassList} onClick={this.onOverlayClick}>
        <div className="modal__content" ref={this.contentRef} onKeyUp={this.onKeyUp}>
          {this.props.children}
        </div>
      </div>
    )

    if (!modalRoot) {
      return null
    }

    return ReactDOM.createPortal(reactElement, modalRoot)
  }
}

export default Modal
