import constants from 'app/constants'
import classNames from 'classnames'
import { useScrollDirection } from 'common/hooks'
// import Device from 'common/util/Device'
import React, { lazy } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMarketStatusById } from 'store/markets'
import LoadingIndicator from 'common/components/LoadingIndicator'
const PlaceBet = lazy(() => import('../place-bet'))
import { Suspense } from 'react'
import SuspensePanel from 'common/components/suspense'

const QuickBet = ({ shouldShow, outcome, marketSelected, setOutcomeSelected, setMarketSelected, place }) => {
  const [isVisible, setVisible] = useState(true)
  const scrollDirection = useScrollDirection(0)
  const [placebetOpen, setPlacebetOpen] = useState(true)
  const questionStatus = useSelector(selectMarketStatusById(marketSelected))
  const isLoading = questionStatus === constants.LOADING
  useEffect(() => {
    if (marketSelected) setPlacebetOpen(true)
  }, [marketSelected])

  useEffect(() => {
    if (!placebetOpen) {
      setTimeout(() => {
        setOutcomeSelected(null)
        setMarketSelected(null)
      }, 200)
    }
  }, [placebetOpen])

  useEffect(() => {
    setVisible(scrollDirection !== 'down')
  }, [scrollDirection])

  return (
    <> 
      <aside
        className={classNames('view-feed--place_bet', {
          'view-feed__aside--closed': !placebetOpen,
          'view-feed__aside--closed--tabBarUp': !placebetOpen && isVisible,
        })}
      >
        {isLoading && <LoadingIndicator mode="inline" />}
        {shouldShow && (
          <Suspense fallback={<SuspensePanel />}>
            <PlaceBet
              question={marketSelected}
              outcome={outcome}
              onChangeOutcome={setOutcomeSelected}
              onToggle={() => setPlacebetOpen(v => !v)}
              quickbet
              open={placebetOpen}
              place={place}
            />
          </Suspense>
        )}
      </aside>
    </>
  )
}

export default QuickBet
