import { useEffect, useState, useRef } from 'react'
import requestService from 'common/services/request'
import Pusher from 'pusher-js'
import { useCurrentUser } from 'common/hooks/useCurrentUser'

const useRealTimeAuthModel = ({ model, parseFn, onReceiveMessage, eventName = '' }) => {
  const currentUser = useCurrentUser()
  const userId = currentUser && currentUser.id ? currentUser.id : 0
  const [updatedModel, setUpdatedModel] = useState(model)
  const pusherSocket = useRef()

  useEffect(() => {
    if (currentUser && currentUser.id) {
      updateModel(model)
    }
  }, [userId])

  useEffect(() => {
    if (!userId) {
      return
    }

    if (!process.env.REACT_APP_PUSHER_APP_KEY || !process.env.REACT_APP_PUSHER_APP_CLUSTER) {
      return
    }

    setupPusherConnection()
    return () => {
      if (pusherSocket.current) {
        pusherSocket.current.disconnect()
      }
    }
  }, [userId])

  const setupPusherConnection = async () => {
    const authorizationToken = await requestService.getAuthorizationToken()
    if (!authorizationToken) {
      throw '[PUSHER] Unauthorized: user is not logged in'
    }

    // const channelName = `private-user-${currentUser.id}`
    const channelName = `private-user-${currentUser.id}`
    if (!eventName) {
      throw '[PUSHER] eventName property is not defined'
    }

    pusherSocket.current = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: true,
      authEndpoint: requestService.getRequestUrl('/pusher/auth/'),
      auth: {
        headers: {
          Authorization: authorizationToken,
        },
      },
    })

    let pusherChannel = pusherSocket.current.subscribe(channelName)
    pusherChannel.bind(eventName, data => {
      updateModel(data)
      onReceiveMessage(data)
    })
  }

  const updateModel = data => {
    if (parseFn) {
      setUpdatedModel(parseFn(data))
    } else {
      setUpdatedModel({ ...data })
    }
  }

  return updatedModel
}

export default useRealTimeAuthModel
