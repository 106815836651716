import React from 'react'
import classNames from 'classnames'
import './FormControls.scss'

const ToggleControl = ({ active, onToggle, className, loading }) => {
  return (
    <div
      data-testid="toggle-control"
      data-checked={active}
      className={classNames(className, 'toggle-control', {
        'toggle-control--active': active,
        'toggle-control--loading skeleton': loading,
      })}
      onClick={onToggle}></div>
  )
}

export default ToggleControl
