/* eslint-disable no-unused-vars */
import classNames from 'classnames'
import { RightArrowIcon, LeftArrowIcon } from 'common/components/icons'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import './Steper.scss'

const Steper = ({
  total = 0,
  current = 0,
  showArrows = true,
  move,
  style = 'dots',
  currentFill = '#777777',
  size,
  ...props
}) => {
  useEffect(() => {
    setIndex(current)
  }, [current])
  const [index, setIndex] = useState(current)

  const change = direction => {
    const newValue = index + direction
    if (newValue >= total || newValue < 0) return
    move(direction)
    setIndex(index + direction)
  }

  const dotStyle = i => ({
    width: size ? size + 'px' : '8px',
    height: size ? size + 'px' : '8px',
    backgroundColor: props.fill && index !== i ? props.fill : index === i && currentFill ? currentFill : '#c2ced1',
  })
  return (
    <div className="steper">
      {showArrows && move && <LeftArrowIcon width="8px" onClick={() => change(-1)} />}
      {new Array(total).fill(0).map((key, i) => (
        <i
          key={'steper' + i}
          style={dotStyle(i)}
          className={classNames('step', {
            'step--current': index == i,
            'step--dots': style === 'dots',
            'step--lines': style === 'lines',
          })}
          width={size}
          height={size}
        ></i>
      ))}
      {showArrows && move && <RightArrowIcon width="8px" onClick={() => change(1)} />}
    </div>
  )
}

export default Steper
