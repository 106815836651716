import Button from 'common/ui/button'
import Device from 'common/util/Device'
import React from 'react'
import './BannerGridEntry.scss'
import { ArrowRightUp } from 'common/components/icons'

const BannerGridEntry = ({ banner, showDescription = false, isSubCategory = false }) => {
  const getFontSizeFromTextLenght = text => {
    const min = 80
    const max = 180
    const value = text.length > max ? max : text.length < min ? min : text.length
    const map = (value, x1, y1, x2, y2) => ((value - x1) * (y2 - x2)) / (y1 - x1) + x2
    return 23 - map(value, min, max, 0, 10)
  }
  const image = innerWidth >= 768 ? banner.desktop_thumbnail_webp : banner.mobile_thumbnail_webp
  const renderFooter = () => {
    return (
      <footer className="banner-grid-entry__footer">
        <svg
          className="d-none d-md-block "
          style={{ position: 'absolute', top: '41px', right: '0' }}
          width="102"
          height="79"
          viewBox="0 0 102 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path id="Vector 1" d="M0 79L79 0H158L79 79H0Z" fill="#ED0071" />
        </svg>
        <div className="banner-grid-entry__footer__left">
          {banner.title && <h5 className="banner-grid-entry__title"> {banner.title} </h5>}
          {showDescription && banner.short_description && (
            <h6
              className="banner-grid-entry__description"
              style={
                Device.isMobile && banner.short_description
                  ? { fontSize: getFontSizeFromTextLenght(banner.short_description) }
                  : {}
              }
              dangerouslySetInnerHTML={{ __html: banner.short_description }}
            />
          )}
        </div>

        {banner && banner.action_text && !isSubCategory && (
          <Button variant="white">
            <span style={{ marginRight: '8px' }}>
              <ArrowRightUp width="16px" />
            </span>
            {banner.action_text}
          </Button>
        )}
      </footer>
    )
  }
  const bannerContent = () => (
    <article className="banner-grid-intro" key={`banner-grid-entry-${banner.id}`}>
      <img src={image} className="banner-grid-entry__image" alt={banner.title} title={banner.title} />
      <div className="banner-grid-entry__banner">{renderFooter()}</div>
    </article>
  )

  return isSubCategory ? (
    bannerContent()
  ) : (
    <a href={banner.link} className="banner-grid-entry">
      {bannerContent()}
    </a>
  )
}

export default BannerGridEntry
