import * as React from 'react'

export default function PaperAirplaneIcon({ fillcolor, className, ...props }) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 18" className={className} {...props}>
      <path
        fill={fillcolor || 'currentColor'}
        d="M1.1175 0.173299L16.5025 8.63497C16.5678 8.67093 16.6223 8.72378 16.6602 8.78798C16.6982 8.85218 16.7182 8.92539 16.7182 8.99997C16.7182 9.07454 16.6982 9.14775 16.6602 9.21195C16.6223 9.27615 16.5678 9.329 16.5025 9.36497L1.1175 17.8266C1.05406 17.8615 0.982623 17.8793 0.910228 17.8782C0.837832 17.8771 0.766977 17.8571 0.704647 17.8203C0.642317 17.7834 0.590664 17.731 0.554778 17.6681C0.518892 17.6052 0.500012 17.534 0.5 17.4616V0.538299C0.500012 0.465895 0.518892 0.394743 0.554778 0.331858C0.590664 0.268972 0.642317 0.216523 0.704647 0.17968C0.766977 0.142838 0.837832 0.122873 0.910228 0.121754C0.982623 0.120635 1.05406 0.138401 1.1175 0.173299ZM2.16667 9.8333V15.3475L13.7083 8.99997L2.16667 2.65247V8.16663H6.33333V9.8333H2.16667Z"
      />
    </svg>
  )
}
