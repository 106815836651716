import React from 'react'

const PasswordResetSuccessIcon = props => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 76" {...props}>
      <path
        d="M32 20c13.234 0 24 10.766 24 24S45.234 68 32 68 8 57.234 8 44s10.766-24 24-24Zm0 4.8c-10.586 0-19.2 8.614-19.2 19.2 0 10.586 8.614 19.2 19.2 19.2 10.586 0 19.2-8.614 19.2-19.2 0-10.586-8.614-19.2-19.2-19.2Zm0 7.2c3.974 0 7.2 3.226 7.2 7.2 0 2.777-1.589 5.158-3.893 6.36L36.8 56h-9.6l1.493-10.44c-2.304-1.202-3.893-3.583-3.893-6.36 0-3.974 3.226-7.2 7.2-7.2Z"
        fill="#fff"
      />
      <path
        d="m19.604 15.472-1.47 1.432 1.47 1.432L29.96 28.432 31.357 27l1.396 1.432L51.396 10.26l1.47-1.432-1.47-1.432-2.9-2.827L47.1 3.207l-1.396 1.36-14.347 13.986-6.06-5.908-1.397-1.361-1.396 1.36-2.9 2.828Z"
        fill="#fff"
        stroke="#57B591"
        strokeWidth="4"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default PasswordResetSuccessIcon
