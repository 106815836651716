import { useNavigate } from 'react-router-dom'
import { userIsAnonymous } from 'api/auth'
import { loginUrl } from 'urls'

const useAuthOnlyRoute = () => {
  const navigate = useNavigate()
  const currentPath =  location.pathname
  userIsAnonymous().then(isAnonymous => {
    if (isAnonymous) { 
      localStorage.setItem('lastPath',currentPath)
      navigate(loginUrl(), { replace: true })
    }
  })
}

export default useAuthOnlyRoute
