/* @flow */
import dateLocale from 'date-fns/locale/en-US'

const formatRelative = {
  lastWeek: "'Last' eeee 'at' p",
  yesterday: "'Yesterday at' p",
  today: "'Today at' p",
  tomorrow: "'Tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: 'MMMM do',
}

export const dateOptions = {
  locale: {
    ...dateLocale,
    formatRelative: token => formatRelative[token],
  },
}
