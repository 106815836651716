import React from 'react'

const EnvelopeIcon = ({ fillcolor = 'currentColor', ...props }) => {
  return (
    <svg {...props}  viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.4974 0.5H16.4974C16.9576 0.5 17.3307 0.8731 17.3307 1.33333V14.6667C17.3307 15.1269 16.9576 15.5 16.4974 15.5H1.4974C1.03716 15.5 0.664062 15.1269 0.664062 14.6667V1.33333C0.664062 0.8731 1.03716 0.5 1.4974 0.5ZM15.6641 4.0316L9.05723 9.94833L2.33073 4.01328V13.8333H15.6641V4.0316ZM2.75695 2.16667L9.04898 7.71833L15.2482 2.16667H2.75695Z"
        fill={fillcolor}
      />
    </svg>
  )
}

export default EnvelopeIcon
