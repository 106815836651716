import ErrorHandler from 'app/components/error-handler'
import classNames from 'classnames'
import OutsideAlerter from 'common/components/outside-alerter'
import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectPageLayout } from 'store/app'
import { ViewActivity } from 'views/activity'
import './NotificationWidget.scss'

// eslint-disable-next-line no-unused-vars
const NotificationWidget = ({ isOpen, onClose }) => {
  const pageLayout = useSelector(selectPageLayout)
  const location = useLocation()

  useEffect(() => {
    if (!pageLayout.notificationWidgetOpen) {
      onClose()
    }
  }, [pageLayout.notificationWidgetOpen])

  const renderContent = () => {
    return (
      <OutsideAlerter
        onClose={ev => {
          if (!Array.from(ev.target.classList).find(c => ['navbar__notification--bell--cover', 'tab'].includes(c))) {
            onClose()
          }
        }}
      >
        <div
          className={classNames('notification_widget__container', {
            'notification_widget__container--open': isOpen,
          })}
        >
          <ErrorHandler location={location} component="notification-widget">
            <div className="notification_widget__container--scroll">{isOpen && <ViewActivity />}</div>
          </ErrorHandler>
        </div>
      </OutsideAlerter>
    )
  }

  return renderContent()
}

export default NotificationWidget
