/* @flow */

const prevent = event => event.preventDefault()
const { body } = global.document

/** Prevents Zoom gesture on iOS 10+ devices */
export default function preventZoom(): Promise<void> {
  return new Promise(resolve => {
    body.addEventListener('gesturestart', prevent)
    body.addEventListener('gesturechange', prevent)
    body.addEventListener('gestureend', prevent)
    resolve()
  })
}
