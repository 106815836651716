import requestService from 'common/services/request'
import { createSlice } from '@reduxjs/toolkit'
import Device from 'common/util/Device'
import constants from 'app/constants'
import { getMarketType } from 'common/util/currencies'

const transformInternalUrls = banner => {
  const b = { ...banner }
  if (banner.link.startsWith('https://futuur.com')) {
    b.link = banner.link.substr(18)
  }
  if (banner.link.startsWith('https://staging.futuur.com')) {
    b.link = banner.link.substr(26)
  }
  if (Device.isCordova) {
    b.short_description = banner.short_description.replace(/https:\/\/futuur.com/i, '#')
  } else {
    b.short_description = banner.short_description?.split('https://futuur.com').join('')
  }

  return b
}

// Reducers / Slices:

const initialState = {
  feed: { data: [], status: constants.IDLE },
  bannersByCategory: {},
  error: null,
  marketType: '',
}

const banners = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    getFeedBannersStart: (state, action) => {
      const { marketType } = action.payload
      if (state.marketType !== marketType) {
        resetBanners(state, action)
      }
      state.feed.status = constants.LOADING
      state.marketType = marketType
    },

    getFeedBannersSuccess: (state, action) => {
      state.feed.status = constants.COMPLETE
      state.feed.data = action.payload.map(transformInternalUrls)
    },

    getFeedBannersError: (state, action) => {
      state.error = action.payload
    },

    getBannersByCategoryStart: (state, action) => {
      const { categoryId, marketType } = action.payload
      if (state.marketType !== marketType) {
        resetBanners(state, action)
      }

      state.marketType = marketType
      state.bannersByCategory[categoryId] = { status: constants.LOADING, data: [] }
    },

    getBannersByCategorySuccess: (state, action) => {
      const { categoryId, banners } = action.payload
      state.bannersByCategory[categoryId] = { status: constants.COMPLETE, data: banners.map(transformInternalUrls) }
    },

    getBannersByCategoryError: (state, action) => {
      const { categoryId, error } = action.payload
      state.bannersByCategory[categoryId] = { status: constants.ERROR, data: [], error }
    },

    resetBanners: state => {
      state.feed = initialState.feed
      state.bannersByCategory = initialState.bannersByCategory
      state.error = initialState.error
      state.marketType = initialState.marketType
    },
  },
})

export const { getFeedBannersStart, getFeedBannersSuccess, getFeedBannersError } = banners.actions
export const { getBannersByCategoryStart, getBannersByCategorySuccess, getBannersByCategoryError } = banners.actions
export const { resetBanners } = banners.actions
export default banners.reducer

// Selectors

export const selectFeedBanners = state => state.banners.feed.data
export const selectFeedBannersStatus = state => state.banners.feed.status
export const selectBannersByCategoryId = categoryId => state => {
  if (state.banners.bannersByCategory[categoryId]) {
    return state.banners.bannersByCategory[categoryId].data
  }
  return []
}

export const selectBannersStatusByCategoryId = categoryId => state => {
  if (state.banners.bannersByCategory[categoryId]) {
    return state.banners.bannersByCategory[categoryId].status
  }
  return constants.IDLE
}

export const selectBannersErrorByCategoryId = categoryId => state => {
  if (state.banners.bannersByCategory[categoryId]) {
    return state.banners.bannersByCategory[categoryId].error
  }
  return null
}

export const selectShouldLoadFeedBanners = marketType => state => {
  return state.banners.marketType !== marketType || state.banners.feed.status === constants.IDLE
}

// Thunks

export const fetchFeedBanners = marketType => async dispatch => {
  const currencyMode = getMarketType(marketType === constants.REAL_MONEY)
  dispatch(getFeedBannersStart({ marketType }))
  try {
    var response = await requestService.get(`/banners/?currency_mode=${currencyMode}`)
  } catch (err) {
    dispatch(getFeedBannersError(err))
    return
  }
  dispatch(getFeedBannersSuccess(await response.json()))
}

export const fetchBannersByCategory = (categoryId, marketType) => async dispatch => {
  const currencyMode = getMarketType(marketType === constants.REAL_MONEY)
  dispatch(getBannersByCategoryStart({ categoryId, marketType }))
  try {
    var response = await requestService.get(`/banners/?category=${categoryId}&currency_mode=${currencyMode}`)
  } catch (err) {
    dispatch(getBannersByCategoryError({ error: err, categoryId }))
  }
  dispatch(getBannersByCategorySuccess({ categoryId, banners: await response.json() }))
}
