import { SuccessIcon } from 'common/components/icons'
import React from 'react'

class FeedbackSent extends React.PureComponent {
  render() {
    return (
      <div className="message">
        <SuccessIcon />

        <div className="message__text">
          <div className="message__main-text">Thanks for your feedback!</div>
          <div className="message__sub-text">You can send as many as you want.</div>
        </div>
      </div>
    )
  }
}

export default FeedbackSent
