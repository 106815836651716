import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ViewCategoryDetails from 'views/category-details'
import ViewMarketPage from 'views/market-page'
import ViewTags from 'views/tags'
// const ViewBetInfo = lazy(() => import('views/bet-info'))

const ViewSellBet = lazy(() => import('views/sell-bet'))

const ViewTagsList = lazy(() => import('views/tags-list'))

export default [
  { path: '/q/feed', element: <Navigate to="/" replace /> },
  { path: '/bet/:predictionId/info', element: <Navigate to="/" replace /> },
  {
    path: '/m/:questionId/sell/:betId',
    element: <ViewSellBet />,
    isModal: true,
  },
  {
    path: ['/q/category/:categoryId', '/q/category/:categoryId/:categorySlug'],
    element: <ViewCategoryDetails />,
  },
  { path: ['/p/tags'], element: <ViewTagsList /> },
  {
    path: ['/q/tag/:tag'],
    element: <ViewTags />,
  },
  {
    path: ['/q/:questionId', '/q/:questionId/:questionSlug'],
    element: <ViewMarketPage />,
  },
  {
    path: '/q/',
    element: <Navigate to="/" replace />,
  },
]
