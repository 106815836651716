import requestService from 'common/services/request'
import { createSlice } from '@reduxjs/toolkit'
import constants from 'app/constants'
import { getCurrencyMode } from 'common/util/currencies'
import { addMarketsToPool } from 'store/markets'
import { addUsersToPool } from 'store/users'
import { addTagsToPool } from 'store/tags'
import { addCategoriesToPool } from 'store/categories'

const initialState = {
  categories: { pagination: {}, data: [], status: constants.IDLE },
  users: { pagination: {}, data: [], status: constants.IDLE },
  markets: { pagination: {}, data: [], status: constants.IDLE },
  tags: { pagination: {}, data: [], status: constants.IDLE },
}

// Utils
const searchReset = (searchType, state) => {
  state[searchType].pagination = {}
  state[searchType].error = null
  state[searchType].data = []
  state[searchType].status = constants.IDLE
}

const searchStart = (searchType, state) => {
  state[searchType].status = constants.LOADING
}

const searchSuccess = (searchType, state, data, pagination) => {
  state[searchType].pagination = pagination
  state[searchType].data = [...new Set(state[searchType].data.concat(data.map(el => el.id)))]
  state[searchType].status = constants.COMPLETE
}

const searchError = (searchType, state, error) => {
  if (!state[searchType]) return
  state[searchType].error = error
  state[searchType].status = constants.ERROR
}

const createQueryString = (query, params) => {
  const {
    ordering,
    live_only,
    categories,
    resolved_only,
    limit,
    offset,
    category,
    people_i_follow,
    currencyId = 'OOM',
  } = params
  let qs = `search=${query}`

  if (ordering != null && ordering.length > 0) {
    qs += `&ordering=${ordering}`
  }

  if (live_only != null && live_only.length > 0) {
    qs += `&live_only=${live_only}`
  }

  if (resolved_only != null && resolved_only.length > 0) {
    qs += `&resolved_only=${resolved_only}`
  }

  if (categories != null && categories.length > 0) {
    qs += `&categories=${categories}`
  }
  if (category != null && category.length > 0) {
    qs += `&category=${category}`
  }
  if (people_i_follow != null && people_i_follow.length > 0) {
    qs += `&people_i_follow=${people_i_follow}`
  }

  if (limit) {
    qs += `&limit=${limit}`
  }

  if (offset) {
    qs += `&offset=${offset}`
  }

  if (currencyId) {
    qs += '&currency_mode=' + getCurrencyMode(currencyId)
  }

  return qs
}

// Reducers / Slices

const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // ? Markets
    searchMarketReset: state => {
      searchReset('markets', state)
    },

    searchMarketStart: state => {
      searchStart('markets', state)
    },

    searchMarketSuccess: (state, action) => {
      const { results, pagination, total } = action.payload
      searchSuccess('markets', state, results, pagination ? pagination : { total })
    },

    searchMarketError: (state, action) => {
      const { error } = action.payload
      searchError('markets', state, error)
    },

    // ? User
    searchUserReset: state => {
      searchReset('users', state)
    },

    searchUsersStart: state => {
      searchStart('users', state)
    },

    searchUsersSuccess: (state, action) => {
      const { results, pagination, total } = action.payload
      searchSuccess('users', state, results, pagination ? pagination : { total })
    },

    searchUsersError: (state, action) => {
      const { error } = action.payload
      searchError('users', error)
    },

    // ? Categories
    searchCategoriesReset: state => {
      searchReset('categories', state)
    },

    searchCategoriesStart: state => {
      searchStart('categories', state)
    },

    searchCategoriesSuccess: (state, action) => {
      const pagination = action.payload.pagination ? action.payload.pagination : { total: action.payload.total }
      searchSuccess('categories', state, action.payload.results, pagination)
    },

    searchCategoriesError: (state, action) => {
      searchError('categories', state, action.payload.error)
    },

    // ? Tags
    searchTagsReset: state => {
      searchReset('tags', state)
    },

    searchTagsStart: state => {
      searchStart('tags', state)
    },

    searchTagsSuccess: (state, action) => {
      const pagination = action.payload.pagination ? action.payload.pagination : { total: action.payload.total }
      searchSuccess('tags', state, action.payload.results, pagination)
    },

    searchTagsError: (state, action) => {
      searchError('tags', state, action.payload.error)
    },
  },
})

export const {
  searchMarketReset,
  searchMarketStart,
  searchMarketSuccess,
  searchMarketError,
  searchUserReset,
  searchUsersStart,
  searchUsersSuccess,
  searchUsersError,
  searchCategoriesStart,
  searchCategoriesSuccess,
  searchCategoriesError,
  searchCategoriesReset,
  searchTagsStart,
  searchTagsSuccess,
  searchTagsError,
  searchTagsReset,
} = search.actions

export default search.reducer

// Selectors:

export const selectMarkets = () => state => state.search.markets.data.map(marketId => state.markets.pool.data[marketId])
export const selectUsers = () => state => state.search.users.data.map(userId => state.users.pool.data[userId])
export const selectCategories = () => state =>
  state.search.categories.data.map(categoryId => state.categories.pool.data[categoryId])

export const selectTags = () => state => state.search.tags.data.map(tagId => state.tags.pool.data[tagId])

export const selectMarketsStatus = () => state => state.search.markets.status
export const selectCategoriesStatus = () => state => state.search.categories.status
export const selectUsersStatus = () => state => state.search.users.status
export const selectTagsStatus = () => state => state.search.tags.status

export const selectMarketsPagination = () => state => state.search.markets.pagination || {}
export const selectUsersPagination = () => state => state.search.users.pagination || {}
export const selectCategoriesPagination = () => state => state.search.categories.pagination || {}
export const selectTagsPagination = () => state => state.search.tags.pagination || {}

// Thunks:

const performSearch = async (dispatch, query, loadingFn, successFn, errorFn, dispatchToPoolCallback) => {
  dispatch(loadingFn())
  try {
    var response = await requestService.get(query)
  } catch (error) {
    dispatch(errorFn({ error }))
  }
  const { results, pagination } = await response.json()
  dispatchToPoolCallback(results, pagination)
  dispatch(successFn({ results, pagination }))
  return results
}

export const resetSearch = () => dispatch => {
  dispatch(searchMarketReset())
  dispatch(searchUserReset())
  dispatch(searchCategoriesReset())
  dispatch(searchTagsReset())
}

export const searchMarkets =
  (query = '', params = {}) =>
  async dispatch => {
    const qs = createQueryString(query, params)
    return await performSearch(
      dispatch,
      `/questions/?${qs}`,
      searchMarketStart,
      searchMarketSuccess,
      searchMarketError,
      markets => dispatch(addMarketsToPool({ markets }))
    )
  }

export const searchUsers =
  (query = '', params = {}) =>
  async dispatch => {
    const qs = createQueryString(query, params)
    const url = `/users/?${qs}`
    return await performSearch(dispatch, url, searchUsersStart, searchUsersSuccess, searchUsersError, users =>
      dispatch(addUsersToPool({ users }))
    )
  }

export const searchCategories =
  (query = '', params = {}) =>
  async dispatch => {
    const currencyMode = getCurrencyMode(params.currencyId)
    const url = `/categories/?search=${query}&currency_mode=${currencyMode}`
    return await performSearch(
      dispatch,
      url,
      searchCategoriesStart,
      searchCategoriesSuccess,
      searchCategoriesError,
      categories => dispatch(addCategoriesToPool({ categories }))
    )
  }
export const searchTags =
  (query = '', params = {}) =>
  async dispatch => {
    const currencyMode = getCurrencyMode(params.currencyId)
    const url = `/tags/?search=${query}&currency_mode=${currencyMode}`
    return await performSearch(dispatch, url, searchTagsStart, searchTagsSuccess, searchTagsError, tags =>
      dispatch(addTagsToPool({ tags }))
    )
  }

export const searchAll =
  (query = {}, params = {}) =>
  async dispatch => {
    const searchParams = []
    query.search = query.q || ''
    delete query.q
    for (const property in query) {
      searchParams.push(property + '=' + query[property])
    }
    const currencyMode = getCurrencyMode(params.currencyId)
    const url = `/search/?${searchParams.join('&')}&currency_mode=${currencyMode}`

    dispatch(searchMarketStart())
    dispatch(searchUsersStart())
    dispatch(searchCategoriesStart())
    dispatch(searchTagsStart())

    try {
      var response = await requestService.get(url)
    } catch (error) {
      dispatch(searchMarketError({ error }))
      dispatch(searchUsersError({ error }))
      dispatch(searchCategoriesError({ error }))
      dispatch(searchTagsError({ error }))
    }

    const json = await response.json()
    dispatch(addMarketsToPool({ markets: json.questions }))
    dispatch(searchMarketSuccess({ results: json.questions, total: json.questions_total }))

    dispatch(addCategoriesToPool({ categories: json.categories }))
    dispatch(searchCategoriesSuccess({ results: json.categories, total: json.categories_total }))

    dispatch(addUsersToPool({ users: json.users }))
    dispatch(searchUsersSuccess({ results: json.users, total: json.users_total }))

    dispatch(addTagsToPool({ tags: json.tags }))
    dispatch(searchTagsSuccess({ results: json.tags, total: json.tags_total }))
  }
