import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import './Toast.scss'
import {
  FeedbackSent,
  UserUnfollow,
  UserFollow,
  WagerBuy,
  WagerSell,
  WagerSellAll,
  Withdraw,
  Deposit,
  PushNotification,
  MarketFollow,
  Success,
  Fail,
} from 'notifications/components/message-types'

const Toast = ({ component, _data }) => {
  const from = typeString => {
    switch (typeString) {
      case 'wagerBuy':
        return <WagerBuy {..._data} />
      case 'wagerSell':
        return <WagerSell {..._data} />
      case 'wagerSellAll':
        return <WagerSellAll {..._data} />
      case 'feedbackSent':
        return <FeedbackSent {..._data} />
      case 'userFollow':
        return <UserFollow {..._data} />
      case 'withdraw':
        return <Withdraw {..._data} />
      case 'deposit':
        return <Deposit {..._data} />
      case 'success':
        return <Success {..._data} />
      case 'fail':
        return <Fail {..._data} />
      case 'userUnfollow':
        return <UserUnfollow {..._data} />
      case 'pushNotification':
        return <PushNotification {..._data} />
      case 'marketFollow':
        return <MarketFollow {..._data} />
      case 'marketUnfollow':
        return <MarketFollow {..._data} />
      case 'currencyChanged':
        return

      default:
        return <></>
    }
  }

  return from(component)
}

export default Toast
