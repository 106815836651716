import React from 'react'

const successIcon = ({ fill = '#00D966', ...props }) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="48" height="48" rx="24" fill={fill} />
      <path d="M22 27.172L31.192 17.979L32.607 19.393L22 30L15.636 23.636L17.05 22.222L22 27.172Z" fill="white" />
    </svg>
  )
}

export default successIcon
