import React from 'react'

const ClipBoard = props => {
  return (
    <svg {...props} viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 2V0H14V2H17.007C17.555 2 18 2.445 18 2.993V19.007C17.9997 19.2703 17.895 19.5227 17.7089 19.7089C17.5227 19.895 17.2703 19.9997 17.007 20H0.993C0.729721 19.9997 0.477302 19.895 0.291135 19.7089C0.104969 19.5227 0.000264735 19.2703 0 19.007V2.993C0 2.445 0.445 2 0.993 2H4ZM4 4H2V18H16V4H14V6H4V4ZM6 2V4H12V2H6Z" />
    </svg>
  )
}

export default ClipBoard
