import React from 'react'

const lock = props => {
  return (
    // <svg width="28" height="40" viewBox="0 0 28 40" xmlns="http://www.w3.org/2000/svg" {...props}>
    //   <path
    //     d="M14 32C15.6569 32 17 30.6569 17 29C17 27.3431 15.6569 26 14 26C12.3431 26 11 27.3431 11 29C11 30.6569 12.3431 32 14 32Z"
    //     fill="inherit"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M24.0001 17.9995V9.99951C24.0001 4.48551 19.5141 -0.000488281 14.0001 -0.000488281C8.48606 -0.000488281 4.00006 4.48551 4.00006 9.99951V17.9995C1.79406 17.9995 6.10352e-05 19.7915 6.10352e-05 21.9995V35.9995C6.10352e-05 38.2055 1.79406 39.9995 4.00006 39.9995H24.0001C26.2061 39.9995 28.0001 38.2055 28.0001 35.9995V21.9995C28.0001 19.7915 26.2061 17.9995 24.0001 17.9995ZM8 10.0004C8 6.69239 10.692 4.00039 14 4.00039C17.308 4.00039 20 6.69239 20 10.0004V18.0004H8V10.0004ZM3.99995 22.0004V36.0004H24.002L24 22.0004H3.99995Z"
    //     fill="inherit"
    //   />
    // </svg>
    <svg width="12" height="14" viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.6667 5.66634H11.3333C11.7015 5.66634 12 5.96481 12 6.33301V12.9997C12 13.3679 11.7015 13.6663 11.3333 13.6663H0.666667C0.29848 13.6663 0 13.3679 0 12.9997V6.33301C0 5.96481 0.29848 5.66634 0.666667 5.66634H1.33333V4.99967C1.33333 2.42235 3.42267 0.333008 6 0.333008C8.57733 0.333008 10.6667 2.42235 10.6667 4.99967V5.66634ZM1.33333 6.99967V12.333H10.6667V6.99967H1.33333ZM5.33333 8.33301H6.66667V10.9997H5.33333V8.33301ZM9.33333 5.66634V4.99967C9.33333 3.15873 7.84093 1.66634 6 1.66634C4.15905 1.66634 2.66667 3.15873 2.66667 4.99967V5.66634H9.33333Z" />
    </svg>
  )
}

export default lock
