import React from 'react'

const EmailReceivedIcon = props => {
  return (
    <svg {...props} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.666 4.03167L9.05935 9.94833L2.33268 4.01333V13.8333H9.83268V15.5H1.49935C1.27834 15.5 1.06637 15.4122 0.910093 15.2559C0.753813 15.0996 0.666016 14.8877 0.666016 14.6667V1.33333C0.666016 1.11232 0.753813 0.900358 0.910093 0.744078C1.06637 0.587797 1.27834 0.5 1.49935 0.5H16.4993C16.7204 0.5 16.9323 0.587797 17.0886 0.744078C17.2449 0.900358 17.3327 1.11232 17.3327 1.33333V8H15.666V4.03167ZM15.2502 2.16667H2.75852L9.05018 7.71833L15.251 2.16667H15.2502ZM15.666 13H18.166L14.8327 16.3333L11.4993 13H13.9993V9.66667H15.666V13Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default EmailReceivedIcon
