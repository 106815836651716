import firebase from 'firebase/compat/app'

// fix iOS runtime bug because it's missing navigator.serviceWorker
if ('serviceWorker' in navigator) {
  require('firebase/compat/messaging')
}

const firebaseConfig = {
  apiKey: 'AIzaSyB2aUbbnutG8otL1tm-xo4hrTRVaetrcA4',
  authDomain: 'futuur-app.firebaseapp.com',
  databaseURL: 'https://futuur-app.firebaseio.com',
  projectId: 'futuur-app',
  storageBucket: 'futuur-app.appspot.com',
  messagingSenderId: '597014708797',
  appId: '1:597014708797:web:887f2f1a09bc4db6f5e054',
}
firebase.initializeApp(firebaseConfig)

const messaging = firebase.messaging?.()

export default messaging
