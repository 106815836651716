import React from 'react'

export default function PersonIcon({ fillcolor, className, ...props }) {
  return (
    <svg
      className={className}
      {...props}
      width={props.width || 16}
      height={props.height || 16}
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.666687 14.667C0.666687 11.7215 3.0545 9.33366 6.00002 9.33366C8.94555 9.33366 11.3334 11.7215 11.3334 14.667H10C10 12.4579 8.20915 10.667 6.00002 10.667C3.79088 10.667 2.00002 12.4579 2.00002 14.667H0.666687ZM6.00002 8.66699C3.79002 8.66699 2.00002 6.87699 2.00002 4.66699C2.00002 2.45699 3.79002 0.666992 6.00002 0.666992C8.21002 0.666992 10 2.45699 10 4.66699C10 6.87699 8.21002 8.66699 6.00002 8.66699ZM6.00002 7.33366C7.47335 7.33366 8.66669 6.14033 8.66669 4.66699C8.66669 3.19366 7.47335 2.00033 6.00002 2.00033C4.52669 2.00033 3.33335 3.19366 3.33335 4.66699C3.33335 6.14033 4.52669 7.33366 6.00002 7.33366Z"
        fill={fillcolor || 'currentColor'}
      />
    </svg>
  )
}
