import React from 'react'

export default function PixIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24" fill="none">
      <path
        d="M15.45 16.52L12.44 13.51C12.33 13.4 12.2 13.38 12.13 13.38C12.06 13.38 11.93 13.4 11.82 13.51L8.8 16.53C8.46 16.87 7.93 17.42 6.16 17.42L9.87 21.12C10.4325 21.6818 11.195 21.9974 11.99 21.9974C12.785 21.9974 13.5475 21.6818 14.11 21.12L17.83 17.41C16.92 17.41 16.16 17.23 15.45 16.52ZM8.8 7.47L11.82 10.49C11.9 10.57 12.02 10.62 12.13 10.62C12.24 10.62 12.36 10.57 12.44 10.49L15.43 7.5C16.14 6.76 16.95 6.59 17.86 6.59L14.14 2.88C13.5775 2.3182 12.815 2.00264 12.02 2.00264C11.225 2.00264 10.4625 2.3182 9.9 2.88L6.19 6.58C7.95 6.58 8.49 7.16 8.8 7.47Z"
        fill="#37B5AA"
      />
      <path
        d="M21.11 9.85001L18.86 7.59001H17.6C17.06 7.59001 16.52 7.81001 16.15 8.20001L13.15 11.2C12.87 11.48 12.5 11.62 12.13 11.62C11.749 11.6146 11.3843 11.4645 11.11 11.2L8.09 8.17001C7.71 7.79001 7.19 7.57001 6.64 7.57001H5.17L2.88 9.87001C2.3182 10.4325 2.00264 11.195 2.00264 11.99C2.00264 12.785 2.3182 13.5475 2.88 14.11L5.17 16.41H6.65C7.19 16.41 7.71 16.19 8.1 15.81L11.12 12.79C11.4 12.51 11.77 12.37 12.14 12.37C12.51 12.37 12.88 12.51 13.16 12.79L16.17 15.8C16.55 16.18 17.07 16.4 17.62 16.4H18.88L21.13 14.14C21.6942 13.5674 22.0087 12.7947 22.005 11.9909C22.0012 11.187 21.6795 10.4174 21.11 9.85001Z"
        fill="#37B5AA"
      />
    </svg>
  )
}
