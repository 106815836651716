import store from 'store'
import { selectActiveLanguage } from 'store/app'
import { i18n_en_us } from './i18n_en_us'
import { i18n_pt_br } from './i18n_pt_br'

const i18nObj = {
  en_us: i18n_en_us,
  pt_br: i18n_pt_br,
}

export function i18n(key, kwargs = {}) {
  if (!store) return null
  const state = store.getState()
  let l = selectActiveLanguage()(state)
  let obj

  if (l === 'pt-br') {
    obj = i18nObj.pt_br
  } else {
    // Default language
    obj = i18nObj.en_us
  }

  const str = key.split('.').reduce((o, i) => o[i], obj)
  if (str instanceof Array) {
    return str
  }
  return new Function(`return \`${str}\`;`).call(kwargs)
}
