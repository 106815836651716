import Cookies from 'universal-cookie'
import storage from 'common/util/storage'

export async function storeCredential(data) {
  // data.expires_in is in seconds and getTime uses miliseconds
  const expiresOn = new Date().getTime() + data.expires_in * 1000
  await storage('userCredentials').set({
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    tokenType: data.token_type,
    scope: data.scope,
    expiresOn,
  })
}

export function getCommunityToken() {
  const cookies = new Cookies()
  return cookies.get('flarum_remember', { path: '/', domain: process.env.REACT_APP_COMMUNITY_COOKIE_DOMAIN })
}

export function storeCommunityToken(token) {
  deleteCommunityToken()
  const cookies = new Cookies()
  cookies.set('flarum_remember', token, { path: '/', domain: process.env.REACT_APP_COMMUNITY_COOKIE_DOMAIN })
}

export function deleteCommunityToken() {
  // TODO: Flarum doesn't have an api to revoke token yet
  const cookies = new Cookies()
  cookies.remove('flarum_remember', { path: '/', domain: process.env.REACT_APP_COMMUNITY_COOKIE_DOMAIN })
}

export async function getCredential() {
  const credential = await storage('userCredentials').get()
  if (credential == null) {
    return null
  }
  return credential
}

export async function deleteCredential() {
  const credential = storage('userCredentials')
  if (credential != null) {
    await credential.remove()
  }
}

export async function deleteCurrencyPreferences() {
  const currency = storage('userCurrency')
  const RealMoneycurrency = storage('lastRealMoneyCurrency')
  if (RealMoneycurrency != null) {
    await RealMoneycurrency.remove()
  }
  if (currency != null) {
    await currency.remove()
  }
}

export async function credentialIsValid() {
  const credential = await getCredential()
  const now = new Date().getTime()
  if (credential && now < credential.expiresOn) {
    return true
  }

  return false
}

export async function userIsAnonymous() {
  const credential = await storage('userCredentials').get()
  if (credential != null) {
    return false
  }

  return true
}

export default {
  userIsAnonymous,
}
