import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { selectPageLayout, setTransparentNavbar } from 'store/app'

const usePageWithTransparentNavbar = () => {
  const dispatch = useDispatch(selectPageLayout)

  useEffect(() => {
    dispatch(setTransparentNavbar(true))
    return () => {
      dispatch(setTransparentNavbar(false))
    }
  }, [])
}

export default usePageWithTransparentNavbar
