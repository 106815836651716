import React from 'react'
import { useNavigate } from 'react-router-dom'
import { i18n } from 'common/locale/i18n'
import { keepQueryString, signupUrl } from 'urls'
import Button from 'common/ui/button'
import './BannerGridIntro.scss'
import { ArrowRightUp } from 'common/components/icons'
// import { EyeIcon } from 'common/components/icons'
const BannerGridIntro = ({ text, image, isConnected }) => {
  const navigate = useNavigate()

  return (
    <article className="banner-grid-intro">
      <img src={image} className="banner-grid-intro__image" alt={text} title={text} />

      <div className="banner-grid-entry__banner">
        <footer className="banner-grid-entry__footer">
          <svg
            className="d-none d-md-block "
            style={{ position: 'absolute', top: '41px', right: '0' }}
            width="102"
            height="79"
            viewBox="0 0 102 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path id="Vector 1" d="M0 79L79 0H158L79 79H0Z" fill="#ED0071" />
          </svg>

          <div className="banner-grid-entry__footer__left">
            {text && <h1 className="banner-grid-entry__title"> {text} </h1>}

            <h2
              className="banner-grid-entry__description"
              dangerouslySetInnerHTML={{ __html: i18n('feed_banner.intro_text') }}
            />
          </div>

          {!isConnected && (
            <Button variant="primary" onClick={() => navigate(keepQueryString(signupUrl()))}>
              <span style={{ marginRight: '8px' }}>
                <ArrowRightUp width="16px" />
              </span>{' '}
              {i18n('feed_banner.start_forecasting')}
            </Button>
          )}
        </footer>
      </div>
    </article>
  )
}

export default BannerGridIntro
