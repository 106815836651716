import React, { lazy } from 'react'
import { searchUrl } from 'explore/urls'
const ViewSearch = lazy(() => import('views/search'))

export default [
  {
    path: searchUrl(),
    element: <ViewSearch />,
  },
]
