import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import './DropdownMenu.scss'

const MenuLink = ({ icon, text, url, target = '', hidden = false }) => {
  const isExternalLink = /^https?:\/\//.test(url)

  const classList = classNames('menu-item menu-item--link', {
    'menu-item--hidden': hidden,
  })

  const content = (
    <React.Fragment>
      <span className="menu-item__icon">{icon}</span>
      {text}
    </React.Fragment>
  )

  if (isExternalLink) {
    return (
      <a href={url} className={classList} target={target}>
        {content}
      </a>
    )
  } else {
    return (
      <Link className={classList} to={hidden ? '/' : url && url !== undefined ? url : '/'}>
        {content}
      </Link>
    )
  }
}

export default MenuLink
