import React, { useLayoutEffect } from 'react'
import { useCurrentUser } from 'common/hooks'
import { useModal } from 'app/components/modal-provider/ModalProvider'
import EmailNotConfirmed from 'app/components/modal-provider/global-modal/email-not-confirmed'
import { useNavigate } from 'react-router-dom'

const useOnlyForVerifiedUsers = () => {
  const { showModal } = useModal()
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  useLayoutEffect(() => {
    if (currentUser && currentUser.id && !currentUser.email_confirmed) {
      showModal({
        title: ' ',
        showCloseButton: true,
        content: <EmailNotConfirmed />,
        hideModal: () => {
          navigate(-1)
        },
      })
    }
  }, [currentUser])
}

export default useOnlyForVerifiedUsers
