import React from 'react'

const EthereumBadgeIcon = ({ fill = '#627eea', ...props }) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
      <path d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z" fill={fill} />
      <path d="M10.311 2.5v5.544l4.686 2.094z" fill="#fff" fillOpacity=".602" />
      <path d="M10.311 2.5l-4.686 7.637 4.686-2.093z" fill="#fff" />
      <path d="M10.311 13.73v3.767L15 11.01z" fill="#fff" fillOpacity=".602" />
      <path d="M10.311 17.497v-3.768L5.625 11.01z" fill="#fff" />
      <path d="M10.311 12.858l4.686-2.72-4.686-2.093z" fill="#fff" fillOpacity=".2" />
      <path d="M5.625 10.137l4.686 2.721V8.045z" fill="#fff" fillOpacity=".602" />
    </svg>
  )
}

export default EthereumBadgeIcon
