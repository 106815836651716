import Device from '../util/Device'
import Sentry from 'app/sentry/Sentry'
import version from 'app/version'

function getFbEventName(eventName) {
  switch (eventName) {
    case 'Registration':
      return 'fb_mobile_complete_registration'
    case 'WagerBuy':
      return 'WagerBuy'
    case 'WagerSell':
      return 'WagerSell'
    case 'WagerBuyRecorrence':
      return 'fb_mobile_achievement_unlocked'
    default:
      return null
  }
}

class AnalyticsService {
  track(event, data, meta) {
    if (global.dataLayer) {
      global.dataLayer.push({
        event,
        data,
        extra: {
          display_mode: Device.displayMode,
          version,
          ...meta,
        },
      })
    }
  }

  trackFacebookEvent(event, value, params) {
    new Promise(async resolve => {
      try {
        if (Device.isCordova) {
          global.facebookConnectPlugin.logEvent(event, params, value)
        } else {
          global.FB.AppEvents.logEvent(event, value, params)
        }
        resolve()
      } catch (error) {
        Sentry.log(
          new Error('Facebook logEvent silently failing.', {
            error,
            isAndroid: Device.isAndroid,
            isIOS: Device.isIOS,
            isCordova: Device.isCordova,
            isDesktop: Device.isDesktop,
            isMobile: Device.isMobile,
          })
        )
        resolve()
      }
    })
  }

  trackWagerBuy(data, meta) {
    this.track('WagerBuy', data, meta)

    this.trackFacebookEvent(
      getFbEventName('WagerBuy'), // eventName
      data.transaction_value, // valueToSum
      data // params
    )
  }
  trackWagerSell(data, meta) {
    this.track('WagerSell', data, meta)

    this.trackFacebookEvent(
      getFbEventName('WagerSell'), // eventName
      data.transaction_value, // valueToSum
      data // params
    )
  }
  trackWagerSellAll(data, meta) {
    this.track('WagerSellAll', data, meta)
  }
  trackMe(data, meta) {
    this.track('Me', data, meta)
  }
  trackRating(data, meta) {
    this.track('Rating', data, meta)
  }
  trackWagerBuyRecorrence(data) {
    this.trackFacebookEvent(getFbEventName('WagerBuyRecorrence'), null, {
      fb_description: 'WagerBuyRecorrence',
      wagers_count: data.wagers_count,
    })
  }
  trackRegistration(data, method = null) {
    switch (method) {
      case 'facebook':
        this.trackFacebookEvent(getFbEventName('Registration'), null, {
          fb_registration_method: data.provider,
        })
        break

      default:
        this.track('Registration', { email: data.email })
        break
    }
  }

  trackError(data, meta) {
    this.track('ErrorPageView', data, meta)
  }
  trackLogs(data) {
    Sentry.log(
      new Error('Loging version number', {
        currentAppVersion: data,
      })
    )
  }
}

const instance = new AnalyticsService()
export default instance
