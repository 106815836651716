import React from 'react'
import classNames from 'classnames'
import './FormControls.scss'

class CheckControl extends React.Component {
  render() {
    const { active, disabled, onToggle } = this.props
    return (
      <div
        onClick={!disabled ? onToggle : () => {}}
        className={classNames(this.props.className, 'check-control', {
          'check-control--active': active,
          'check-control--disabled': disabled,
        })}></div>
    )
  }
}

export default CheckControl
