import React from 'react'
import MarketCard from '../market-card/MarketCard'
import { questionDetailUrl } from 'urls'
import Helmet from 'common/components/helmet'
import ShareService from 'social/services/share'
import futuurCover from 'landing/assets/futuur.com.png'
import { i18n } from 'common/locale/i18n'
import './HelmetMarketImage.scss'
import FutuurLogo from 'common/components/FutuurLogo'
const HelmetMarketImage = ({ market }) => {
  const renderHelmet = question => {
    const categories = question.category.map(category => category.title)
    const title = `${question.title} - ${categories[categories.length - 1]} ${i18n(
      'question_details.meta_tags.title_suffix'
    )}`
    const url = ShareService.shareableUrl(questionDetailUrl(question))
    const images = question.id
      ? `http://137.184.94.17:3000/cdn/market/${question.id}`
      : futuurCover

    const description = i18n('question_details.meta_tags.description', {
      subcategory: categories[categories.length - 1],
    })

    return <Helmet id="view-question-details" url={url} title={title} images={images} description={description} />
  }

  return (
    <>
      {market && renderHelmet(market)}
      <div
        className="fixed helmet-market-card-image left-0 top-[6000px] z-10 w-[1200px] h-[675px] flex flex-col justify-center gap-8 bg-gradient-to-br to-[#0010FF] from-[#FF5129] pt-10 px-24 pb-20"
        id={'helmet-market-card-image-' + market.id}>
        <div className="flex justify-between items-center">
          <FutuurLogo white fill="white" width="1em" height="1em" />
          <span>BET ON THE FUTURE</span>
        </div>
        <MarketCard
          key={'question-card-' + market.id}
          question={market}
          categories={market && market.category}
          inlineBehavior
          noAnimation
        />
      </div>
    </>
  )
}

export default HelmetMarketImage
