import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Tooltip from 'common/components/tooltip'
import Button from 'common/ui/button'
import { i18n } from 'common/locale/i18n'
import { cashInUrl, kycUrl } from 'urls'
import { updateCurrentUserSettings } from 'store/account'
import './KycCallout.scss'

const KycCallout = ({ me }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [hide, setHide] = useState(
    me && me.settings && me.settings.dismissed_kyc_callout && !location.pathname === cashInUrl()
  )

  const onDismiss = () => {
    setHide(true)
    dispatch(updateCurrentUserSettings({ dismissed_kyc_callout: true }))
  }

  const onVerifyClick = () => {
    setHide(true)
    navigate(kycUrl(), { returnUrl: location.pathname })
  }

  return (
    !hide && (
      <Tooltip componentClassName="kyc-callout" indicatorClassName="kyc-callout__arrow">
        <h3 className="kyc-callout__title">{i18n('kyc_warning.verify_your_identity')}</h3>
        <p>{i18n('kyc_warning.unlock_deposit_warning')}</p>
        <footer className="kyc-callout__footer">
          <Button className="mr-2" size="large" variant="white" onClick={onVerifyClick}>
            Verify
          </Button>
          <Button size="large" variant="white" type="outlined" onClick={onDismiss}>
            Dismiss
          </Button>
        </footer>
      </Tooltip>
    )
  )
}

export default KycCallout
