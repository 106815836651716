import { useDispatch } from 'react-redux'
import { fetchABTests } from 'store/app'

const useABTests = () => {
  const dispatch = useDispatch()
  const synchronizeTests = tests => {
    const localTests = JSON.parse(localStorage.getItem('abTests')) || {}

    Object.keys(localTests).forEach(key => {
      if (!tests[key] || tests[key].segments.includes(localTests[key])) delete localTests[key]
    })

    tests.forEach(test => {
      const { slug, segments, user_segment } = test

      if (!localTests[slug] || (user_segment && localTests[slug].segment !== user_segment)) {
        const randomSegment = segments[Math.floor(Math.random() * segments.length)]
        localTests[slug] = user_segment || randomSegment
      }
    })

    Object.keys(localTests).forEach(key => {
      if (!tests.find(test => test.slug === key)) {
        delete localTests[key]
      }
    })

    localStorage.setItem('abTests', JSON.stringify(localTests))
  }
  const fetchAndSyncTests = async () => {
    try {
      const data = await dispatch(fetchABTests())
      synchronizeTests(data)
    } catch (error) {
      console.error('Error fetching AB tests:', error)
    }
  }

  const getABTestSegment = slug => {
    const tests = JSON.parse(localStorage.getItem('abTests')) || {}
    return tests[slug]
  }
  const getAllABTests = () => {
    return JSON.parse(localStorage.getItem('abTests')) || {}
  }

  return { getABTestSegment, fetchAndSyncTests, getAllABTests }
}

export default useABTests
