import React from 'react'
import classnames from 'classnames'
import { CrossIcon, FutuurIcon } from 'common/components/icons'
import { useState } from 'react'
import 'common/components/action-popup/ActionPopup.scss'
import Button from 'common/ui/button'

export default function ActionPopup({ onDismiss, onAccept, titleText, bodyText, buttonText, position }) {
  const [display, setDisplay] = useState(true)

  const onHidePopup = () => {
    onDismiss()
    setDisplay(false)
  }

  return (
    <div className={classnames('action-popup--container', position)}>
      <div className={classnames('action-popup', { 'action-popup--hiding': !display })}>
        <div className="action-popup__squareWithIcon">
          <FutuurIcon className="navbar__logo-mobile" width="1em" fillcolor="currentColor" />
        </div>
        <div className="action-popup__action">
          <div className="action-popup__labels">
            <div>
              <div className="action-popup__labels--medium">{titleText}</div>
              <div className="action-popup__labels--small">{bodyText}</div>
            </div>
          </div>
          <Button className="action-popup__action-button" size="medium" type="text-only" onClick={onAccept}>
            {buttonText}
          </Button>
        </div>
        <div onClick={onHidePopup}>
          <CrossIcon className="question-card__close-button action-popup__labels--close" />
        </div>
      </div>
    </div>
  )
}
