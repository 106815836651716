import accountRoutes from './account'
import infoRoutes from './info'
import questionRoutes from './questions'
import searchRoutes from './search'
import socialRoutes from './social'
import profileRoutes from './profile'
import pageTitles from './titles'

export default [].concat(accountRoutes, infoRoutes, questionRoutes, searchRoutes, socialRoutes, profileRoutes)
export { pageTitles as titles }
