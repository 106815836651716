import React from 'react'

const ArrowRightUp = ({ fill = 'currentColor', ...props }) => {
  return (
    <svg {...props} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0027 6.276L5.26469 12.014L4.32202 11.0713L10.06 5.33333H5.00202V4H12.3354V11.3333H11.002L11.0027 6.276Z"
        fill={fill}
      />
    </svg>
  )
}

export default ArrowRightUp
