import React from 'react'

const ApprovedIcon = ({ fill = '#1591ED' }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 17.3333C4.3975 17.3333 0.666667 13.6025 0.666667 8.99996C0.666667 4.39746 4.3975 0.666626 9 0.666626C13.6025 0.666626 17.3333 4.39746 17.3333 8.99996C17.3333 13.6025 13.6025 17.3333 9 17.3333ZM9 15.6666C10.7681 15.6666 12.4638 14.9642 13.714 13.714C14.9643 12.4638 15.6667 10.7681 15.6667 8.99996C15.6667 7.23185 14.9643 5.53616 13.714 4.28591C12.4638 3.03567 10.7681 2.33329 9 2.33329C7.23189 2.33329 5.5362 3.03567 4.28595 4.28591C3.03571 5.53616 2.33333 7.23185 2.33333 8.99996C2.33333 10.7681 3.03571 12.4638 4.28595 13.714C5.5362 14.9642 7.23189 15.6666 9 15.6666ZM8.16917 12.3333L4.63333 8.79746L5.81167 7.61913L8.16917 9.97663L12.8825 5.26246L14.0617 6.44079L8.16917 12.3333Z"
        fill={fill}
      />
    </svg>
  )
}

export default ApprovedIcon
