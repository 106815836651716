import React from 'react'

const MarketContext = React.createContext({
  activeCurrency: 'OOM',
  setActiveCurrency: () => {},
  marketEnable: false,
  setMarketEnable: () => {},
})

export default MarketContext
