import React from 'react'
import classNames from 'classnames'
import './DropdownMenu.scss'

const MenuAction = ({ icon, text, action, hidden = false }) => {
  return (
    <div
      className={classNames('menu-item menu-item--action', {
        'menu-item--hidden': hidden,
      })}
      onClick={hidden ? null : action}>
      <span className="menu-item__icon">{icon}</span>
      {text}
    </div>
  )
}

export default MenuAction
