/* @flow */
import UAParser from 'ua-parser-js'

const parser = new UAParser()

const browser = parser.getBrowser()
const device = parser.getDevice()
const os = parser.getOS()
const hasSafeArea = global.CSS && global.CSS.supports && global.CSS.supports('padding-top: env(safe-area-inset-top)')

export default Object.freeze({
  isUndefined: device.type === undefined,
  isMobile: device.type === 'mobile',
  isIOS: os.name === 'iOS',
  isAndroid: os.name === 'Android',
  isCordova: process.env.REACT_APP_ENVIRONMENT === 'cordova',
  isDesktop: process.env.REACT_APP_PLATFORM === 'desktop',
  isWebappMobile: process.env.REACT_APP_PLATFORM === 'mobile',
  isMobileSize: window.innerWidth < 768,
  isChrome: ['Chromium', 'Chrome'].indexOf(browser.name) >= 0,
  isSafari: ['Mobile Safari', 'Safari', 'WebKit'].indexOf(browser.name) >= 0,
  hasSafeArea,
})

export function isMobileViewport() {
  const breakpoint = 768
  const viewportWidth = Math.min(global.document.documentElement.clientWidth, global.window.innerWidth)
  return viewportWidth <= breakpoint
}
