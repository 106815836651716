/* @flow */
import React from 'react'
import { Link } from 'react-router-dom'
import Image from 'common/components/Image'
//FIXME: This import is horrendous, but we have no time left
import placeholder from 'common/assets/picture.svg'
import { userUrlByUsername } from 'profile/urls'

class UserFollow extends React.PureComponent {
  handleClick() {
    const { username, onMessageClick } = this.props
    const userUrl = userUrlByUsername(username)

    if (onMessageClick) {
      onMessageClick(userUrl)
    }
  }

  render() {
    const { username, picture } = this.props
    const userUrl = userUrlByUsername(username)
    return (
      <div className="message" onClick={this.handleClick.bind(this)}>
        <Image
          className="message__image message__image--round"
          src={picture}
          placeholder={placeholder}
          alt={username + "'s profile picture"}
          title={username + "'s profile picture"}
        />
        <div className="message__text">
          <div className="message__main-text">
            You are now following
            <br />
            <Link className="message__icon message__icon--arrow" to={userUrl}>
              @{username}
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default UserFollow
