import React from 'react';
import classNames from 'classnames';
import './FormControls.scss';

const RadioControl = ({ active, className }) => {
  return (
    <div
      className={classNames(className, 'radio-control', {
        'radio-control--active': active,
      })}
    ></div>
  );
};

export default RadioControl;
