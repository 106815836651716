import React from 'react'

const ipBlock = ({ width = '40', height = '40', ...props }) => {
  return (
    <svg {...props} width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M20 0c11.028 0 20 8.972 20 20 0 11.03-8.972 20-20 20S0 31.03 0 20C0 8.972 8.972 0 20 0zM4 20c0 8.822 7.178 16 16 16 3.511 0 6.762-1.137 9.403-3.062L7.062 10.598A15.914 15.914 0 004 20zM20 4a15.914 15.914 0 00-9.403 3.062l22.341 22.34A15.914 15.914 0 0036 20c0-8.822-7.178-16-16-16z"
          fill="#E00280"
        />
        <path
          d="M20.365 9C15.753 9 12 13.053 12 18.034c0 4.98 4.856 10.66 8.365 13.91 3.506-3.248 8.364-8.928 8.364-13.91C28.73 13.053 24.977 9 20.365 9z"
          fill="#E00280"
          fillRule="nonzero"
        />
        <path stroke="#FFF" strokeWidth="3" fill="#D8D8D8" d="M37.5 32.5l-31-31" />
      </g>
    </svg>
  )
}

export default ipBlock
