import React from 'react'

const tronBadge = ({ fill = '#ef0027', ...props }) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zM4.687 4.536l9.02 1.66 2.342 2.226-6.615 8.059zm10.256 3.703l-1.38-1.312-2.394 1.995zM6.37 5.486l3.978 3.299 2.524-2.102zm3.046 9.245l.649-5.362-4.146-3.44zm5.428-5.834l-4.18.756-.614 5.083z"
        fill={fill}
      />
    </svg>
  )
}

export default tronBadge
