import requestService from 'common/services/request'
import { createSlice } from '@reduxjs/toolkit'
import constants from 'app/constants'

const initialState = {
  mine: { data: [], status: constants.IDLE, pagination: {}, error: null },
  following: { data: [], status: constants.IDLE, pagination: {}, error: null },
  unread: { data: null, status: constants.IDLE },
}

// Reducers / Slices

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotificationStart: (state, action) => {
      const { type } = action.payload
      state[type].status = constants.LOADING
    },
    getNotificationError: (state, action) => {
      const { type, error } = action.payload
      state[type].status = constants.ERROR
      state[type].error = error
    },
    getNotificationSuccess: (state, action) => {
      const { type, data, pagination } = action.payload
      state[type].status = constants.COMPLETE
      state[type].data = state[type].data.concat(data)
      state[type].pagination = pagination
    },
    getNotificationCountStart: state => {
      state.unread.status = constants.LOADING
    },
    getNotificationCountSuccess: (state, action) => {
      state.unread.status = constants.SUCCESS
      state.unread.data = action.payload.count
    },
    clearNotificationCount: state => {
      state.unread.data = 0
      state.unread.status = constants.IDLE
    },
  },
})

export const { getNotificationStart, getNotificationError, getNotificationSuccess } = notifications.actions
export const { getNotificationCountStart, getNotificationCountSuccess, clearNotificationCount } = notifications.actions
export default notifications.reducer

// Selectors:
export const selectNotificationStatus = type => state => state.notifications[type].status
export const selectNotifications = type => state => state.notifications[type].data
export const selectNotificationsPagination = type => state => state.notifications[type].pagination
export const selectUnreadNotificationCount = () => state => state.notifications.unread.data
export const selectUnreadNotificationCountStatus = () => state => state.notifications.unread.status

export const notificationTypes = { following: 'following', mine: 'mine' }

// Thunks:
export const fetchNotifications = (options = {}) => async dispatch => {
  const params = requestService.parseFilters(options)
  const type = options.following_notifications ? notificationTypes.following : notificationTypes.mine
  dispatch(getNotificationStart({ type }))
  try {
    var response = await requestService.get(`/notifications/?${params.toString()}`)
  } catch (err) {
    dispatch(getNotificationError(err))
    return
  }
  const json = await response.json()
  dispatch(getNotificationSuccess({ type, data: json.results, pagination: json.pagination }))
  return json
}

export const fetchUnreadNotificationCount = () => async dispatch => {
  dispatch(getNotificationCountStart())
  try {
    var response = await requestService.get(`/notifications/unread_count/`)
    const count = await response.json()
    dispatch(getNotificationCountSuccess({ count }))
    return count
  } catch (err) {
    console.error(err)
  }
}

export const readAllNotifications = () => dispatch => {
  requestService.patch(`/notifications/read_all/`)
  dispatch(clearNotificationCount())
}

export const registerPushNotification = (device, token, oldToken = null) => async () => {
  return requestService.post(`/push_subscription/`, {
    type: 'fcm',
    device,
    endpoint: token,
    current_endpoint: oldToken,
  })
}
// export const checkFCMTokenExpiration = token => async () => {
//   const response = await requestService.get('/push_subscription/last_renew/?endpoint=' + token)
//   const json = await response.json()
//   return json
// }
