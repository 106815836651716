/* @flow */

import requestService from 'common/services/request'
import Sentry from 'app/sentry/Sentry'
import { amountFunction, lmsrPrice, lmsrShares, sharesFromCostPowell } from 'api/price'
import { Decimal } from 'decimal.js'

// function analyticsData(wagerResponse, shares) {
//   const outcome = !isFinite(wagerResponse.outcome)
//     ? wagerResponse.outcome
//     : wagerResponse.question.outcomes.find(outcome => {
//         return outcome.id === wagerResponse.outcome
//       })

//   const outcomeName = 'title' in outcome ? outcome.title : ''
//   const currency = wagerResponse.currency

//   return {
//     productType: currency === 'OOM' ? 'Play Money' : 'Real Money',
//     currency,
//     outcome: outcome.id,
//     outcome_name: outcomeName,
//     question: wagerResponse.question.id,
//     question_name: wagerResponse.question.title,
//     scoring_rule: wagerResponse.question.scoring_rule,
//     wager: wagerResponse.id,
//     transaction_value: wagerResponse.total_amount,
//     shares,
//   }
// }
class WagerService {
  retrieve(id) {
    return new Promise((resolve, reject) => {
      requestService.get(`/wagers/${id}/`).then(response => {
        if (response.status !== 200) {
          return reject(new Error(`server error${response.status}`))
        }
        return resolve(response.json())
      })
    })
  }

  getAmount(scoring_rule, outcomeList, outcomeIndex, shares, tax, position, exchangeRate = 1) {
    /**
     * ! 'basic' and 'lmsr' scoring rules are deprecated
     */

    var price
    switch (scoring_rule) {
      case 'basic':
        //price = outcome.price[currencyId] * shares
        break
      case 'lmsr':
        price = lmsrPrice()
        break
      case 'lslmsr':
        price = amountFunction(outcomeList, outcomeIndex, shares, tax, position)
        break
      default:
        Sentry.log(new Error('Invalid question scoring_rule'))
    }

    return price * exchangeRate
  }

  getShares(scoring_rule, outcomeList, outcomeIndex, amount, tax, position) {
    /**
     * ! 'basic' and 'lmsr' scoring rules are deprecated
     */
    switch (scoring_rule) {
      case 'basic': // ![deprecated]
        return // amount / outcome.price[currencyId]
      case 'lmsr': // ![deprecated]
        return lmsrShares()
      case 'lslmsr':
        return sharesFromCostPowell(outcomeList, amount, outcomeIndex, tax, position)
      default:
        Sentry.log(new Error('Invalid question scoring_rule'))
    }
  }

  roundPrice(price, decimalPlaces) {
    const number = new Decimal(price || 0)
    return number.toDecimalPlaces(decimalPlaces).toNumber()
  }

  ceilPrice(price, decimalPlaces) {
    const number = new Decimal(price || 0)
    return number.toDecimalPlaces(decimalPlaces, Decimal.ROUND_UP).toNumber()
  }

  floorPrice(price, decimalPlaces) {
    const number = new Decimal(price || 0)
    return number.toDecimalPlaces(decimalPlaces, Decimal.ROUND_DOWN).toNumber()
  }
}

const instance = new WagerService()
export default instance
