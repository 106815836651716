/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser'
const TruncatedHtmlContent = (htmlContent, maxLength) => {
  const [isTruncated, setIsTruncated] = useState(true)
  function truncateHtmlString(htmlString, maxLength) {
    let truncatedHtml = htmlString.slice(0, maxLength)
    let openTags = []

    // Find all opening tags and closing tags in the original HTML string
    const regexTags = /<[^>]+>/g
    let match,
      lastPos = 0
    while ((match = regexTags.exec(htmlString)) !== null) {
      if (match.index < maxLength) {
        lastPos = match.index + match[0].length
        if (match[0][1] !== '/') {
          // Opening tag
          openTags.push(match[0])
        } else {
          // Closing tag
          const tagName = match[0].match(/<\/(\w+)/)[1]
          const lastOpeningTag = openTags[openTags.length - 1]
          if (lastOpeningTag && lastOpeningTag.indexOf(`<${tagName}`) === 0) {
            openTags.pop() // Remove the last opening tag since it's closed
          }
        }
      } else {
        break // Stop if the match is beyond the maxLength
      }
    }

    // Adjust the truncatedHtml to end at the last complete tag within maxLength
    truncatedHtml = htmlString.slice(0, lastPos)

    // Close any unclosed tags
    for (let i = openTags.length - 1; i >= 0; i--) {
      const tagName = openTags[i].match(/<(\w+)/)[1]
      truncatedHtml += `</${tagName}>`
    }

    return truncatedHtml
  }

  const renderContent = () => {
    if (isTruncated) {
      return (
        <>
          {parse(truncateHtmlString(htmlContent, maxLength))}
          {htmlContent.length >= maxLength && (
            <span
              className="link"
              onClick={() => setIsTruncated(false)}
              style={{
                display: 'inline',
                padding: 0,
                border: 'none',
                cursor: 'pointer',
              }}>
              See More
            </span>
          )}
        </>
      )
    } else {
      return (
        <>
          {parse(htmlContent)}
          <span
            style={{
              display: 'inline',
              padding: 0,
              border: 'none',
              cursor: 'pointer',
            }}
            className="link"
            onClick={() => setIsTruncated(true)}>
            See Less
          </span>
        </>
      )
    }
  }

  return <div>{renderContent()}</div>
}

export default TruncatedHtmlContent
