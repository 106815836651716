/* @flow */
const dataUrlRegex = /^data:([^;]+);base64,(.*)$/i

export function dataURLToArrayBuffer(base64: string): { buffer: ?ArrayBuffer, type: ?string } {
  const match = base64.match(dataUrlRegex)
  if (!match) {
    return {
      buffer: null,
      type: null,
    }
  }

  const contentType = match[1]

  const binaryString = global.atob(match[2])
  const { length } = binaryString
  const bytes = new Uint8Array(length)
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  return {
    buffer: bytes.buffer,
    type: contentType,
  }
}

export function dataURL64toDataView(base64: string): ?DataView {
  const { buffer } = dataURLToArrayBuffer(base64)
  return buffer ? new DataView(buffer) : null
}

export function dataURLToFile(base64: string): ?File {
  const { buffer, type } = dataURLToArrayBuffer(base64)
  const name = (type === 'image/jpeg' && 'file.jpg') || (type === 'image/png' && 'file.png') || undefined
  return buffer ? new global.File([buffer], name, { type }) : null
}
