import React from 'react'

const bitcoinCashBadge = ({ fill = '#8DC351', ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 32 32"
      xmlSpace="preserve">
      <g>
        <circle fillRule="evenodd" clipRule="evenodd" fill={fill} cx="16" cy="16" r="16" />
        <path
          fill="#FFFFFF"
          d="M21.2,10.5c-0.8-2-2.7-2.1-5-1.7L15.4,6l-1.7,0.5l0.8,2.7c-0.4,0.1-0.9,0.3-1.4,0.4l-0.8-2.8l-1.7,0.5l0.8,2.8
		c-0.4,0.1-0.7,0.2-1.1,0.3l0,0L8,11.2L8.5,13c0,0,1.3-0.4,1.2-0.4c0.7-0.2,1,0.1,1.2,0.5l0.9,3.2c0,0,0.1,0,0.2,0l-0.2,0.1l1.3,4.5
		c0,0.2,0,0.6-0.5,0.8c0,0-1.2,0.4-1.2,0.4l0.2,2.1l2.2-0.6c0.4-0.1,0.8-0.2,1.2-0.3l0.8,2.8l1.7-0.5l-0.8-2.8
		c0.5-0.1,0.9-0.2,1.4-0.4l0.8,2.8l1.7-0.5l-0.8-2.8c2.8-1,4.6-2.3,4.1-5.1c-0.4-2.2-1.7-2.9-3.5-2.8C21.4,13,21.8,12,21.2,10.5
		L21.2,10.5z M20.6,17.3c0.6,2.1-3.1,2.9-4.3,3.3l-1.1-3.8C16.4,16.5,19.9,15.1,20.6,17.3L20.6,17.3z M18.2,12.2
		c0.6,1.9-2.5,2.6-3.5,2.9l-1-3.4C14.7,11.4,17.7,10.2,18.2,12.2L18.2,12.2z"
        />
      </g>
    </svg>
  )
}

export default bitcoinCashBadge
