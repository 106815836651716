import { feedUrl } from 'urls'
import { accountUrl, walletUrl } from 'urls'
import { searchUrl } from 'explore/urls'
import { activityFollowingUrl, activityNotificationsUrl } from 'notifications/urls'
import { settingsUrl } from 'profile/urls'
import { i18n } from 'common/locale/i18n'

export default [
  { path: feedUrl(), render: () => i18n('navbar.markets') },
  { path: settingsUrl(), render: () => i18n('navbar.settings') },
  { path: searchUrl(), render: () => i18n('navbar.search') },
  { path: walletUrl(), render: () => i18n('navbar.wallet') },
  { path: accountUrl(), render: () => i18n('navbar.portfolio') },
  { path: activityNotificationsUrl(), render: () => i18n('navbar.notification') },
  { path: activityFollowingUrl(), render: () => i18n('navbar.notification') },
  { path: '/c/leaderboard', render: () => i18n('navbar.leaderboard') },
]
