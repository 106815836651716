import React from 'react'

const binanceCoinBadge = ({ fill = '#f3ba2f', ...props }) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zM10 6.575L7.572 9.002 6.16 7.59 10 3.75l3.841 3.841-1.412 1.413zM5.162 8.588L3.75 10l1.412 1.412L6.575 10zM10 13.425l-2.428-2.428-1.414 1.411.002.002L10 16.25l3.841-3.842-1.412-1.412zm4.838-4.838L13.425 10l1.412 1.412L16.25 10zM11.434 10h-.002L10 8.566 8.94 9.625l-.121.122-.25.251-.003.002.002.002L10 11.434 11.434 10z"
        fill={fill}
      />
    </svg>
  )
}

export default binanceCoinBadge
