import React from 'react'

export default function ParchmentIcon({ fillcolor, className, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fill={fillcolor}
      className={className}
      {...props}
    >
      <path
        d="M14.1666 13.1666V14.8333C14.1666 15.0543 14.2544 15.2663 14.4107 15.4225C14.5669 15.5788 14.7789 15.6666 14.9999 15.6666C15.2209 15.6666 15.4329 15.5788 15.5892 15.4225C15.7455 15.2663 15.8333 15.0543 15.8333 14.8333V2.33329H4.16659V11.5H2.49992V1.49996C2.49992 1.27895 2.58772 1.06698 2.744 0.910704C2.90028 0.754423 3.11224 0.666626 3.33325 0.666626H16.6666C16.8876 0.666626 17.0996 0.754423 17.2558 0.910704C17.4121 1.06698 17.4999 1.27895 17.4999 1.49996V14.8333C17.4999 15.4963 17.2365 16.1322 16.7677 16.6011C16.2988 17.0699 15.663 17.3333 14.9999 17.3333H3.33325C2.67021 17.3333 2.03433 17.0699 1.56548 16.6011C1.09664 16.1322 0.833252 15.4963 0.833252 14.8333V13.1666H14.1666Z"
        fill={fillcolor}
      />
    </svg>
  )
}
