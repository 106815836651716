import settingsService from 'common/services/settings'

function formatPercentage(value, fixed = 2, suffix = '%') {
  const locale = settingsService.getLanguage()

  if (value == null) {
    return '-'
  } else {
    return global.Intl.NumberFormat(`${locale}`).format(`${(100 * value).toFixed(fixed)}`) + suffix
  }
}

const roundDownSignificantDigits = (number, decimals) => {
  let significantDigits = parseInt(number.toExponential().split('e-')[1]) || 0
  let decimalsUpdated = (decimals || 0) + significantDigits
  decimals = Math.min(decimalsUpdated, number.toString().length)

  return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals)
}

const formatNumber = (value, locale, minimumFractionDigits, maximumFractionDigits, roundDown = false) =>
  global.Intl.NumberFormat(`${locale}`, {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(roundDown ? roundDownSignificantDigits(value, maximumFractionDigits) : value)

const getSeparators = locale => {
  const numberWithDecimalSeparator = 1.1
  const formattedNumber = numberWithDecimalSeparator.toLocaleString(locale)
  const decimalSeparator = formattedNumber.indexOf('.') >= 0 ? '.' : ','
  const thousandsSeparator = decimalSeparator === '.' ? ',' : '.'
  return { decimalSeparator, thousandsSeparator }
}

/**
 * Formats a given number string into English standard format, using dots as decimal separator
 * @returns a string containing the formatted number. Ex: "1.412,50" returns "1412.50"
 */
const normalizeNumericStr = (numberStr, locale = 'en-US') => {
  const separators = getSeparators(locale)
  const decimalSeparator = separators.decimalSeparator
  const thousandSeparator = separators.thousandsSeparator
  const numberWithoutThousands = numberStr.replace(new RegExp('\\' + thousandSeparator, 'g'), '')
  const numberString = numberWithoutThousands.replace(decimalSeparator, '.')
  return numberString.trim()
}

export { formatPercentage, formatNumber, getSeparators, normalizeNumericStr }
