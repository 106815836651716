import React from 'react'

export default function LeftArrowIcon({ fillcolor, className, ...props }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 16"
      className={className}
      fill={fillcolor || 'currentColor'}
      {...props}
    >
      <path d="M9 14.666L7.554 16 0 8l7.554-8L9 1.334 2.706 8z" />
    </svg>
  )
}
