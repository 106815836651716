import React from 'react'

const ArrowRightTailed = props => {
  return (
    <svg {...props} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0309 6.99992L7.66689 1.63592L9.08089 0.221924L16.8589 7.99992L9.08089 15.7779L7.66689 14.3639L13.0309 8.99992H0.858887V6.99992H13.0309Z"
        fill={props.fill || 'currentColor'}
      />
    </svg>
  )
}

export default ArrowRightTailed
