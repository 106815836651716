/* eslint-disable no-unreachable */
import { useEffect, useRef } from 'react'
import Pusher from 'pusher-js'
import { useDispatch, useSelector } from 'react-redux'
import { updateMarketOutcomes } from 'store/markets'
import { matchPath } from 'react-router-dom'
import { accountUrl } from 'urls'
import { fetchCurrentUserBets, selectUserBets } from 'store/wagers'
import constants from 'app/constants'
import { selectActiveCurrency } from 'store/app'

const useRealTimeMarket = () => {
  const pusherSocket = useRef()
  const dispatch = useDispatch()
  const activeCurrency = useSelector(selectActiveCurrency)

  const activeBets = useSelector(selectUserBets(constants.PLAY_MONEY, true))
  const pastBets = useSelector(selectUserBets(constants.PLAY_MONEY, true))
  const isInPortfolio = () => matchPath({ path: accountUrl() },location.pathname)

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return
    }

    if (!process.env.REACT_APP_PUSHER_APP_KEY || !process.env.REACT_APP_PUSHER_APP_CLUSTER) {
      console.log('[Pusher]: missing app key or cluster')
      return
    }

    pusherSocket.current = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    })

    const channelId = `market`
    let pusherChannel = pusherSocket.current.subscribe(channelId)

    pusherChannel.bind('price-change', data => {
      console.log('pusher data: ', data)

      if (isInPortfolio()) {
        // if there is a wagers for this market
        if ([...activeBets, ...pastBets].find(bet => bet.question.id === data.id))
          dispatch(fetchCurrentUserBets({ question: data.id }))
      } else {
        // dispatch(fetchMarketById(data.id ,false))
        dispatch(
          updateMarketOutcomes({
            marketId: data.id,
            data,
            activeCurrency: activeCurrency === 'OOM' ? constants.PLAY_MONEY : constants.REAL_MONEY,
          })
        )
      }
    })

    return () => {
      if (pusherSocket.current) {
        console.log('[Pusher] disconnecting from markets ')
        pusherSocket.current.disconnect()
      }
    }
  }, [activeCurrency])
}

export default useRealTimeMarket
