import React from 'react'
import { useState } from 'react'

const DiscordIcon = () => {
  const [hovered, setHovered] = useState(false)
  return (
    <div onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {hovered ? (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="24" fill="#5865F2" />
          <path
            d="M31.2684 17.328C29.9384 16.708 28.4984 16.258 26.9984 15.998C26.9853 15.9976 26.9722 16 26.9601 16.0052C26.948 16.0104 26.9372 16.0182 26.9284 16.028C26.7484 16.358 26.5384 16.788 26.3984 17.118C24.8074 16.878 23.1894 16.878 21.5984 17.118C21.4584 16.778 21.2484 16.358 21.0584 16.028C21.0484 16.008 21.0184 15.998 20.9884 15.998C19.4884 16.258 18.0584 16.708 16.7184 17.328C16.7084 17.328 16.6984 17.338 16.6884 17.348C13.9684 21.418 13.2184 25.378 13.5884 29.298C13.5884 29.318 13.5984 29.338 13.6184 29.348C15.4184 30.668 17.1484 31.468 18.8584 31.998C18.8884 32.008 18.9184 31.998 18.9284 31.978C19.3284 31.428 19.6884 30.848 19.9984 30.238C20.0184 30.198 19.9984 30.158 19.9584 30.148C19.3884 29.928 18.8484 29.668 18.3184 29.368C18.2784 29.348 18.2784 29.288 18.3084 29.258C18.4184 29.178 18.5284 29.088 18.6384 29.008C18.6584 28.988 18.6884 28.988 18.7084 28.998C22.1484 30.568 25.8584 30.568 29.2584 28.998C29.2784 28.988 29.3084 28.988 29.3284 29.008C29.4384 29.098 29.5484 29.178 29.6584 29.268C29.6984 29.298 29.6984 29.358 29.6484 29.378C29.1284 29.688 28.5784 29.938 28.0084 30.158C27.9684 30.168 27.9584 30.218 27.9684 30.248C28.2884 30.858 28.6484 31.438 29.0384 31.988C29.0684 31.998 29.0984 32.008 29.1284 31.998C30.8484 31.468 32.5784 30.668 34.3784 29.348C34.3984 29.338 34.4084 29.318 34.4084 29.298C34.8484 24.768 33.6784 20.838 31.3084 17.348C31.2984 17.338 31.2884 17.328 31.2684 17.328ZM20.5184 26.908C19.4884 26.908 18.6284 25.958 18.6284 24.788C18.6284 23.618 19.4684 22.668 20.5184 22.668C21.5784 22.668 22.4184 23.628 22.4084 24.788C22.4084 25.958 21.5684 26.908 20.5184 26.908ZM27.4884 26.908C26.4584 26.908 25.5984 25.958 25.5984 24.788C25.5984 23.618 26.4384 22.668 27.4884 22.668C28.5484 22.668 29.3884 23.628 29.3784 24.788C29.3784 25.958 28.5484 26.908 27.4884 26.908Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M31.2684 17.328C29.9384 16.708 28.4984 16.258 26.9984 15.998C26.9853 15.9976 26.9722 16 26.9601 16.0052C26.948 16.0104 26.9372 16.0182 26.9284 16.028C26.7484 16.358 26.5384 16.788 26.3984 17.118C24.8074 16.878 23.1894 16.878 21.5984 17.118C21.4584 16.778 21.2484 16.358 21.0584 16.028C21.0484 16.008 21.0184 15.998 20.9884 15.998C19.4884 16.258 18.0584 16.708 16.7184 17.328C16.7084 17.328 16.6984 17.338 16.6884 17.348C13.9684 21.418 13.2184 25.378 13.5884 29.298C13.5884 29.318 13.5984 29.338 13.6184 29.348C15.4184 30.668 17.1484 31.468 18.8584 31.998C18.8884 32.008 18.9184 31.998 18.9284 31.978C19.3284 31.428 19.6884 30.848 19.9984 30.238C20.0184 30.198 19.9984 30.158 19.9584 30.148C19.3884 29.928 18.8484 29.668 18.3184 29.368C18.2784 29.348 18.2784 29.288 18.3084 29.258C18.4184 29.178 18.5284 29.088 18.6384 29.008C18.6584 28.988 18.6884 28.988 18.7084 28.998C22.1484 30.568 25.8584 30.568 29.2584 28.998C29.2784 28.988 29.3084 28.988 29.3284 29.008C29.4384 29.098 29.5484 29.178 29.6584 29.268C29.6984 29.298 29.6984 29.358 29.6484 29.378C29.1284 29.688 28.5784 29.938 28.0084 30.158C27.9684 30.168 27.9584 30.218 27.9684 30.248C28.2884 30.858 28.6484 31.438 29.0384 31.988C29.0684 31.998 29.0984 32.008 29.1284 31.998C30.8484 31.468 32.5784 30.668 34.3784 29.348C34.3984 29.338 34.4084 29.318 34.4084 29.298C34.8484 24.768 33.6784 20.838 31.3084 17.348C31.2984 17.338 31.2884 17.328 31.2684 17.328ZM20.5184 26.908C19.4884 26.908 18.6284 25.958 18.6284 24.788C18.6284 23.618 19.4684 22.668 20.5184 22.668C21.5784 22.668 22.4184 23.628 22.4084 24.788C22.4084 25.958 21.5684 26.908 20.5184 26.908ZM27.4884 26.908C26.4584 26.908 25.5984 25.958 25.5984 24.788C25.5984 23.618 26.4384 22.668 27.4884 22.668C28.5484 22.668 29.3884 23.628 29.3784 24.788C29.3784 25.958 28.5484 26.908 27.4884 26.908Z"
            fill="currentColor"
          />
          <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="currentColor" strokeOpacity="0.48" />
        </svg>
      )}
    </div>
  )
}

export default DiscordIcon
