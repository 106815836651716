import React from 'react'
import { useState } from 'react'

const TwitterIcon = () => {
  const [hovered, setHovered] = useState(false)
  return (
    <div onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {hovered ? (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="24" fill="#4A98E9" />
          <path
            d="M34.1911 17.6807C33.4277 18.0184 32.6181 18.2402 31.7891 18.3387C32.6629 17.8161 33.3168 16.9937 33.6291 16.0247C32.8091 16.5127 31.9101 16.8547 30.9731 17.0397C30.3437 16.3663 29.5095 15.9197 28.6001 15.7693C27.6907 15.6189 26.757 15.7732 25.9444 16.2082C25.1317 16.6431 24.4855 17.3344 24.1062 18.1745C23.7269 19.0146 23.6359 19.9565 23.8471 20.8537C22.1842 20.7704 20.5574 20.3382 19.0724 19.5854C17.5873 18.8325 16.2772 17.7758 15.2271 16.4837C14.8554 17.1222 14.6601 17.8479 14.6611 18.5867C14.6611 20.0367 15.3991 21.3177 16.5211 22.0677C15.8571 22.0468 15.2077 21.8675 14.6271 21.5447V21.5967C14.6273 22.5624 14.9615 23.4983 15.573 24.2458C16.1844 24.9932 17.0356 25.5062 17.9821 25.6977C17.3657 25.8647 16.7194 25.8894 16.0921 25.7697C16.359 26.6009 16.8791 27.3279 17.5797 27.8488C18.2803 28.3697 19.1262 28.6585 19.9991 28.6747C19.1316 29.356 18.1383 29.8597 17.076 30.1569C16.0137 30.4541 14.9032 30.539 13.8081 30.4067C15.7198 31.6361 17.9452 32.2888 20.2181 32.2867C27.9111 32.2867 32.1181 25.9137 32.1181 20.3867C32.1181 20.2067 32.1131 20.0247 32.1051 19.8467C32.924 19.2549 33.6307 18.5217 34.1921 17.6817L34.1911 17.6807Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M34.191 17.6807C33.4275 18.0184 32.6179 18.2402 31.789 18.3387C32.6627 17.8161 33.3167 16.9937 33.629 16.0247C32.809 16.5127 31.91 16.8547 30.973 17.0397C30.3436 16.3663 29.5093 15.9197 28.5999 15.7693C27.6905 15.6189 26.7569 15.7732 25.9442 16.2082C25.1316 16.6431 24.4854 17.3344 24.1061 18.1745C23.7268 19.0146 23.6357 19.9565 23.847 20.8537C22.1841 20.7704 20.5573 20.3382 19.0723 19.5854C17.5872 18.8325 16.2771 17.7758 15.227 16.4837C14.8553 17.1222 14.6599 17.8479 14.661 18.5867C14.661 20.0367 15.399 21.3177 16.521 22.0677C15.857 22.0468 15.2076 21.8675 14.627 21.5447V21.5967C14.6272 22.5624 14.9614 23.4983 15.5728 24.2458C16.1843 24.9932 17.0355 25.5062 17.982 25.6977C17.3656 25.8647 16.7193 25.8894 16.092 25.7697C16.3589 26.6009 16.879 27.3279 17.5796 27.8488C18.2802 28.3697 19.1261 28.6585 19.999 28.6747C19.1315 29.356 18.1382 29.8597 17.0759 30.1569C16.0136 30.4541 14.9031 30.539 13.808 30.4067C15.7197 31.6361 17.9451 32.2888 20.218 32.2867C27.911 32.2867 32.118 25.9137 32.118 20.3867C32.118 20.2067 32.113 20.0247 32.105 19.8467C32.9238 19.2549 33.6306 18.5217 34.192 17.6817L34.191 17.6807Z"
            fill="currentColor"
          />
          <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="currentColor" strokeOpacity="0.48" />
        </svg>
      )}
    </div>
  )
}

export default TwitterIcon
