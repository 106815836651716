import React from 'react'

export default function BellIcon({ fillcolor, className, ...props }) {
  return (
    <svg
      className={className}
      fill={fillcolor || 'currentColor'}
      {...props}
      viewBox="0 0 18 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9998 17C12 17.5046 11.8094 17.9906 11.4664 18.3605C11.1233 18.7305 10.653 18.9572 10.1498 18.995L9.99985 19H7.99985C7.49527 19.0002 7.00928 18.8096 6.6393 18.4665C6.26932 18.1234 6.04269 17.6532 6.00485 17.15L5.99985 17H11.9998ZM8.99985 9.54067e-10C10.8148 -2.9945e-05 12.5589 0.704894 13.8641 1.96607C15.1693 3.22726 15.9336 4.94609 15.9958 6.76L15.9998 7V10.764L17.8218 14.408C17.9014 14.567 17.9412 14.7429 17.9378 14.9206C17.9345 15.0984 17.8881 15.2727 17.8027 15.4286C17.7173 15.5845 17.5953 15.7174 17.4473 15.8158C17.2993 15.9143 17.1297 15.9754 16.9528 15.994L16.8378 16H1.16185C0.984013 16.0001 0.808815 15.957 0.651269 15.8745C0.493724 15.792 0.358527 15.6725 0.257266 15.5264C0.156004 15.3802 0.0916959 15.2116 0.0698528 15.0351C0.0480097 14.8586 0.0692826 14.6795 0.131849 14.513L0.177849 14.408L1.99985 10.764V7C1.99985 5.14348 2.73735 3.36301 4.0501 2.05025C5.36286 0.737498 7.14333 9.54069e-10 8.99985 9.54067e-10ZM8.99985 2C7.71139 2.00007 6.47269 2.49754 5.54207 3.38866C4.61146 4.27978 4.06077 5.49575 4.00485 6.783L3.99985 7V10.764C3.99986 11.012 3.95374 11.2579 3.86385 11.489L3.78885 11.659L2.61885 14H15.3818L14.2118 11.658C14.1009 11.4363 14.032 11.1959 14.0088 10.949L13.9998 10.764V7C13.9998 5.67392 13.4731 4.40215 12.5354 3.46447C11.5977 2.52678 10.3259 2 8.99985 2Z"
        fill={fillcolor || "var(--color-Neutral-6)"}
      />
    </svg>
  )
}
