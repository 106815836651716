import React from 'react'

const maskIcon = ({ fill = 'currentColor', width = '1em', ...props }) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width={width} {...props} fill={fill}>
      <path d="M2.66663 14.6667C2.66663 11.7212 5.05444 9.33335 7.99996 9.33335C10.9455 9.33335 13.3333 11.7212 13.3333 14.6667H12C12 12.4576 10.2091 10.6667 7.99996 10.6667C5.79082 10.6667 3.99996 12.4576 3.99996 14.6667H2.66663ZM7.99996 8.66669C5.78996 8.66669 3.99996 6.87669 3.99996 4.66669C3.99996 2.45669 5.78996 0.666687 7.99996 0.666687C10.21 0.666687 12 2.45669 12 4.66669C12 6.87669 10.21 8.66669 7.99996 8.66669ZM7.99996 7.33335C9.47329 7.33335 10.6666 6.14002 10.6666 4.66669C10.6666 3.19335 9.47329 2.00002 7.99996 2.00002C6.52663 2.00002 5.33329 3.19335 5.33329 4.66669C5.33329 6.14002 6.52663 7.33335 7.99996 7.33335Z" />
    </svg>
  )
}

export default maskIcon
