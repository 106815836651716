/* @flow */

export default function createSrcset(srcsetObj) {
  if (!srcsetObj) {
    return null
  }

  const srcList = []

  for (const size of Object.keys(srcsetObj)) {
    const url = srcsetObj[size]
    if (typeof url === 'string') {
      srcList.push(`${url} ${size}`)
    }
  }

  if (srcList.length === 0) {
    return null
  }

  return srcList.join(', ')
}
