import React from 'react'

const arrowRight = props => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 15" {...props}>
      <path d="M.175 1.238L1.399 0 9 7.523 1.397 15l-1.22-1.242L6.52 7.52.175 1.24z" fill="currentColor" />
    </svg>
  )
}

export default arrowRight
