import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { keepQueryString, feedUrl, loginRegisterUrl, tagsListUrl } from 'urls'
import { GlobeIcon, Over18Icon } from 'common/components/icons'
// import Device from 'common/util/Device'
// import SettingsList from 'settings/components/settings-list'
import { feedbackUrl } from 'urls'
import { howItWorksUrl, jobs, publicApiDocs } from 'landing/urls'
import { i18n } from 'common/locale/i18n'
import { privacyPolicyUrl, termsUrl } from 'urls'
import { useCurrentUser } from 'common/hooks'
import { selectActiveLanguage, selectPageLayout, setActiveLanguage } from 'store/app'
import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentUserSettings } from 'store/account'
import './Footer.scss'
import FilterDropdown from 'common/components/filter-dropdown/FilterDropdown'
import {
  DiscordIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MeduimIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from 'common/components/icons/footerSocials'
import FutuurLogo from 'common/components/FutuurLogo'
import { useState } from 'react'
import classNames from 'classnames'
// import CurrencyIcon from 'common/components/currencyIcon'
import { helpCenterUrl } from 'social/urls'
import AnjouanLicensingLogo from "../../common/components/icons/AnjouanLicensingLogo";

const Footer = () => {
  const currentUser = useCurrentUser()
  const language = useSelector(selectActiveLanguage())
  const pageLayout = useSelector(selectPageLayout)
  const [open, setOpen] = useState()
  const dispatch = useDispatch()
  // const exchangeRate = useCurrencies()
  // const realMoneyCurrencies = exchangeRate.realMoneyCurrencies
  // const currencyList = Object.keys(realMoneyCurrencies)
  //   .map(currencyId => realMoneyCurrencies[currencyId])
  //   .filter(currency => currency.is_available_for_deposit)
  //   .sort()

  // const fiat = currencyList.filter(currency => currency.currency_type === 'fiat')
  // const crypto = currencyList.filter(currency => currency.currency_type === 'crypto')
  useEffect(() => {
    try {
      if (window && window.apg_05bd754e_a13e_467c_8c37_58905b380cff) {
        window.apg_05bd754e_a13e_467c_8c37_58905b380cff.init()
      }

      /* Anjouan Licensing */
      window.anj_48e708ff_20b3_48b4_a346_a5ab12ed8b74.init()

    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    // TODO : when adding new languages this should change

    const lang = language.substring(0, 2)
    document.querySelector('html').setAttribute('lang', lang)
  }, [language])
  const onChangeLanguage = language => {
    const _language = language === 'pt-br' ? 'pt_br' : language
    dispatch(updateCurrentUserSettings({ language: _language }))
    dispatch(setActiveLanguage(language))
    location.reload()
  }

  const languageOptions = [
    { key: 'English', text: 'English', value: 'en' },
    { key: 'Portuguese', text: 'Portuguese', value: 'pt-br' },
  ]

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__row footer__company">
          {/* <div className="footer__logo">
            <FutuurBlackIcon height="42px" width="91px" />
          </div> */}
          <div className="footer__social">
            <a href="https://twitter.com/futuurHQ" target="_blank" rel="noreferrer" className="footer__social__twitter">
              <TwitterIcon />
            </a>
            <a
              href="https://www.instagram.com/futuurHQ"
              target="_blank"
              rel="noreferrer"
              className="footer__social__instagram">
              <InstagramIcon />
            </a>
            <a href="https://www.medium.com/futuur" target="_blank" rel="noreferrer" className="footer__social__medium">
              <MeduimIcon />
            </a>
            <a
              href="https://discord.gg/BJMX5mRZT5"
              target="_blank"
              rel="noreferrer"
              className="footer__social__discord">
              <DiscordIcon />
            </a>
            <a
              href="https://www.youtube.com/channel/UCEJkPOzzJzlV8jeb5mKRHeQ"
              target="_blank"
              rel="noreferrer"
              className="footer__social__youtube">
              <YoutubeIcon />
            </a>
            <a
              href="https://www.facebook.com/futuurHQ"
              target="_blank"
              rel="noreferrer"
              className="footer__social__facebook">
              <FacebookIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/futuur/"
              target="_blank"
              rel="noreferrer"
              className="footer__social__facebook">
              <LinkedinIcon />
            </a>
            <a
              href="https://www.tiktok.com/@futuur.com"
              target="_blank"
              rel="noreferrer"
              className="footer__social__facebook">
              <TiktokIcon />
            </a>
          </div>
        </div>

        <div className="footer__row footer__sitemap ">
          <div className="footer__section footer__section--description">
            <h4>{i18n('footer.title')}</h4>

            <p className="mb-4">{i18n('footer.disclaimer')}</p>

            <span className="d-none d-xl-block">© 2024 Futuur Inc.</span>
          </div>
          <div className="footer__explore_about">
            <div className="footer__section">
              <ul className="footer__link-list">
                {!currentUser && (
                  <li className="footer__link">
                    <Link to={keepQueryString(loginRegisterUrl())}>{i18n('footer.signup')}</Link>
                  </li>
                )}
                <li className="footer__link">
                  <Link to={keepQueryString(tagsListUrl())}>Tags</Link>
                </li>
                <li className="footer__link">
                  <Link to={keepQueryString(howItWorksUrl())}>{i18n('footer.how_works')}</Link>
                </li>
                <li className="footer__link">
                  <a href={helpCenterUrl()} rel="noreferrer" target="_blank">
                    {i18n('footer.help_center')}
                  </a>
                </li>
                <li className="footer__link">
                  <a href={jobs()} rel="noreferrer" target="_blank">
                    {i18n('footer.hiring')}
                  </a>
                </li>
                <li className="footer__link">
                  <a href={publicApiDocs()} rel="noreferrer" target="_blank">
                    {i18n('footer.api_docs')}
                  </a>
                </li>
                <div
                  className={classNames('footer__Language', {
                    'footer__Language--open': open,
                  })}>
                  <GlobeIcon width="16px" />
                  <FilterDropdown
                    type="list"
                    value={language}
                    list={languageOptions}
                    highlightSelected
                    arrow="outlined"
                    onOpenChange={setOpen}
                    onChange={onChangeLanguage}
                  />
                </div>
              </ul>
            </div>

            <div className="footer__section">
              <ul className="footer__link-list">
                <li className="footer__link">
                  <a href={process.env.REACT_APP_COMMUNITY_URL} target="_blank" rel="noreferrer">
                    {i18n('footer.community')}
                  </a>
                </li>
                <li className="footer__link">
                  <Link to={keepQueryString(feedbackUrl('suggestion'))}>{i18n('footer.suggest')}</Link>
                </li>
                <li className="footer__link">
                  <Link to={keepQueryString(feedbackUrl('bugs'))}>{i18n('footer.report')}</Link>
                </li>
                <li className="footer__link">
                  <Link to={keepQueryString(feedbackUrl('suggestion'))}>{i18n('footer.contact')}</Link>
                </li>
                <li className="footer__link">
                  <a href={termsUrl()} rel="noreferrer" target="_blank">
                    {i18n('footer.terms')}
                  </a>
                </li>
                <li className="footer__link">
                  <a href={privacyPolicyUrl()} rel="noreferrer" target="_blank">
                    {i18n('footer.privacy')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer__section footer__sitemap footer__sitemap--signatures">
          <Link to={feedUrl()} className="navbar__flex-column navbar__flex-column-left  ">
            <FutuurLogo white={pageLayout.theme === 'light'} className="navbar__logo d-block" width="100px" />
          </Link>

          <div className="footer__verification ">

            <AnjouanLicensingLogo />

            <div
              id="apg-05bd754e-a13e-467c-8c37-58905b380cff"
              className="footer__curacao"
              data-apg-seal-id="05bd754e-a13e-467c-8c37-58905b380cff"
              data-apg-image-size="50"
              data-apg-image-type="basic-small"
            />
            <Over18Icon className="footer__over18icon" width="32px" />
          </div>
        </div>
        <hr />
        <p className="text-Neutral-3 !text-xs !font-normal !leading-3" dangerouslySetInnerHTML={{ __html: i18n('footer.psp_disclamer') }} />

        {/* <div className="footer__section footer__sitemap footer__sitemap--currencies">
          <ul className="footer__currencies_list">
            {crypto.map(currency => (
              <li key={'footer-crypto-currencies-' + currency.display_code} className=" footer__currency">
                <CurrencyIcon currency={currency} width="26px" />
              </li>
            ))}
          </ul>
          <ul className="footer__currencies_list">
            {fiat.map(currency => (
              <li
                className=" footer__currency"
                style={{ marginLeft: '32px' }}
                key={'footer-fiat-currencies-' + currency.display_code}>
                <CurrencyIcon currency={currency} width="26px" />

                <span>{currency.display_code}</span>
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    </footer>
  )
}

export default Footer
