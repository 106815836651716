import React from 'react'

const busdIcon = props => {
  const color = props.whitepath ? 'white' : '#f0b90b'
  return (
    <div
      {...props}
      style={{
        background: props.fill || 'black',
        borderRadius: '100%',
        height: props.width,
        width: props.width,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <svg width="60%" height="60%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 336.41 337.42">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path style={{ fill: color, stroke: color }} d="M168.2.71l41.5,42.5L105.2,147.71l-41.5-41.5Z" />
            <path style={{ fill: color, stroke: color }} d="M231.2,63.71l41.5,42.5L105.2,273.71l-41.5-41.5Z" />
            <path style={{ fill: color, stroke: color }} d="M42.2,126.71l41.5,42.5-41.5,41.5L.7,169.21Z" />
            <path style={{ fill: color, stroke: color }} d="M294.2,126.71l41.5,42.5L168.2,336.71l-41.5-41.5Z" />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default busdIcon
