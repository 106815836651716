import { setDisplayFooter } from 'store/app'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const usePageWithoutFooter = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setDisplayFooter(false))
    return () => {
      dispatch(setDisplayFooter(true))
    }
  }, [])
}

export default usePageWithoutFooter
