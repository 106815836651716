import { useEffect, useState } from 'react'
import storage from 'common/util/storage'
import Device from 'common/util/Device'

const usePWAInstallation = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null)
  const [installationDismissed, setInstallationDismissed] = useState(false)
  const [appHasBeenInstalled, setAppHasBeenInstalled] = useState()

  // Handling if PWA installation should be prompted to the user
  useEffect(() => {
    storage('hidePopup')
      .get()
      .then(hidePopupDate => { 
        if (!hidePopupDate || is20DaysOld(hidePopupDate)) {
          // if there nothing in storage, or if it's 20days old
          // we show the popup
          setInstallationDismissed(false)
          storage('hidePopup').remove()
        } else {
          setInstallationDismissed(true)
        }
      })
  }, [])

  useEffect(() => {
    setAppHasBeenInstalled(window.matchMedia('(display-mode: standalone)').matches || 'standalone' in window.navigator)
  }, [])

  // Events for PWA installation
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', e => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e)
    })
  }, [])

  const installPWA = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt()
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt')
        } else {
          console.log('User dismissed the install prompt')
        }
        dismissPWA()
      })
    } else {
      dismissPWA()
    }
  }
  const is20DaysOld = milliseconds => {
    const inputDate = new Date(milliseconds)
    const currentDate = new Date()
    const timeDifference = currentDate.getTime() - inputDate.getTime()
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24)
    return dayDifference >= 20
  }
  const dismissPWA = async () => {
    await storage('hidePopup').set(new Date().getTime())
    setInstallationDismissed(true)
  }

  return {
    install: installPWA,
    dismiss: dismissPWA,
    shouldPromptInstallation:
      !installationDismissed && appHasBeenInstalled === false && (Device.isWebappMobile || Device.iswebapp),
  }
}

export default usePWAInstallation
