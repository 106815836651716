import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Image from 'common/components/Image'
import avatarPlaceholder from 'common/assets/picture.svg'
import userPictureUrl from 'common/util/userPictureUrl'
import { i18n } from 'common/locale/i18n'
import { userUrl } from 'profile/urls'
import './UserFollowItem.scss'
import { keepQueryString } from 'urls'
import Button from 'common/ui/button'

const UserFollowItem = ({ me, user, following, onUnfollow, onFollow }) => {
  const [isFollowing, setFollowing] = useState(me && me.id && user && following && following.includes(user.id))

  const onClickButton = event => {
    event.preventDefault()
    event.stopPropagation()

    if (isFollowing) {
      onUnfollow(user)
      setFollowing(false)
    } else {
      onFollow(user)
      setFollowing(true)
    }
  }

  if (!user) {
    return null
  }

  useEffect(() => {
    setFollowing(me && me.id && user && following && following.includes(user.id))
  }, [following])
  const username = user.username || '-'
  const avatarUrl = userPictureUrl(user, 50)

  return (
    <Link
      className={classNames('user-follow-item', {
        'user-follow-item--me': me && me.id === user.id,
      })}
      to={keepQueryString(userUrl(user))}
    >
      <Image
        className="user-follow-item__image"
        alt={username + "'s profile picture"}
        title={username + "'s profile picture"}
        src={avatarUrl}
        placeholder={avatarPlaceholder}
      />

      <div className="user-follow-item__names">
        <div className="user-follow-item__username">{username}</div>
      </div>

      {me &&
        me.id &&
        me.id !== user.id &&
        (isFollowing ? (
          <Button size="small" onClick={onClickButton}>
            {i18n('profile.following_action')}
          </Button>
        ) : (
          <Button size="small" onClick={onClickButton} type="outlined">
            {i18n('profile.follow_action')}
          </Button>
        ))}
    </Link>
  )
}

export default UserFollowItem
