import React from 'react'

const InfoBalloon = props => {
  return (
    <svg {...props} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.37925 13.8333L0.666748 16.75V1.33333C0.666748 1.11232 0.754545 0.900358 0.910826 0.744078C1.06711 0.587797 1.27907 0.5 1.50008 0.5H16.5001C16.7211 0.5 16.9331 0.587797 17.0893 0.744078C17.2456 0.900358 17.3334 1.11232 17.3334 1.33333V13C17.3334 13.221 17.2456 13.433 17.0893 13.5893C16.9331 13.7455 16.7211 13.8333 16.5001 13.8333H4.37925ZM3.80258 12.1667H15.6667V2.16667H2.33341V13.3208L3.80258 12.1667ZM8.16675 6.33333H9.83341V8H8.16675V6.33333ZM4.83341 6.33333H6.50008V8H4.83341V6.33333ZM11.5001 6.33333H13.1667V8H11.5001V6.33333Z"
        fill={props.fillcolor || 'black'}
      />
    </svg>
  )
}

export default InfoBalloon
