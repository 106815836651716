export const transformArrayIntoMap = (array, key = 'id') => {
  return array?.reduce((previous, current) => {
    return { ...previous, [current[key]]: current }
  }, {})
}

export const splitArrayIntoMap = (array, key = 'id') => {
  return array.reduce((obj, current) => {
    if (!obj.hasOwnProperty(current[key])) {
      obj[current[key]] = []
    }

    obj[current[key]] = obj[current[key]].concat(current)
    return obj
  }, {})
}
