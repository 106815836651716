import React from 'react'

const currencyWithCircle = ({ icon, color }) => {
  const styles = {
    display: 'flex',
    lineHeight: 1,
    borderRadius: '50%',
    backgroundColor: color,
    color: '#ffffff',
    width: '1em',
    height: '1em',
    alignItems: 'center',
    justifyContent: 'center',
  }

  return <div style={styles}>{React.createElement(icon, { width: '0.5em', fill: 'currentColor' })}</div>
}

export default currencyWithCircle
