import React from 'react'

const meneroIcon = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 3756.09 3756.49">
      <path
        fill="#fff"
        d="M4128 2249.81c0 1037.19-840.74 1878.05-1878 1878.05S372 3287 372 2249.81 1212.76 371.75 2250 371.75s1878 840.79 1878 1878.06z"
        transform="translate(-371.96 -371.75)"
      ></path>
      <path
        fill={props.whitepath ? '#dadada' : '#f26822'}
        d="M2250 371.75c-1036.89 0-1879.12 842.06-1877.8 1878 .26 207.26 33.31 406.63 95.34 593.12h561.88V1263L2250 2483.57 3470.52 1263v1579.9h562c62.12-186.48 95-385.85 95.37-593.12C4129.66 1212.76 3287 372 2250 372z"
        data-name="149931032"
        transform="translate(-371.96 -371.75)"
      ></path>
      <path
        fill={props.whitepath ? '#dadada' : '#4d4d4d'}
        d="M1969.3 2764.17l-532.67-532.7v994.14h-407.25l-384.29.07c329.63 540.8 925.35 902.56 1604.91 902.56s1275.31-361.84 1605-902.64h-791.75v-994.13l-532.7 532.7-280.61 280.61-280.62-280.61z"
        data-name="149931160"
        transform="translate(-371.96 -371.75)"
      ></path>
    </svg>
  )
}

export default meneroIcon
