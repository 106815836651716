import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'common/ui/button'
import FormImage from 'common/components/FormImage'
import Formsy from 'formsy-react'
import LoadingIndicator from 'common/components/LoadingIndicator'
import SignupLayout from 'layouts/components/signup-layout/SignupLayout'
import classNames from 'classnames'
import constants from 'app/constants'
import * as usernameValidator from 'common/form/username'
import { i18n } from 'common/locale/i18n'
import { Link, useNavigate } from 'react-router-dom'
import { feedUrl, keepQueryString, loginRegisterUrl, loginUrl, privacyPolicyUrl, signupUrl, termsUrl } from 'urls'
import {
  PasswordIcon,
  EmailIcon,
  UserIcon,
  PenIcon,
  PersonIcon,
  EmailReceivedIcon,
  DangerIcon,
  CheckIcon,
} from 'common/components/icons'

import { usePageWithoutNavbar, usePageWithoutFooter } from 'common/hooks'
import { TextFieldFormsy } from 'common/components/text-field'
import {
  resetSessionError,
  selectCurrentUser,
  selectSessionStatus,
  selectSessionError,
  fetchCurrentUser,
  selectCurrentUserStatus,
  selectCurrentUserErrors,
  registerEmail,
  // isValidEmailForSignup,
  check_username,
  isValidEmailForSignup,
} from 'store/account'

import 'views/signup/ViewSignup.scss'
import LoginError from 'session/components/LoginError' 
import { useLocation } from 'react-router-dom'
import useIsMobileSize from 'common/hooks/useIsMobileSize'

export const ViewSignupPages = {
  register: 'register',
  set_username: 'set_username',
  set_picture: 'set_picture',
  setting_up_account: 'setting_up_account',
  email_confirmation_pending: 'email_confirmation_pending',
  email_confirmed: 'email_confirmed',
}

const ViewSignup = ({ startPage = '' }) => {
  usePageWithoutNavbar(true)
  usePageWithoutFooter()
  const [valid, setValid] = useState(false)
  const [currentPage, setCurrentPage] = useState(startPage)
  const [hasImage, setHasImage] = useState(false)
  const [userData, setUserData] = useState({})
  const [debouncedUserData, setDebouncedUserData] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isMobileSize = useIsMobileSize();

  const sessionStatus = useSelector(selectSessionStatus)
  const errors = useSelector(selectSessionError)
  const [userDataErrors, setUserDataErrors] = useState({})
  const currentUser = useSelector(selectCurrentUser)
  const currentUserStatus = useSelector(selectCurrentUserStatus)
  const currentUserErrors = useSelector(selectCurrentUserErrors)
  const isLoading = sessionStatus === constants.LOADING
  const isSaving = currentUserStatus === constants.SAVING
  const contentPaddingTop = isMobileSize ? '127px' : '26vh'
  useEffect(() => {
    setUserDataErrors({ email: null, username: null })
    const handler = setTimeout(() => {
      setDebouncedUserData({ email: userData.email, username: userData.username })
    }, 1000) // Adjust debounce time as needed

    return () => {
      clearTimeout(handler)
    }
  }, [userData.email, userData.username])
  const searchParams = path => {
    const start = path.indexOf('?') !== -1 ? path.indexOf('?') + 1 : 0
    path = path.substring(start)
    const s = {}
    path.split('&').forEach(a => (s[a.split('=')[0]] = a.split('=')[1])) //.map(e => ({ key: e.split('=')[0], value: e.split('=')[1] }))
    return s
  }
  useEffect(() => {
    if (debouncedUserData.username && currentPage === ViewSignupPages.set_username) {
      verifyUserNameExistance(debouncedUserData.username)
    }
  }, [debouncedUserData.email, debouncedUserData.username])

  useEffect(() => {
    if (!currentUser) {
      dispatch(fetchCurrentUser())
    }
  }, [])

  useEffect(() => {
    if (currentUser && currentUser.username && currentUser.email_confirmed) {
      navigate(feedUrl(), { replace: true })
    }
  }, [currentUser])

  useEffect(() => {
    if (sessionStatus === constants.COMPLETE && ViewSignupPages.email_confirmed !== currentPage) {
      setCurrentPage(ViewSignupPages.email_confirmation_pending)
    }
  }, [sessionStatus])

  const onFormValid = () => {
    setValid(true)
    if (errors) {
      dispatch(resetSessionError())
    }
  }

  const onFormError = invalidate => error => {
    if (error.status === 400) {
      invalidate(errors)
    }
  }

  const onFormInvalid = () => {
    setValid(false)
  }

  // const onFormSubmit = async data => {
  //   const searchQuery = new urlSearchParams(location.search)
  //   // TODO : SHOULD BE IMPROVED
  //   await dispatch(registerEmail(data.email, data.password, searchQuery.invite_key, {}))
  // }

  const verifyEmailExistance = async email => {
    const errorObj = errors && errors.error
    const emailErrorList = errorObj && errorObj.email
    if (emailErrorList) return false

    try {
      await isValidEmailForSignup(email)
      return true
    } catch (error) {
      setUserDataErrors(userData => ({ ...userData, email: error.error.email }))
      return false
    }
  }

  const emailPasswordProvided = async data => {
    const allowed = await verifyEmailExistance(data.email)
    if (allowed) {
      setUserData(userData => ({ ...userData, email: data.email, password: data.password }))
      setCurrentPage(ViewSignupPages.set_username)
    }
  }
  const onUsernameFormSubmit = data => {
    if (userDataErrors.username === false) {
      setCurrentPage(ViewSignupPages.set_picture)
      setUserData(userData => ({ ...userData, username: data.username }))
    }
  }
  const onPictureFormSubmit = data => {
    setCurrentPage(ViewSignupPages.setting_up_account)
    setUserData(userData => {
      const newData = { ...userData, picture: data.picture }
      saveUser(newData)
      return newData
    })
  }

  const saveUser = data => {
    // Save the user
    if (data.username && data.password && data.email) {
      dispatch(registerEmail(data))
    }
  }

  const onChangeImage = file => {
    setHasImage(!!file)
  }

  const parseErrorList = errorList =>
    errorList && Array.isArray(errorList) && <React.Fragment>{errorList.join(<br />)}</React.Fragment>

  const verifyUserNameExistance = username => {
    check_username(username)
      .then(res => {
        if (res && res.code) setUserDataErrors(userData => ({ ...userData, username: false }))
      })
      .catch(err =>
        setUserDataErrors(userData => ({ ...userData, username: err.error.msg || i18n('signup.' + err.error.code) }))
      )
  }
  /**
   * Initial form to register user email and password
   * onBack => sign up options (google, facebook, email)
   * goes to => 'set_username' @renderUsernameForm
   */
  const renderSignupForm = () => {
    const errorObj = errors && errors.error
    const emailErrorList =
      (errorObj && errorObj.email) || (userDataErrors && userDataErrors.email && [userDataErrors.email])
    const passwordErrorList = errorObj && errorObj.password
    return (
      <SignupLayout
        title={i18n('signup.welcome')}
        onGoBack={() => navigate(signupUrl())}
        steperCurrent={0}
        style={{ alignItems: 'unset', paddingTop: contentPaddingTop }}>
        <Formsy
          className="view-signup__form"
          action=""
          onValid={onFormValid}
          onInvalid={onFormInvalid}
          onValidSubmit={emailPasswordProvided}>
          <TextFieldFormsy
            containerClassName="view-signup__row"
            name="email"
            type="email"
            value={userData.email}
            autoComplete="email"
            onChange={em => {
              setUserData(userData => ({ ...userData, email: em }))
              setUserDataErrors(ude => ({ ...ude, email: null }))
            }}
            validateOnBlur={() => {
              verifyEmailExistance(userData.email)
            }}
            label={i18n('signup.email')}
            validationError={i18n('signup.error_email')}
            required
            placeholder={i18n('signup.email_placeholder')}
            icon={<EmailIcon width="1em" />}
            onError={() => setUserDataErrors(ude => ({ ...ude, email: i18n('signup.error_email') }))}
            error={parseErrorList(emailErrorList)}
          />
          <TextFieldFormsy
            containerClassName="view-signup__row"
            name="password"
            type="password"
            value={userData.password}
            autoComplete="new-password"
            label={i18n('signup.password')}
            validations={{ matchRegexp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/gi }}
            validationError={i18n('login.password_invalid')}
            error={parseErrorList(passwordErrorList)}
            required
            helperText={
              <ul className="view-signup__helper-text">
                <li>{i18n('signup.password_helper_1')}</li>
                <li>{i18n('signup.password_helper_2')}</li>
              </ul>
            }
            icon={<PasswordIcon width="1em" />}
          />
          {!emailErrorList && !passwordErrorList && errors && (
            <div className="view-signup__small" style={{ color: '#fc4a37' }}>
              <LoginError error={errors} />
            </div>
          )}

          <Button
            type="solid"
            size="full"
            variant="primary"
            disabled={isLoading || !valid || (emailErrorList && emailErrorList.length)}
            loading={isLoading}>
            {i18n('signup.continue')}
          </Button>
          <div className="view-signup__disclamer  ">
            {i18n('login.terms_text1')}
            <Link to={keepQueryString(termsUrl())} target="_blank">{i18n('login.terms')}</Link>
            {i18n('login.terms_text2')}
            <Link to={keepQueryString(privacyPolicyUrl())} target="_blank">
              {i18n('login.privacy')}
            </Link>
            {'.'}
          </div>
        </Formsy>
        <div className="view-signup__small">
          {i18n('signup.already_forecaster')}&nbsp;
          <Link to={loginUrl()} className="view-signup__small-link">
            {i18n('signup.signin')}
          </Link>
        </div>
      </SignupLayout>
    )
  }

  /**
   * Form where users can pick a unique username.
   * onBack => disabled
   * goes to => set_picture @renderUserPictureForm
   */
  const renderUsernameForm = () => {
    const placeholder = <div className="view-signup__username_warning">{i18n('signup.username_warning')}</div>
    const onGoBack = () => {
      setCurrentPage(ViewSignupPages.register)
      setUserData(userData => ({ ...userData, username: null }))
    }
    const errors =
      (currentUserErrors && currentUserErrors.error && currentUserErrors.error.username) ||
      (userDataErrors && userDataErrors.username && [userDataErrors.username]) ||
      []
    return (
      <SignupLayout
        onGoBack={onGoBack}
        title={i18n('signup.pick_a_username')}
        steperCurrent={1}
        style={{ alignItems: 'unset', paddingTop: contentPaddingTop }}>
        <Formsy
          className="view-signup__form"
          onValid={onFormValid}
          onInvalid={onFormInvalid}
          onValidSubmit={onUsernameFormSubmit}>
          <TextFieldFormsy
            containerClassName="view-signup__row"
            name="username"
            type="text"
            value={userData.username}
            autoComplete="username"
            helperText={placeholder}
            label={i18n('signup.username')}
            pattern={usernameValidator.pattern}
            validations={usernameValidator.rules}
            validationErrors={usernameValidator.errors}
            onChange={un => setUserData(userData => ({ ...userData, username: un }))}
            required
            autoCorrect="off"
            autoCapitalize="off"
            placeholder={i18n('signup.username_placeholder')}
            spellCheck={false}
            autofocus={true}
            error={errors.join('')}
            icon={<UserIcon width="1em" fill="currentColor" />}
          />

          <Button
            size="full"
            disabled={isSaving || !valid || errors.length > 0 || userDataErrors.username === null}
            loading={isSaving || (userData.username && userDataErrors.username === null)}>
            {i18n('signup.continue')}
          </Button>
        </Formsy>
      </SignupLayout>
    )
  }

  /**
   * Form where users can choose a profile picture or skip
   * onBack => user profile screen / @renderUsernameForm
   * goes to => "setting_up_account" / @renderSettingUpAccount
   */
  const renderUserPictureForm = () => {
    const profileImage = userData.picture
    const profileFormClassList = classNames('view-signup__form')
    const onGoBack = () => {
      setCurrentPage(ViewSignupPages.set_username)
      setUserData(userData => ({ ...userData, picture: null }))
      setHasImage(false)
    }
    return (
      <SignupLayout
        title={i18n('signup.set_pp') + userData.username}
        onGoBack={onGoBack}
        steperCurrent={2}
        style={{ alignItems: 'unset', paddingTop: contentPaddingTop }}>
        <Formsy
          className={profileFormClassList}
          action=""
          onValid={onFormValid}
          onInvalid={onFormInvalid}
          onValidSubmit={onPictureFormSubmit}>
          <div className="view-signup__picture">
            <FormImage
              className="view-signup__picture-image"
              name="picture"
              placeholderImg={profileImage}
              imageHasChanged={onChangeImage}
            />
            {!hasImage && (
              <div className=" view-signup__picture-placehoder">
                <PersonIcon width="16px" />
              </div>
            )}
            <div className="view-signup__picture-badge">
              <PenIcon width="1em" fill="currentColor" />
            </div>
          </div>
          <p className="view-signup__info">{i18n('signup.choose_profile_picture_info')}</p>
          <Button
            type="solid"
            size="full"
            variant="primary"
            disabled={!hasImage || isSaving || !valid}
            loading={isSaving}>
            {i18n('signup.continue')}
          </Button>
        </Formsy>
        <p className="view-signup__small">
          <span onClick={() => onPictureFormSubmit({}, null, onFormError)} className="view-signup__skip-link">
            {i18n('signup.skip')}
          </span>
        </p>
      </SignupLayout>
    )
  }

  /**
   * onBack => disabled
   * goesTo => email_confirmation_pending / renderEmailConfirmationPending (after a few seconds, check side effects)
   */
  const renderSettingUpAccount = () => {
    return (
      <section className="view-signup__setting-up">
        <SignupLayout displayGoBackArrow={false} title={i18n('signup.setting_up_account')}>
          <div className="view-signup__loading">
            <LoadingIndicator mode={'inline'} />
          </div>
        </SignupLayout>
      </section>
    )
  }

  /**
   * Page asking user to verify inbox
   * goesTo: feed
   */
  const renderEmailConfirmationPending = () => {
    const title = (
      <>
        <i className="view-signup__inbox-icon">
          <EmailReceivedIcon fill="currentColor" width="20px" />
        </i>
        <h2 className="signup-layout__title">{i18n('signup.you_got_mail')}</h2>
      </>
    )
    return (
      <SignupLayout displayGoBackArrow={false}>
        {title}
        <p className="view-signup__paragraph">
          {i18n('signup.verification_info')}
          <a href="#" target={'_blank'}>
            {i18n('signup.identity_verification_policy')}.
          </a>
        </p>
        <div className="view-signup__small">
          {i18n('signup.proceed_without_verifying')}{' '}
          <Link className="view-signup__skip-link" to={feedUrl()}>
            {i18n('signup.verify_later')}
          </Link>
        </div>
      </SignupLayout>
    )
  }

  /**
   * this should not be displayed,
   * because we handle all possible errors in the past forms
   */
  const renderUnexpectedError = () => {
    return (
      <SignupLayout title="Unexpected issue encountered">
        <i className="view-signup__inbox-icon  ">
          <DangerIcon fill="currentColor" width="19px" />
        </i>

        <p className="view-signup__paragraph">{errors.error.detail}</p>

        <footer className="view-signup__small">
          <Button
            type="solid"
            size="full"
            variant="primary"
            onClick={() => {
              if (errors.error.email || errors.error.password) setCurrentPage(ViewSignupPages.register)
              else if (errors.error.username) setCurrentPage(ViewSignupPages.set_username)
              else {
                navigate(loginRegisterUrl())
              }
            }}>
            Try again
          </Button>
        </footer>
      </SignupLayout>
    )
  }
  /**
   * Landing page that indicates users got their e-mail confirmed
   */
  const renderEmailConfirmed = () => {
    const params = searchParams(location.search)
    const msg = params['msg']
    if (msg === 'invalid_token')
      return (
        <SignupLayout title={i18n('signup.invalid_link')} displayGoBackArrow={false}>
          <i className="view-signup__inbox-icon view-signup__inbox-icon--confirmation">
            <DangerIcon fill="currentColor" width="19px" />
          </i>

          <footer className="view-signup__small">
            <Button type="solid" size="full" variant="primary" onClick={() => navigate(feedUrl())}>
              {i18n('signup.go_to_futuur')}
            </Button>
          </footer>
        </SignupLayout>
      )
    else
      return (
        <SignupLayout title={i18n('signup.email_verified')} displayGoBackArrow={false}>
          <i className="view-signup__inbox-icon view-signup__inbox-icon--confirmation">
            <CheckIcon fill="currentColor" width="19px" />
          </i>

          <p className="view-signup__paragraph">{i18n('signup.time_to_predict_the_future')}.</p>

          <footer className="view-signup__small">
            <Button
              type="solid"
              size="full"
              variant="primary"
              onClick={() => {
                navigate(loginUrl())
              }}>
              {i18n('signup.start_forecasting')}
            </Button>
          </footer>
        </SignupLayout>
      )
  }
  if (errors) console.log(errors)
  return (
    <React.Fragment>
      {currentPage === ViewSignupPages.register && renderSignupForm()}
      {currentPage === ViewSignupPages.set_username && renderUsernameForm()}
      {currentPage === ViewSignupPages.set_picture && renderUserPictureForm()}
      {currentPage === ViewSignupPages.setting_up_account && errors && errors.error && renderUnexpectedError()}
      {currentPage === ViewSignupPages.setting_up_account && renderSettingUpAccount()}
      {currentPage === ViewSignupPages.email_confirmed && renderEmailConfirmed()}
      {currentPage === ViewSignupPages.email_confirmation_pending && renderEmailConfirmationPending()}
    </React.Fragment>
  )
}

export default ViewSignup
