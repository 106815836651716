import React from 'react'
import reportWebVitals from './reportWebVitals'
import Futuur from 'app/components/Futuur'
import preventZoom from 'common/util/preventZoom'
import orientationLock from 'common/util/orientationLock'
import { createRoot } from 'react-dom/client'
import './index.scss'
// import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'
// import LoadingIndicator from 'common/components/LoadingIndicator'

orientationLock('portrait')
preventZoom()
createRoot(document.getElementById('app')).render(
  <React.StrictMode>
    <Futuur />
    {/* <ClearBrowserCacheBoundary auto fallback={<LoadingIndicator mode="overlay" />} duration={15 * 60 * 1000}>
  </ClearBrowserCacheBoundary> */}
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
