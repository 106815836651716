import React from 'react'

const key = props => {
  return (
    <svg width="26" height="24" {...props} viewBox="0 0 26 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7507 11.4211L21.3373 0.834473L24.1653 3.66247L22.28 5.54781L25.108 8.37581L20.3947 13.0905L17.5667 10.2625L13.58 14.2491C14.3141 15.6615 14.5188 17.2899 14.1572 18.84C13.7955 20.3902 12.8914 21.7599 11.6081 22.7016C10.3249 23.6434 8.74706 24.0951 7.15981 23.9752C5.57257 23.8553 4.08055 23.1717 2.95333 22.0478C1.82553 20.9218 1.13841 19.4291 1.01647 17.8401C0.894532 16.2511 1.34585 14.671 2.28863 13.3861C3.23142 12.1012 4.60329 11.1966 6.15565 10.8361C7.70802 10.4756 9.33815 10.6832 10.7507 11.4211ZM9.55199 19.2185C9.80669 18.9725 10.0098 18.6782 10.1496 18.3529C10.2894 18.0275 10.3629 17.6776 10.366 17.3235C10.3691 16.9695 10.3016 16.6183 10.1675 16.2906C10.0334 15.9629 9.83543 15.6651 9.58505 15.4148C9.33467 15.1644 9.03693 14.9664 8.7092 14.8323C8.38148 14.6982 8.03033 14.6307 7.67626 14.6338C7.32218 14.6369 6.97226 14.7104 6.64691 14.8502C6.32157 14.99 6.02732 15.1931 5.78133 15.4478C5.29557 15.9507 5.02679 16.6244 5.03286 17.3235C5.03894 18.0227 5.31939 18.6916 5.81381 19.186C6.30823 19.6804 6.97707 19.9609 7.67626 19.9669C8.37545 19.973 9.04905 19.7042 9.55199 19.2185Z"
        fill={props.fill || '#438FE6'}
      />
    </svg>
  )
}

export default key
