import React from 'react'

const Kyc = props => {
  return (
    <svg {...props} width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.044 2.76767L12 0.333008L22.956 2.76767C23.2521 2.83349 23.5169 2.9983 23.7067 3.23488C23.8965 3.47147 23.9999 3.7657 24 4.06901V17.385C23.9999 18.702 23.6747 19.9986 23.0532 21.1597C22.4317 22.3208 21.5332 23.3105 20.4373 24.041L12 29.6663L3.56267 24.041C2.46703 23.3106 1.56861 22.3211 0.947131 21.1603C0.325648 19.9994 0.000315142 18.7031 0 17.3863V4.06901C5.20504e-05 3.7657 0.103517 3.47147 0.293319 3.23488C0.483122 2.9983 0.747916 2.83349 1.044 2.76767ZM2.66667 5.13834V17.385C2.66668 18.263 2.88343 19.1273 3.29768 19.9014C3.71193 20.6755 4.31087 21.3353 5.04133 21.8223L12 26.4623L18.9587 21.8223C19.6889 21.3354 20.2878 20.6758 20.702 19.902C21.1162 19.1282 21.3331 18.2641 21.3333 17.3863V5.13834L12 3.06634L2.66667 5.13834ZM12 13.6663C11.1159 13.6663 10.2681 13.3152 9.64298 12.69C9.01786 12.0649 8.66667 11.2171 8.66667 10.333C8.66667 9.44895 9.01786 8.60111 9.64298 7.97599C10.2681 7.35086 11.1159 6.99967 12 6.99967C12.8841 6.99967 13.7319 7.35086 14.357 7.97599C14.9821 8.60111 15.3333 9.44895 15.3333 10.333C15.3333 11.2171 14.9821 12.0649 14.357 12.69C13.7319 13.3152 12.8841 13.6663 12 13.6663ZM6.036 20.333C6.19763 18.8642 6.89558 17.5067 7.99611 16.5206C9.09664 15.5346 10.5223 14.9893 12 14.9893C13.4777 14.9893 14.9034 15.5346 16.0039 16.5206C17.1044 17.5067 17.8024 18.8642 17.964 20.333H6.036Z"
        fill="white"
      />
    </svg>
  )
}

export default Kyc
