import * as React from 'react'

export default function PasswordIcon({ fillcolor, className, ...props }) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" className={className} {...props}>
      <path
        fill={fillcolor || 'currentColor'}
        d="M13.8333 7.33329H14.6667C14.8877 7.33329 15.0996 7.42109 15.2559 7.57737C15.4122 7.73365 15.5 7.94561 15.5 8.16663V16.5C15.5 16.721 15.4122 16.9329 15.2559 17.0892C15.0996 17.2455 14.8877 17.3333 14.6667 17.3333H1.33333C1.11232 17.3333 0.900358 17.2455 0.744078 17.0892C0.587797 16.9329 0.5 16.721 0.5 16.5V8.16663C0.5 7.94561 0.587797 7.73365 0.744078 7.57737C0.900358 7.42109 1.11232 7.33329 1.33333 7.33329H2.16667V6.49996C2.16667 5.73391 2.31755 4.97537 2.6107 4.26764C2.90386 3.55991 3.33354 2.91684 3.87521 2.37517C4.41689 1.83349 5.05995 1.40381 5.76768 1.11066C6.47541 0.81751 7.23396 0.666626 8 0.666626C8.76604 0.666626 9.52459 0.81751 10.2323 1.11066C10.9401 1.40381 11.5831 1.83349 12.1248 2.37517C12.6665 2.91684 13.0961 3.55991 13.3893 4.26764C13.6825 4.97537 13.8333 5.73391 13.8333 6.49996V7.33329ZM2.16667 8.99996V15.6666H13.8333V8.99996H2.16667ZM7.16667 10.6666H8.83333V14H7.16667V10.6666ZM12.1667 7.33329V6.49996C12.1667 5.39489 11.7277 4.33508 10.9463 3.55368C10.1649 2.77228 9.10507 2.33329 8 2.33329C6.89493 2.33329 5.83512 2.77228 5.05372 3.55368C4.27232 4.33508 3.83333 5.39489 3.83333 6.49996V7.33329H12.1667Z"
      />
    </svg>
  )
}
