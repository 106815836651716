import React from 'react'

const btcIcon = ({ fill = '#A6B6BD', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 20" {...props}>
      <path
        d="M14.964 8.263c.3-1.996-1.222-3.07-3.3-3.785l.674-2.705-1.646-.41-.657 2.634c-.432-.109-.876-.21-1.319-.31l.662-2.652-1.646-.41-.674 2.704c-.358-.082-.71-.162-1.051-.248l.001-.008-2.27-.567L3.3 4.264s1.222.28 1.196.297c.667.167.787.608.767.959L4.495 8.6c.046.012.105.03.171.055l-.174-.043-1.076 4.316c-.082.202-.289.506-.755.391.017.024-1.197-.298-1.197-.298l-.817 1.884 2.143.534c.398.1.789.205 1.173.303l-.681 2.735 1.644.41.675-2.705c.45.12.886.233 1.312.34l-.672 2.693 1.646.41.68-2.73c2.808.532 4.919.317 5.808-2.222.716-2.044-.036-3.224-1.513-3.992 1.076-.248 1.886-.956 2.102-2.418zm-3.762 5.275c-.508 2.045-3.95.94-5.067.662l.905-3.624c1.116.279 4.694.83 4.162 2.962zm.51-5.304c-.464 1.86-3.33.914-4.258.683l.82-3.286c.928.231 3.921.663 3.438 2.603z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default btcIcon
