function userPictureUrl(user, heightPx, fbAccessToken) {
  if (!user) {
    return null
  }

  if (user.picture) {
    return user.picture
  }

  if (user.facebook_id && fbAccessToken) {
    return `https://graph.facebook.com/v2.11/${user.facebook_id}/picture?height=${heightPx}&access_token=${fbAccessToken}`
  }

  return null
}

export default userPictureUrl
