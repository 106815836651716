/* @flow */
import React from 'react'
import { walletUrl } from 'urls'
import { i18n } from 'common/locale/i18n'

class Withdraw extends React.PureComponent {
  handleClick() {
    const { onMessageClick } = this.props
    if (onMessageClick) {
      const url = walletUrl()
      onMessageClick(url)
    }
  }

  render() {
    const { amount, currency } = this.props
    return (
      <div className="message" onClick={this.handleClick.bind(this)}>
        <div className="message__text">
          <div className="message__main-text">
            <em>{i18n('withdraw.withdraw_done')}</em>
          </div>
          <div className="message__sub-text">
            {i18n('withdraw.your_amount_of')} {amount} {currency.symbol} {i18n('withdraw.is_being_processed')}
          </div>
        </div>
      </div>
    )
  }
}

export default Withdraw
