import React from 'react'

export default function ShieldIcon({ ...props }) {
  return (
    <svg {...props} width={props.width || "12"} height={props.height || "16"} viewBox="0 0 12 16"  xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.522047 1.88431L6 0.666992L11.4779 1.88431C11.783 1.9521 12 2.22264 12 2.53511V9.19293C12 10.5303 11.3316 11.7793 10.2188 12.5211L6 15.3337L1.7812 12.5211C0.668407 11.7793 0 10.5303 0 9.19293V2.53511C0 2.22264 0.21702 1.9521 0.522047 1.88431ZM1.33333 3.06989V9.19293C1.33333 10.0845 1.77893 10.9171 2.5208 11.4117L6 13.7312L9.4792 11.4117C10.2211 10.9171 10.6667 10.0845 10.6667 9.19293V3.06989L6 2.03285L1.33333 3.06989Z"
        fill="currentColor"
      />
    </svg>
  )
}
