import React from 'react'

class WagerSellAll extends React.PureComponent {
  render() {
    return (
      <div className="message">
        <img />
        <div>thanks for your feedback</div>
        <p>icon</p>
      </div>
    )
  }
}

export default WagerSellAll
