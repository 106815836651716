import React from 'react'

const NetellerIcon = props => {
  return (
    <svg width="174" height="30" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M170.431 2.28C168.22.765 164.871 0 160.482 0h-11.494l-4.273 23.198h-15.057l.968-5.263h9.07l1.075-5.911-9.065.013.97-5.24h9.343l1.243-6.795H124.29l-4.057 22.004h-6.962L117.354.001h-9.918l-4.057 22.004h-6.783L100.684 0h-9.913l-4.278 23.197H71.328l.958-5.263h9.107l1.076-5.878-9.098-.009.965-5.25h9.448L85.019 0H25.895L22.53 18.382 15.828 0H5.538L0 30h9.827l3.352-18.89L19.683 30h35.22l4.281-23.205h5.509L60.41 30h92.67l2.687-14.648L160.114 30h9.832l-4.504-11.969 1.03-.222c2.27-.486 4.04-1.53 5.273-3.097 1.231-1.581 1.858-3.61 1.858-6.049 0-2.838-1.038-4.926-3.174-6.384l.002.001zM46.351 23.198H31.308l.971-5.263h9.436l1.07-5.928H33.44l.9-5.208h15.02l-3.012 16.399h.003zM162.33 13.093c-.989.691-2.422 1.028-4.383 1.028h-1.943l1.49-8.05h1.43c1.628 0 2.815.26 3.634.792.64.418 1.4 1.243 1.4 2.805-.003 1.888-.887 2.903-1.63 3.425h.002z"
        fill="#97A4AA"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default NetellerIcon
