import React, { createContext, useContext, useState } from 'react'

const ModalContext = createContext() // This should not be undefined

export const useModal = () => useContext(ModalContext) // Ensure this is correct

export const ModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({ isOpen: false })

  const showModal = props => {
    setModalProps({ ...props, isOpen: true })
  }

  const hideModal = () => {
    setModalProps({ isOpen: false })
  }

  return <ModalContext.Provider value={{ modalProps, showModal, hideModal }}>{children}</ModalContext.Provider>
}
