import React, { lazy } from 'react'
const ViewLogin = lazy(() => import('views/login'))

import { brazilianCampainUrl, emailUpdateUrl, kycUrl, passwordUpdateUrl, recoverPassword } from 'urls'
import { ViewSignupPages } from 'views/signup'

const LoginReset = lazy(() => import('views/login-reset'))
const ViewPasswordUpdate = lazy(() => import('portfolio/components/password-update'))
const ViewEmailUpdate = lazy(() => import('portfolio/components/email-update'))
const ViewSignup = lazy(() => import('views/signup'))
const ViewSignupOptions = lazy(() => import('views/signup-options'))
const ViewSignupUserProfile = lazy(() => import('views/signup-user-profile'))
const Logout = lazy(() => import('views/logout'))
const ViewRecoverPassword = lazy(() => import('views/recover-password/ViewRecoverPassword'))
const ViewKYC = lazy(() => import('views/kyc/ViewKYC'))
const ViewBrazilianCampain = lazy(() => import('views/brazilian-campain'))
import {
  loginUrl,
  signupFormUrl,
  loginProfileUrl,
  loginRegisterUrl,
  loginResetUrl,
  emailConfirmationRequiredUrl,
  emailConfirmedUrl,
  setUserNameAfterSignIn,
  logoutUrl,
} from 'urls'
import { Navigate } from 'react-router-dom'

export default [
  { path: '/l', element: <Navigate to={loginUrl()} replace /> },
  { path: signupFormUrl(), element: <ViewSignup startPage={ViewSignupPages.register} /> },
  { path: loginProfileUrl(), element: <ViewSignupUserProfile /> },
  { path: logoutUrl(), element: <Logout /> },
  {
    path: emailConfirmationRequiredUrl(),
    element: <ViewSignup startPage={ViewSignupPages.email_confirmation_pending} />,
  },
  { path: setUserNameAfterSignIn(), element: <ViewSignup startPage={ViewSignupPages.set_username} /> },
  { path: emailConfirmedUrl(), element: <ViewSignup startPage={ViewSignupPages.email_confirmed} /> },
  { path: loginUrl(), element: <ViewLogin /> },
  { path: loginRegisterUrl(), element: <ViewSignupOptions /> },
  { path: passwordUpdateUrl(), element: <ViewPasswordUpdate /> },
  { path: emailUpdateUrl(), element: <ViewEmailUpdate /> },
  { path: loginResetUrl(), element: <LoginReset /> },
  { path: recoverPassword(), element: <ViewRecoverPassword /> },
  { path: kycUrl(), element: <ViewKYC />, isModal: true },
  { path: brazilianCampainUrl(), element: <ViewBrazilianCampain /> },
]
