import { useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {
  const lastValidUrl = useRef()
  const location = useLocation()

  useLayoutEffect(() => {
    if (!location.pathname.startsWith('/m') && location.pathname !== lastValidUrl.current) {
      lastValidUrl.current = location.pathname
      window.scrollTo(0, 0)
    }
  }, [location.pathname])
  return null
}

export default ScrollToTop
