import React from 'react'

export default function FacebookIcon({ width = 8, height = 14, fill = 'currentColor', className, outlined = false }) {
  return !outlined ? (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 8 14">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fill} fillRule="nonzero" transform="translate(-637 -911)">
          <g transform="translate(484 905)">
            <g transform="translate(144)">
              <path d="M16.5 6v2.8h-1.4c-.483 0-.7.567-.7 1.05v1.75h2.1v2.8h-2.1V20h-2.8v-5.6H9.5v-2.8h2.1V8.8A2.8 2.8 0 0114.4 6h2.1z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0,0,256,256">
      <g transform="translate(-32,-32) scale(1.25,1.25)">
        <g fill={fill}>
          <g transform="scale(8,8)">
            <path d="M7,5c-1.09375,0 -2,0.90625 -2,2v18c0,1.09375 0.90625,2 2,2h18c1.09375,0 2,-0.90625 2,-2v-18c0,-1.09375 -0.90625,-2 -2,-2zM7,7h18v18h-5.1875v-6.75h2.59375l0.375,-3h-2.96875v-1.9375c0,-0.875 0.21484,-1.46875 1.46875,-1.46875h1.625v-2.71875c-0.27734,-0.03516 -1.23828,-0.09375 -2.34375,-0.09375c-2.30469,0 -3.875,1.38672 -3.875,3.96875v2.25h-2.625v3h2.625v6.75h-9.6875z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
