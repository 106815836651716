import React, { useEffect } from 'react'
import { i18n } from 'common/locale/i18n'
import { DropdownMenu, MenuLink, MenuAction } from 'common/components/dropdown-menu'
import { DotsIcon, FutuurIcon, InfoBalloonIcon, Lune, PersonIcon, WalletIcon } from 'common/components/icons'
import {
  termsUrl,
  privacyPolicyUrl,
  feedbackUrl,
  keepQueryString,
  communityUrl,
  userUrl,
  walletUrl,
  loginUrl,
} from 'urls'
import { howItWorksUrl } from 'landing/urls'
import { settingsUrl } from 'profile/urls'
import {
  EmailIcon,
  PasswordIcon,
  CommunityIcon,
  PaperAirplaneIcon,
  ParchmentIcon,
  CogIcon,
  ExitIcon,
} from 'common/components/icons'
import { useNavigate } from 'react-router'
import './NavbarMenu.scss'
import { ToggleControl } from 'common/components/form-controls'
import { useAppTheme } from 'common/hooks'
import { helpCenterUrl } from 'social/urls'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminMode, selectPageLayout } from 'store/app'

const NavbarMenu = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { toggleTheme } = useAppTheme()
  const { adminMode } = useSelector(selectPageLayout)
  const handleLogout = async () => {
    navigate(loginUrl(true))
  }

  useEffect(() => {
    const mode = localStorage.getItem('adminMode')
    if (adminMode === null && !mode) return
    else if (adminMode === null) dispatch(getAdminMode(mode === 'true'))
    else if (adminMode !== null) localStorage.setItem('adminMode', adminMode)
  }, [adminMode])
  const renderIcon = () => {
    return (
      <div className="navbar-menu__dots">
        <DotsIcon fillcolor="currentColor" />
      </div>
    )
  }
  const myPorfile = () => {
    const userName = _me => {
      var UN = _me.username
      if (_me.first_name) UN = _me.first_name
      if (_me.last_name) UN = UN + ' ' + _me.last_name
      return UN
    }
    return (
      <div className="navbar-menu__myProfile">
        <img
          src={me.picture}
          className="navbar-menu__myProfile__avatar"
          alt={me.username + "'s picture"}
          title={me.username + "'s picture"}
        />
        <div>
          <div className="navbar-menu__myProfile__name">{userName(me)}</div>
          <div className="navbar-menu__myProfile__username">@{me.username}</div>
          <div className="navbar-menu__myProfile__link">
            <a href={keepQueryString(userUrl(me))} className="link">
              {i18n('navbar_menu.my_profile')}
            </a>
          </div>
        </div>
      </div>
    )
  }
  const { hasUserSession, me } = props

  return (
    <DropdownMenu renderIcon={renderIcon} alwaysKeepHidden={props.hideSubmenu} me={me}>
      {hasUserSession && (
        <>
          {myPorfile()}

          <MenuLink
            icon={<WalletIcon width="18px" color="currentColor" />}
            text={i18n('portfolio.view_wallet')}
            url={keepQueryString(walletUrl())}
            hidden={!hasUserSession}
          />
          <MenuLink
            icon={<CogIcon fillcolor="currentColor" />}
            text={i18n('navbar_menu.settings')}
            url={keepQueryString(settingsUrl())}
            hidden={!hasUserSession}
          />
        </>
      )}
      <div className="menu-item menu-item--noHover" onClick={toggleTheme}>
        <span className="menu-item__icon">
          <Lune width="24px" height="24px" fillcolor="currentColor" />
        </span>
        {localStorage.getItem('theme') === 'dark' ? 'Dark mode' : 'Light mode'}
        <div style={{ marginLeft: 'auto' }}>
          <ToggleControl active={localStorage.getItem('theme') === 'dark'} />
        </div>
      </div>
      {me && me.is_staff && (
        <div
          className="menu-item menu-item--noHover"
          onClick={() => {
            if (adminMode) {
              dispatch(getAdminMode(false))
            } else dispatch(getAdminMode('true'))
          }}>
          <span className="menu-item__icon">
            <PersonIcon width="24px" height="24px" fillcolor="currentColor" />
          </span>
          Staff mode
          <div style={{ marginLeft: 'auto' }}>
            <ToggleControl active={adminMode} />
          </div>
        </div>
      )}
      <MenuLink
        icon={<FutuurIcon width="10px" fillcolor="currentColor" />}
        text={i18n('footer.how_works')}
        url={keepQueryString(howItWorksUrl())}
      />
      <MenuLink
        icon={<EmailIcon width="18px" />}
        text={i18n('footer.contact')}
        url={keepQueryString(feedbackUrl('contact'))}
      />
      <MenuLink
        icon={<PaperAirplaneIcon width="16px" />}
        text={i18n('footer.suggest')}
        url={keepQueryString(feedbackUrl('suggestion'))}
      />
      <MenuLink icon={<CommunityIcon />} target="_blank" text={i18n('footer.community')} url={communityUrl()} />
      <MenuLink
        icon={<PasswordIcon />}
        text={i18n('footer.privacy')}
        url={keepQueryString(privacyPolicyUrl())}
        target="_blank"
      />
      <MenuLink
        icon={<ParchmentIcon fillcolor="currentColor" />}
        text={i18n('footer.terms')}
        url={keepQueryString(termsUrl())}
        target="_blank"
      />

      <MenuLink
        icon={<InfoBalloonIcon fillcolor="currentColor" />}
        text={i18n('footer.help_center')}
        url={helpCenterUrl()}
      />
      {hasUserSession && (
        <MenuAction icon={<ExitIcon />} text={i18n('settings.logout')} action={handleLogout} hidden={!hasUserSession} />
      )}
    </DropdownMenu>
  )
}

export default NavbarMenu
